import Joi from "@app/utils/joi";
import { ObjectId, ReplaceIn } from "@app/utils/generics";
import { IComplexAssignment } from "../assignments/complex/helper-schemas";

export enum PublicTestSourceType {
	admin = "ADM",
	user = "USR",
}
export const PublicTestSourceTypes = [
	PublicTestSourceType.admin,
	PublicTestSourceType.user,
];

export enum PublicTestStatusType {
	notChecked = "NCH",
	seen = "SEEN",
	verified = "VRF",
}
export const PublicTestStatusTypes = [
	PublicTestStatusType.notChecked,
	PublicTestStatusType.seen,
	PublicTestStatusType.verified,
];

export enum PublicTestType {
	ordinary = "ORD",
	accumulative = "ACC",
	complex = "CMP",
	cognitive = "COG",
}
export const PublicTestTypes = [
	PublicTestType.ordinary,
	PublicTestType.accumulative,
	PublicTestType.complex,
	PublicTestType.cognitive,
];

export const PublicTestMetadataSchema = Joi.object({
	complexAssignmentInfo: Joi.object({
		targetConceptIds: Joi.array().items(Joi.objectId()).required(),
		topicId: Joi.objectId().required(),
	}).optional(),
	cognitiveAssignmentInfo: Joi.object({
		iconFullUrl: Joi.string().required(),
		iconName: Joi.string().required(),
	}).optional(),
});
export interface IPublicTestMetadata {
	complexAssignmentInfo?: Pick<
		IComplexAssignment,
		"targetConceptIds" | "topicId"
	>;
	cognitiveAssignmentInfo?: {
		iconFullUrl: string;
		iconName: string;
	};
}
export const PublicTestSchema = Joi.object({
	id: Joi.number().integer().required(),
	originalCourseId: Joi.string().required(),
	courseId: Joi.string().allow(null).required(),
	folderId: Joi.string().allow(null).required(),
	feedbackId: Joi.string().allow(null).required(),
	testId: Joi.string().required(),
	name: Joi.string().required(),
	author: Joi.number().integer().required(),
	source: Joi.string()
		.valid(...PublicTestSourceTypes)
		.required(),
	status: Joi.string()
		.valid(...PublicTestStatusTypes)
		.required(),
	questionsCount: Joi.number().integer().required(),
	isPublic: Joi.boolean().required(),
	type: Joi.string()
		.valid(...PublicTestTypes)
		.required(),
	metadata: PublicTestMetadataSchema.allow(null),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IPublicTest {
	id: number;
	originalCourseId: string;
	courseId: string | null;
	folderId: string | null;
	feedbackId: string | null;
	testId: string;
	name: string;
	author: number;
	source: PublicTestSourceType;
	status: PublicTestStatusType;
	questionsCount: number;
	isPublic: boolean;
	type: PublicTestType;
	metadata?: IPublicTestMetadata | null;
	createdAt: Date;
	updatedAt: Date;
}

export const RPublicTestSchema = PublicTestSchema.keys({
	originalCourseId: Joi.objectId().required(),
	courseId: Joi.objectId().allow(null).required(),
	folderId: Joi.objectId().allow(null).required(),
	testId: Joi.objectId().required(),
	feedbackId: Joi.objectId().allow(null).required(),
});
export type IRPublicTest = ReplaceIn<
	IPublicTest,
	{
		originalCourseId: ObjectId;
		courseId: ObjectId | null;
		folderId: ObjectId | null;
		testId: ObjectId;
		feedbackId: ObjectId | null;
	}
>;
