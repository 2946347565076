import Joi from "@app/utils/joi";
import {
	FileSchema,
	FileWithFullContentSchema,
	IFile,
	IFileWithFullContent,
} from "./helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	markKeysOptional,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateFileSchema = FileSchema.keys(insertStripKeys);

export type IAPOSTCreateFile = ToInsertKeys<IFile>;

export const RPOSTCreateFileSchema = FileSchema;
export type IRPOSTCreateFile = IFile;

///

export const APUTFileSchema = FileSchema.fork("items", markKeysOptional).keys(
	updateStripKeys
);

export type IAPUTFile = ToUpdateKeys<IFile, "items">;

///

export const AGETFileSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETFile {
	_id: ObjectId;
	courseId: ObjectId;
}

export const RGETFileSchema = FileSchema;
export type IRGETFile = IFile;

///

export const ADELETEFileSchema = AGETFileSchema;
export type IADELETEFile = IAGETFile;

///

export const RGETAllFilesSchema = Joi.array().items(FileSchema).required();
export type IRGETAllFiles = IFile[];

///

export const APUTReadFileInfoSchema = Joi.object({
	fileId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
	startedReadingAt: Joi.date().required(),
	progress: Joi.number().required(),
	timeSpent: Joi.number().integer().required(),
});
export interface IAPUTReadFileInfo {
	fileId: ObjectId;
	folderId: ObjectId;
	courseId: ObjectId;
	startedReadingAt: Date;
	progress: number;
	timeSpent: number;
}

///

export const AGETFileWithFullContentSchema = Joi.object({
	_id: Joi.objectId().required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETFileWithFullContent {
	_id: ObjectId;
	courseId: ObjectId;
}

export const RGETFileWithFullContentSchema = FileWithFullContentSchema;
export type IRGETFileWithFullContent = IFileWithFullContent;

///

export const AGETManyFilesByIdsSchema = Joi.object({
	fileIds: Joi.array().items(Joi.objectId().required()).required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETManyFilesByIds {
	fileIds: ObjectId[];
	courseId: ObjectId;
}

export const RGETManyFilesByIdsSchema = RGETAllFilesSchema.required();
export type IRGETManyFilesByIds = IRGETAllFiles;
