import {
	ISummonsWithIsInAudience,
	SummonsWithIsInAudienceSchema,
} from "@app/api/summonses/helper-schemas";
import { store } from "index";
import {
	filterByLoadTime,
	getDefaultReducer,
	getDefaultStorageSettings,
	listenToLocalStorageChange,
	loadFromStorage,
} from "m-model-common";
import { createCRUDActionTypes, createModel, RawInstances } from "m-model-core";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { MIN_LOAD_TIME } from "./constants";
import { QueryMetaInfo } from "./utils/query-meta";
import { IAGETMySummonses } from "@app/api/summonses/validators";
import { uniquelize } from "@app/utils/array";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = ISummonsWithIsInAudience;
export type IStateSummonss = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(SummonsWithIsInAudienceSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("summons");
const storageSettings = getDefaultStorageSettings("summonses");
const metaInformationName = "summonsesMetaInformation";

const MAX_LOAD_TIME_DIFF = 5 * 60 * 60 * 1000;
const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().summonses) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"ObjectId",
				SummonsWithIsInAudienceSchema
			),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Summons extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static getMySummonses(query: IAGETMySummonses, userId: number) {
		const summonses: Summons[] = [];
		if (query.includeCreatedByMe) {
			summonses.push(...this.findManySync({ author: userId }));
		}
		if (query.includeIfIAmInAudience) {
			summonses.push(...this.findManySync({ isInAudience: true }));
		}
		return uniquelize(summonses, (summons) => summons._id);
	}

	static meta = new QueryMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Summons.meta);

export const summonsesReducer = getDefaultReducer(
	storageSettings,
	() => Summons
);
