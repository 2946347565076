import {
	ClassroomJoinRequestSchema,
	IClassroomJoinRequest,
	RequesteeInfoType,
} from "@app/api/classrooms/join-requests/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";
import { ObjectId } from "@app/utils/generics";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IClassroomJoinRequest;
export type IStateClassroomJoinRequests = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(ClassroomJoinRequestSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("classroomJoinRequest");
const storageSettings = getDefaultStorageSettings("classroomJoinRequests");
const metaInformationName = "classroomJoinRequestsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().classroomJoinRequests) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"ObjectId",
				ClassroomJoinRequestSchema
			),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class ClassroomJoinRequest extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static getUserRequestsSync(
		user: {
			id: number;
			mobile: string | null;
		},
		excludeUserAsRequester?: boolean
	): ClassroomJoinRequest[] {
		const docs = this.getAllSync();
		return docs.filter((e) => {
			if (
				(e.requesteeInfo.type === RequesteeInfoType.id &&
					e.requesteeInfo.id === user.id) ||
				(e.requesteeInfo.type === RequesteeInfoType.mobile &&
					e.requesteeInfo.mobile === user.mobile)
			) {
				return true;
			}
			if (excludeUserAsRequester) return false;
			return e.requester.id === user.id;
		});
	}

	static deleteClassroomRequests(classroomId: ObjectId) {
		this.deleteManySync({ classroomId });
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(
	storage,
	metaInformationName,
	ClassroomJoinRequest.meta
);

export const classroomJoinRequestsReducer = getDefaultReducer(
	storageSettings,
	() => ClassroomJoinRequest
);
