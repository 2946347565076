import { createRouteList } from "../config";
import UserRoute from "@app/routes/containers/user-route";
import { UserType } from "@app/api/helper-schemas";
import { headmasterLinks } from "./link";
import { defaultComponentWrapper } from "../wrapper";
import { CommonRouteProps, defaultRouteMapper } from "../route-props-map-utils";
// import { StudentListing } from "@app/components/teachers/student-listing";

const routes = createRouteList<CommonRouteProps>({
	exact: true,
	RouteComponent: UserRoute,
	routeProps: {
		dontRedirectIfNotAuthenticated: true,
		availableUserTypes: UserType.headmaster,
	},
	componentWrapper: defaultComponentWrapper,
	routeMapFn: defaultRouteMapper,
});

///

const TeacherGroupTimetablePage = () =>
	import("@app/components/teachers/timetables/groups").then((module) => ({
		default: module.TeacherGroupTimetablePage,
	}));

routes.add({
	path: headmasterLinks.groups.timetable.routePath,
	component: TeacherGroupTimetablePage,
	showSidebar: true,
});

///

const ClassroomTimetablePage = () =>
	import("@app/components/teachers/timetables/personal").then((module) => ({
		default: module.ClassroomTimetablePage,
	}));

routes.add({
	path: headmasterLinks.classrooms.timetable.routePath,
	component: ClassroomTimetablePage,
	showSidebar: true,
});

///

const HeadmasterGroupTimetable = () =>
	import("@app/components/headmaster/timetable").then((module) => ({
		default: module.HeadmasterGroupTimetable,
	}));

routes.add({
	path: headmasterLinks.timetable.routePath,
	component: HeadmasterGroupTimetable,
});

///

const GroupGradebook = () =>
	import("@app/components/teachers/gradebook/group").then((module) => ({
		default: module.GroupGradebook,
	}));

routes.add({
	path: headmasterLinks.groups.gradebook.routePath,
	component: GroupGradebook,
	showSidebar: true,
});

///

const GroupSemesterbook = () =>
	import("@app/components/teachers/semesterbook/viewer-types/index").then(
		(module) => ({
			default: module.SemesterbookAsGroup,
		})
	);

routes.add({
	path: headmasterLinks.groups.semesterbook.routePath,
	component: GroupSemesterbook,
	showSidebar: true,
});

///

const GroupStudentList = () =>
	import("@app/components/teachers/student-listing/group").then((module) => ({
		default: module.GroupStudentList,
	}));

routes.add({
	path: headmasterLinks.groups.listOfStudents.routePath,
	component: GroupStudentList,
	showSidebar: true,
});

///

const ClassroomStudentList = () =>
	import("@app/components/teachers/student-listing").then((module) => ({
		default: module.StudentListing,
	}));

routes.add({
	path: headmasterLinks.classrooms.listOfStudents.routePath,
	component: ClassroomStudentList,
	showSidebar: true,
});

///

const GroupAssignments = () =>
	import("@app/components/teachers/assignments/index").then((module) => ({
		default: module.GroupAssignments,
	}));

routes.add({
	path: headmasterLinks.groups.assignments.routePath,
	component: GroupAssignments,
	showSidebar: true,
});

///

const ClassroomAssignments = () =>
	import("@app/components/teachers/assignments/index").then((module) => ({
		default: module.ClassroomAssignments,
	}));

routes.add({
	path: headmasterLinks.classrooms.assignments.routePath,
	component: ClassroomAssignments,
	showSidebar: true,
});

///

const CreateClassroom = () =>
	import("@app/components/teachers/createClassroom").then((module) => ({
		default: module.CreateClassroom,
	}));

routes.add({
	path: headmasterLinks.classrooms.create.routePath,
	component: CreateClassroom,
	headerTheme: "dark",
});

routes.add({
	path: headmasterLinks.classrooms.edit.routePath,
	component: CreateClassroom,
	headerTheme: "dark",
});
///

const SchoolUsersList = () =>
	import("@app/components/headmaster/users-list").then((module) => ({
		default: module.SchoolUsersList,
	}));

routes.add({
	path: headmasterLinks.users.list.routePath,
	component: SchoolUsersList,
});

///

const HeadmasterAddTeacher = () =>
	import("@app/components/headmaster/teachers/index").then((module) => ({
		default: module.HeadmasterAddTeacherPage,
	}));

routes.add({
	path: headmasterLinks.users.teachers.add.routePath,
	component: HeadmasterAddTeacher,
});

///

const ArchivedGroupsContainerForHeadmaster = () =>
	import("../../components/teachers/main/tvschool/classrooms").then(
		(module) => ({
			default: module.HeadmastersClassroomsArchivePage,
		})
	);

routes.add({
	path: headmasterLinks.archived.classrooms.routePath,
	component: ArchivedGroupsContainerForHeadmaster,
});

export default routes;
