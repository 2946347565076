import {
	ClassroomEnrollmentCodeSchema,
	IClassroomEnrollmentCode,
} from "./helper-schemas";
import Joi from "@app/utils/joi";
import {
	UserShortInfoSchema,
	IUserShortInfo,
} from "@app/api/users/helper-schemas";

export const RPOSTClassroomEnrollmentCodeSchema = ClassroomEnrollmentCodeSchema;
export type IRPOSTClassroomEnrollmentCode = IClassroomEnrollmentCode;

export const APUTConnectToClassroomEnrollmentCodesSchema = Joi.object({
	codes: Joi.array().items(Joi.string().required()).required(),
	shouldFulfill: Joi.boolean().required(),
});
export interface IAPUTConnectToClassroomEnrollmentCodes {
	codes: string[];
	shouldFulfill: boolean;
}

export type IRPUTConnectToClassroomEnrollmentCodes = void;

///

export const AGETTeacherInfoUsingCodeSchema = Joi.object({
	code: Joi.string().required(),
});
export interface IAGETTeacherInfoUsingCode {
	code: string;
}

export const RGETTeacherInfoUsingCodeSchema = UserShortInfoSchema;
export type IRGETTeacherInfoUsingCode = IUserShortInfo;

///

export const APOSTEnrollClassroomBySlugSchema = Joi.object({
	slug: Joi.string().required(),
});
export interface IAPOSTEnrollClassroomBySlug {
	slug: string;
}
