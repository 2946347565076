import Joi from "@app/utils/joi";
import {
	GradeCategorySchema,
	IGradeCategory,
	GradeSchema,
	IGrade,
	GradeEdgeSchema,
	IGradeEdge,
} from "./helper-schemas";
import {
	updateStripKeys,
	markKeysOptional,
	ToUpdateKeys,
	ToInsertKeys,
	insertStripKeys,
	markKeysStripped,
} from "../helper-schemas";

export const AGETGradesWithCategoriesSchema = Joi.object({
	country: Joi.string().empty(["", "null"]).allow(null).default(null),
	website: Joi.string().empty(["", "null"]).allow(null).default(null),
	language: Joi.string().empty(["", "null"]).allow(null).default(null),
});
export interface IAGETGradesWithCategories {
	country: string | null;
	website: string | null;
	language: string | null;
}

export const RGETGradesWithCategoriesSchema = Joi.object({
	grades: Joi.array().items(GradeSchema),
	categories: Joi.array().items(GradeCategorySchema),
	edges: Joi.array().items(GradeEdgeSchema).required(),
});
export interface IRGETGradesWithCategories {
	grades: IGrade[];
	categories: IGradeCategory[];
	edges: IGradeEdge[];
}

///

export const ADELETEGradeByIdSchema = Joi.object({
	id: Joi.number().integer().required(),
});

export interface IADELETEGradeById {
	id: number;
}

///

export const RGETRawGradesSchema = Joi.array().items(GradeSchema);
export type IRGETRawGrades = IGrade[];

///

export const RGETRawCategoriesSchema = Joi.array().items(GradeCategorySchema);
export type IRGETRawCategories = IGradeCategory[];

///

export const AGETGradeWithEdgesSchema = Joi.object({
	id: Joi.number().integer().required(),
});
export interface IAGETGradeWithEdges {
	id: number;
}

export const RGETGradeWithEdgesSchema = Joi.object({
	grade: GradeSchema.required(),
	edges: Joi.array().items(GradeEdgeSchema).required(),
});

export interface IRGETGradeWithEdges {
	grade: IGrade;
	edges: IGradeEdge[];
}

///

export const APOSTGradeSchema = Joi.object({
	grade: GradeSchema.keys(insertStripKeys).required(),
	edges: Joi.array()
		.items(
			GradeEdgeSchema.keys(insertStripKeys).fork(
				"gradeId",
				markKeysStripped
			)
		)
		.required(),
});
export interface IAPOSTGrade {
	grade: ToInsertKeys<IGrade>;
	edges: ToInsertKeys<IGradeEdge, never, "gradeId">[];
}

export const RPOSTGradeSchema = Joi.object({
	grade: GradeSchema.required(),
	edges: Joi.array().items(GradeEdgeSchema).required(),
});
export interface IRPOSTGrade {
	grade: IGrade;
	edges: IGradeEdge[];
}

///

export const APUTGradeSchema = Joi.object({
	grade: GradeSchema.keys(updateStripKeys).required(),
	edges: Joi.array()
		.items(
			GradeEdgeSchema.keys(updateStripKeys)
				.fork("id", markKeysOptional)
				.fork("gradeId", markKeysStripped)
		)
		.required(),
	deletedEdgeIds: Joi.array().items(Joi.number().integer()).required(),
});
export interface IAPUTGrade {
	grade: ToUpdateKeys<IGrade>;
	edges: ToUpdateKeys<IGradeEdge, "id", "gradeId">[];
	deletedEdgeIds: number[];
}
export const RPUTGradeSchema = Joi.object({
	grade: GradeSchema.required(),
	edges: Joi.array().items(GradeEdgeSchema).required(),
});
export interface IRPUTGrade {
	grade: IGrade;
	edges: IGradeEdge[];
}

export const APOSTGradeCategorySchema =
	GradeCategorySchema.keys(insertStripKeys);
export type IAPOSTGradeCategory = ToInsertKeys<IGradeCategory>;

export const RPOSTGradeCategorySchema = GradeCategorySchema;
export type IRPOSTGradeCategory = IGradeCategory;

export const APUTGradeCategorySchema =
	GradeCategorySchema.keys(updateStripKeys);
export type IAPUTGradeCategory = ToUpdateKeys<IGradeCategory>;

export const RPUTGradeCategorySchema = GradeCategorySchema;
export type IRPUTGradeCategory = IGradeCategory;

///

export const AGETCategoryByIdSchema = Joi.object({
	id: Joi.number().integer().required(),
});

export interface IAGETCategoryById {
	id: number;
}

export const RGETCategoryByIdSchema = GradeCategorySchema;
export type IRGETCategoryById = IGradeCategory;

///

export const ADELETECategoryByIdSchema = AGETCategoryByIdSchema;

export type IADELETECategoryById = IAGETCategoryById;

///
