import { Folder } from "@app/models/folder";
import { FolderHierarchy } from "@app/models/folder-hierarchy";
import { UserFolderLevel } from "@app/models/user-folder-level";
import { inject } from "@app/modules";
import { HierarchyToFoldersComparer } from "@app/services/hierarchy-info/folders/comparer";
import { generateFakeObjectId, objectValues } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { collmise } from "collmise";
import { createCoursedModelCollmise } from "../collmise-helpers";
import { IRequest } from "../requests";
import { IFolderSingleItem, ItemType } from "./helper-schemas";
import {
	IADELETEFolder,
	IADELETERemoveItemFromFolder,
	IAGETAllSubFoldersProgresses,
	IAGETFolder,
	IAGETFolderHierarchy,
	IAGETFolderItemsRecursively,
	IAGETFolderLevels,
	IAGETFoldersByIds,
	IAGETFoldersByItem,
	IAPOSTAddItemInFolder,
	IAPOSTCopyFolderItems,
	IAPOSTCreateFolder,
	IAPUTEditItemInFolder,
	IAPUTFolder,
	IAPUTMoveFolder,
	IAPUTReorderItems,
	IAPUTResetItemProgress,
	IAPUTResetProgress,
	IRGETAllSubFoldersProgresses,
	IRGETFolderHierarchy,
	IRGETFolderItemsRecursively,
	IRGETFolderLevels,
	IRGETFoldersByIds,
	IRGETFoldersByItem,
	IRPOSTCreateFolder,
	IRPUTMoveFolder,
	RGETFolderHierarchySchema,
	RGETFolderLevelsSchema,
	RGETFoldersByIdsSchema,
	RGETFoldersByItemSchema,
	RGETFolderSchema,
	RPOSTCreateFolderSchema,
} from "./validators";

export class FoldersController {
	private readonly Request: IRequest;

	private readonly _FolderModel = inject("FolderModel");
	private readonly _FolderHierarchyModel = inject("FolderHierarchyModel");
	private readonly _FolderHierarchyService = inject("FolderHierarchyService");
	private readonly _UserFolderProgressModel = inject(
		"UserFolderProgressModel"
	);
	private readonly _FolderItemsService = inject("FolderItemsService");
	private readonly _TestModel = inject("TestModel");
	private readonly _CardModel = inject("CardModel");
	private readonly _FileModel = inject("FileModel");
	private readonly _UserFolderLevelModel = inject("UserFolderLevelModel");
	private readonly _UserFolderProgressResetService = inject(
		"UserFolderProgressResetService"
	);
	private readonly assertAndGetCoursesUser = inject(
		"assertAndGetCoursesUser"
	);

	private folderCollmise = createCoursedModelCollmise({
		model: this._FolderModel,
		name: "Folder",
		multiId: "folderIds",
		getMany: (query: IAGETFoldersByIds) =>
			this.Request.send<IRGETFoldersByIds>(
				"POST",
				"/api/folders/get-may-by-ids",
				query,
				null,
				{ responseSchema: RGETFoldersByIdsSchema }
			),
	});
	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateFolder): Promise<Folder> =>
		this.Request.send("POST", "/api/folders", args, null, {
			responseSchema: RPOSTCreateFolderSchema,
		}).then((data: IRPOSTCreateFolder) => {
			const folder = this._FolderModel.loadOneSync(data); // folder must be loaded first
			if (args.parentId) {
				try {
					this._FolderItemsService.addItemInParentSync({
						parentFolderId: args.parentId,
						item: {
							id: data._id,
							name: data.name,
							type: ItemType.folder,
						},
						courseId: args.originalCourseId,
					});
				} catch (e) {}
			}
			return folder;
		});

	update = async (args: IAPUTFolder): Promise<Folder | null> =>
		this.Request.send("PUT", "/api/folders/:_id", args).then(() => {
			try {
				this._FolderItemsService.updateItemSync({
					courseId: args.courseId,
					item: {
						id: args._id,
						type: ItemType.folder,
						name: args.name,
					},
				});
			} catch (e) {}
			return this._FolderModel.updateOneSync({ _id: args._id }, args);
		});

	getById = async (
		args: IAGETFolder,
		loadFresh?: boolean
	): Promise<Folder> => {
		return this.folderCollmise
			.on(args)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/folders/:_id", args, null, {
					responseSchema: RGETFolderSchema,
				})
			);
	};

	getManyByIds = async (
		args: IAGETFoldersByIds,
		loadFresh?: boolean
	): Promise<Folder[]> => {
		return this.folderCollmise.collectors
			.many(args)
			.fresh(loadFresh)
			.request();
	};

	deleteById = async (args: IADELETEFolder): Promise<void> =>
		this.Request.send("DELETE", "/api/folders/:_id", args).then(() => {
			try {
				this._FolderItemsService.deleteItemSync({
					itemId: args._id,
					type: ItemType.folder,
					courseId: args.courseId,
				});
			} catch (e) {}
			this._FolderModel.deleteByIdSync(args._id);
		});

	reorderItems = async (args: IAPUTReorderItems): Promise<Folder | null> =>
		this.Request.send("PUT", "/api/folders/:_id/reorder", args).then(() => {
			const folder = this._FolderModel.findByIdSync(args._id);
			if (!folder) return null;

			const newArray = [...(folder.items || [])];
			const removed = newArray[args.sourceIndex];
			if (!removed) {
				return folder;
			}
			newArray.splice(args.sourceIndex, 1);
			newArray.splice(args.destinationIndex, 0, removed);
			folder.items = newArray;
			folder.saveSync();
			return folder;
		});

	getItems = async (
		args: IAGETFolderItemsRecursively,
		loadFresh?: boolean
	): Promise<IRGETFolderItemsRecursively> => {
		if (
			!loadFresh &&
			args.itemTypes !== undefined &&
			args.itemTypes.length === 1 &&
			args.itemTypes[0] === ItemType.folder // TODO: enhance logic of checking whether all required info are in front
		) {
			const folderHierarchy =
				this._FolderHierarchyModel.findOneByCourseSync(args.courseId);
			if (folderHierarchy) {
				const folderIds =
					this._FolderHierarchyService.getDescendantIdsSync(
						args.courseId,
						args.folderId,
						args.depth
					);
				const folders = this._FolderModel.findManyByIdsSync(folderIds);
				if (folderIds.length === folders.length) {
					return this._FolderItemsService.searchForAllItemsRecursivelySync(
						args
					);
				}
			}
		}
		return this.Request.send(
			"GET",
			"/api/folders/:folderId/items",
			args
		).then((data: IRGETFolderItemsRecursively) => {
			if (data.folders) {
				const folders = objectValues(data.folders);
				this._FolderModel.loadManySync(folders);
			}
			if (data.tests) {
				const tests = objectValues(data.tests);
				this._TestModel.loadManySync(tests);
			}
			if (data.cards) {
				const cards = objectValues(data.cards);
				this._CardModel.loadManySync(cards);
			}
			if (data.files) {
				const files = objectValues(data.files);
				console.log(files);
				this._FileModel.loadManySync(files);
			}
			return data;
		});
	};

	private userFolderLevelCollmise = collmise();

	getLevels = async (
		args: IAGETFolderLevels,
		loadFresh?: boolean,
		forceLoadingIfNeedsRecalculation?: boolean
	): Promise<UserFolderLevel> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		if (!loadFresh) {
			const levelsDoc = this._UserFolderLevelModel.findOneSync({
				userId,
				courseId: args.courseId,
			});
			if (levelsDoc) {
				if (!levelsDoc.needsRecalculation) return levelsDoc;
				if (forceLoadingIfNeedsRecalculation) {
					return this.getLevels(args, true);
				}
				this.getLevels(args, true).then();
				return levelsDoc;
			}
		}
		return this.userFolderLevelCollmise
			.on({ userId, courseId: args.courseId })
			.requestAs(() =>
				this.Request.send(
					"GET",
					"/api/folders/levels",
					args,
					undefined,
					{
						responseSchema: RGETFolderLevelsSchema,
					}
				)
					.then((data: IRGETFolderLevels) => {
						this._UserFolderLevelModel.deleteOneSync({
							userId,
							courseId: args.courseId,
						});
						return this._UserFolderLevelModel.loadOneSync(data);
					})
					.catch((e) => {
						if (!e.response || e.response.status !== 404) {
							throw e;
						}
						this._UserFolderLevelModel.deleteOneSync({
							userId,
							courseId: args.courseId,
						});
						const emptyData: IRGETFolderLevels = {
							_id: generateFakeObjectId(),
							userId,
							courseId: args.courseId,
							levelsByFolderId: {},
						};
						return this._UserFolderLevelModel.loadOneSync(
							emptyData
						);
					})
			);
	};

	subFolderCollmise = collmise<IAGETAllSubFoldersProgresses>();

	getSubFoldersProgress = async (
		args: IAGETAllSubFoldersProgresses
	): Promise<IRGETAllSubFoldersProgresses> => {
		const user = this.assertAndGetCoursesUser();
		const coursesUserId = user.id;
		return this.subFolderCollmise.on(args).requestAs(() =>
			this.Request.send(
				"GET",
				"/api/folders/:folderId/all-folders-progress",
				args
			).then((data: IRGETAllSubFoldersProgresses) => {
				let descendants: ObjectId[] = [];
				const foundFolderIds = new Set(Object.keys(data));
				try {
					descendants =
						this._FolderHierarchyService.getDescendantIdsSync(
							args.courseId,
							args.folderId,
							args.depth
						);
				} catch (e) {
					//
				}
				descendants = [...descendants, args.folderId];
				this._UserFolderProgressModel.meta.markMany({
					courseId: args.courseId,
					coursesUserId,
					notFoundFolderIds: descendants.filter(
						(folderId) => !foundFolderIds.has(folderId)
					),
				});
				this._UserFolderProgressModel.loadManySync(objectValues(data));
				return data;
			})
		);
	};

	moveItemToAnotherFolder = async (
		args: IAPUTMoveFolder
	): Promise<IRPUTMoveFolder> => {
		const user = this.assertAndGetCoursesUser();
		return this.Request.send(
			"PUT",
			"/api/folders/:currentFolderId/move",
			args
		).then((data: IRPUTMoveFolder) => {
			this._FolderItemsService.moveToAnotherFolderSync(args, user);
			return data;
		});
	};

	addItemInFolder = async (args: IAPOSTAddItemInFolder): Promise<void> =>
		this.Request.send("POST", "/api/folders/:folderId/items", args).then(
			() => {
				this._FolderItemsService.addItemInParentSync({
					courseId: args.courseId,
					item: args.item as IFolderSingleItem,
					parentFolderId: args.folderId,
				});
			}
		);

	updateItemInFolder = async (args: IAPUTEditItemInFolder): Promise<void> =>
		this.Request.send("PUT", "/api/folders/:folderId/items", args).then(
			() => {
				this._FolderModel.updateItemInParentSync(
					args.folderId,
					args.item as IFolderSingleItem
				);
			}
		);

	removeItemFromFolder = async (
		args: IADELETERemoveItemFromFolder
	): Promise<void> =>
		this.Request.send("DELETE", "/api/folders/:folderId/items", args).then(
			() => {
				this._FolderItemsService.deleteItemSync({
					deleteOnlyInParentFolder: true,
					itemId: args.itemId,
					parentFolderId: args.folderId,
					type: args.itemType,
					courseId: args.courseId,
				});
				if (args.itemType === ItemType.test) {
					this._TestModel.deleteByIdSync(args.itemId);
				}
			}
		);

	resetProgress = async (args: IAPUTResetProgress): Promise<void> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		return this.Request.send(
			"PUT",
			"/api/folders/reset-progress",
			args
		).then(() => {
			this._UserFolderProgressResetService.resetProgressManySync(
				args.courseId,
				args.folderIds,
				userId
			);
		});
	};

	resetItemProgress = async (args: IAPUTResetItemProgress): Promise<void> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		return this.Request.send(
			"PUT",
			"/api/folders/reset-item-progress",
			args
		).then(() => {
			this._UserFolderProgressResetService.resetProgressOneSync(
				args,
				userId
			);
		});
	};

	copyFolderItems = async (args: IAPOSTCopyFolderItems): Promise<void> => {
		return this.Request.send("POST", "/api/folders/copy-items", args);
	};

	getByItem = async (args: IAGETFoldersByItem): Promise<IRGETFoldersByItem> =>
		this.Request.send("GET", "/api/folders/by-item", args, null, {
			responseSchema: RGETFoldersByItemSchema,
		});

	hierarchyCollmise = collmise({
		findCachedData: (courseId: ObjectId) =>
			this._FolderHierarchyModel.findOneByCourseSync(courseId),
		dataTransformer: (data: IRGETFolderHierarchy) =>
			this._FolderHierarchyModel.loadOneSync(data),
	});
	getHierarchy = async (
		args: IAGETFolderHierarchy,
		loadFresh?: boolean
	): Promise<FolderHierarchy> => {
		return this.hierarchyCollmise
			.on(args.courseId)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/folders/hierarchy", args, null, {
					responseSchema: RGETFolderHierarchySchema,
				}).then((data: IRGETFolderHierarchy) => {
					const areFoldersWithSync =
						HierarchyToFoldersComparer.areFoldersWithSync({
							hierarchy: data,
							folders: this._FolderModel.getAllSync(),
						});
					if (!areFoldersWithSync) {
						// TODO: start loading folders instead of clearing up
						this._FolderModel.clearAllSync();
					}
					return data;
				})
			);
	};

	loadAllSubFolders = async (
		{
			folderId,
			courseId,
			depth,
		}: {
			folderId: ObjectId;
			courseId: ObjectId;
			depth?: number;
		},
		loadFresh?: boolean
	): Promise<Folder[]> => {
		if (!loadFresh) {
			const { folders, fullyLoaded } =
				this._FolderModel.loadAllSubFoldersSync(folderId, depth);
			if (fullyLoaded) return folders;
		}
		return this.getItems(
			{
				folderId,
				courseId,
				itemTypes: [ItemType.folder],
				depth,
			},
			true
		).then((data) => {
			const folders = objectValues(data.folders!);
			return this._FolderModel.loadManySync(folders);
		});
	};
}
