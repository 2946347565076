export function getIntersectionOfSets<T extends string | number>(
	set1: Set<T>,
	set2: Set<T>
) {
	const intersect = new Set<T>();
	for (const x of set1) {
		if (set2.has(x)) intersect.add(x);
	}
	return intersect;
}

export function intersectWith<T extends string | number>(
	this: Set<T>,
	set2: Set<T>
) {
	for (const x of this) {
		if (!set2.has(x)) this.delete(x);
	}
	return this;
}

export function getUnionOfSets<T extends string | number>(
	set1: Iterable<T>,
	set2: Iterable<T>
) {
	const union = new Set<T>(set1);
	for (const x of set2) {
		union.add(x);
	}
	return union;
}

export function unionWith<T extends string | number>(
	this: Set<T>,
	set2: Iterable<T>
) {
	for (const x of set2) {
		this.add(x);
	}
	return this;
}

export function subtractSet<T extends string | number>(
	this: Set<T>,
	set2: Iterable<T>
) {
	for (const x of set2) {
		this.delete(x);
	}
	return this;
}

export function isSubsetOf<
	T extends string | number | boolean | null | undefined,
>(this: Iterable<T>, set2: ReadonlySet<T>) {
	for (const x of this) {
		if (!set2.has(x)) return false;
	}
	return true;
}

export function hasCommonWith<T extends string | number>(
	this: ReadonlySet<T>,
	set2: ReadonlySet<T>
) {
	for (const x of this) {
		if (set2.has(x)) return true;
	}
	return false;
}

export const flattenSet = <
	T extends ReadonlySet<unknown> | ReadonlyArray<unknown> = any,
>(
	arr: T
): T extends ReadonlySet<infer R>
	? R
	: T extends ReadonlyArray<infer R>
		? R
		: never => {
	const flattened = new Set<any>();
	for (const item of arr) {
		if (item instanceof Set || Array.isArray(item)) {
			const items = flattenSet(item);
			unionWith.call(flattened, items);
		} else {
			flattened.add(item);
		}
	}
	return flattened as any;
};
