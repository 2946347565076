import { useAddElementFn } from "@app/components/playground";
import { VideoInstruction } from "@app/components/tv-school-landing/video-instruction";
import { CallsPopup } from "@app/components/widgets/calls";
import { useGoToUrl } from "@app/hooks/front";
import { useLogoutFn } from "@app/hooks/users";
import { IAnyObj } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import { CreateCusto } from "custo";
import React, { useMemo } from "react";
import { HeaderProviders, HeaderTree } from "../../layouts/header/tree";
import { TitleNextToLogo } from "./title-next-to-logo";

export const TeacherHeader: React.FC<IAnyObj> = (props) => {
	return (
		<HeaderProviders.PartialMergingProvider
			value={{
				components: {
					NextToLogo: TitleNextToLogo,
				},
				elements: {
					ProfileImg: CreateCusto.Component("img", {
						style: {
							margin: "3px 0 0 -1px",
						},
					}),
				},
				data: {
					profileImg: "/imgs/TeacherAvatar.svg",
					menuOptions: CreateCusto.functionOf.Data(
						useTvschoolTeacherHeaderOptions
					),
				},
			}}
		>
			<HeaderTree.components.OuterContainer />
		</HeaderProviders.PartialMergingProvider>
	);
};

enum TvschoolUserOption {
	profile,
	logout,
	videoInstruction,
	faq,
	calls,
	assignments,
}

const useTvschoolTeacherHeaderOptions = () => {
	const goto = useGoToUrl((str: string) => str);

	const addElement = useAddElementFn();

	const onLogout = useLogoutFn();

	return useMemo(
		(): {
			value: TvschoolUserOption;
			label: JSX.Element | string;
			onSelect: () => void;
		}[] => [
			{
				value: TvschoolUserOption.calls,
				label: "ცხელი ხაზი",
				onSelect: () =>
					addElement(({ onClose }) => (
						<CallsPopup onClose={onClose} />
					)),
			},
			{
				value: TvschoolUserOption.videoInstruction,
				label: getFormattedMessage("instruction"),
				onSelect: () =>
					addElement(({ onClose }) => (
						<VideoInstruction onClose={onClose} />
					)),
			},
			{
				value: TvschoolUserOption.faq,
				label: getFormattedMessage("faq"),
				onSelect: () => goto("/faq"),
			},
			{
				value: TvschoolUserOption.profile,
				label: getFormattedMessage("profile"),
				onSelect: () => goto("/profile"),
			},
			{
				value: TvschoolUserOption.logout,
				label: getFormattedMessage("logout"),
				onSelect: onLogout,
			},
		],
		[addElement, goto, onLogout]
	);
};
