import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles/blog.module.css";
import { FormattedMessage } from "react-intl";
import { useHistory, match } from "react-router-dom";
import { inject } from "@app/modules";
import { Blog } from "@app/models/blog";
import { LittleBlogBox, ILittleBlogBoxProps } from "./little-blog";
import { ObjectId } from "@app/utils/generics";

export const Blogs: React.FC<{
	blog: ILittleBlogBoxProps;
	match: match<{ [key: string]: string | undefined }>;
}> = (props) => {
	const [blogs, setBlogs] = useState<Blog[]>();
	useEffect(() => {
		const BlogController = inject("BlogController");
		BlogController.getAllBlogs().then((blogs) => setBlogs(blogs));
	}, []);

	const history = useHistory();
	const redirectToCreateBlogPage = useCallback(() => {
		history.push("/create-blog");
	}, [history]);

	const isAdmin = true;

	const redirectToBlog = (blogId: ObjectId) => {
		history.push(`/blogs/${blogId}`);
	};

	return (
		<div className={styles.blogMainPage}>
			<div className={styles.blogs}>
				{blogs &&
					blogs.map((blog, index) => (
						<LittleBlogBox
							key={blog._id}
							blog={{
								title: blog.title,
								photoAddress: blog.photoAddress,
								date: blog.createdAt,
								id: blog._id,
							}}
							IsAdmin={isAdmin}
							onViewBlog={(e) => redirectToBlog(blog._id)}
							isUploadPhotoButtonVissible={false}
						/>
					))}
			</div>
			{isAdmin && (
				<button
					onClick={redirectToCreateBlogPage}
					className={styles.addNewBlogButton}
				>
					<FormattedMessage id="addNewBlog" />
				</button>
			)}
		</div>
	);
};
