import Joi from "@app/utils/joi";

export const SchoolSchema = Joi.object({
	id: Joi.number().integer().required(),
	name: Joi.string().required(),
	city_id: Joi.number().integer().required(),
	is_other: Joi.boolean(),
});
export interface ISchool {
	id: number;
	name: string;
	city_id: number;
	is_other?: boolean;
}
