import Joi from "../../../utils/joi";

export enum PropertyType {
	Number = 0,
	Text = 1,
	TextArray = 2,
	HistDate = 3,
}

export const PropertyTypeKeys = [
	PropertyType.Number,
	PropertyType.Text,
	PropertyType.TextArray,
	PropertyType.HistDate,
];

export enum HalfDate {
	first = 0,
	second = 1,
}

export const HalfDateKeys = [HalfDate.first, HalfDate.second];

export const MillionDateSchema = Joi.object({
	million: Joi.number().required(),
});
export interface IMillionDate {
	million: number;
}

export const MilleniumDateSchema = Joi.object({
	millenium: Joi.number().integer().required(),
	half: Joi.number().valid(...HalfDateKeys),
});
export interface IMilleniumDate {
	millenium: number;
	half?: HalfDate;
}

export const CenturyDateSchema = Joi.object({
	century: Joi.number().integer().required(),
	half: Joi.number().valid(...HalfDateKeys),
	decade: Joi.number().min(0).max(9),
});
export interface ICenturyDate {
	century: number;
	half?: HalfDate;
	decade?: number;
}

export const YearDateSchema = Joi.object({
	year: Joi.number().integer().required(),
	month: Joi.number().integer().min(0).max(11),
	day: Joi.number().integer().min(1).max(31),
});
export interface IYearDate {
	year: number;
	month?: number;
	day?: number;
}

export const DatePropertySchema = Joi.alternatives([
	MilleniumDateSchema,
	MillionDateSchema,
	CenturyDateSchema,
	YearDateSchema,
]);
export type IDateProperty =
	| IMilleniumDate
	| IMillionDate
	| ICenturyDate
	| IYearDate;

export const CardTemplatePropertySchema = Joi.object({
	id: Joi.number().required(),
	name: Joi.string().required(),
	isPrimary: Joi.boolean().optional(),
	type: Joi.number()
		.valid(...PropertyTypeKeys)
		.required(),
	templateId: Joi.number().integer(),
});

export interface ICardTemplateProperty {
	id: number;
	name: string;
	type: PropertyType;
	isPrimary?: boolean;
	templateId?: number;
}

export const LabelSchema = Joi.object({
	id: Joi.number().required(),
	name: Joi.string().required(),
});
export interface ILabel {
	id: number;
	name: string;
}

export const CardTemplateSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	properties: Joi.array()
		.items(CardTemplatePropertySchema.required())
		.required(),
	labels: Joi.array().items(LabelSchema).required(),
	authorId: Joi.number().integer().required(),
});

export interface ICardTemplate {
	_id: string;
	name: string;
	properties: ICardTemplateProperty[];
	labels: ILabel[];
	authorId: number;
}
