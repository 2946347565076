import Joi from "@app/utils/joi";
import { UserSpecialTestSchema, IUserSpecialTest } from "./helper-schemas";
import { ObjectId } from "@tests-core/utils/joi";
import { markKeysStripped, markKeysOptional } from "../helper-schemas";
import { OptionalKeys } from "@app/utils/generics";

export const AGETUserDocsByGroupSchema = Joi.object({
	testGroup: Joi.string().required(),
});
export interface IAGETUserDocsByGroup {
	testGroup: string;
}

export const RGETUserDocsByGroupSchema = Joi.array().items(
	UserSpecialTestSchema
);
export type IRGETUserDocsByGroup = {
	_id: ObjectId;
	userId: number;
	testId: number;
	answers: ({
		qId: number;
		answerId: number;
		answeredAt: Date;
	} & {})[];
	isFinished: boolean;
	createdAt: Date;
	updatedAt: Date;
	testGroup?: string;
	results?: any;
}[];

///

export const AGETUserDocByTestSchema = Joi.object({
	testId: Joi.number().integer().required(),
});
export interface IAGETUserDocByTest {
	testId: number;
}
export const RGETUserDocByTestSchema = Joi.alternatives([
	Joi.object({
		hasFound: Joi.boolean().valid(true).required(),
		doc: UserSpecialTestSchema.required(),
	}),
	Joi.object({
		hasFound: Joi.boolean().valid(false).required(),
	}),
]);
export type IRGETUserDocByTest =
	| {
			hasFound: true;
			doc: IUserSpecialTest;
	  }
	| {
			hasFound: false;
	  };

///

export const APOSTSaveUsertestSchema = UserSpecialTestSchema.fork(
	["createdAt", "updatedAt"],
	markKeysStripped
).fork("_id", markKeysOptional);

export type IAPOSTSaveUsertest = OptionalKeys<
	Omit<IUserSpecialTest, "createdAt" | "updatedAt">,
	"_id"
>;
