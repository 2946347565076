import {
	insertStripKeys,
	ToInsertKeys,
	ToUpdateKeys,
	updateStripKeys,
} from "@app/api/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import {
	StudentGradeCategorySchema,
	IStudentGradeCategory,
} from "./helper-schemas";

export const APOSTClassroomStudentGradeCategorySchema =
	StudentGradeCategorySchema.keys(insertStripKeys);
export type IAPOSTClassroomStudentGradeCategory =
	ToInsertKeys<IStudentGradeCategory>;

export const RPOSTClassroomStudentGradeCategorySchema =
	StudentGradeCategorySchema;
export type IRPOSTClassroomStudentGradeCategory = IStudentGradeCategory;

///

export const APUTClassroomStudentGradeCategorySchema =
	StudentGradeCategorySchema.keys(updateStripKeys);
export type IAPUTClassroomStudentGradeCategory =
	ToUpdateKeys<IStudentGradeCategory>;

export const RPUTClassroomStudentGradeCategorySchema =
	StudentGradeCategorySchema;
export type IRPUTClassroomStudentGradeCategory = IStudentGradeCategory;

///

export const AGETStudentGradeCategoryByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETStudentGradeCategoryById {
	_id: ObjectId;
}

export const RGETStudentGradeCategoryByIdSchema = StudentGradeCategorySchema;
export type IRGETStudentGradeCategoryById = IStudentGradeCategory;

///

export const AGETManyStudentGradeCategoriesByIdsSchema = Joi.object({
	ids: Joi.array().items(Joi.objectId()).required(),
});
export interface IAGETManyStudentGradeCategoriesByIds {
	ids: ObjectId[];
}

export const RGETManyStudentGradeCategoriesByIdsSchema = Joi.array().items(
	StudentGradeCategorySchema
);
export type IRGETManyStudentGradeCategoriesByIds = IStudentGradeCategory[];

///

export const AGETStudentGradeCategoriesSchema = Joi.object({
	grades: Joi.array().items(Joi.number().integer().required()).optional(),
});
export interface IAGETStudentGradeCategories {
	grades?: number[];
}

export const RGETStudentGradeCategoriesSchema = Joi.array().items(
	StudentGradeCategorySchema
);
export type IRGETStudentGradeCategories = IStudentGradeCategory[];

///

export const ADELETEStudentGradeCategoryByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IADELETEStudentGradeCategoryById {
	_id: ObjectId;
}
