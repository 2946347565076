import * as React from "react";
import { iconClassName } from "./";

const DragIndicatoriIcon = (props) => (
	<svg
		viewBox="0 0 512 512"
		{...props}
		className={iconClassName + (props.className || " ")}
	>
		<path d="M507.353 245.245l-83.692-78.769a14.768 14.768 0 1 0-24.891 10.755v34.462h-98.462v-98.462h34.462a14.77 14.77 0 0 0 10.755-24.891l-78.77-83.693a14.772 14.772 0 0 0-21.51 0L166.476 88.34a14.767 14.767 0 0 0 10.755 24.891h34.462v98.462h-98.462v-34.462a14.77 14.77 0 0 0-24.891-10.755L4.647 245.245a14.772 14.772 0 0 0 0 21.51l83.692 78.769a14.768 14.768 0 1 0 24.891-10.755v-34.462h98.462v98.462H177.23a14.77 14.77 0 0 0-10.755 24.891l78.769 83.692a14.766 14.766 0 0 0 21.51 0l78.769-83.692a14.767 14.767 0 0 0-10.755-24.891h-34.462v-98.462h98.462v34.462a14.77 14.77 0 0 0 24.891 10.755l83.692-78.769a14.77 14.77 0 0 0 .002-21.51z" />
	</svg>
);

export default DragIndicatoriIcon;
