import * as React from "react";
import FillingBlanksContainer, { IProps as IFBProps } from "../filling-blanks";
import memoizeOne from "memoize-one";
import MultipleChoiceContainer, {
	IProps as IMCProps,
} from "../multiple-choice";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import MultipleContents from "./class";
import SortItemsContainer, { IProps as ISIProps } from "../sort-items";
import styled from "@emotion/styled";
import {
	AnyComponent,
	NotUndefined,
	NotUndefinedAtAll,
	Omit,
} from "../../../../utils/generics";
import {
	ContentType,
	ITextStatement,
} from "../../../../schemas/questions/contnets/common-schemas";
import { css } from "emotion";
import {
	IAnyObj,
	mergeComponentObjects,
	mergeStylesObjects,
} from "../../../../utils";
import { IContentProps } from "../schemas";
import { IExplanationTexts } from "../common/explanation";
import {
	IMultipleContents,
	IMultipleContentsUserAns,
	IRMultipleContents,
} from "../../../../schemas/questions/contnets/multiple-contents/schema";
import { IQContentPassableProps } from "..";
import { IUserAnswer } from "../../../../schemas/questions/contnets/ans-schema";
import { newContent } from "../new-content";

export interface IMUCPassableProps {
	styles?: IMCContainerStyles;
	components?: IMCContentComponents;
	texts?: ITexts;
}

type IMCContentComponents = IMUCComponents & {
	container?: AnyComponent<IMultipleContentsContProps>;
};
export type IMCContainerStyles = IMUCStyles & {
	container?: string;
};

interface ITexts extends IMUCSTexts {
	explanation?: IExplanationTexts;
}

export type IProps = IContentProps<IMultipleContentsUserAns> &
	IQContentPassableProps & {
		content: IRMultipleContents | IMultipleContents;
		shuffleKey?: number;
	};

class MultipleContentsContainer extends React.PureComponent<IProps> {
	defaultComponents = {
		container:
			MultipleContentsCont as AnyComponent<IMultipleContentsContProps>,
	};

	getComponents = memoizeOne((components?: IMCContentComponents) => {
		return mergeComponentObjects(components || {}, this.defaultComponents);
	});

	lastKey = 0;

	getKey = memoizeOne((content) => {
		this.lastKey++;
		return this.lastKey;
	});

	onUserAnswerChange = (id: number, ans: IUserAnswer) => {
		const userAns = this.props.userAnswer
			? { ...this.props.userAnswer }
			: {};
		userAns[id] = ans;
		this.props.onUserAnswerChange(userAns);
	};

	render() {
		const components = this.getComponents(this.props.mucProps?.components);
		const QContainer = components.container;
		const contentKey = this.getKey(this.props.content);
		return (
			<div className={this.props.mucProps?.styles?.container}>
				<QContainer
					{...this.props}
					key={contentKey}
					onUserAnswerChange={this.onUserAnswerChange}
				/>
			</div>
		);
	}
}

interface IMUCComponents {
	contentsContainers?: {
		mc?: AnyComponent<IMCProps>;
		si?: AnyComponent<ISIProps>;
		fb?: AnyComponent<IFBProps>;
	};
}

interface IMUCStyles {
	nextButton?: string;
	prevButton?: string;
}
interface IMUCSTexts {
	nextButton?: string;
	prevButton?: string;
}

export type IMultipleContentsContProps = {
	content: IRMultipleContents | IMultipleContents;
	userAnswer?: IMultipleContentsUserAns;
	onUserAnswerChange: (id: number, ans: IUserAnswer) => void;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
} & IQContentPassableProps;

interface IMultipleContentsContState {
	pageIndex: number;
	maxPageIndex: number;
}

const nextButtonCSS = css`
	margin: 20px 5px 10px 5px;
	background: transparent;
	border: 1px solid #ccc;
	padding: 7px 20px;
	border-radius: 4px;
	font-size: 17px;
	cursor: pointer;
	outline: none;
	&:hover {
		background: #e5e5e5;
	}
	& > * {
		display: inline-block;
		vertical-align: middle;
	}
`;

export class MultipleContentsCont extends React.PureComponent<
	IMultipleContentsContProps,
	IMultipleContentsContState
> {
	state: IMultipleContentsContState = {
		pageIndex: 0,
		maxPageIndex: 0,
	};

	defaultComponents = {
		contentsContainers: {
			mc: MultipleChoiceContainer,
			si: SortItemsContainer,
			fb: FillingBlanksContainer,
		},
	} as NotUndefinedAtAll<IMUCComponents>;

	defaultTexts = {
		nextButton: "Next",
		prevButton: "Previous",
	} as NotUndefinedAtAll<IMUCSTexts>;

	defaultStyles = {
		nextButton: nextButtonCSS,
		prevButton: nextButtonCSS,
	} as NotUndefinedAtAll<IMUCStyles>;

	getStyles = memoizeOne((styles?: IMUCStyles) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	getComponents = memoizeOne((components?: IMUCComponents) => {
		return mergeComponentObjects(components || {}, this.defaultComponents);
	});

	getTexts = memoizeOne((texts?: IMUCSTexts) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	getContent = memoizeOne(
		(content: IMultipleContentsContProps["content"]) => {
			return newContent(content as IMultipleContents) as MultipleContents;
		}
	);

	getAppropriateComponent = (
		type: Pick<
			| IRMultipleContents["items"][number]
			| IMultipleContents["items"][number],
			"content"
		>["content"]["type"]
	): { Component: AnyComponent<IAnyObj>; customProps?: IAnyObj } => {
		const components = this.getComponents(
			this.props.mucProps && this.props.mucProps.components
		);
		switch (type) {
			case ContentType.MultipleChoice:
				return {
					Component: components.contentsContainers.mc,
					customProps: this.props.mcProps,
				};
			case ContentType.SortItems:
				return {
					Component: components.contentsContainers.si,
					customProps: this.props.siProps,
				};
			case ContentType.FillingBlanks:
				return {
					Component: components.contentsContainers.fb,
					customProps: this.props.fbProps,
				};
			case ContentType.Text:
				return { Component: TextStatement };
			case ContentType.MultipleContents:
				return {
					Component: MultipleContentsContainer,
					customProps: this.props,
				};
			default:
				throw new Error(`Content Type ${type} is not supported`);
		}
	};

	onUserAnswerChange = (itemId, ans) => {
		this.props.onUserAnswerChange(itemId, ans);
	};

	onPageSwitch = (pageIndex: number) => {
		if (!this.canViewPage(pageIndex)) return;
		this.setState({
			pageIndex,
			maxPageIndex: Math.max(this.state.maxPageIndex, pageIndex),
		});
	};

	canViewPage = (
		pageIndex: number,
		userAnswer: IMultipleContentsContProps["userAnswer"] = this.props
			.userAnswer
	) => {
		if (pageIndex < 0) return false;
		const content = this.getContent(this.props.content);
		const numOfPages = content.getNumOfPages();
		if (pageIndex >= numOfPages) return false;
		if (this.props.displayAnswer || this.props.disableEditingAnswer) {
			return true;
		}
		if (!this.props.content.restrictViewingPagesBeforehand) return true;
		if (pageIndex > 0) {
			return content.hasDonePage(pageIndex - 1, userAnswer);
		}
		return true;
	};

	canEditAnswersOnPage = (pageIndex: number) => {
		if (this.props.disableEditingAnswer) return false;
		if (this.props.content.restrictViewingPagesBeforehand) {
			if (this.state.maxPageIndex > pageIndex) return false;
			if (pageIndex > 0) {
				const content = this.getContent(this.props.content);
				if (
					!content.hasDonePage(pageIndex - 1, this.props.userAnswer)
				) {
					return false;
				}
			}
		}
		return true;
	};

	render() {
		const content = this.getContent(this.props.content);
		const styles = this.getStyles(
			this.props.mucProps && this.props.mucProps.styles
		);
		const texts = this.getTexts(
			this.props.mucProps && this.props.mucProps.texts
		);
		const numOfPages = content.getNumOfPages();
		const items = content.getPageItems(this.state.pageIndex);
		const { pageIndex } = this.state;
		return (
			<div>
				{items.map((item) => {
					const { Component, customProps } =
						this.getAppropriateComponent(item.content.type);
					return (
						<Component
							{...this.props}
							{...customProps}
							key={item.id}
							content={item.content}
							userAnswer={
								this.props.userAnswer &&
								this.props.userAnswer[item.id]
							}
							onUserAnswerChange={(ans: IUserAnswer) =>
								this.onUserAnswerChange(item.id, ans)
							}
							disableEditingAnswer={
								!this.canEditAnswersOnPage(pageIndex)
							}
						/>
					);
				})}
				{numOfPages > 1 && (
					<>
						<div style={{ textAlign: "center" }}>
							<button
								className={styles.prevButton}
								onClick={() => this.onPageSwitch(pageIndex - 1)}
								style={{
									opacity: pageIndex > 0 ? 1 : 0.25,
								}}
							>
								<ChevronLeft />
								<span>{texts.prevButton}</span>
							</button>
							<button
								className={styles.nextButton}
								onClick={() => this.onPageSwitch(pageIndex + 1)}
								style={{
									opacity:
										pageIndex < numOfPages - 1 ? 1 : 0.25,
								}}
							>
								<span>{texts.nextButton}</span>
								<ChevronRight />
							</button>
						</div>
						<SmallBalls
							length={numOfPages}
							selectedIndex={pageIndex}
							onSelect={this.onPageSwitch}
						/>
					</>
				)}
			</div>
		);
	}
}

const TextStatement = React.memo(
	({ content }: { content: NotUndefined<Omit<ITextStatement, "id">> }) => (
		<div className={textContainerCSS}>
			<div dangerouslySetInnerHTML={{ __html: content.text }} />
		</div>
	)
);

const textContainerCSS = css`
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
	margin: 10px 0;
`;

interface ISmallBallsProps {
	length: number;
	selectedIndex: number;
	onSelect: (index: number) => void;
}

const SmallBalls: React.FC<ISmallBallsProps> = React.memo((props) => {
	const arr = new Array(props.length).fill(0);
	return (
		<div className={SmallBallsContainerCSS}>
			{arr.map((e, i) => (
				<SmallBall
					key={i}
					isSelected={props.selectedIndex === i}
					onClick={() => props.onSelect(i)}
				/>
			))}
		</div>
	);
});

const SmallBallsContainerCSS = css`
	margin: 10px 0;
	text-align: center;
`;

const SmallBall = styled("div")`
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	margin: 5px;
	background: ${(props: { isSelected: boolean }) =>
		props.isSelected ? "#555" : "#ccc"};
	cursor: pointer;
`;

export default MultipleContentsContainer;
