import { arrayToObject } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CloseIcon from "@material-ui/icons/Close";
import DownArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import UpArrowIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";
import AutosizeInput from "react-input-autosize";
import {
	IMultipleSelectHierarchy,
	IMultipleSelectItem,
} from "./deep-multiple-select";
import multipleSelectStyles from "./styles/multiple-select.module.css";
import { useBoolean } from "@app/hooks/general";
import { getFormattedMessage } from "@app/utils/locale";

interface IMSelectWrapperProps {
	items: IMultipleSelectItem<string>[];
	hierarchyInfo: IMultipleSelectHierarchy<string>;
	defaultSelectedItemIds?: ObjectId[];
	onChange?: (selectedItemIds: ObjectId[]) => void;
	placeholder?: string;
	className?: string;
	iconClassName?: string;
	icon?: JSX.Element;
}

function gteCheckedItemsObj(
	selectedItemIds?: ObjectId[]
): Record<string, true | undefined> {
	if (!selectedItemIds) return {};
	const obj: Record<string, true | undefined> = {};
	for (const itemId of selectedItemIds) {
		obj[itemId] = true;
	}
	return obj;
}

export const DeepMultipleSelectWithSearch: React.FC<IMSelectWrapperProps> =
	React.memo((props) => {
		const [searchQuery, setSearchQuery] = React.useState("");
		const [checkItems, setCheckItems] = React.useState(
			gteCheckedItemsObj(props.defaultSelectedItemIds)
		);
		const [isSelectOpen, setIsSelectOpen] = React.useState(false);

		const openSelect = React.useCallback(() => {
			setIsSelectOpen(true);
		}, []);

		const closeSelect = React.useCallback(() => {
			setIsSelectOpen(false);
		}, []);

		const itemsObj = React.useMemo(() => {
			return arrayToObject(props.items, "id");
		}, [props.items]);

		const changeCheckedItems = React.useCallback(
			(itemId: string) => {
				setCheckItems((checkItems) => {
					if (!checkItems[itemId]) {
						setIsSelectOpen(false);
						const newObj = {
							...checkItems,
							[itemId]: true as const,
						};

						if (props.onChange) {
							props.onChange(Object.keys(newObj));
						}
						return newObj;
					}
					const newObj = { ...checkItems };
					delete newObj[itemId];
					if (props.onChange) {
						props.onChange(Object.keys(newObj));
					}

					return newObj;
				});
			},
			[props]
		);

		const filteredItemIds = React.useMemo(() => {
			if (!searchQuery) return undefined;
			const searched: { [itemId: string]: true | undefined } = {};
			for (const itemId in itemsObj) {
				const item = itemsObj[itemId]!;
				if (item.name.indexOf(searchQuery) > -1) {
					let isMyAncesstorChecked = false;
					if (checkItems[itemId]) {
						continue;
					}

					let currentItemId = itemId;
					while (
						props.hierarchyInfo.parentInfo[currentItemId] !==
						undefined
					) {
						if (
							checkItems[
								props.hierarchyInfo.parentInfo[currentItemId]!
							] === true
						) {
							isMyAncesstorChecked = true;
							break;
						}
						currentItemId =
							props.hierarchyInfo.parentInfo[currentItemId]!;
					}

					if (!isMyAncesstorChecked) {
						searched[itemId] = true;
					}
				}
			}
			return searched;
		}, [checkItems, itemsObj, props.hierarchyInfo.parentInfo, searchQuery]);

		const inputRef = React.useRef<HTMLInputElement>(null);

		const onSearchIconClick = () => {
			openSelect();
			focusMethod();
		};
		const focusMethod = React.useCallback(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, []);

		const inputRefFunc = React.useCallback((e: HTMLInputElement | null) => {
			(inputRef as any).current = e;
		}, []);

		return (
			<ClickAwayListener onClickAway={closeSelect}>
				<div className={multipleSelectStyles.multipleSelectContainer}>
					<div
						className={
							props.className ||
							multipleSelectStyles.multipleSelectSearchBar
						}
					>
						<div
							className={multipleSelectStyles.searchItems}
							onClick={openSelect}
						>
							<SearchItems
								hierarchy={props.hierarchyInfo}
								items={itemsObj}
								checkItems={checkItems}
								onChangeChekedItems={changeCheckedItems}
								searchQuery={searchQuery}
								setSearchQuery={setSearchQuery}
								inputRef={inputRef}
								inputRefFunc={inputRefFunc}
								focusMethod={focusMethod}
								placeholder={props.placeholder}
							/>
						</div>
						<div
							className={
								props.iconClassName
									? props.iconClassName
									: multipleSelectStyles.searchIcon
							}
						>
							{props.icon ? (
								<div onClick={onSearchIconClick}>
									{props.icon}
								</div>
							) : (
								<SearchIcon
									onClick={onSearchIconClick}
									style={{ cursor: "pointer" }}
								/>
							)}
						</div>
					</div>
					{isSelectOpen && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<div className={multipleSelectStyles.allContent}>
								<MultipleSelect
									hierarchy={props.hierarchyInfo}
									items={itemsObj}
									checkItems={checkItems}
									onChangeChekedItems={changeCheckedItems}
									searchedObj={filteredItemIds}
									searchQuery={searchQuery}
								/>
							</div>
						</div>
					)}
				</div>
			</ClickAwayListener>
		);
	});

interface ISearchItemsProps {
	hierarchy: IMultipleSelectHierarchy<string>;
	items: Record<string, IMultipleSelectItem<string> | undefined>;
	checkItems: Record<string, boolean | undefined>;
	onChangeChekedItems: (itemId: string) => void;
	searchQuery: string;
	setSearchQuery: (searchQuery: string) => void;
	inputRef: React.RefObject<HTMLInputElement>;
	inputRefFunc: (e: HTMLInputElement | null) => void;
	focusMethod: () => void;
	placeholder?: string;
}
const SearchItems: React.FC<ISearchItemsProps> = React.memo((props) => {
	const elements: any[] = [];

	for (const itemId in props.checkItems) {
		if (props.checkItems[itemId]) {
			const item = props.items[itemId];
			if (!item) continue;
			elements.push(
				<div
					className={multipleSelectStyles.checkedItems}
					key={item.id}
				>
					<div style={{ flex: 1, padding: 5 }}>{item.name}</div>
					<div
						className={multipleSelectStyles.deleteButton}
						onClick={(e) => {
							e.stopPropagation();
							props.onChangeChekedItems(itemId);
						}}
					>
						<CloseIcon />
					</div>
				</div>
			);
		}
	}

	const { onChangeChekedItems, checkItems } = props;

	const onKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Backspace" && (e.target as any).value === "") {
				const newArr = Object.keys(checkItems);
				if (newArr.length !== 0) {
					onChangeChekedItems(newArr[newArr.length - 1]);
				}
			}
		},
		[onChangeChekedItems, checkItems]
	);

	return (
		<div onClick={props.focusMethod}>
			{elements}
			<AutosizeInput
				inputRef={props.inputRefFunc}
				inputClassName={multipleSelectStyles.input}
				value={props.searchQuery}
				onChange={(e) => props.setSearchQuery(e.target.value)}
				onKeyDown={onKeyDown}
				placeholder={props.placeholder}
			/>
		</div>
	);
});

const isTopParents = (
	hierarchy: IMultipleSelectHierarchy<string>,
	itemId: string
): boolean => {
	return !hierarchy.parentInfo[itemId];
};

interface IMultipleSelectProps {
	hierarchy: IMultipleSelectHierarchy<string>;
	items: Record<string, IMultipleSelectItem<string> | undefined>;
	checkItems: Record<string, boolean | undefined>;
	onChangeChekedItems: (itemId: string) => void;
	searchedObj?: Record<string, true | undefined>;
	searchQuery: string;
}
interface ItemsObj {
	[itemId: string]: IMultipleSelectItem<string>;
}

const MultipleSelect: React.FC<IMultipleSelectProps> = (props) => {
	const [openitems, setOpenItems] = React.useState(
		{} as Record<string, boolean | undefined>
	);

	const changeOpenItems = React.useCallback((itemId: string) => {
		setOpenItems((openitems) => ({
			...openitems,
			[itemId]: !openitems[itemId],
		}));
	}, []);
	const itemsArray: any[] = [];
	for (const itemId in props.items) {
		let displayItem = false;
		if (!props.searchedObj) {
			displayItem =
				!!props.items[itemId] && isTopParents(props.hierarchy, itemId);
		} else {
			displayItem = !!props.searchedObj[itemId];
		}
		if (displayItem === true && props.checkItems[itemId]) {
			displayItem = false;
		}
		if (displayItem) {
			itemsArray.push(
				<div key={itemId} className={multipleSelectStyles.topParent}>
					<TopParents
						items={props.items}
						hierarchy={props.hierarchy}
						key={itemId}
						itemId={itemId}
						openitems={openitems}
						onItemOpenChange={changeOpenItems}
						onChangeChekedItems={props.onChangeChekedItems}
						checkItems={props.checkItems}
						searchQuery={props.searchQuery}
					/>
				</div>
			);
		}
	}

	if (itemsArray.length === 0) {
		return (
			<div style={{ width: "100%", textAlign: "center", color: "#777" }}>
				{props.searchQuery
					? getFormattedMessage("noOptions")
					: getFormattedMessage("allOptionsChosen")}
			</div>
		);
	}
	return <div style={{ width: "100%" }}>{itemsArray}</div>;
};

interface ITopParentsProps {
	hierarchy: IMultipleSelectHierarchy<string>;
	itemId: string;
	items: Record<string, IMultipleSelectItem<string> | undefined>;
	openitems: Record<string, boolean | undefined>;
	onItemOpenChange: (itemId: string) => void;
	checkItems: Record<string, boolean | undefined>;
	onChangeChekedItems: (itemId: string) => void;
	searchQuery: string;
}

const TopParents: React.FC<ITopParentsProps> = (props) => {
	const { onItemOpenChange, onChangeChekedItems } = props;
	const {
		value: isCheckedCheckbox,
		setTrue: setCheckedCheckbox,
		setFalse: hideCheckedCheckbox,
	} = useBoolean();
	const onChangeOpen = React.useCallback(
		(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
			e.stopPropagation();
			onItemOpenChange(props.itemId);
		},
		[onItemOpenChange, props.itemId]
	);
	const onChangeCheck = React.useCallback(() => {
		setCheckedCheckbox();
		onChangeChekedItems(props.itemId);
	}, [onChangeChekedItems, props.itemId, setCheckedCheckbox]);

	const onChangeCheckboxCheck = React.useCallback(() => {
		if (!isCheckedCheckbox) {
			setCheckedCheckbox();
		} else {
			hideCheckedCheckbox();
		}
	}, [hideCheckedCheckbox, isCheckedCheckbox, setCheckedCheckbox]);

	const item = props.items[props.itemId];
	if (!item) return null;
	const children = props.hierarchy.childrenInfo[props.itemId];
	const isOpened = !!props.openitems[props.itemId];
	const isCheked = !!props.checkItems[props.itemId];
	const classes = [multipleSelectStyles.checkBox];

	if (isCheked) return null;
	return (
		<div>
			<div>
				<div
					className={multipleSelectStyles.parentAndBoxes}
					onClick={onChangeCheck}
				>
					<div
						onClick={onChangeCheckboxCheck}
						className={classes.join(" ")}
						tabIndex={-1}
					/>
					{children &&
						children.length > 0 &&
						(isOpened ? (
							<UpArrowIcon
								onClick={onChangeOpen}
								className={multipleSelectStyles.openBox}
							/>
						) : (
							<DownArrowIcon
								onClick={onChangeOpen}
								className={multipleSelectStyles.openBox}
							/>
						))}
					<div className={multipleSelectStyles.parentsName}>
						<ItemName
							searchQuery={props.searchQuery}
							name={item.name}
						/>
					</div>
				</div>
			</div>
			<div
				style={{ paddingLeft: 40 }}
				className={multipleSelectStyles.childrenName}
			>
				{children &&
					isOpened &&
					children.map((childId) => (
						<TopParents {...props} key={childId} itemId={childId} />
					))}
			</div>
		</div>
	);
};

interface IItemNameProps {
	name: string;
	searchQuery: string;
}
const ItemName: React.FC<IItemNameProps> = (props) => {
	const searchedIndex = props.name.indexOf(props.searchQuery);
	return (
		<div className={multipleSelectStyles.searchItems}>
			<div style={{ display: "inline-block", textAlign: "center" }}>
				{props.name.slice(0, searchedIndex)}
			</div>
			<div style={{ backgroundColor: "yellow", display: "inline-block" }}>
				{props.name.slice(
					searchedIndex,
					searchedIndex + props.searchQuery.length
				)}
			</div>
			<div style={{ display: "inline-block" }}>
				{props.name.slice(searchedIndex + props.searchQuery.length)}
			</div>
		</div>
	);
};
