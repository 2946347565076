import * as React from "react";
import memoizeOne from "memoize-one";
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import {
	Explanation,
	IExplanationProps,
	IExplanationStyles,
	IExplanationTexts,
} from "../common/explanation";
import { IContentProps } from "../schemas";
import {
	IItemComponents,
	IItemProps,
	IItemStyles,
	Item,
	SIItem,
} from "./items";
import {
	IItemContainerClassNames,
	IItemContainerProps,
	IItemTextProps,
	ItemContainer,
} from "../common/stats";
import {
	IRSortItemsContent,
	ISortItemsContent,
	ISortItemsUserAns,
} from "../../../../schemas/questions/contnets/sort-items/schema";
import {
	IViewModeChangeComponentProps,
	IViewModeStyles,
	IViewModeTexts,
	ViewMode,
	ViewModeChangeComponent,
} from "../common/view-mode";
import {
	mergeComponentObjects,
	mergeStylesObjects,
	reorder,
} from "../../../../utils";
import { shuffleArrayByKey } from "../../../../utils/shuffle";
import { v4 as uuid } from "uuid";
import {
	AnyComponent,
	NotUndefined,
	AnyComponentOptionalProps,
} from "../../../../utils/generics";

export interface ISIPassableProps {
	styles?: SortItemsContainerStyles;
	components?: ISortItemsContentComponents;
	texts?: ISortItemsTexts;
	hideViewMode?: boolean;
	defaultViewMode?: ViewMode;
}

type ISortItemsContentComponents = ISortItemsContentContComponents & {
	container?: AnyComponent<ISortItemsContentContProps>;
};

type SortItemsContainerStyles = ISortItemsContentContStyles & {
	container?: string;
};

interface ISortItemsTexts {
	viewMode?: IViewModeTexts;
	explanation?: IExplanationTexts;
}

export interface IProps extends IContentProps<ISortItemsUserAns> {
	content: IRSortItemsContent | ISortItemsContent;
	shuffleKey?: number;
	userAnswer?: ISortItemsUserAns;
	onUserAnswerChange: (userAnswer: ISortItemsUserAns) => void;
	styles?: SortItemsContainerStyles;
	components?: ISortItemsContentComponents;
	texts?: ISortItemsTexts;
	hideViewMode?: boolean;
	defaultViewMode?: ViewMode;
}

class SortItemsContainer extends React.PureComponent<IProps> {
	render() {
		let QContainer: AnyComponent<ISortItemsContentContProps> =
			SortItemsContentCont;
		if (this.props.components && this.props.components.container) {
			QContainer = this.props.components.container;
		}
		return (
			<div className={this.props.styles?.container}>
				<QContainer {...this.props} />
			</div>
		);
	}
}

interface ISIItemsStatementComponents {
	container?: AnyComponent<IItemContainerProps>;
	text?: AnyComponent<IItemTextProps>;
}

interface ISortItemsContentContComponents {
	items?: ISIItemsContainerComponents & {
		container?: AnyComponent<ISIItemsContainerProps>;
		after?: AnyComponentOptionalProps<{
			content: ISortItemsContentContProps["content"];
			viewMode: ViewMode;
		}>;
	};
	statement?: ISIItemsStatementComponents;
	viewMode?: AnyComponent<IViewModeChangeComponentProps>;
	explanation?: AnyComponent<IExplanationProps>;
}

interface ISortItemsContentContStyles {
	items?: ISIItemsContainerStyles;
	statement?: IItemContainerClassNames;
	viewMode?: IViewModeStyles;
	explanation?: IExplanationStyles;
}

interface ISortItemsContentContProps {
	content: IRSortItemsContent | ISortItemsContent;
	userAnswer?: ISortItemsUserAns;
	onUserAnswerChange: (userAnswer: ISortItemsUserAns) => void;
	styles?: ISortItemsContentContStyles;
	components?: ISortItemsContentContComponents;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	texts?: ISortItemsTexts;
	hideViewMode?: boolean;
	shuffleKey?: number;
	defaultViewMode?: ViewMode;
}

interface ISortItemsContentContState {
	viewMode: ViewMode;
}

export class SortItemsContentCont extends React.PureComponent<
	ISortItemsContentContProps,
	ISortItemsContentContState
> {
	state: ISortItemsContentContState = {
		viewMode:
			this.props.defaultViewMode === undefined
				? "userAnswer"
				: this.props.defaultViewMode,
	};

	defaultStyles = {
		items: {},
		statement: {},
		explanation: {},
	} as NotUndefined<ISortItemsContentContProps["styles"]>;

	getStyles = memoizeOne((styles: ISortItemsContentContProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	defaultComponents = {
		items: {
			container: SIItemsContainer,
		},
		statement: {
			container: ItemContainer,
		},
		viewMode: ViewModeChangeComponent,
		explanation: Explanation,
	} as NotUndefined<ISortItemsContentContComponents>;

	getComponents = memoizeOne(
		(components: ISortItemsContentContProps["components"]) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents
			);
		}
	);

	detectAnswerDisplayChange = memoizeOne((displayAnswer: boolean) => {
		if (!displayAnswer && this.state.viewMode === "correctAnswer") {
			setTimeout(() => {
				this.setState({
					viewMode: "userAnswer",
				});
			}, 0);
		}
	});

	onViewModeChange = (viewMode: ViewMode) => {
		this.setState({
			viewMode,
		});
	};

	render() {
		const components = this.getComponents(this.props.components);
		const styles = this.getStyles(this.props.styles);
		const StatementContainer = components.statement!.container!;
		const ItemsContainer = components.items!.container!;
		const ViewModeComponent = components.viewMode!;
		this.detectAnswerDisplayChange(this.props.displayAnswer);
		const ExpContainer = components.explanation!;
		let { viewMode } = this.state;
		if (!this.props.displayAnswer && viewMode !== "userAnswer") {
			setTimeout(() => this.onViewModeChange("userAnswer"), 0);
			viewMode = "userAnswer";
		}
		const ChoicesAfter = components.items!.after;
		let displayViewMode =
			this.props.displayAnswer && !this.props.hideViewMode;
		let forciblyShowCorrectOrder = false;
		if (
			displayViewMode &&
			viewMode === "userAnswer" &&
			!this.props.userAnswer
		) {
			displayViewMode = false;
			forciblyShowCorrectOrder = true;
		}
		return (
			<div>
				<StatementContainer
					index={0}
					components={components.statement}
					styles={styles.statement}
					statement={this.props.content.statement}
				/>
				<ItemsContainer
					components={components.items}
					items={this.props.content.items}
					disableEditingAnswer={this.props.disableEditingAnswer}
					displayAnswer={this.props.displayAnswer}
					onUserAnswerChange={this.props.onUserAnswerChange}
					styles={styles.items}
					userAnswer={this.props.userAnswer}
					forciblyShowCorrectOrder={forciblyShowCorrectOrder}
					correctOrder={
						(this.props.content as ISortItemsContent).correctOrder
					}
					viewMode={viewMode}
					shuffleKey={this.props.shuffleKey}
				/>
				{ChoicesAfter && (
					<ChoicesAfter
						content={this.props.content}
						viewMode={viewMode}
					/>
				)}
				{displayViewMode && (
					<ViewModeComponent
						mode={viewMode}
						onChange={this.onViewModeChange}
						styles={styles.viewMode}
						texts={this.props.texts && this.props.texts.viewMode}
					/>
				)}
				<ExpContainer
					explanation={
						(this.props.content as ISortItemsContent).explanation
					}
					show={!!this.props.displayAnswer}
					styles={styles.explanation}
					texts={this.props.texts && this.props.texts.explanation}
				/>
			</div>
		);
	}
}

interface ISIItemsContainerComponents {
	innerItem?: IItemComponents & {
		container?: AnyComponent<IItemProps>;
	};
}

interface ISIItemsContainerStyles {
	innerItem?: IItemStyles;
	container?: string;
}

export interface ISIItemsContainerProps {
	items: IRSortItemsContent["items"] | ISortItemsContent["items"];
	correctOrder?: ISortItemsContent["correctOrder"];
	userAnswer?: ISortItemsUserAns;
	onUserAnswerChange: (userAnswer: ISortItemsUserAns) => void;
	forciblyShowCorrectOrder?: boolean;
	styles?: ISIItemsContainerStyles;
	components?: ISIItemsContainerComponents;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	viewMode: ISortItemsContentContState["viewMode"];
	shuffleKey?: number;
}

class SIItemsContainer extends React.PureComponent<ISIItemsContainerProps> {
	defaultStyles = {
		innerItem: {} as NotUndefined<ISIItemsContainerStyles["innerItem"]>,
	};

	getStyles = memoizeOne((styles: ISIItemsContainerProps["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	defaultComponents = {
		innerItem: {
			container: Item,
		},
	} as NotUndefined<ISIItemsContainerComponents>;

	getComponents = memoizeOne(
		(components: ISIItemsContainerProps["components"]) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents
			);
		}
	);

	uuid = uuid();

	lastOrder: number[] = [];

	detectItemsReordering = memoizeOne((items: ISortItemsContent["items"]) => {
		if (this.props.userAnswer !== undefined) return;
		const order = items.map((e) => e.id);
		if (
			this.lastOrder.length !== order.length ||
			!this.lastOrder.every((e, i) => e === order[i])
		) {
			setTimeout(() => this.props.onUserAnswerChange(order), 0);
			this.lastOrder = order;
		}
	});

	defaultShuffleKey = Math.floor(Math.random() * 1e7);

	getItems = memoizeOne(
		(
			items: SIItem[],
			userAnswer: ISIItemsContainerProps["userAnswer"],
			viewMode: ISIItemsContainerProps["viewMode"]
		): SIItem[] => {
			if (viewMode === "correctAnswer") {
				const { correctOrder } = this.props;
				if (correctOrder) {
					return [...items].sort(
						(i1, i2) =>
							correctOrder.indexOf(i1.id) -
							correctOrder.indexOf(i2.id)
					);
				}
			}
			if (!userAnswer) {
				return this.props.shuffleKey === undefined
					? shuffleArrayByKey(items, this.defaultShuffleKey)
					: shuffleArrayByKey(items, this.props.shuffleKey);
			}
			return [...items].sort(
				(i1, i2) =>
					userAnswer.indexOf(i1.id) - userAnswer.indexOf(i2.id)
			);
		}
	);

	onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		if (result.source.index === result.destination.index) return;

		const reorderedItemIds = reorder(
			this.getItems(
				this.props.items,
				this.props.userAnswer,
				this.props.viewMode
			),
			result.source.index,
			result.destination.index
		).map((e) => e.id);
		this.props.onUserAnswerChange(reorderedItemIds);
	};

	render() {
		const { correctOrder } = this.props;
		const components = this.getComponents(this.props.components);
		const styles = this.getStyles(this.props.styles);
		const ItemComponent = components.innerItem!.container!;
		const items = this.getItems(
			this.props.items as SIItem[],
			this.props.userAnswer,
			this.props.forciblyShowCorrectOrder
				? "correctAnswer"
				: this.props.viewMode
		);
		this.detectItemsReordering(items);
		return (
			<div className={styles.container}>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId={this.uuid}>
						{(provided, snapshot) => (
							<div ref={provided.innerRef}>
								{(items as SIItem[]).map((item, i) => (
									<Draggable
										isDragDisabled={
											!!this.props.disableEditingAnswer
										}
										key={item.id}
										draggableId={item.id.toString()}
										index={i}
									>
										{(provided2, snapshot2) => {
											let correctIndex:
												| number
												| undefined;
											if (
												this.props.displayAnswer &&
												correctOrder
											) {
												const indx =
													correctOrder.findIndex(
														(e) => e === item.id
													);
												if (indx > -1) {
													correctIndex = indx;
												}
											}
											return (
												<div
													ref={provided2.innerRef}
													{...provided2.draggableProps}
													{...provided2.dragHandleProps}
												>
													<ItemComponent
														key={item.id}
														index={i}
														item={item}
														styles={
															styles.innerItem
														}
														components={
															components.innerItem
														}
														displayAnswer={
															this.props
																.displayAnswer &&
															(!!this.props
																.userAnswer ||
																this.props
																	.viewMode ===
																	"correctAnswer")
														}
														disableEditingAnswer={
															this.props
																.disableEditingAnswer
														}
														correctIndex={
															correctIndex
														}
													/>
												</div>
											);
										}}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		);
	}
}

export default SortItemsContainer;
