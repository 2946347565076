import { isStudentStatusVisible } from "@app/consts";
import { collmise } from "collmise";
import { IRequest } from "../requests";
import { IUserStatus } from "../users/helper-schemas";
import {
	IAGETUserStatus,
	IAGETManyUsersStatus,
	IAPUTSaveTestUser,
} from "../users/validators";
import {
	AGETFindUsersSchema,
	IAGETFindUsers,
	IRGETFindUsers,
	RGETFindUsersSchema,
} from "./validators";

const emptyValue = "empty" as any;

export class FindUserController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	private status = collmise<number, IUserStatus>().addCollector({
		name: "many",
		onRequest: (ids: number[]): Promise<IUserStatus[]> =>
			this.rawGetManyUsersStatus({ userIds: ids }),
		findOne: (id, results) =>
			results.find((e) => e.userId === id) || emptyValue,
		mergeOnData: (many, cached) => [
			...(many || []),
			...cached.map((e) => e.data).filter((e) => e !== emptyValue),
		],
	});

	findUsers(args: IAGETFindUsers): Promise<IRGETFindUsers> {
		return this.Request.send("GET", "/api/find-users", args, null, {
			requestSchema: AGETFindUsersSchema,
			responseSchema: RGETFindUsersSchema,
		});
	}

	getUserStatus(args: IAGETUserStatus): Promise<IUserStatus> {
		return this.status
			.on(args.userId)
			.request(() =>
				this.Request.send(
					"GET",
					"/api/find-users/:userId/status",
					args,
					null
				)
			);
	}
	getManyUsersStatus(args: IAGETManyUsersStatus): Promise<IUserStatus[]> {
		return this.status.collectors.many(args.userIds).request();
	}

	private async rawGetManyUsersStatus(
		args: IAGETManyUsersStatus
	): Promise<IUserStatus[]> {
		if (!isStudentStatusVisible) return [];
		return this.Request.send("GET", "/api/find-users/status", args, null);
	}

	saveOrUpdateTestUserStatusById(args: IAPUTSaveTestUser) {
		return this.Request.send(
			"PUT",
			"/api/find-users/save-test-status",
			args
		);
	}
}
