import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
import { UserFolderProgressSchema } from "../folders/helper-schemas";
import { UserTaskTypeLevelSchema } from "../task-types/helper-schemas";
import { UserTopicLevelSchema } from "../topics/helper-schemas";
import { SingleUserStats, SingleUserStatsSchema } from "./helper-schemas";

///

export const AGETSingleUserStatsSchema = Joi.object({
	userId: Joi.number().required(),
	classroomId: Joi.objectId().required(),
});
export interface IAGETSingleUserStats {
	classroomId: ObjectId;
	userId?: number | undefined;
}

export const RGETSingleUserStatsSchema = SingleUserStatsSchema;

export type IRGETSingleUserStats = SingleUserStats;
///

export const AGETUserStatsSchema = Joi.object({
	classroomId: Joi.objectId().required(),
});
export interface IAGETUserStats {
	classroomId: ObjectId;
}

export const RGETUserStatsSchema = Joi.object().pattern(
	/^\d+$/,
	Joi.object({
		folders: Joi.array().items(UserFolderProgressSchema).required(),
		topics: UserTopicLevelSchema.allow(null).required(),
		taskTypes: UserTaskTypeLevelSchema.allow(null).required(),
	})
);
export type IRGETUserStats = Record<
	number | string,
	IRGETSingleUserStats | undefined
>;
