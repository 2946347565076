import {
	useTopLevelAccessibleSchoolLabels,
	useSchoolStructureOfCurrentUser,
	useViewableLabelIds,
} from "@app/hooks/school-structure";
import { SetState } from "react-prop-hooks";
import { ObjectId } from "@app/utils/generics";
import React, { useCallback, useMemo } from "react";
import { useSimplifiedSavedValue } from "./hooks";
import { SchoolStructure } from "@app/models/school-structure";
import { useClassroomsUser } from "@app/hooks/users";
import { flatten } from "@app/utils/common";

export const SELECTED_SCHOOL_LABEL_QUERY_KEY = "sLabelId";
const LAST_SELECTED_SCHOOL_LABEL_KEY = "LAST_SELECTED_SCHOOL_LABEL";
const LAST_LABEL_ACTION_KEY = "lastVisitedsLabelId";

export const SchoolLabelInitializer = React.memo<{
	labelId: ObjectId | null;
	setLabelId: SetState<ObjectId | null>;
}>(function SchoolLabelInitializer({ labelId, setLabelId }) {
	const schoolStructure = useSchoolStructureOfCurrentUser();
	const availableLabels = useTopLevelAccessibleSchoolLabels();
	const ownViewableLabelIds = useViewableLabelIds();

	const normFn = useLabelNormalizerFn();

	const isValidSchoolMemoizedFn = useCallback(
		(labelId: ObjectId | null) => {
			if (!labelId || !ownViewableLabelIds) return true;
			const descAndAncIds = flatten(
				ownViewableLabelIds
					.map(
						(id) =>
							schoolStructure.doc
								?.getDescendantLabels(id)
								.map((e) => e._id) || []
					)
					.concat(
						ownViewableLabelIds.map(
							(id) =>
								schoolStructure.doc
									?.getParentsOfLabel(id)
									.map((e) => e._id) || []
						)
					)
			).concat(ownViewableLabelIds);
			const has = descAndAncIds.includes(labelId);
			if (!has) return false;
			return normFn(labelId, schoolStructure.doc) === labelId;
		},
		[ownViewableLabelIds, schoolStructure, normFn]
	);

	const getLastPriorityLabel = useCallback(() => {
		if (availableLabels && availableLabels.length > 0) {
			return normFn(availableLabels[0]._id, schoolStructure.doc);
		}
		return null;
	}, [availableLabels, schoolStructure, normFn]);

	useSimplifiedSavedValue({
		currentValue: labelId,
		setValue: setLabelId,
		isLoaded: !!availableLabels,
		isValidValue: isValidSchoolMemoizedFn,
		getLastPriorityValue: getLastPriorityLabel,
		keys: {
			action: LAST_LABEL_ACTION_KEY,
			session: LAST_SELECTED_SCHOOL_LABEL_KEY,
			query: SELECTED_SCHOOL_LABEL_QUERY_KEY,
		},
		parseValueFromStr: parseObjectIdFromStr,
		fetchFlag: "nullToUndefined",
	});
	return null;
});

const parseObjectIdFromStr = (
	value: string | null | undefined
): string | null | undefined => {
	if (value === "null") return null;
	return value;
};

const normalizeLabel =
	(ownViewableLabelIds: ObjectId[] | null) =>
	<L extends ObjectId | null>(
		labelId: L,
		schoolStructure: SchoolStructure | undefined
	): L => {
		const descendants =
			(labelId && schoolStructure?.getDescendantLabels(labelId!)) || [];
		const validDescendant = descendants.find((desc) => {
			if (ownViewableLabelIds === null) return true;
			return ownViewableLabelIds.some(
				(id) =>
					id === desc._id ||
					schoolStructure
						?.getDescendantLabels(id)
						.map((e) => e._id)
						.includes(desc._id)
			);
		});
		return (validDescendant?._id ?? labelId) as L;
	};

const unnormalizeLabel =
	(ownViewableLabelIds: ObjectId[] | null) =>
	<L extends ObjectId | null>(
		labelId: L,
		schoolStructure: SchoolStructure | undefined
	): L => {
		const roots =
			(labelId && schoolStructure?.getAncestorLabels(labelId!)) || [];
		return (roots.length > 0 ? roots[0]._id : labelId) as L;
	};

export const useLabelNormalizerFn = () => {
	const user = useClassroomsUser()!;
	const ownViewableLabelIds = useViewableLabelIds();
	const isHeadmaster = user.isHeadmaster();
	return useMemo(
		() =>
			isHeadmaster
				? normalizeLabel(ownViewableLabelIds)
				: unnormalizeLabel(ownViewableLabelIds),
		[ownViewableLabelIds, isHeadmaster]
	);
};
