import Joi from "@app/utils/joi";
import { ICertificate } from "./helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { UserType } from "../helper-schemas";

export const APOSTGenerateCertificateSchema = Joi.object({
	receiversFullName: Joi.string().required(),
});

export interface IAPOSTGenerateCertificate {
	receiversFullName: string;
}

export type IRPOSTGenerateCertificate = ICertificate;

export const AGETCertificateContentForSharingSchema = Joi.object({
	userType: Joi.string()
		.valid(UserType.teacher, UserType.student)
		.default(UserType.teacher),
	imgPath: Joi.string().required(),
	title: Joi.string().optional(),
	description: Joi.string().optional(),
});
export interface IAGETCertificateContentForSharing {
	userType: UserType.teacher | UserType.student;
	imgPath: string;
	title?: string;
	description?: string;
}

export interface IRGETCertificateContentForSharing {
	url: string;
	type: string;
	title: string;
	description: string;
	imgPath: string;
}

export const RGETUserCertificateStatusSchema = Joi.object({
	isQualified: Joi.boolean().required(),
});
export interface IRGETUserCertificateStatus {
	isQualified: boolean;
}

export const AGETCertificateCandidateStudentsSchema = Joi.object({
	classroomId: Joi.objectId().optional(),
});
export interface IAGETCertificateCandidateStudents {
	classroomId?: ObjectId;
}

export enum CertificateCandidateStudentType {
	notRegistered = 0,
	registered = 1,
	deletedFromCandidates = 2,
	alreadySent = 3,
}

export const CertificateCandidateStudentInfoSchema = Joi.object({
	id: Joi.number().integer().required(),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	mobile: Joi.string().allow(null).required(),
	city: Joi.number().integer().allow(null).required(),
	grade: Joi.number().integer().required(),
	school: Joi.number().integer().allow(null).required(),
});
export interface ICertificateCandidateStudentInfo {
	id: number;
	firstname: string;
	lastname: string;
	mobile: string | null;
	city: number | null;
	grade: number;
	school: number | null;
}

export const CertificateCandidateStudentSchema = Joi.alternatives(
	Joi.object({
		type: Joi.number()
			.valid(
				CertificateCandidateStudentType.registered,
				CertificateCandidateStudentType.deletedFromCandidates,
				CertificateCandidateStudentType.alreadySent
			)
			.required(),
		user: CertificateCandidateStudentInfoSchema.required(),
	}),
	Joi.object({
		type: Joi.number().valid(CertificateCandidateStudentType.notRegistered),
		grade: Joi.number().integer().required(),
		name: Joi.string().required(),
	})
);

export interface IRegisteredCertificateCandidateStudent {
	type:
		| CertificateCandidateStudentType.registered
		| CertificateCandidateStudentType.deletedFromCandidates
		| CertificateCandidateStudentType.alreadySent;
	user: ICertificateCandidateStudentInfo;
}
export interface IUnregisteredCertificateCandidateStudent {
	type: CertificateCandidateStudentType.notRegistered;
	grade: number;
	name: string;
}
export type ICertificateCandidateStudent =
	| IRegisteredCertificateCandidateStudent
	| IUnregisteredCertificateCandidateStudent;

export const RGETCertificateCandidateStudentsSchema = Joi.object().pattern(
	Joi.number().integer(),
	Joi.array().items(CertificateCandidateStudentSchema)
);
export type IRGETCertificateCandidateStudents = Record<
	number,
	ICertificateCandidateStudent[] | undefined
>;

// update candidate students' status
export const APUTCertificateCandidateStudentsStatusSchema = Joi.array().items(
	Joi.object({
		studentId: Joi.number().integer().required(),
		approved: Joi.boolean().required(),
	})
);
export type IAPUTCertificateCandidateStudentsStatus = {
	studentId: number;
	approved: boolean;
}[];

export const RPUTCertificateCandidateStudentsStatusSchema =
	RGETCertificateCandidateStudentsSchema;
export type IRPUTCertificateCandidateStudentsStatus =
	IRGETCertificateCandidateStudents;

export const AGETPreviewStudentCertificateForTeacherSchema = Joi.object({
	studentId: Joi.number().integer().required(),
	fullname: Joi.string().allow(""),
});
export interface IAGETPreviewStudentCertificateForTeacher {
	studentId: number;
	fullname?: string;
}

export const RGETPreviewStudentCertificateForTeacherSchema = Joi.object({
	certificatePath: Joi.string().required(),
});
export interface IRGETPreviewStudentCertificateForTeacher {
	certificatePath: string;
}

export const ADELETEStudentCertificateDecisionSchema = Joi.object({
	studentId: Joi.number().integer().required(),
});
export interface IADELETEStudentCertificateDecision {
	studentId: number;
}

export const RGETCertificatesForStudentSchema = Joi.object({
	certificatePath: Joi.string().allow(null).required(),
	seen: Joi.boolean(),
	teacherNames: Joi.array().items(Joi.string()).required(),
});
export interface IRGETCertificatesForStudent {
	certificatePath: string | null;
	seen?: boolean;
	teacherNames: string[];
}
export const RDeleteStudentCertificateDecisionSchema =
	RGETCertificateCandidateStudentsSchema;
export type IRDeleteStudentCertificateDecision =
	IRGETCertificateCandidateStudents;

///
export const AGETSpecialCertificateSchema = Joi.object({
	certId: Joi.number().required(),
	code: Joi.string().required(),
	markAsSeen: Joi.boolean().required(),
});

export interface IAGETSpecialCertificate {
	certId: number;
	code: string;
	markAsSeen: boolean;
}

export const RGETSpecialCertificateSchema = Joi.object({
	certificatePath: Joi.string().required(),
	fullname: Joi.string().allow(null).required(),
});

export interface IRGETSpecialCertificate {
	certificatePath: string;
	fullname: string | null;
}

///

export const APUTSpecialCertificateActionSchema = Joi.object({
	certId: Joi.number().required(),
	code: Joi.string().required(),
	action: Joi.string().valid("download", "share").required(),
});

export interface IAPUTSpecialCertificateAction {
	certId: number;
	code: string;
	action: "download" | "share";
}

///

export const AGETSpecialCertificateContentForSharingSchema = Joi.object({
	certId: Joi.number().required(),
	code: Joi.string().required(),
	imgPath: Joi.string().required(),
	title: Joi.string().optional(),
	description: Joi.string().optional(),
});
export interface IAGETSpecialCertificateContentForSharing {
	certId: number;
	code: string;
	imgPath: string;
	title?: string;
	description?: string;
}
