import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { IGroup } from "@app/api/groups/helper-schemas";
import { IRGETMyInfoAsTeacher } from "@app/api/users/validators";
import { useArchivedSemesters } from "@app/components/archive/utils";
import { isEligibleGradeForWeeklyTests } from "@app/components/users/popups/weekly-test/utils";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import { useTeacherAssignmentsCount } from "@app/hooks/assignments";
import { useMyClassroomsAsTeacher } from "@app/hooks/classrooms";
import { useFetch } from "@app/hooks/fetch";
import { useHistory, useLocationQuery } from "@app/hooks/front";
import { useLocale } from "@app/hooks/intl";
import { useTeacheruseSchoolAndLabelFiltererFn } from "@app/hooks/school-structure";
import { useTeacherInfo } from "@app/hooks/teacher";
import { useConferences } from "@app/hooks/video-lessons";
import { Classroom } from "@app/models/classroom";
import { inject } from "@app/modules";
import { disabledTimetableSchools } from "@app/services/video-lessons/utils";
import { User } from "@app/user";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { SubWebsiteOrigin } from "../../../../globals";
import { useSubWebsite } from "../../../../hooks/bc";
import { useClassroomsUser } from "../../../../hooks/users";
import { Group } from "../../../../models/group";
import { sortByClassTimes } from "../../../headmaster/main/with-classrooms";
import {
	useSelectedSchoolLabelId,
	useTeacherSelectedSchoolId,
} from "../../contexts/teacher-school";
import { ArchivedClassroomsContainer } from "./archived-classrooms";
import {
	TVSchoolClassroomBoxesContainer,
	TVSchoolClassroomBoxesLoaderContainer,
} from "./classrooms";
import { TeacherTVSchoolPopups } from "./popups";
import styles from "./styles/tvschool-classroom.module.css";

export const archiveClassroomUpperBoundDate = new Date(2020, 8, 6);

export const TVSchoolTeacherMainPage: React.FC<{ user: User }> = ({ user }) => {
	const assignemntsCount = useTeacherAssignmentsCount();
	const history = useHistory();
	const teacherInfo = useTeacherInfo();
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;

	useLayoutEffect(() => {
		if (!assignemntsCount.isSuccessfullyLoaded) return;
		if (!teacherInfo) return;
		const ClassroomModel = inject("ClassroomModel");
		const GroupModel = inject("GroupModel");
		const visibleClassroomsCount = teacherInfo.classrooms
			.map((e) => new ClassroomModel(e))
			.filter((e) => e.isVisible()).length;
		const visibleGroupsCount = teacherInfo.groups
			.map((e) => new GroupModel(e))
			.filter((e) => e.isVisible()).length;

		const shouldRedirectToNewAssignmentPage =
			assignemntsCount.doc.count === 0 &&
			visibleClassroomsCount === 0 &&
			visibleGroupsCount === 0 &&
			!isBritishCenter;

		if (shouldRedirectToNewAssignmentPage) {
			sessionStorage.setItem("isFirstAssignment", "true");
			history.push(`/assignments/new`);
		}
	}, [
		history,
		assignemntsCount.isSuccessfullyLoaded,
		assignemntsCount.doc,
		teacherInfo,
		isBritishCenter,
	]);

	if (!assignemntsCount.isSuccessfullyLoaded || !teacherInfo) {
		return <FancyLoadingCenter />;
	}

	return (
		<>
			<TeacherClassroomsPage teacherInfo={teacherInfo} />
			<TeacherTVSchoolPopups />
		</>
	);
};

const TeacherClassroomsPage = React.memo<{
	teacherInfo: ReturnType<typeof useTeacherInfo>;
	//TODO: Fix this later
	// eslint-disable-next-line max-lines-per-function
}>(({ teacherInfo }) => {
	const myClassrooms = useMyClassroomsAsTeacher(true);
	const locale = useLocale();
	const isGeo = locale === "ka";
	const teacherSchoolId = useTeacherSelectedSchoolId();
	const schoolLabelId = useSelectedSchoolLabelId();
	const filterBySchool = useTeacheruseSchoolAndLabelFiltererFn();
	const user = useClassroomsUser()!;
	const visibleClassrooms = useMemo(
		() =>
			(myClassrooms || [])
				.filter((e) => e.isVisible())
				.filter(filterBySchool),
		[myClassrooms, filterBySchool]
	);
	const displayTimetable =
		visibleClassrooms.some((e) => e.classTimes.length > 0) &&
		!disabledTimetableSchools.includes(teacherSchoolId!);

	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	const classroomIds = useMemo(
		() =>
			[...visibleClassrooms]
				.sort(isBritishCenter ? sortByClassTimes : compareClassrooms)
				.map((e) => e._id),
		[visibleClassrooms, isBritishCenter]
	);

	const visibleGroups = useMemo(() => {
		return filterGroupsForTeacher(teacherInfo, "visible", filterBySchool);
	}, [teacherInfo, filterBySchool]);

	const conferences = useConferences({
		schoolId: teacherSchoolId,
		schoolLabelId: schoolLabelId,
		includeLabelless: true,
	});

	const enableArchive = useMemo(() => {
		return visibleClassrooms.map((e) => e.isPremium()).includes(false);
	}, [visibleClassrooms]);

	const archivedClassrooms = useArchivedClassroomsOfTeacher(
		user.id,
		filterBySchool
	);
	const archivedGroups = filterGroupsForTeacher(
		teacherInfo,
		"archived",
		filterBySchool
	);
	const archivedSemesters = useArchivedSemesters({
		classrooms: archivedClassrooms,
		groups: archivedGroups,
	});
	return (
		<div className={styles.classrooms}>
			<div className={styles.myClassrooms}>
				{getFormattedMessage("myClassrooms")}
			</div>
			<div className="relative z-10 flex flex-col justify-center items-center py-0 2xl:px-24 xl:px-10 px-8">
				{!teacherInfo ? (
					<TVSchoolClassroomBoxesLoaderContainer />
				) : (
					<TVSchoolClassroomBoxesContainer
						classroomIds={classroomIds}
						enableArchive={enableArchive}
						groups={visibleGroups}
						academicPersonnelConferences={conferences}
						displayTimetable={displayTimetable}
						archivedSemesters={archivedSemesters}
					/>
				)}
			</div>
			<ArchivedClassroomsContainer />
		</div>
	);
});

const useArchivedClassroomsOfTeacher = (
	teacherId: number | null,
	filterer?: (classroom: IClassroom) => boolean
) => {
	const classrooms = useFetch(() => {
		return inject("SchoolsController").getArchivedClassroomsForTeacher({
			teacherId: teacherId!,
			isArchived: true,
		});
	}, [teacherId]);

	return useMemo(() => {
		if (!classrooms.isSuccessfullyLoaded) return null;
		if (!filterer) return classrooms.doc;
		return classrooms.doc.filter(filterer);
	}, [classrooms, filterer]);
};

const isTeacherElligibleForWeeklyTests = (
	classrooms: IClassroom[] | undefined
) => {
	if (!classrooms) return false;
	const ClassroomModel = inject("ClassroomModel");
	const hasPremiumClassroom = classrooms.some((e) =>
		new ClassroomModel(e).isPremium()
	);

	return (
		!hasPremiumClassroom &&
		classrooms.some((c) => isEligibleGradeForWeeklyTests(c.grade))
	);
};

export const useDisplayTeacherKingsPopupEvenIfHasSeen = () => {
	return !!useDisplayPopupEvenIfHasSeen("kp");
};

const useDisplayPopupEvenIfHasSeen = (queryKey: string) => {
	const history = useHistory();
	const { [queryKey]: displayEvenIfHasSeen } = useLocationQuery(true);
	useEffect(() => {
		if (displayEvenIfHasSeen) {
			history.replace("/"); // TODO: instead of redirecting to main page, try simply removing the query from search url
		}
	}, [displayEvenIfHasSeen, history]);
	return displayEvenIfHasSeen;
};

const compareClassrooms = (cl1: Classroom, cl2: Classroom) => {
	if (cl1.grade !== cl2.grade) {
		return cl1.grade - cl2.grade;
	}
	return cl1.name < cl2.name ? -1 : 1;
};

export const filterGroupsForTeacher = (
	teacherInfo: IRGETMyInfoAsTeacher | null | undefined,
	flag: "visible" | "archived",
	filterer?: (group: IGroup) => boolean
) => {
	if (!teacherInfo) return null;
	const GroupModel = inject("GroupModel");
	return teacherInfo.groups
		.map((gr) => new GroupModel(gr))
		.filter(
			(gr) =>
				(flag === "visible" ? gr.isVisible() : !gr.isVisible()) &&
				(!filterer || filterer(gr))
		)
		.sort(groupsComparer);
};

export const groupsComparer = (a: Group, b: Group) => {
	if (a.name && b.name) {
		return a.name > b.name ? 1 : -1;
	}
	return a.grade - b.grade;
};
