/* eslint-disable max-lines-per-function */
import React, {
	useState,
	useRef,
	useLayoutEffect,
	useImperativeHandle,
} from "react";
import CancelIcon from "@material-ui/icons/CancelRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import {
	Cancel,
	PopupBody,
	PopupCSS,
	PopupHeader,
	IconContainer,
} from "./styles/styles";
import "./styles/popup.css";
import * as classNames from "classnames";
import Portal from "@material-ui/core/Portal";
import { classNamed } from "@pckgs/classed-components";
import { useElementSize } from "@pckgs/responsive/resize";
import { useWindowHeight } from "@app/hooks/front";

interface IPopupProps {
	onClose?: () => void;
	className?: string;
	style?: React.CSSProperties;
}

class Popup extends React.Component<IPopupProps, { show: boolean }> {
	_isMounted = false;

	state = {
		show: false,
	};

	popupRef = React.createRef<HTMLDivElement>();

	componentDidMount() {
		this._isMounted = true;
		document.body.style.overflow = "hidden";

		const popupDiv = document.createElement("div");
		(this.popupRef as any).current = popupDiv;
		document.body.appendChild(popupDiv);
		this.forceUpdate();
		setTimeout(() => {
			this.setState({ show: true });
		}, 1);
	}

	componentWillUnmount() {
		this.onClose();
	}

	onClose = () => {
		this._isMounted = false;
		document.body.style.overflow = "auto";
		if (this.popupRef.current) {
			this.popupRef.current.remove();
		}
	};

	private popupBlackClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget && this.props.onClose) {
			this.props.onClose();
		}
	};

	render() {
		if (!this.popupRef) return null;
		return (
			<React.Fragment>
				<Portal container={this.popupRef.current}>
					<div
						className={classNames(
							"popupBlackDiv",
							this.props.className
						)}
						onClick={this.popupBlackClick}
						style={this.props.style}
					>
						{this.state.show && this.props.children}
					</div>
				</Portal>
			</React.Fragment>
		);
	}
}

interface IPopupContentProps {
	scrollable?: boolean;
	style?: React.CSSProperties;
	className?: string;
}

export const PopupContent: React.FC<IPopupContentProps> = ({
	scrollable = true,
	style,
	className,
	children,
}) => (
	<div
		className={classNames(
			"popup-content",
			scrollable && "scrollable",
			className
		)}
		style={style}
	>
		{children}
	</div>
);

interface IPopupContentWithCloseProps {
	onClose: () => void;
	style?: React.CSSProperties;
	className?: string;
	icon?: JSX.Element;
	popupBodyClassname?: string;
}

export const PopupContentWithClose: React.FC<IPopupContentWithCloseProps> = (
	props
) => (
	<div className={classNames(PopupCSS, props.className)} style={props.style}>
		{props.icon ? (
			<div onClick={props.onClose} className={IconContainer}>
				{props.icon}
			</div>
		) : (
			<div className={PopupHeader}>
				<CancelIcon className={Cancel} onClick={props.onClose} />
			</div>
		)}

		<div className={props.popupBodyClassname || PopupBody}>
			{props.children}
		</div>
	</div>
);

export default Popup;

export const PopupTitle = classNamed("h1")(
	"text-center text-base sm:text-lg md:text-2xl text-primary-dark font-roboto-geo-caps mb-6"
);

export const PopupClose = ({ onClick }: { onClick?: () => void }) => {
	return (
		<CloseRoundedIcon
			style={{ top: "1rem", right: "1rem" }}
			className="text-primary-dark absolute cursor-pointer"
			onClick={onClick}
		/>
	);
};

interface FancyPopupProps {
	onClose?: () => void;
	hideCloseIcon?: boolean;
	onCloseIconClick?: () => void;
	title?: string | JSX.Element | null | number;
	children?: any;
	direction?: "left" | "center";
	containerStyles?: React.CSSProperties;
	containerClassName?: string;
}

export interface FancyPopupRef {
	onClose: () => void;
}

export const FancyPopup = React.forwardRef<FancyPopupRef, FancyPopupProps>(
	(
		{
			hideCloseIcon,
			onClose,
			onCloseIconClick,
			title,
			children,
			direction = "center",
			containerStyles,
			containerClassName,
		},
		ref
	) => {
		const [show, setShow] = useState(false);

		const popupRef = useRef<HTMLDivElement>(null);
		const contentRef = useRef<HTMLDivElement>(null);
		const unmountRef = useRef<() => void>();

		useImperativeHandle(
			ref,
			() => ({
				onClose: () => unmountRef.current?.(),
			}),
			[]
		);

		useLayoutEffect(() => {
			document.body.style.overflow = "hidden";
			const popupDiv = document.createElement("div");
			(popupRef as any).current = popupDiv;
			document.body.appendChild(popupDiv);
			const timer = setTimeout(() => {
				setShow(true);
			}, 1);
			unmountRef.current = () => {
				clearTimeout(timer);
				document.body.style.overflow = "auto";
				popupRef.current?.remove();
			};
			return unmountRef.current;
		}, []);

		const popupBlackClick = (e: React.MouseEvent<HTMLDivElement>) => {
			if (e.target === e.currentTarget && onClose) {
				onClose();
			}
		};

		const { height } = useElementSize(contentRef);
		const windowHeight = useWindowHeight();

		return (
			<Portal container={popupRef.current}>
				<div
					className="popupBlackDiv"
					onClick={popupBlackClick}
					style={{
						alignItems:
							typeof height === "number" &&
							height + 30 > windowHeight
								? "flex-start"
								: "center",
					}}
				>
					{show && (
						<div
							className={
								"p-4 sm:p-8 bg-white rounded-xl relative my-8 font-roboto-geo-nus " +
								(containerClassName || "")
							}
							style={{
								width: 600,
								maxWidth: "95%",
								textAlign: direction,
								...containerStyles,
							}}
							ref={contentRef}
						>
							{!hideCloseIcon && (
								<PopupClose
									onClick={onCloseIconClick || onClose}
								/>
							)}
							{typeof title !== "undefined" && title !== null && (
								<PopupTitle>{title}</PopupTitle>
							)}
							{children}
						</div>
					)}
				</div>
			</Portal>
		);
	}
);
