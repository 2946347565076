import { TopicSchema, ITopic } from "@app/api/topics/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CourseCommonMetaInfo } from "./meta-info";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = ITopic;
export type IStateTopics = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(TopicSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("topic");
const storageSettings = getDefaultStorageSettings("topics");
const metaInformationName = "topicsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().topics) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", TopicSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Topic extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static meta = new CourseCommonMetaInfo(storage, metaInformationName);
}

export type ITopicInstance = Topic;
export type ITopicModel = typeof Topic;

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Topic.meta);

export const topicsReducer = getDefaultReducer(storageSettings, () => Topic);
