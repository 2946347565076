import {
	IUserFolderLevel,
	UserFolderLevelSchema as OriginalUserFolderLevelSchema,
} from "@app/api/folders/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CourseCommonMetaInfo } from "./meta-info";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

const UserFolderLevelSchema = OriginalUserFolderLevelSchema.keys({
	needsRecalculation: Joi.boolean(),
});

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IUserFolderLevel & { needsRecalculation?: boolean };
export type IStateUserFolderLevels = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(UserFolderLevelSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("userFolderLevel");
const storageSettings = getDefaultStorageSettings("userFolderLevels");
const metaInformationName = "userFolderLevelsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().userFolderLevels) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"ObjectId",
				UserFolderLevelSchema
			),
			filter: isLoadedRecentlyEnough,
		}),
	indices: [{ fields: ["userId", "courseId"], unique: true }],
});

// ==============Main Model=================

export class UserFolderLevel extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	getLevel(folderId: ObjectId) {
		const level = this.levelsByFolderId[folderId];
		if (level) {
			return new FolderLevel(level);
		}
		return new FolderLevel({
			cardsLevel: 0,
			questionsLevel: 0,
			totalLevel: 0,
		});
	}

	static meta = new CourseCommonMetaInfo(storage, metaInformationName);
}

const formatLevel = (level: number): string => {
	return "" + numericFormatLevel(level);
};
const numericFormatLevel = (level: number): number => {
	return Math.round(level * 100) / 10;
};

export class FolderLevel {
	questionsLevel?: number;
	cardsLevel?: number;
	totalLevel: number;

	constructor(args: {
		questionsLevel?: number;
		cardsLevel?: number;
		totalLevel: number;
	}) {
		this.questionsLevel = args.questionsLevel;
		this.cardsLevel = args.cardsLevel;
		this.totalLevel = args.totalLevel;
	}

	getFormattedTotalLevel() {
		return formatLevel(this.totalLevel);
	}

	getFormattedQuestionsLevel() {
		return formatLevel(this.questionsLevel || 0);
	}

	getFormattedCardsLevel() {
		return formatLevel(this.cardsLevel || 0);
	}

	static formatLevel = formatLevel;
	static numericFormatLevel = numericFormatLevel;
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, UserFolderLevel.meta);

export const userFolderLevelsReducer = getDefaultReducer(
	storageSettings,
	() => UserFolderLevel
);
