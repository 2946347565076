/* eslint-disable max-lines-per-function */
import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import styles from "@app/components/layouts/sidebar/styles/index.module.scss";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { InputWithSaveButton } from "@app/components/widgets/input-with-save-button";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { SubWebsiteOrigin } from "@app/globals";
import { useSubWebsite } from "@app/hooks/bc";
import { useBoolean } from "@app/hooks/general";
import { useClassroomsUser } from "@app/hooks/users";
import { useVideoLesson } from "@app/hooks/video-lessons";
import { inject } from "@app/modules";
import { copyTextToClipboard } from "@app/utils/clipboard";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useCallback, useMemo } from "react";
import {
	getResourceIdFromWhazz,
	getResourceTypeFromWhazz,
} from "./helpers/functions";
import { SidebarWhazz } from "./helpers/interfaces";

export const VideoLessonLink = React.memo<{
	resourceId: ObjectId;
	resourceType: VideoLessonResourceTypes;
}>(({ resourceId, resourceType }) => {
	const { value: isLinkRequestPopupOpen, switchValue: toggleRequestPopup } =
		useBoolean();
	const user = useClassroomsUser();
	const subWebsite = useSubWebsite();
	const { handleVideoURLSave, onTemporaryLinkRequest } =
		useVideoLinkHelperFns({
			resourceId,
			resourceType,
			toggleRequestPopup,
		});

	const videoLesson = useVideoLesson(resourceId, resourceType);
	const videoLink = videoLesson.doc?.url;

	const texts = useVideoLinkTexts(resourceType);
	const handleCopy = () => {
		if (videoLink) {
			copyTextToClipboard(videoLink);
			alert("ბმული დაკოპირდა");
		}
	};

	const isBasicHeadmaster =
		user?.isHeadmaster() && subWebsite === SubWebsiteOrigin.main;
	const showLink =
		(subWebsite === SubWebsiteOrigin.britishCenter && !user?.isStudent()) ||
		user?.isTeacher();
	return (
		<div className={styles["video-link-container"]}>
			{!isBasicHeadmaster && (
				<div className={styles["video-link-button"]}>
					<img
						src="/imgs/TuTorial.svg"
						className={styles.video_icon}
						alt=""
					/>
					<span>{texts.title}</span>
				</div>
			)}

			{showLink && (
				<React.Fragment>
					<div className={styles["instruction"]}>
						{texts.description}
					</div>

					<InputWithSaveButton
						onSave={handleVideoURLSave}
						defaultValue={videoLink || undefined}
					/>
				</React.Fragment>
			)}
			<div className={styles.copy_link__wrapper} onClick={handleCopy}>
				{videoLink && (
					<div style={{ position: "relative", bottom: 0 }}>
						{user?.isStudent() && (
							<p className={styles.show_link_wrapper}>
								{videoLink}
							</p>
						)}
						{(subWebsite === SubWebsiteOrigin.britishCenter ||
							user?.isTeacher() ||
							user?.isStudent()) && (
							<p className={styles.show_link_text}>
								ბმულის დაკოპირება
							</p>
						)}
					</div>
				)}
			</div>

			{videoLink && user?.isTeacher() && (
				<div
					className={styles["video-link-not-working"]}
					onClick={toggleRequestPopup}
					style={{ marginTop: 40 }}
				>
					არ მუშაობს ბმული?
				</div>
			)}
			{isLinkRequestPopupOpen && (
				<VideoLinkReplacePopup
					onConfirm={onTemporaryLinkRequest}
					onClose={toggleRequestPopup}
				/>
			)}
		</div>
	);
});

const useVideoLinkHelperFns = ({
	resourceId,
	resourceType,
	toggleRequestPopup,
}: {
	resourceId: ObjectId;
	resourceType: VideoLessonResourceTypes;
	toggleRequestPopup: () => void;
}) => {
	const handleVideoURLSave = useCallback(
		(newLink: string) => {
			const VideoLessonsController = inject("VideoLessonsController");
			VideoLessonsController.add({
				resourceId,
				resourceType,
				url: newLink,
			})
				.then(() => {
					openConfirmationPopup({
						text: `${
							(resourceType === VideoLessonResourceTypes.CLASSROOM
								? "ვიდეოგაკვეთილის"
								: "") +
							(resourceType === VideoLessonResourceTypes.GROUP
								? "სადამრიგებლო საათის"
								: "") +
							(resourceType ===
							VideoLessonResourceTypes.CONFERENCE
								? "სამასწავლებლო შეხვედრის"
								: "")
						} ბმული წარმატებით დაემატა`,
						approveTitle: "გასაგებია",
					});
				})
				.catch((e) => alert("დაფიქსირდა შეცდომა"));
		},
		[resourceId, resourceType]
	);

	const onTemporaryLinkRequest = useCallback(() => {
		const VideoLessonsController = inject("VideoLessonsController");
		VideoLessonsController.requestTemporaryLink({
			resourceId,
			resourceType,
			allClassrooms: true,
		})
			.then(() => {
				openConfirmationPopup({
					text:
						`თქვენ ჩაანაცვლეთ ძველი ბმული. შეგიძლიათ, შეხვიდეთ ${
							resourceType === VideoLessonResourceTypes.CLASSROOM
								? "გაკვეთილზე"
								: "შეხვედრაზე"
						}. ` +
						`ბმული ჩანაცვლებულია ${
							resourceType === VideoLessonResourceTypes.CONFERENCE
								? "მასწავლებლებთანაც"
								: "მოსწავლეებთანაც"
						}. სთხოვეთ მათ ხელახლა დააწკაპუნონ ` +
						`ღილაკს "${
							(resourceType === VideoLessonResourceTypes.CLASSROOM
								? "ვიდეოგაკვეთილზე"
								: "") +
							(resourceType === VideoLessonResourceTypes.GROUP
								? "სადამრიგებლოში"
								: "") +
							(resourceType ===
							VideoLessonResourceTypes.CONFERENCE
								? "სამასწავლებლოში"
								: "")
						} შესვლა".`,
					approveTitle: "გასაგებია",
				});
			})
			.catch((e) => alert("დაფიქსირდა შეცდომა"));
		toggleRequestPopup();
	}, [resourceId, resourceType, toggleRequestPopup]);

	return { onTemporaryLinkRequest, handleVideoURLSave };
};

const useVideoLinkTexts = (resourceType: VideoLessonResourceTypes) => {
	return useMemo((): { title: string; description: string } => {
		switch (resourceType) {
			case VideoLessonResourceTypes.CLASSROOM:
				return {
					title: "ვიდეოგაკვეთილის ბმული",
					description:
						"გთხოვთ, ჩააკოპიროთ დაგეგმილი ვიდეოგაკვეთილის ბმული",
				};
			case VideoLessonResourceTypes.GROUP:
				return {
					title: "სადამრიგებლო საათის ბმული",
					description:
						"გთხოვთ, ჩააკოპიროთ დაგეგმილი სადამრიგებლო საათის ბმული",
				};
			case VideoLessonResourceTypes.CONFERENCE:
				return {
					title: getFormattedMessage("conferenceLink"),
					description: getFormattedMessage(
						"conferenceLinkDescription"
					),
				};
		}
	}, [resourceType]);
};

interface IVideoLinkReplacePopup {
	onConfirm: () => void;
}

const VideoLinkReplacePopup: React.FC<
	IVideoLinkReplacePopup & {
		onClose: () => void;
	}
> = React.memo(({ onConfirm, onClose }) => {
	return (
		<Popup onClose={() => onClose()}>
			<PopupContentWithClose onClose={() => onClose()}>
				<div style={{ padding: "10px 30px" }}>
					<p>
						თუ არსებული ბმული აღარ მუშაობს, შეგიძლიათ ჩაანაცვლოთ
						ახლით.
						<br />
						გსურთ ბმულის ჩანაცვლება?
					</p>
					<div className={styles["popup-buttons"]}>
						<button
							className={styles["confirm"]}
							onClick={onConfirm}
						>
							კი
						</button>
						<button className={styles["reject"]} onClick={onClose}>
							არა
						</button>
					</div>
				</div>
			</PopupContentWithClose>
		</Popup>
	);
});

export const getVideoLessonComponent = (whazz: SidebarWhazz) => {
	const resourceId = getResourceIdFromWhazz(whazz);
	const resourceType = getResourceTypeFromWhazz(whazz);

	if (!resourceId || resourceType === null) return null;

	return (
		<div className={styles.video_link__wrapper}>
			<VideoLessonLink
				resourceId={resourceId}
				resourceType={resourceType}
			/>
		</div>
	);
};
