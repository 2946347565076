import Joi from "@app/utils/joi";

export const CitySchema = Joi.object({
	id: Joi.number().integer().required(),
	name: Joi.string().required(),
	country: Joi.string().required(),
});
export interface ICity {
	id: number;
	name: string;
	country: string;
}
