import React from "react";
import { css } from "emotion";
import classNames from "classnames";

interface Option {
	value: number | string;
	label: string | JSX.Element;
}

interface TabNavProps<O extends Option> {
	options: O[];
	onChange: (option: O) => void;
	value: O["value"] | null;
	whiteSelectedOption?: boolean;
}

export const TabNav = <O extends Option>(props: TabNavProps<O>) => {
	return (
		<div className={containerCSS}>
			{props.options.map((option) => (
				<div
					className={classNames(
						optionCSS,
						option.value === props.value &&
							(props.whiteSelectedOption
								? moreContrastSelectedOptionCSS
								: selectedOptionCSS)
					)}
					key={option.value}
					onClick={() => props.onChange(option)}
				>
					{option.label}
				</div>
			))}
		</div>
	);
};

const containerCSS = css`
	border: 1px solid #b7b7b7;
	border-radius: 12px;
	display: inline-flex;
`;

const optionCSS = css`
	font-family: "Roboto Geo MT Bold";
	color: #b7b7b7;
	height: 35px;
	border-radius: 12px;
	padding: 5px 10px;
	min-width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 14px;

	@media screen and (max-width: 600px) {
		min-width: 100px;
	}
`;

const selectedOptionCSS = css`
	background: #5273e6;
	color: white;
	box-shadow: 0 0 0 1px #5273e6;
`;

const moreContrastSelectedOptionCSS = css`
	background: white;
	color: #5273e6;
	box-shadow: 0 0 0 2px #e7e7e7;
`;
