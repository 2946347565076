import {
	AssignmentSchema,
	IAssignment,
	AnsweredQuestionSchema,
	AssignmentEdgeSchema,
	UserWrittenAssignmentSchema,
	CustomSettingsSchema,
	RUserWrittenAssignmentSchema,
	IRUserWrittenAssignment,
	AssignmentSettingsSchema,
	IUserWrittenAssignment,
	IAssignmentSettings,
	IStartedStudentAssignmentEdge,
	NumQuestionsSettingsSchema,
	ICustomSettings,
	IAnsweredQuestion,
	IAssignmentEdge,
	INumQuestionsSettings,
	StartedStudentAssignmentEdgeSchema,
} from "./helper-schemas";
import Joi, { ObjectId } from "@tests-core/utils/joi";
import { RGETTestContentsSchema, IRGETTestContents } from "../tests/validators";
import {
	markKeysForbidden,
	ToInsertKeys,
	insertStripKeys,
	updateStripKeys,
	ToUpdateKeys,
	ToLooseUpdateKeys,
	toLooseUpdateSchema,
} from "../helper-schemas";
import {
	TestTypeSettingsSchema,
	ITestTypeSettings,
} from "../test-types/helper-schemas";

/* create assignment */
const assignmentPostForbiddenKeys = [
	"testId",
	"author",
	"numQuestions",
	"classroomIds",
] as const;

export const APOSTCreateAssignmentSchema = AssignmentSchema.keys(
	insertStripKeys
)
	.fork(assignmentPostForbiddenKeys, markKeysForbidden)
	.keys({
		classroomIds: Joi.array().items(Joi.objectId()).required(),
		questions: Joi.array().items(Joi.objectId()).required(),
		customSettings: CustomSettingsSchema,
	});
export type IAPOSTCreateAssignment = ToInsertKeys<
	IAssignment,
	never,
	(typeof assignmentPostForbiddenKeys)[number]
> & {
	classroomIds: ObjectId[];
	questions: ObjectId[];
	customSettings?: ICustomSettings;
};

export const RPOSTCreateAssignmentSchema = AssignmentSchema;
export type IRPOSTCreateAssignment = IAssignment;

/* get assignment(s) */
export const RGETByIdSchema = AssignmentSchema;
export type IRGETById = IAssignment;

///
export const AGETByClassroomSchema = Joi.object({
	classroomId: Joi.objectId().required(),
});
export interface IAGETByClassroom {
	classroomId: ObjectId;
}

export const RGETByClassroomSchema = Joi.array().items(AssignmentSchema);
export type IRGETByClassroom = IAssignment[];

///

export const AGETCountByClassroomSchema = Joi.object({
	classroomId: Joi.objectId().required(),
});
export interface IAGETCountByClassroom {
	classroomId: ObjectId;
}

export const RGETCountByClassroomSchema = Joi.object({
	count: Joi.number().integer().required(),
});
export interface IRGETCountByClassroom {
	count: number;
}

///

/* start assignment */
const AssignmentStartCourseInfoSchema = Joi.object({
	courseId: Joi.objectId().required(),
	testsFolderId: Joi.objectId().allow(null).required(),
});
interface IAssignmentStartCourseInfo {
	courseId: ObjectId;
	testsFolderId: ObjectId | null;
}

//

export const RPUTStartAssignmentSchema = Joi.object({
	content: RGETTestContentsSchema.allow(null).required(),
	assignment: AssignmentSchema.required(),
	studentEdge: StartedStudentAssignmentEdgeSchema.required(),
	settings: AssignmentSettingsSchema.required(),
	writtenAssignment: UserWrittenAssignmentSchema,
	deadlinePassed: Joi.boolean().required(),
	isSubmitted: Joi.boolean().required(),
	isAvailable: Joi.boolean().required(),
	courseInfo: AssignmentStartCourseInfoSchema.required(),
});
export interface IRPUTStartAssignment {
	content: IRGETTestContents | null;
	assignment: IAssignment;
	studentEdge: IStartedStudentAssignmentEdge;
	writtenAssignment?: IUserWrittenAssignment;
	settings: IAssignmentSettings;
	deadlinePassed: boolean;
	isSubmitted: boolean;
	isAvailable: boolean;
	courseInfo: IAssignmentStartCourseInfo;
}

/* close assignment (move deadline and optionally make answers available) */
export const APUTCloseAssignmentSchema = Joi.object({
	assignmentId: Joi.objectId().required(),
	hideAnswers: Joi.boolean().optional(),
});
export interface IAPUTCloseAssignment {
	assignmentId: ObjectId;
	hideAnswers?: boolean;
}

export type IRPUTCloseAssignment = void;

/* open assignment ( hide answers) */
export const APUTOpenAssignmentSchema = Joi.object({
	assignmentId: Joi.objectId().required(),
	openAnswers: Joi.boolean().optional(),
});
export interface IAPUTOpenAssignment {
	assignmentId: ObjectId;
	openAnswers?: boolean;
}

export type IRPUTOpenAssignment = void;

export const APUTArchiveAssignmentSchema = Joi.object({
	assignmentId: Joi.objectId().required(),
	archived: Joi.boolean().required(),
});
export interface IAPUTArchiveAssignment {
	assignmentId: ObjectId;
	archived: boolean;
}

export type IRPUTArchiveAssignment = void;

/* save assignment progress */
export const APUTSaveAssignmentProgressSchema = Joi.object({
	assignmentId: Joi.objectId().required(),
	startedAt: Joi.date().required(),
	answers: Joi.array().items(AnsweredQuestionSchema).required(),
	submit: Joi.boolean(),
	assignmentTimerToken: Joi.string(),
});
export interface IAPUTSaveAssignmentProgress {
	assignmentId: ObjectId;
	startedAt: Date;
	answers: IAnsweredQuestion[];
	submit?: boolean;
	assignmentTimerToken?: string;
}

export const RPUTSaveAssignmentProgressSchema = Joi.object({
	questions: Joi.array().items(Joi.any()).optional(),
	texts: Joi.array().items(Joi.any()).optional(),
	userWrittenAssignmentId: Joi.objectId().required(),
});
export interface IRPUTSaveAssignmentProgress {
	questions?: any[];
	texts?: any[];
	userWrittenAssignmentId: ObjectId;
}

/* submit assignment */

export const APUTSubmitAssignmentSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAPUTSubmitAssignment {
	_id: ObjectId;
}
export const RPUTSubmitAssignmentSchema = RPUTSaveAssignmentProgressSchema;
export type IRPUTSubmitAssignment = IRPUTSaveAssignmentProgress;

/* edit assignment */
export const APUTEditAssignmentSchema = toLooseUpdateSchema(
	AssignmentSchema
).keys({
	updateForEveryone: Joi.boolean(),
	classroomIdsToRemove: Joi.array().items(Joi.objectId()),
	questions: Joi.array().items(Joi.objectId()),
});
export interface IAPUTEditAssignment extends ToLooseUpdateKeys<IAssignment> {
	customSettings?: ICustomSettings;
	updateForEveryone?: boolean;
	classroomIdsToRemove?: ObjectId[];
	questions?: ObjectId[];
}

export const RPUTEditAssignmentSchema = AssignmentSchema;
export type IRPUTEditAssignment = IAssignment;

/* get homework list */
export const AGETTeacherHomeworkListSchema = Joi.object({
	classroomId: Joi.objectId(),
	startPosition: Joi.number().integer().greater(-1),
	limit: Joi.number(),
	sortBy: Joi.string().valid("UPDATED_AT", "DEADLINE"),
	order: Joi.string().valid("ASC", "DESC"),
	studentIds: Joi.array().items(Joi.number()),
});
export interface IAGETTeacherHomeworkList {
	classroomId?: ObjectId;
	startPosition?: number;
	limit?: number;
	sortBy?: "UPDATED_AT" | "DEADLINE";
	order?: "ASC" | "DESC";
	studentIds?: number[];
}

export const AGETStudentHomeworkListSchema = Joi.object({
	classroomId: Joi.objectId(),
	cognitiveClassroomIds: Joi.array().items(Joi.objectId().required()),
	startPosition: Joi.number().integer().greater(-1),
	limit: Joi.number(),
	sortBy: Joi.string().valid("UPDATED_AT", "DEADLINE"),
	order: Joi.string().valid("ASC", "DESC"),
	studentIds: Joi.array().items(Joi.number()),
});
export interface IAGETStudentHomeworkList {
	classroomId?: ObjectId;
	cognitiveClassroomIds?: ObjectId[];
	startPosition?: number;
	limit?: number;
	sortBy?: "UPDATED_AT" | "DEADLINE";
	order?: "ASC" | "DESC";
	studentIds?: number[];
}

///

export const AGETStudentCognitiveHomeworkListSchema = Joi.object({
	cognitiveCourseIds: Joi.array().items(Joi.objectId().required()).required(),
	startPosition: Joi.number().integer().greater(-1),
	limit: Joi.number(),
	sortBy: Joi.string().valid("UPDATED_AT", "DEADLINE"),
	order: Joi.string().valid("ASC", "DESC"),
	studentIds: Joi.array().items(Joi.number()),
});

export interface IAGETStudentCognitiveHomeworkList {
	cognitiveCourseIds: ObjectId[];
	startPosition?: number;
	limit?: number;
	sortBy?: "UPDATED_AT" | "DEADLINE";
	order?: "ASC" | "DESC";
	studentIds?: number[];
}

export type IRGETCognitiveHomeworkListForStudent = IStudentHomeworkListItem[];

///

export const TeacherHomeworkListItemSchema = Joi.object({
	assignmentId: Joi.objectId().required(),
	assignment: AssignmentSchema.required(),
	edges: Joi.array().items(AssignmentEdgeSchema).required(),
	writtenAssignments: Joi.array()
		.items(UserWrittenAssignmentSchema)
		.required(),
});
export interface ITeacherHomeworkListItem {
	assignmentId: ObjectId;
	assignment: IAssignment;
	edges: IAssignmentEdge[];
	writtenAssignments: IUserWrittenAssignment[];
}

export const StudentHomeworkListItemSchema = Joi.object({
	assignmentId: Joi.objectId().required(),
	assignment: AssignmentSchema.required(),
	edges: Joi.array().items(AssignmentEdgeSchema).required(),
	writtenAssignment: RUserWrittenAssignmentSchema.allow(null).required(),
}) as any;
export type IStudentHomeworkListItem = Omit<
	ITeacherHomeworkListItem,
	"writtenAssignments"
> & { writtenAssignment: IRUserWrittenAssignment | null };

export const RGETHomeworkListForTeacherSchema = Joi.array().items(
	TeacherHomeworkListItemSchema
);
export type IRGETHomeworkListForTeacher = ITeacherHomeworkListItem[];

export const RGETHomeworkListForStudentSchema = Joi.array().items(
	StudentHomeworkListItemSchema
) as any;

export const RGETCognitiveHomeworkListForStudentSchema = Joi.array().items(
	StudentHomeworkListItemSchema
) as any;
export type IRGETHomeworkListForStudent = IStudentHomeworkListItem[];

// many assignments for teacher by ids
export const AGETManyByIdsSchema = Joi.object({
	ids: Joi.array().items(Joi.objectId()).required(),
});
export interface IAGETManyByIds {
	ids: ObjectId[];
}

export const RGETManyByIdsSchema = Joi.array().items(AssignmentSchema);
export type IRGETManyByIds = IAssignment[];

// single assignment fully for teacher
export const AGETSingleAssignmentForTeacherSchema = Joi.object({
	assignmentId: Joi.objectId().required(),
	classroomId: Joi.objectId().required(),
});
export interface IAGETSingleAssignmentForTeacher {
	assignmentId: ObjectId;
	classroomId: ObjectId;
}

export const RGETSingleAssignmentForTeacherSchema = Joi.object({
	assignment: AssignmentSchema.required(),
	edges: Joi.array().items(AssignmentEdgeSchema).required(),
	writtenAssignments: Joi.array()
		.items(UserWrittenAssignmentSchema)
		.required(),
});
export interface IRGETSingleAssignmentForTeacher {
	assignment: IAssignment;
	edges: IAssignmentEdge[];
	writtenAssignments: IUserWrittenAssignment[];
}

// single assignment of user for teacher
export const AGETSingleUserAssignmentForTeacherSchema = Joi.object({
	assignmentId: Joi.objectId().required(),
	userId: Joi.number().required(),
	classroomId: Joi.objectId().required(),
});
export interface IAGETSingleUserAssignmentForTeacher {
	assignmentId: ObjectId;
	classroomId: ObjectId;
	userId: number;
}

export const RGETSingleUserAssignmentForTeacherSchema = Joi.object({
	assignment: AssignmentSchema.required(),
	mainEdge: AssignmentEdgeSchema.required(),
	writtenAssignment: UserWrittenAssignmentSchema.allow(null).required(),
	settings: AssignmentSettingsSchema.required(),
});
export interface IRGETSingleUserAssignmentForTeacher {
	assignment: IAssignment;
	mainEdge: IAssignmentEdge;
	writtenAssignment: IUserWrittenAssignment | null;
	settings: IAssignmentSettings;
}

///

export const AGETTaskTypeContentsSchema = Joi.object({
	topicIds: Joi.array().items(Joi.objectId()),
	getAnswers: Joi.boolean().required(),
	getLevelStats: Joi.boolean(),
	taskTypes: Joi.array().items(Joi.objectId()),
	numQuestions: NumQuestionsSettingsSchema,
	classroomId: Joi.objectId().allow(null).required(),
	courseId: Joi.objectId().allow(null),
});
export interface IAGETTaskTypeContents {
	getAnswers: boolean;
	topicIds?: ObjectId[];
	getLevelStats?: boolean;
	taskTypes?: ObjectId[];
	numQuestions: INumQuestionsSettings;
	classroomId: ObjectId | null;
	courseId?: ObjectId | null;
}

export type IRGETTaskTypeContents = IRGETTestContents;

// public assignments
const publicAssignmentCreateForbiddenKeys = [
	"_id",
	"createdAt",
	"updatedAt",
	"author",
	"numQuestions",
	"testId",
	"code",
	"originalCourseId",
	"subjects",
] as const;

export const APOSTCreatePublicAssignmentSchema = AssignmentSchema.fork(
	publicAssignmentCreateForbiddenKeys,
	markKeysForbidden
).keys({
	courseId: Joi.objectId().required(),
	questions: Joi.array().items(Joi.objectId()).required(),
	grade: Joi.number().integer().required(),
	subject: Joi.objectId().required(),
	schoolId: Joi.number().required(),
	classroomName: Joi.string().allow("").required(),
	piblicTestId: Joi.number().optional(),
	testTypeSettings: TestTypeSettingsSchema.optional(),
});
export type IAPOSTCreatePublicAssignment = Omit<
	IAssignment,
	(typeof publicAssignmentCreateForbiddenKeys)[number]
> & {
	courseId: ObjectId;
	questions: ObjectId[];
	grade: number;
	schoolId: number;
	subject: ObjectId;
	classroomName: string;
	publicTestId?: number;
	testTypeSettings?: ITestTypeSettings;
};

export const RPOSTCreatePublicAssignmentSchema = AssignmentSchema;
export type IRPOSTCreatePublicAssignment = IAssignment;

// (GET) all for teacher
export const RGETCountForTeacherSchema = Joi.object({
	count: Joi.number().integer().min(0).required(),
});
export interface IRGETCountForTeacher {
	count: number;
}

export const RGETAllForTeacherSchema = Joi.array().items(AssignmentSchema);
export type IRGETAllForTeacher = IAssignment[];

///

export const AGETPublicAssignmentByCodeSchema = Joi.object({
	code: Joi.string().required(),
});
export interface IAGETPublicAssignmentByCode {
	code: string;
}

export const RGETPublicAssignmentByCodeSchema = Joi.object({
	content: RGETTestContentsSchema.allow(null).required(),
	assignment: AssignmentSchema.required(),
	teacherInfo: Joi.alternatives(
		Joi.object({
			id: Joi.number().integer().required(),
			firstname: Joi.string().required(),
			lastname: Joi.string().required(),
		}),
		Joi.object({
			fullname: Joi.string().required(),
		})
	),
	writtenAssignment: UserWrittenAssignmentSchema,
	settings: AssignmentSettingsSchema.required(),
	deadlinePassed: Joi.boolean().required(),
	isSubmitted: Joi.boolean().required(),
	studentEdge: StartedStudentAssignmentEdgeSchema,
	courseInfo: AssignmentStartCourseInfoSchema.required(),
});

export const RGETPublicAssignmentByCodeSchemaWithIncorrcetKey = Joi.object({
	assignment: RGETPublicAssignmentByCodeSchema,
	isIncorrectCode: Joi.boolean().required(),
});

export interface IRGETPublicAssignmentByCodeWithIncorrcetKey {
	assignment?: IRGETPublicAssignmentByCode | undefined;
	isIncorrectCode: boolean;
}

export interface IRGETPublicAssignmentByCode {
	content: IRGETTestContents | null;
	assignment: IAssignment;
	teacherInfo:
		| { id: number; firstname: string; lastname: string }
		| { id?: undefined; fullname: string };
	writtenAssignment?: IUserWrittenAssignment;
	settings: IAssignmentSettings;
	deadlinePassed: boolean;
	isSubmitted: boolean;
	studentEdge?: IStartedStudentAssignmentEdge;
	courseInfo: IAssignmentStartCourseInfo;
}

///

export const AGETUserWrittenAssignmentSchema = Joi.object({
	writtenAssignmentId: Joi.objectId().required(),
	classroomId: Joi.objectId().required(),
	assignmentId: Joi.objectId().required(),
});
export interface IAGETUserWrittenAssignment {
	writtenAssignmentId: ObjectId;
	classroomId: ObjectId;
	assignmentId: ObjectId;
}

export const RGETUserWrittenAssignmentSchema = Joi.object({
	assignment: AssignmentSchema.required(),
	writtenAssignment: UserWrittenAssignmentSchema.required(),
	settings: AssignmentSettingsSchema.required(),
});
export interface IRGETUserWrittenAssignment {
	assignment: IAssignment;
	writtenAssignment: IUserWrittenAssignment;
	settings: IAssignmentSettings;
}

///

export const APOSTSubmitPublicAssignment = Joi.object({
	assignmentId: Joi.objectId().required(),
	startedAt: Joi.date().required(),
	answers: Joi.array().items(AnsweredQuestionSchema).required(),
	userCredentials: Joi.object({
		name: Joi.string().required(),
		mobile: Joi.string(),
	})
		.allow(null)
		.required(),
});
export interface IAPOSTSubmitPublicAssignment {
	assignmentId: ObjectId;
	startedAt: Date;
	answers: IAnsweredQuestion[];
	userCredentials: {
		name: string;
		mobile?: string;
	} | null;
}

export const RPOSTSubmitPublicAssignmentSchema = Joi.object({
	userWrittenAssignmentId: Joi.objectId().required(),
});
export interface IRPOSTSubmitPublicAssignment {
	userWrittenAssignmentId: ObjectId;
}

///

export const APUTWrittenAssignmentSchema =
	UserWrittenAssignmentSchema.keys(updateStripKeys);
export type IAPUTWrittenAssignment = ToUpdateKeys<IUserWrittenAssignment>;

export const RPUTWrittenAssignmentSchema = UserWrittenAssignmentSchema;
export type IRPUTWrittenAssignment = IUserWrittenAssignment;

///

export const APOSTMakeWrittenAssignmentRewritableSchema = Joi.object({
	writtenAssignmentId: Joi.objectId().required(),
	value: Joi.boolean().required(),
});
export interface IAPOSTMakeWrittenAssignmentRewritable {
	writtenAssignmentId: ObjectId;
	value: boolean;
}

export type IRPOSTMakeWrittenAssignmentRewritable = void;

export const APOSTStartAssignmentTimerScheme = Joi.object({
	assignmentId: Joi.objectId().required(),
});
export interface IAPOSTStartAssignmentTimer {
	assignmentId: ObjectId;
}

export const RPOSTStartAssignmentTimerScheme = Joi.alternatives(
	Joi.object({
		hasTimer: Joi.boolean().valid(true).required(),
		assignmentTimerToken: Joi.string().required(),
	}),
	Joi.object({
		hasTimer: Joi.boolean().valid(false).required(),
	})
);

export type IRPOSTStartAssignmentTimer =
	| { hasTimer: false }
	| {
			hasTimer: true;
			assignmentTimerToken: string;
	  };

export const AGETHomeworksByStudentIdSchema = Joi.object({
	studentId: Joi.objectId().required(),
	classroomId: Joi.objectId().required(),
});

export interface IAGETHomeworksByStudentId {
	studentId: number;
	classroomId: ObjectId;
}

export const RGETHomeworksByStudentIdSchema = Joi.array().items(
	StudentHomeworkListItemSchema
);

export type IRGETHomeworksByStudentId = IStudentHomeworkListItem[];

///

export interface IAPOSTSaveNoteAssignment {
	classroomId: ObjectId;
	content: string;
	subjectId: ObjectId;
}
///

export interface IAGETNoteAssignments {
	classroomId: ObjectId;
	last?: boolean;
}
