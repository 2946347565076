import React, { useState, useEffect } from "react";
import styles from "./styles/blog.module.css";
import { ObjectId } from "@tests-core/utils/joi";
import { inject } from "@app/modules";
import { Blog } from "@app/models/blog";

export const WholeBlog: React.FC<{
	blogId: ObjectId;
}> = ({ blogId }) => {
	const blog = useBlogById(blogId);

	if (!blog) return null;

	return (
		<div className={styles.wholeBlogMainText}>
			<div>{blog.title}</div>
			<div
				dangerouslySetInnerHTML={{
					__html: `${blog.mainText}`,
				}}
			/>
		</div>
	);
};

const useBlogById = (blogId: ObjectId) => {
	const [blog, setBlog] = useState<Blog | null>(null);
	useEffect(() => {
		const BlogController = inject("BlogController");
		let isCancelled = false;
		BlogController.getBlogById({ _id: blogId }).then((blog) => {
			if (isCancelled) return;
			setBlog(blog);
		});
		return () => {
			isCancelled = true;
		};
	}, [blogId]);
	return blog;
};
