import React, { useState, useEffect, useMemo } from "react";
import genStyles from "../general_components/styles/general_styles.module.css";
import { IRGETProfileInfoAsStudent } from "@app/api/users/validators";
import { inject } from "@app/modules";
import { UserType } from "@app/api/helper-schemas";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import classnames from "classnames";
import SertificateIcon from "../general_components/general_imgs/Award.jpg";
import { useWindowSize } from "@app/hooks/front";
import { BottomSideBarStudent } from "../general_components/BottomSideBar";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useSubjectsForProfileWithPagnation } from "../general_components/hooks";
import { FormattedMessage } from "react-intl";
import DesktopLogOut from "../general_components/desktop_logOut";
import { isCertificateServicesAvaliable } from "@app/consts";

export const ProfileSubjectPagnations: React.FC<{
	canShiftLeft: boolean;
	canShiftRight: boolean;
	shiftLeft: () => void;
	shiftRight: () => void;
}> = React.memo(({ canShiftLeft, canShiftRight, shiftLeft, shiftRight }) => (
	<div className={genStyles.title}>
		<FormattedMessage id="mySubjects" />
		{(canShiftLeft || canShiftRight) && (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					height: "20px",
				}}
			>
				<ChevronLeftIcon
					className={classnames(
						genStyles.shiftIcon,
						canShiftLeft ? undefined : genStyles.light
					)}
					onClick={(
						e: React.MouseEvent<SVGSVGElement, MouseEvent>
					) => {
						e.stopPropagation();
						if (canShiftLeft) shiftLeft();
					}}
				/>
				<ChevronRightIcon
					className={classnames(
						genStyles.shiftIcon,
						canShiftRight ? undefined : genStyles.light
					)}
					onClick={(
						e: React.MouseEvent<SVGSVGElement, MouseEvent>
					) => {
						e.stopPropagation();
						if (canShiftRight) shiftRight();
					}}
				/>
			</div>
		)}
	</div>
));

const DekstopProfile: React.FC<{
	profileInfo: IRGETProfileInfoAsStudent;
	onCertificateClick: () => void;
}> = ({ profileInfo, onCertificateClick }) => {
	const { width } = useWindowSize();

	const { canShiftLeft, canShiftRight, currentChunk, shiftLeft, shiftRigth } =
		useSubjectsForProfileWithPagnation(profileInfo.subjects, 8);

	return (
		<section className={genStyles.profile_right__sidebar}>
			<DesktopLogOut />
			<section
				className={genStyles.student_right__sidebar_content_wrapper}
			>
				<>
					{currentChunk && (
						<>
							<ProfileSubjectPagnations
								canShiftLeft={canShiftLeft}
								canShiftRight={canShiftRight}
								shiftLeft={shiftLeft}
								shiftRight={shiftRigth}
							/>
							<section
								className={genStyles.right__sidebar_box}
								style={{ minHeight: "290px" }}
							>
								{(currentChunk || []).map((e, i) => (
									<section
										key={i}
										className={
											genStyles.student_subject_box__item
										}
									>
										<img src={e.photo || ""} alt="icon" />
										<p>{e.name}</p>
									</section>
								))}
							</section>
						</>
					)}
					<section className={genStyles.divider}></section>
					<section className={genStyles.right__sidebar_box}>
						<section
							className={classnames(
								genStyles.sertificate_box__item,
								width < 1000 && genStyles.box_height
							)}
						>
							<p className={genStyles.secondary_text}>
								სულ შესრულებული დავალებები
							</p>
							<span>{profileInfo.submitedAssignments || 0}</span>
							<p className={genStyles.blue_text}>დავალება</p>
						</section>
						{isCertificateServicesAvaliable && (
							<section
								className={classnames(
									genStyles.sertificate_box__item,
									width < 1000 && genStyles.box_height
								)}
								style={{ cursor: "pointer" }}
								onClick={onCertificateClick}
							>
								<img
									src={SertificateIcon}
									alt=""
									className={genStyles.sertification_img}
								/>
								<p className={genStyles.sertification}>
									ჩემი სერთიფიკატი
								</p>
							</section>
						)}
					</section>
				</>
			</section>
		</section>
	);
};

export const StudentProfileRightPanel: React.FC = React.memo(() => {
	const [profileInfo, setProfileInfo] = useState<IRGETProfileInfoAsStudent>();
	const [isCertificateOpen, setIsCertificateOpen] = useState<boolean>(false);
	const { width } = useWindowSize();

	useEffect(() => {
		inject("UsersController")
			.getProfileInfo(UserType.student)
			.then((e) => setProfileInfo(e))
			.catch((e) =>
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				})
			);
	}, []);

	const isResponsive = width <= 1000;
	if (!profileInfo) return null;

	return (
		<React.Fragment>
			{isResponsive ? (
				<BottomSideBarStudent
					info={profileInfo}
					onCertificateClick={() => setIsCertificateOpen(true)}
				/>
			) : (
				<DekstopProfile
					onCertificateClick={() => setIsCertificateOpen(true)}
					profileInfo={profileInfo}
				/>
			)}
		</React.Fragment>
	);
});
