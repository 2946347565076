import {
	CourseInfoSchema,
	ICourseInfo,
} from "@app/api/courses-info/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { ObjectId } from "@app/utils/generics";
import { isSuitable } from "./utils/match";
import { getMaxPriority } from "./utils/priorities";
import { QueryMetaInfo } from "./utils/query-meta";

const keyOfId = "courseId";
type IdKey = typeof keyOfId;
type DOC = ICourseInfo;
export type IStateCoursesInfo = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(CourseInfoSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("courseInfo", "coursesInfo");
const storageSettings = getDefaultStorageSettings("coursesInfo");
const metaInformationName = "coursesInfoMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().coursesInfo) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", CourseInfoSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class CourseInfo extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static searchSync(
		query: CourseAvailabilityQuery,
		sort = true
	): CourseInfo[] {
		const docs = this.getAllSync();
		const filteredDocs = docs.filter((doc) => {
			const matchingAvailabilities = doc.getMatchingAvailabilities(query);
			return !matchingAvailabilities || matchingAvailabilities.length > 0;
		});
		if (!sort) {
			return filteredDocs;
		}
		return filteredDocs
			.map((courseInfo) => {
				return {
					courseInfo,
					matchingAvailabilities:
						courseInfo.getMatchingAvailabilities(query),
				};
			})
			.sort(
				(a, b) =>
					getMaxPriority(b.matchingAvailabilities) -
					getMaxPriority(a.matchingAvailabilities)
			)
			.map((e) => e.courseInfo);
	}

	static findByCourseIdSync = Model.findByIdSync;
	static findManyByCourseIdsSync = Model.findManyByIdsSync;

	static meta = new QueryMetaInfo(storage, metaInformationName);

	getMatchingAvailabilities(query: CourseAvailabilityQuery | null) {
		if (!query) return this.availableIn;
		if (!this.availableIn) return null;
		return this.availableIn.filter((availability) => {
			if (!isSuitable(query.subject, availability.subjects)) {
				return false;
			}
			if (!isSuitable(query.grade, availability.grades)) {
				return false;
			}
			if (!isSuitable(query.language, availability.languages)) {
				return false;
			}
			if (!isSuitable(query.country, availability.countries)) {
				return false;
			}
			if (!isSuitable(query.website, availability.websites)) {
				return false;
			}
			return true;
		});
	}
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, CourseInfo.meta);

export const coursesInfoReducer = getDefaultReducer(
	storageSettings,
	() => CourseInfo
);

///
export interface CourseAvailabilityQuery {
	subject?: ObjectId | ObjectId[] | null;
	grade?: number | number[] | null;
	language?: string | string[] | null;
	country?: string | string[] | null;
	website?: string | string[] | null;
}
