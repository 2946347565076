import {
	ComplexAssignmentStatementType,
	IComplexAssignmentStatement,
} from "@app/api/assignments/complex/helper-schemas";
import { FancyFileBox, FileBox } from "@app/components/widgets/files/file-box";
import { FilesInput } from "@app/components/widgets/files/files-input";
import {
	EditOutlinedIcon,
	LinkIcon,
	LookIcon,
	SaveIcon,
} from "@app/icons/index";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import classnames from "classnames";
import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import { ComplexAssignmentContentMode } from ".";
import { CreateLink } from "./helper-functions";
import { CreateLinkModal } from "./link-modal";
import {
	AddRoundButton,
	ChosenConceptResults,
	CloseRoundButton,
	ComplexTopicSelect,
	TargetConceptSelectsWrapper,
} from "./select";
import assignmentContentStyles from "./styles/assignment-content-styles.module.css";
import selectorStyles from "./styles/selectors-styles.module.css";
export enum AssignmentContentTypes {
	statement = "statement",
	coreTasks = "coreTasks",
	practicalSuggestions = "practicalSuggestions",
}

interface LinkFormaterPopupState {
	isOpen: boolean;
	type: AssignmentContentTypes;
	id: number;
	prevValue: string;
	linkId?: string;
	top?: number;
	left?: number;
	fromSelect: boolean;
	selected: string;
}
export interface FormatedLink {
	link: string;
	label: string;
}

enum NumOfInputsChangingButtonTypes {
	add = "add",
	delete = "delete",
}

interface IAssignmentContentInputProps {
	value: string;
	allowLink?: boolean;
	onTextChange?: (
		event: ContentEditableEvent,
		id: number,
		type: AssignmentContentTypes
	) => void;
	id: number;
	type: AssignmentContentTypes;
	placeholder?: string;
	classNames?: string;
	files?: IComplexAssignmentStatement[ComplexAssignmentStatementType.file];
	onFileDelete?: () => void;
	numOfInputsChangingButtonType?: NumOfInputsChangingButtonTypes;
	deleteAssignmentContent?: (
		id: number,
		type: AssignmentContentTypes
	) => void;
	addAssignmentContent?: (type: AssignmentContentTypes) => void;
	attachAsignmentContentFile?: (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number,
		type: AssignmentContentTypes
	) => void;
	disabled: boolean;
	handleOpenFormatLinkPopup?: (
		type: AssignmentContentTypes,
		id: number,
		prevValue: string
	) => void;
	handeOpenPopupOnMouseOver?: (
		linkId: string,
		type: AssignmentContentTypes,
		top?: number,
		left?: number
	) => void;
	handleOpenLinkFormaterWithSelectText: (
		type: AssignmentContentTypes,
		id: number,
		prevValue: string,
		selected: string
	) => void;
	coreTasksTemplate?: string;
	deleteAssignmentContentFile?: (
		file: {
			name: string;
			path: string;
		},
		inputIndex: number,
		type: AssignmentContentTypes
	) => void;
	showFancyFileBox?: boolean;
}

interface IAssignmentContentWrapperProps {
	handleAssignmentContentTextChange?: (
		event: ContentEditableEvent,
		id: number,
		type: AssignmentContentTypes
	) => void;
	statement: IComplexAssignmentStatement[];
	coreTasks: IComplexAssignmentStatement[];
	practicalSuggestions?: IComplexAssignmentStatement[];
	deleteAssignmentContent?: (
		id: number,
		type: AssignmentContentTypes
	) => void;
	addAssignmentContent?: (type: AssignmentContentTypes) => void;
	chosenTopics: string[];
	handleTargetConceptsChange?: (
		targetConceptIds: ObjectId[],
		resultIds: ObjectId[]
	) => void;
	originalCourseId: ObjectId;
	topicId: string;
	handleComplexTopicChange?: (newValue: string) => void;
	handleAssignemntContentSubmission: () => void;
	targetConceptIds: string[];
	resultIds: string[];
	attachAsignmentContentFile?: (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number,
		type: AssignmentContentTypes
	) => void;
	handleEditOrPreviewModeChange?: (
		editOrPreviewMode: ComplexAssignmentContentMode
	) => void;
	editOrPreviewMode: ComplexAssignmentContentMode;
	formatedLinks: FormatedLink[];
	handleAddFormatedLink?: (link: FormatedLink) => void;
	handleEditLink?: (
		formatedLink: { link: string; label: string },
		index: number,
		id: number,
		type: AssignmentContentTypes,
		value: string
	) => void;
	deleteAssignmentContentFile?: (
		file: {
			name: string;
			path: string;
		},
		inputIndex: number,
		type: AssignmentContentTypes
	) => void;
	withoutSelectsAndResults?: boolean;
	classNames?: Partial<{
		assignmentPracticalSuggestions: string;
		assignmentMandatoryContentWrapper: string;
		assignmentPracticalSuggestionsInputWrapper: string;
	}>;
	showFancyFileBox?: boolean;
}

const contentEditableStyles = {
	outline: "none",
	padding: "10px",
	borderRadius: "20px",
	boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)",
};

export const AssignmentContentInput: React.FC<IAssignmentContentInputProps> = ({
	value,
	allowLink,
	onTextChange,
	id,
	type,
	classNames,
	numOfInputsChangingButtonType,
	deleteAssignmentContent,
	addAssignmentContent,
	files,
	attachAsignmentContentFile,
	disabled,
	handeOpenPopupOnMouseOver,
	handleOpenFormatLinkPopup,
	handleOpenLinkFormaterWithSelectText,
	coreTasksTemplate,
	deleteAssignmentContentFile,
	showFancyFileBox,
}) => {
	const minHeight =
		type === AssignmentContentTypes.statement ? "120px" : "60px";
	const deleteButtonColor =
		type === AssignmentContentTypes.practicalSuggestions
			? "#b4c0e9 "
			: "white";
	const [selected, setSelected] = useState<string>("");

	const ref = React.createRef<HTMLElement>();

	const onClickContentEditable = (e) => {
		if (
			e.target === ref.current ||
			!handeOpenPopupOnMouseOver ||
			disabled
		) {
			return;
		}

		if (e.target.tagName && e.target.id && e.target.tagName === "A") {
			handeOpenPopupOnMouseOver(e.target.id, type, e.clientY, e.clientX);
		}
	};

	const onFileDelete = useCallback(
		(file: { name: string; path: string }) => {
			if (!deleteAssignmentContentFile) return;
			deleteAssignmentContentFile(file, id, type);
		},
		[deleteAssignmentContentFile, id, type]
	);

	return (
		<div
			className={classnames(
				classNames,
				assignmentContentStyles.assignmentContentInputWrapper
			)}
		>
			<div className={assignmentContentStyles.contentInput}>
				<div className={assignmentContentStyles.inputContainer}>
					<ContentEditable
						style={{ ...contentEditableStyles, minHeight }}
						html={value}
						onChange={(e) =>
							onTextChange ? onTextChange(e, id, type) : undefined
						}
						disabled={disabled}
						onMouseOver={onClickContentEditable}
						innerRef={ref}
						onSelect={() => {
							const curSelected = window
								.getSelection()
								?.toString();
							if (curSelected) setSelected(curSelected);
						}}
						data-placeholder={
							coreTasksTemplate
								? `კრიტერიუმის მაგალითი: ${coreTasksTemplate}`
								: undefined
						}
					/>
					{allowLink && !disabled && handleOpenFormatLinkPopup && (
						<div
							className={assignmentContentStyles.attachLink}
							onClick={(
								e: React.MouseEvent<HTMLDivElement, MouseEvent>
							) => {
								if (selected !== "") {
									handleOpenLinkFormaterWithSelectText(
										type,
										id,
										value,
										selected
									);
									setSelected("");
									return;
								}
								handleOpenFormatLinkPopup(type, id, value);
							}}
						>
							<LinkIcon style={{ height: "17px" }} />
							<span>ბმულის ჩასმა</span>
						</div>
					)}
				</div>
				{!disabled &&
					numOfInputsChangingButtonType &&
					(numOfInputsChangingButtonType ===
					NumOfInputsChangingButtonTypes.add ? (
						<AddRoundButton
							classNames={
								assignmentContentStyles.addAssignmentContentButton
							}
							onClick={() => addAssignmentContent!(type)}
						/>
					) : (
						<CloseRoundButton
							color={deleteButtonColor}
							classNames={
								assignmentContentStyles.deleteAssignmentContentButton
							}
							onClick={() => deleteAssignmentContent!(id, type)}
						/>
					))}
			</div>
			{/* {file && (
				<FileDescription filename={file} onDelete={onFileDelete} />
			)} */}
			{attachAsignmentContentFile ? (
				<div className={assignmentContentStyles.attachFile}>
					<FilesInput
						files={
							files?.map((e) => ({
								path: e.url,
								name: e.filename,
							})) || []
						}
						onDelete={onFileDelete}
						direction="right"
						onAttachFile={(event: ChangeEvent<HTMLInputElement>) =>
							attachAsignmentContentFile(event, id, type)
						}
					/>
				</div>
			) : files ? (
				<div className={assignmentContentStyles.filesContainer}>
					{files.map((file, i) =>
						!showFancyFileBox ? (
							<FileBox
								key={i}
								canDownload={false}
								fileName={file.filename}
								fileUrl={file.url}
								showPreviewOnClick={false}
								marginLeft={7}
							/>
						) : (
							<FancyFileBox
								key={i}
								fileName={file.filename}
								fileUrl={file.url}
								showPreviewOnClick={true}
								marginLeft={7}
							/>
						)
					)}
				</div>
			) : null}
		</div>
	);
};

export const FileDescription: React.FC<{
	filename: string;
	onDelete: () => void;
}> = ({ filename }) => {
	return (
		<div>
			<span>{filename}</span>
		</div>
	);
};

export const AssignmentStatement: React.FC<{
	Input: JSX.Element;
}> = ({ Input }) => {
	return (
		<div className={assignmentContentStyles.assignmentStatement}>
			<span className={assignmentContentStyles.assignmentStatementTitle}>
				დავალების პირობა
			</span>
			{Input}
		</div>
	);
};

export const AssignmentCoreTasks: React.FC<{
	Inputs: JSX.Element;
}> = ({ Inputs }) => {
	return (
		<div className={assignmentContentStyles.assignmentCoreTasks}>
			<span className={assignmentContentStyles.assignmentCoreTasksTitle}>
				ნაშრომში ხაზგასმით წარმოაჩინეთ:
			</span>
			{Inputs}
		</div>
	);
};

export const AssignmentPracticalSuggestions: React.FC<{
	Inputs: JSX.Element;
	wrapperClassName?: string;
}> = ({ Inputs, wrapperClassName }) => {
	return (
		<div
			className={
				wrapperClassName ||
				assignmentContentStyles.assignmentPracticalSuggestions
			}
		>
			<span
				className={
					assignmentContentStyles.assignmentPracticalSuggestionsTitle
				}
			>
				პრაქტიკული რჩევები კომპლექსურ დავალებაზე მუშაობისთვის:
			</span>
			{Inputs}
		</div>
	);
};

export const EditAndSaveButtons: React.FC<{
	handleEditOrPreviewModeChange?: (
		editOrPreviewMode: ComplexAssignmentContentMode
	) => void;
	editOrPreviewMode: ComplexAssignmentContentMode;
	handleAssignmentContentSave: () => void;
}> = ({
	handleEditOrPreviewModeChange,
	editOrPreviewMode,
	handleAssignmentContentSave,
}) => {
	return editOrPreviewMode === ComplexAssignmentContentMode.view ? null : (
		<div className={assignmentContentStyles.editAndSaveButtons}>
			{handleEditOrPreviewModeChange && (
				<button
					onClick={() =>
						handleEditOrPreviewModeChange(editOrPreviewMode)
					}
				>
					{editOrPreviewMode ===
					ComplexAssignmentContentMode.preview ? (
						<>
							<EditOutlinedIcon />
							<span>რედაქტირება</span>
						</>
					) : (
						<>
							<LookIcon />
							<span>გადახედვა</span>
						</>
					)}
				</button>
			)}
			<button onClick={handleAssignmentContentSave}>
				<SaveIcon />
				<span>შენახვა და გამოქვეყნება</span>
			</button>
		</div>
	);
};

export const AssignmentContentWrapper: React.FC<
	IAssignmentContentWrapperProps
> = ({
	handleAssignmentContentTextChange,
	statement,
	coreTasks,
	practicalSuggestions,
	addAssignmentContent,
	deleteAssignmentContent,
	originalCourseId,
	handleTargetConceptsChange,
	topicId,
	handleComplexTopicChange,
	handleAssignemntContentSubmission,
	targetConceptIds,
	resultIds,
	attachAsignmentContentFile,
	editOrPreviewMode,
	handleEditOrPreviewModeChange,
	formatedLinks,
	handleAddFormatedLink,
	handleEditLink,
	deleteAssignmentContentFile,
	withoutSelectsAndResults,
	classNames,
	showFancyFileBox,
}) => {
	const [formatLinkPopup, setFormatLinkPopup] =
		useState<LinkFormaterPopupState>(() => ({
			isOpen: false,
			type: AssignmentContentTypes.statement,
			id: 0,
			prevValue: "",
			fromSelect: false,
			selected: "",
		}));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [coreTaskTemplates, setCoreTaskTemplates] = useState<string[]>();
	useMemo(() => {
		return inject("ComplexAssignmentController")
			.getTargetConcepts({ originalCourseId })
			.then((data) => {
				const templates = data
					.filter(
						(e) => targetConceptIds.includes(e._id) && e.coreTasks
					)
					.map((e) => e.coreTasks);
				const spreadTemplates: string[] = [];
				for (const t of templates) {
					spreadTemplates.push(...t);
				}
				setCoreTaskTemplates(spreadTemplates);
			});
	}, [originalCourseId, targetConceptIds]);

	const handleOpenFormatLinkPopup = useCallback(
		(type: AssignmentContentTypes, id: number, prevValue: string) =>
			setFormatLinkPopup({
				isOpen: true,
				type,
				id,
				prevValue,
				selected: "",
				fromSelect: false,
			}),
		[]
	);

	const handleOpenLinkFormaterWithSelectText = useCallback(
		(
			type: AssignmentContentTypes,
			id: number,
			prevValue: string,
			selected: string
		) => {
			setFormatLinkPopup({
				isOpen: true,
				type,
				id,
				prevValue,
				selected,
				fromSelect: true,
			});
		},
		[]
	);

	const handeOpenPopupOnMouseOver = (
		linkId: string,
		type: AssignmentContentTypes,
		top: number,
		left: number
	) =>
		formatLinkPopup.linkId === linkId
			? undefined
			: setFormatLinkPopup((cur) => ({
					...cur,
					isOpen: true,
					linkId,
					type,
					top,
					left,
				}));

	const formatedLink = useMemo(() => {
		return formatLinkPopup.linkId &&
			Number(formatLinkPopup.linkId) < formatedLinks.length
			? formatedLinks[Number(formatLinkPopup.linkId)]
			: undefined;
	}, [formatLinkPopup.linkId, formatedLinks]);

	const handleEditFormatedLink = (link: FormatedLink) => {
		if (
			!handleEditFormatedLink ||
			!handleEditLink ||
			!handleAddFormatedLink ||
			!handleAssignmentContentTextChange
		) {
			return;
		}
		if (formatLinkPopup.fromSelect && formatLinkPopup.selected) {
			handleAddFormatedLink(link);
			handleAssignmentContentTextChange(
				{
					target: {
						value: formatLinkPopup.prevValue.replace(
							formatLinkPopup.selected,
							" " +
								CreateLink({
									link,
									index: formatedLinks.length,
								}) +
								" "
						),
					},
				} as any,
				formatLinkPopup.id,
				formatLinkPopup.type
			);
			return;
		}
		if (formatLinkPopup.linkId) {
			handleEditLink(
				link,
				Number(formatLinkPopup.linkId),
				formatLinkPopup.id,
				formatLinkPopup.type,
				formatLinkPopup.prevValue
			);
		} else {
			handleAddFormatedLink(link);
			handleAssignmentContentTextChange(
				{
					target: {
						value:
							formatLinkPopup.prevValue +
							" " +
							CreateLink({
								link,
								index: formatedLinks.length,
							}) +
							" ",
					},
				} as any,
				formatLinkPopup.id,
				formatLinkPopup.type
			);
		}
	};
	return (
		<>
			{!withoutSelectsAndResults && (
				<div
					className={
						assignmentContentStyles.targetConceptsAndTopicsContainer
					}
				>
					<div
						className={
							assignmentContentStyles.targetConceptsAndTopics
						}
					>
						{formatLinkPopup.isOpen && (
							<CreateLinkModal
								label={
									formatLinkPopup.selected
										? formatLinkPopup.selected
										: formatedLink?.label
								}
								link={formatedLink?.link}
								onClose={() =>
									setFormatLinkPopup((cur) => ({
										...cur,
										isOpen: false,
										linkId: undefined,
										selected: "",
										fromSelect: false,
									}))
								}
								top={formatLinkPopup.top}
								left={formatLinkPopup.left}
								onSave={handleEditFormatedLink}
								closeOnScroll
							/>
						)}
						<TargetConceptSelectsWrapper
							conceptResults={resultIds}
							originalCourseId={originalCourseId}
							chosenConcepts={targetConceptIds}
							onChange={handleTargetConceptsChange}
							classNames={{
								chooserContainer:
									selectorStyles.thirdStepTargetConpetChooserContainer,
								chooserHeading:
									selectorStyles.thirdStepTargetConpetChooserHeading,
								chooserHeader:
									selectorStyles.thirdStepTargetConpetChooserHeader,
							}}
							headingText="სამიზნე ცნება:"
							targetIconOpts={{ ifShow: false }}
							selectableStyles={{
								width: 200,
								height: 35,
								hideIndicator:
									editOrPreviewMode !==
									ComplexAssignmentContentMode.edit,
							}}
							addTargetConceptButtonClassNames={
								selectorStyles.addOrDeleteButton
							}
							showChosenConceptResults={false}
							disabled={
								editOrPreviewMode !==
								ComplexAssignmentContentMode.edit
							}
						/>
						<ComplexTopicSelect
							originalCourseId={originalCourseId}
							onChange={handleComplexTopicChange}
							value={topicId}
							targetConcepts={targetConceptIds}
							selectableStyles={{
								width: 200,
								height: 35,
								hideIndicator:
									editOrPreviewMode !==
									ComplexAssignmentContentMode.edit,
							}}
							classNames={{
								topicChooserContainer:
									selectorStyles.thirdStepTopicChooserContainer,
								chooserHeader:
									selectorStyles.thirdStepTargetConpetChooserHeading,
							}}
							targetIconOpts={{ ifShow: false }}
							disabled={
								editOrPreviewMode !==
								ComplexAssignmentContentMode.edit
							}
						/>
					</div>
					<ChosenConceptResults
						conceptResultIds={resultIds}
						originalCourseId={originalCourseId}
						classNames={{
							container:
								assignmentContentStyles.thirdStepChosenConceptResultsContainer,
							resultsBox:
								assignmentContentStyles.thirdStepChosenConceptResultsBox,
							text: assignmentContentStyles.thirdStepChosenConceptResultsText,
						}}
					/>
				</div>
			)}

			<div className={assignmentContentStyles.assignmentContentWrapper}>
				<div
					className={
						classNames?.assignmentMandatoryContentWrapper ||
						assignmentContentStyles.assignmentMandatoryContentWrapper
					}
				>
					<AssignmentStatement
						Input={
							<>
								{statement.map((e, i) => (
									<AssignmentContentInput
										key={i}
										showFancyFileBox={showFancyFileBox}
										value={
											e[
												ComplexAssignmentStatementType
													.text
											]?.text || ""
										}
										onTextChange={(event) =>
											handleAssignmentContentTextChange
												? handleAssignmentContentTextChange(
														event,
														i,
														AssignmentContentTypes.statement
													)
												: undefined
										}
										id={i}
										type={AssignmentContentTypes.statement}
										allowLink={true}
										placeholder="ჩაწერეთ კომპლექსური დავალება"
										classNames={classnames(
											assignmentContentStyles.assignmentStatementInputWrapper
										)}
										files={
											e[
												ComplexAssignmentStatementType
													.file
											]
										}
										attachAsignmentContentFile={
											attachAsignmentContentFile
										}
										disabled={
											editOrPreviewMode !==
											ComplexAssignmentContentMode.edit
										}
										handeOpenPopupOnMouseOver={
											handeOpenPopupOnMouseOver
										}
										handleOpenFormatLinkPopup={
											handleOpenFormatLinkPopup
										}
										handleOpenLinkFormaterWithSelectText={
											handleOpenLinkFormaterWithSelectText
										}
										deleteAssignmentContentFile={
											deleteAssignmentContentFile
										}
									/>
								))}
							</>
						}
					/>
					<AssignmentCoreTasks
						Inputs={
							<>
								{coreTasks.map((e, i, array) => (
									<AssignmentContentInput
										key={i}
										showFancyFileBox={showFancyFileBox}
										value={
											e[
												ComplexAssignmentStatementType
													.text
											]?.text || ""
										}
										onTextChange={(event) =>
											handleAssignmentContentTextChange
												? handleAssignmentContentTextChange(
														event,
														i,
														AssignmentContentTypes.coreTasks
													)
												: undefined
										}
										id={i}
										type={AssignmentContentTypes.coreTasks}
										allowLink={true}
										placeholder="ჩაწერეთ კომპლექსური დავალება"
										classNames={classnames(
											assignmentContentStyles.assignmentCoreTasksInputWrapper
										)}
										files={
											e[
												ComplexAssignmentStatementType
													.file
											]
										}
										numOfInputsChangingButtonType={
											array.length - 1 === i
												? NumOfInputsChangingButtonTypes.add
												: NumOfInputsChangingButtonTypes.delete
										}
										addAssignmentContent={
											addAssignmentContent
												? () =>
														addAssignmentContent(
															AssignmentContentTypes.coreTasks
														)
												: undefined
										}
										deleteAssignmentContent={
											deleteAssignmentContent
												? () =>
														deleteAssignmentContent(
															i,
															AssignmentContentTypes.coreTasks
														)
												: undefined
										}
										attachAsignmentContentFile={
											attachAsignmentContentFile
										}
										disabled={
											editOrPreviewMode !==
											ComplexAssignmentContentMode.edit
										}
										handeOpenPopupOnMouseOver={
											handeOpenPopupOnMouseOver
										}
										handleOpenFormatLinkPopup={
											handleOpenFormatLinkPopup
										}
										handleOpenLinkFormaterWithSelectText={
											handleOpenLinkFormaterWithSelectText
										}
										coreTasksTemplate={
											coreTaskTemplates &&
											(coreTaskTemplates[i] || undefined)
										}
										deleteAssignmentContentFile={
											deleteAssignmentContentFile
										}
									/>
								))}
							</>
						}
					/>
				</div>

				{practicalSuggestions && (
					<AssignmentPracticalSuggestions
						wrapperClassName={
							classNames?.assignmentPracticalSuggestions
						}
						Inputs={
							<>
								{practicalSuggestions.map((e, i, array) => (
									<AssignmentContentInput
										key={i}
										showFancyFileBox={showFancyFileBox}
										value={
											e[
												ComplexAssignmentStatementType
													.text
											]?.text || ""
										}
										onTextChange={(event) =>
											handleAssignmentContentTextChange
												? handleAssignmentContentTextChange(
														event,
														i,
														AssignmentContentTypes.practicalSuggestions
													)
												: undefined
										}
										id={i}
										type={
											AssignmentContentTypes.practicalSuggestions
										}
										allowLink={true}
										placeholder="ჩაწერეთ კომპლექსური დავალება"
										classNames={
											classNames?.assignmentPracticalSuggestionsInputWrapper
										}
										files={
											e[
												ComplexAssignmentStatementType
													.file
											]
										}
										numOfInputsChangingButtonType={
											array.length - 1 === i
												? NumOfInputsChangingButtonTypes.add
												: NumOfInputsChangingButtonTypes.delete
										}
										addAssignmentContent={
											addAssignmentContent
												? () =>
														addAssignmentContent(
															AssignmentContentTypes.practicalSuggestions
														)
												: undefined
										}
										deleteAssignmentContent={
											deleteAssignmentContent
												? () =>
														deleteAssignmentContent(
															i,
															AssignmentContentTypes.practicalSuggestions
														)
												: undefined
										}
										attachAsignmentContentFile={
											attachAsignmentContentFile
										}
										disabled={
											editOrPreviewMode !==
											ComplexAssignmentContentMode.edit
										}
										handeOpenPopupOnMouseOver={
											handeOpenPopupOnMouseOver
										}
										handleOpenFormatLinkPopup={
											handleOpenFormatLinkPopup
										}
										handleOpenLinkFormaterWithSelectText={
											handleOpenLinkFormaterWithSelectText
										}
										deleteAssignmentContentFile={
											deleteAssignmentContentFile
										}
									/>
								))}
							</>
						}
					/>
				)}
				<EditAndSaveButtons
					editOrPreviewMode={editOrPreviewMode}
					handleEditOrPreviewModeChange={
						handleEditOrPreviewModeChange
					}
					handleAssignmentContentSave={
						handleAssignemntContentSubmission
					}
				/>
			</div>
		</>
	);
};
