import { Subject } from "@app/models/subject";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { collmise } from "collmise";
import stringify from "json-stable-stringify";
import { createModelCollmise } from "../collmise-helpers";
import { IRequest } from "../requests";
import { ISubject } from "./helper-schemas";
import {
	IAGETManySubjectsByIds,
	IAGETSubjects,
	IAPOSTCreateSubject,
	IAPUTCreateSubject,
	IRPOSTCreateSubject,
	IRPUTCreateSubject,
	RGETManySubjectsByIdsSchema,
	RPOSTCreateSubjectSchema,
	RPUTCreateSubjectSchema,
} from "./validators";

export class SubjectsController {
	private readonly Request: IRequest;

	private readonly _SubjectModel = inject("SubjectModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	private subjectPromise = createModelCollmise({
		name: "subject",
		model: this._SubjectModel,
		getMany: (ids) =>
			this.Request.send("POST", "/api/subjects/by-ids", ids, null, {
				responseSchema: RGETManySubjectsByIdsSchema,
			}),
	});

	add = (args: IAPOSTCreateSubject): Promise<Subject> =>
		this.Request.send("POST", "/api/subjects", args, null, {
			responseSchema: RPOSTCreateSubjectSchema,
		}).then((data: IRPOSTCreateSubject) => {
			return this._SubjectModel.loadOneSync(data);
		});

	update = (args: IAPUTCreateSubject): Promise<Subject> =>
		this.Request.send("PUT", "/api/subjects", args, null, {
			responseSchema: RPUTCreateSubjectSchema,
		}).then((data: IRPUTCreateSubject) => {
			return this._SubjectModel.loadOneSync(data);
		});

	getManyByIds = async (
		ids: IAGETManySubjectsByIds,
		loadFresh = false
	): Promise<Subject[]> => {
		return this.subjectPromise.collectors
			.many(ids)
			.fresh(loadFresh)
			.request();
	};

	getById = async (
		args: { _id: ObjectId },
		loadFresh = false
	): Promise<Subject> => {
		return this.subjectPromise
			.on(args._id)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/subjects/:_id", args)
			);
	};

	subjQueryGetter = collmise<IAGETSubjects, ISubject[], Subject[]>({
		serializeId: stringify,
		dataTransformer: (data, query) => {
			const oldIds = this._SubjectModel
				.searchSync(query)
				.map((e) => e._id);
			this._SubjectModel.meta.updateLoadTime(query);
			return this._SubjectModel.loadManySync(data, oldIds);
		},
		findCachedData: (query) => {
			if (this._SubjectModel.meta.isLoaded(query)) {
				return this._SubjectModel.searchSync(query);
			}
		},
	});

	getAll = async (
		query: IAGETSubjects,
		loadFresh = false
	): Promise<Subject[]> => {
		return this.subjQueryGetter
			.on(query)
			.fresh(loadFresh)
			.request(() => this.Request.send("GET", "/api/subjects", query));
	};

	deleteById = (args: { _id: ObjectId }): Promise<void> =>
		this.Request.send("DELETE", "/api/subjects/:_id", args).then(() => {
			this._SubjectModel.deleteByIdSync(args._id);
		});
}
