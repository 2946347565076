import { Requests } from "@app/api";

export const setCurrentChild = async (userId: number) => {
	[sessionStorage, localStorage].forEach((s) =>
		s.setItem("currentChild", userId.toString())
	);
};

export const unsetCurrentChild = () => {
	[sessionStorage, localStorage].forEach((s) => s.removeItem("currentChild"));
	Requests.clearChildAccessToken();
};
