import React, { useState, useEffect, useCallback } from "react";
import { ISubject } from "@app/api/subjects/helper-schemas";
import { chunkArray } from "@app/utils/common";
export const useSubjectsForProfileWithPagnation = (
	subjects: ISubject[],
	maxOnPage: number
): {
	currentChunk: ISubject[];
	canShiftLeft: boolean;
	canShiftRight: boolean;
	shiftRigth: () => void;
	shiftLeft: () => void;
} => {
	const [allSubject] = useState<ISubject[]>(subjects);
	const [chuckedSubjects, setChunkedSubjects] = useState<ISubject[][]>([[]]);
	const [currentIndex, setCurrentIndex] = useState<number>(0);

	useEffect(() => {
		setChunkedSubjects(chunkArray(allSubject, maxOnPage));
	}, [allSubject, maxOnPage]);

	const shiftRigth = useCallback(() => {
		if (currentIndex >= chuckedSubjects.length - 1) return;
		setCurrentIndex(currentIndex + 1);
	}, [chuckedSubjects.length, currentIndex]);

	const shiftLeft = useCallback(() => {
		if (currentIndex === 0) return;
		setCurrentIndex(currentIndex - 1);
	}, [currentIndex]);

	return {
		currentChunk: chuckedSubjects[currentIndex],
		canShiftLeft: currentIndex !== 0,
		canShiftRight: currentIndex < chuckedSubjects.length - 1,
		shiftRigth,
		shiftLeft,
	};
};
