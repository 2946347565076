import { insertStripKeys, ToInsertKeys } from "@app/api/helper-schemas";
import Joi from "@app/utils/joi";
import {
	IWeeklyTestSubject,
	IWeeklyTestSubjectMeta,
	WeeklyTestSubjectMetaSchema,
	WeeklyTestSubjectSchema,
} from "./helper-schemas";

export const RGETWeeklyTestSubjectsSchema = Joi.array().items(
	WeeklyTestSubjectSchema
);
export type IRGETWeeklyTestSubjects = IWeeklyTestSubject[];

export const APOSTWeeklyTestSubjectMetaSchema =
	WeeklyTestSubjectMetaSchema.keys(insertStripKeys);
export type IAPOSTWeeklyTestSubjectMeta = ToInsertKeys<IWeeklyTestSubjectMeta>;

export const RPOSTWeeklyTestSubjectMetaSchema = WeeklyTestSubjectMetaSchema;
export type IRPOSTWeeklyTestSubjectMeta = IWeeklyTestSubjectMeta;

export const APUTWeeklyTestSubjectMetaSchema =
	WeeklyTestSubjectMetaSchema.keys(insertStripKeys);
export type IAPUTWeeklyTestSubjectMeta = ToInsertKeys<IWeeklyTestSubjectMeta>;

export const RPUTWeeklyTestSubjectMetaSchema = WeeklyTestSubjectMetaSchema;
export type IRPUTWeeklyTestSubjectMeta = IWeeklyTestSubjectMeta;
