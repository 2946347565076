/* eslint-disable max-lines-per-function */
import React, { useRef, useCallback, useState } from "react";
import { FilesInput } from "@app/components/widgets/files/files-input";
import { inject } from "@app/modules";
import { addLoader } from "@app/common-javascript";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { ReviewUploadedFilesModal } from "./uploaded-files-preview-modal";

interface IFile {
	path: string;
	name: string;
}

export const FileViewerAndUpdater: React.FC<{
	files: IFile[];
	onChange?: (files: IFile[]) => void;
	direction?: "left" | "right";
	twoStepUpload?: boolean;
	isTeacherOfThisClassroom?: boolean;
}> = React.memo(
	({
		files,
		onChange,
		direction = "left",
		twoStepUpload = false,
		isTeacherOfThisClassroom = true,
	}) => {
		const [filesForReview, setFilesForReview] = useState<IFile[] | null>(
			null
		);
		const filesRef = useRef(files);
		filesRef.current = files;

		const handleAttachFile = useCallback(
			async (event: React.ChangeEvent<HTMLInputElement>) => {
				let removeLoader: Function | undefined = undefined;

				try {
					if (!onChange) return;
					const formData = new FormData();
					if (!event.target.files) return;
					for (let i = 0; i < event.target.files.length; i += 1) {
						formData.append("files", event.target.files[i]);
					}
					removeLoader = addLoader();

					await inject("PhotosController")
						.uploadGeneric(formData as any)
						.then((data) => {
							console.log(data);
							const newfiles = data.files.map((e) => {
								return {
									name: e.fileOriginalName
										? e.fileOriginalName
										: "",
									path: e.fileFullUrl,
								};
							});
							if (twoStepUpload && newfiles.length !== 0) {
								setFilesForReview(newfiles);
							} else {
								onChange([...filesRef.current, ...newfiles]);
							}
						})
						.catch((err) => {
							console.log(err);
						});
				} catch (e) {
					openConfirmationPopup({
						text: getFormattedMessage("errorAlert"),
					});
				} finally {
					if (removeLoader) {
						removeLoader();
					}
				}
			},
			[onChange, twoStepUpload]
		);

		const onDelete = useCallback(
			(file: IFile) =>
				onChange?.(
					filesRef.current.filter(
						(e) => e.name !== file.name || e.path !== file.path
					)
				),
			[onChange]
		);

		const handleReviewedFilesSave = (approved: IFile[]) => {
			if (!onChange) return;
			handleReviewdFilesClose();
			onChange([...filesRef.current, ...approved]);
		};

		const handleReviewdFilesClose = useCallback(
			() => setFilesForReview(null),
			[]
		);

		return (
			<React.Fragment>
				{filesForReview && (
					<ReviewUploadedFilesModal
						files={filesForReview}
						onSave={handleReviewedFilesSave}
						onClose={handleReviewdFilesClose}
					/>
				)}
				{isTeacherOfThisClassroom && (
					<FilesInput
						files={files}
						onDelete={onChange && onDelete}
						onAttachFile={onChange && handleAttachFile}
						direction={direction}
					/>
				)}
			</React.Fragment>
		);
	}
);
