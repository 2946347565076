import { useHeaderOptions } from "@app/components/layouts/header/options";
import {
	HeaderProviders,
	HeaderTree,
} from "@app/components/layouts/header/tree";
import { CreateCusto } from "custo";
import React from "react";
import { TitleNextToLogo } from "./title-next-to-logo";

export const HeadmasterHeader: React.FC<any> = (props) => {
	return (
		<HeaderProviders.PartialMergingProvider
			value={{
				components: {
					NextToLogo: TitleNextToLogo,
				},
				data: {
					profileImg: "/imgs/SchoolAvatar.svg",
					menuOptions: CreateCusto.hookOf.Data(useHeaderOptions),
				},
			}}
		>
			<HeaderProviders.PartialMergingProvider
				value={props.priorityContextValue}
			>
				<HeaderTree.components.OuterContainer />
			</HeaderProviders.PartialMergingProvider>
		</HeaderProviders.PartialMergingProvider>
	);
};
