import { Classroom } from "@app/models/classroom";
import { inject } from "@app/modules";
import { removeKeys } from "@app/utils/common";
import { ObjectId, ReplaceIn } from "@app/utils/generics";
import { createModelSingleCollmise } from "../collmise-helpers";
import { IRequest } from "../requests";
import { VideoLessonResourceTypes } from "../video-lessons/helper-schemas";
import {
	AGETClassroomsSlugSchema,
	AGETHeadmastersOfClassroomSchema,
	APOSTAffectingCoursesExistanceSchema,
	APUTHeadmastersToClassroomSchema,
	APUTUpdateClassroomClassTimesSchema,
	IAGETAllClassrooms,
	IAGETClassroomByCourse,
	IAGETClassroomGradebookId,
	IAGETClassroomsSlug,
	IAGETHeadmastersOfClassroom,
	IAGETManyClassroomsByIds,
	IAGETManyClassroomsByIdsWithoutFiltering,
	IAPOSTAffectingCoursesExistance,
	IAPOSTCreateClassroom,
	IAPOSTEnrollByAssignmentCode,
	IAPUTHeadmastersToClassroom,
	IAPUTRemoveStudentsFromClassroom,
	IAPUTUpdateClassroom,
	IAPUTUpdateClassroomClassTimes,
	IPUTArchiveBySchoolId,
	IRGETClassroomByCourse,
	IRGETClassroomGradebookId,
	IRGETClassroomsSlug,
	IRGETGetAllClassrooms,
	IRGETHeadmastersOfClassroom,
	IRGETManyClassroomsByIds,
	IRPOSTAffectingCoursesExistance,
	IRPOSTCreateClassroom,
	IRPUTRemoveStudentsFromClassroom,
	IRPUTUpdateClassroom,
	IRPUTUpdateClassroomClassTimes,
	RGETClassroomGradebookIdSchema,
	RGETClassroomsSlugSchema,
	RGETHeadmastersOfClassroomSchema,
	RGETManyClassroomsByIdsSchema,
	RGETManyClassroomsByIdsWithoutFilteringSchema,
	RPOSTAffectingCoursesExistanceSchema,
	RPUTUpdateClassroomClassTimesSchema,
	RPUTUpdateClassroomSchema,
} from "./validators";

export class ClassroomsController {
	private readonly Request: IRequest;

	private readonly _ClassroomModel = inject("ClassroomModel");
	private readonly _VideoLessonModel = inject("VideoLessonModel");
	private readonly _ClassroomJoinRequestModel = inject(
		"ClassroomJoinRequestModel"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	private classroomPromises = createModelSingleCollmise({
		model: this._ClassroomModel,
		name: "Classroom",
	});

	add = (args: IAPOSTCreateClassroom): Promise<Classroom> =>
		this.Request.send("POST", "/api/classrooms", args).then(
			(data: IRPOSTCreateClassroom) => {
				return this._ClassroomModel.loadOneSync(data);
			}
		);

	getAll = (args: IAGETAllClassrooms): Promise<Classroom[]> =>
		this.Request.send("GET", "/api/classrooms", args).then(
			(data: IRGETGetAllClassrooms) => {
				return this._ClassroomModel.loadManySync(data);
			}
		);

	getById = async (
		args: { _id: ObjectId },
		loadFresh?: boolean
	): Promise<Classroom> => {
		return this.classroomPromises
			.on(args._id)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/classrooms/:_id", args)
			);
	};

	getByCourseId = async (
		args: IAGETClassroomByCourse,
		loadFresh?: boolean
	): Promise<Classroom> => {
		if (!loadFresh) {
			const classrooms = this._ClassroomModel.getAllSync();
			const classroom = classrooms.find(
				(e) => e.course.courseId === args.classroomCourseId
			);
			if (classroom) return classroom;
		}
		return this.Request.send(
			"GET",
			"/api/classrooms/by-course/:classroomCourseId",
			args
		).then((data: IRGETClassroomByCourse) => {
			return this._ClassroomModel.loadOneSync(data);
		});
	};

	getManyByIds = async (
		args: IAGETManyClassroomsByIds,
		loadFresh?: boolean
	): Promise<Classroom[]> => {
		if (!loadFresh) {
			const classrooms = this._ClassroomModel.findManyByIdsSync(args.ids);
			if (classrooms.length === args.ids.length) {
				return classrooms;
			}
		}
		return this.Request.send("POST", "/api/classrooms/by-ids", args, null, {
			responseSchema: RGETManyClassroomsByIdsSchema,
		}).then((data: IRGETManyClassroomsByIds) => {
			return this._ClassroomModel.loadManySync(data, args.ids);
		});
	};

	getManyByIdsWithoutFiltering = async (
		args: IAGETManyClassroomsByIdsWithoutFiltering,
		loadFresh?: boolean
	): Promise<Classroom[]> => {
		if (!loadFresh) {
			const classrooms = this._ClassroomModel.findManyByIdsSync(args.ids);
			if (classrooms.length === args.ids.length) {
				return classrooms;
			}
		}
		return this.Request.send(
			"GET",
			"/api/classrooms/all-by-ids",
			args,
			null,
			{
				responseSchema: RGETManyClassroomsByIdsWithoutFilteringSchema,
			}
		).then((data: IRGETManyClassroomsByIds) => {
			return this._ClassroomModel.loadManySync(data, args.ids);
		});
	};

	delete = (classroomId: ObjectId): Promise<void> =>
		this.Request.send("DELETE", "/api/classrooms/:_id", {
			_id: classroomId,
		}).then(() => {
			this._ClassroomModel.deleteByIdSync(classroomId);
			this._ClassroomJoinRequestModel.deleteClassroomRequests(
				classroomId
			);
		});

	update = (args: IAPUTUpdateClassroom): Promise<Classroom> => {
		const existingDoc = this._ClassroomModel.findByIdSync(args._id);
		return this.Request.send("PUT", "/api/classrooms/:_id", args, null, {
			responseSchema: RPUTUpdateClassroomSchema,
		}).then((classroom: IRPUTUpdateClassroom) => {
			if (existingDoc && existingDoc.teacherId !== classroom.teacherId) {
				this._VideoLessonModel.deleteOneSync({
					resourceId: args._id,
					resourceType: VideoLessonResourceTypes.CLASSROOM,
				});
			}
			return this._ClassroomModel.loadOneSync(classroom);
		});
	};

	enrollByAssignmentCode = async (
		args: IAPOSTEnrollByAssignmentCode
	): Promise<void> =>
		this.Request.send(
			"POST",
			"/api/classrooms/enroll-by-assignment-code",
			args
		).then();

	removeStudents = async (
		args: IAPUTRemoveStudentsFromClassroom
	): Promise<Classroom> => {
		return this.Request.send(
			"PUT",
			"/api/classrooms/:_id/remove-students",
			args
		).then((data: IRPUTRemoveStudentsFromClassroom) => {
			return this._ClassroomModel.loadOneSync(data);
		});
	};

	ensureAffectingCoursesExistance = async (
		args: IAPOSTAffectingCoursesExistance
	): Promise<IRAffectingCoursesExistance> => {
		return this.Request.send(
			"POST",
			"/api/classrooms/ensure-courses",
			args,
			null,
			{
				requestSchema: APOSTAffectingCoursesExistanceSchema,
				responseSchema: RPOSTAffectingCoursesExistanceSchema,
			}
		).then((data: IRPOSTAffectingCoursesExistance) => {
			if (data.newHiddenClassroom) {
				const newHiddenClassroom = this._ClassroomModel.loadOneSync(
					data.newHiddenClassroom
				);
				return {
					...data,
					newHiddenClassroom,
				};
			}
			return removeKeys(data, "newHiddenClassroom");
		});
	};

	updateClassroomClasstimes = async (
		args: IAPUTUpdateClassroomClassTimes
	): Promise<IRPUTUpdateClassroomClassTimes> =>
		this.Request.send(
			"POST",
			"/api/classrooms/classroom-classtimes",
			args,
			null,
			{
				requestSchema: APUTUpdateClassroomClassTimesSchema,
				responseSchema: RPUTUpdateClassroomClassTimesSchema,
			}
		);

	getClassroomGradebookId = (
		args: IAGETClassroomGradebookId
	): Promise<IRGETClassroomGradebookId> => {
		return this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/gradebook/id",
			args,
			null,
			{
				responseSchema: RGETClassroomGradebookIdSchema,
			}
		);
	};

	archiveBySchoolID(args: IPUTArchiveBySchoolId) {
		return this.Request.send(
			"PUT",
			"/api/schools/classrooms/archive",
			args
		);
	}

	async getClassroomSlug(
		args: IAGETClassroomsSlug
	): Promise<IRGETClassroomsSlug> {
		return this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/slug",
			args,
			undefined,
			{
				requestSchema: AGETClassroomsSlugSchema,
				responseSchema: RGETClassroomsSlugSchema,
			}
		).then((data: IRGETClassroomsSlug) => {
			this._ClassroomModel.updateOneSync(
				{ _id: args.classroomId },
				{ slug: data.slug }
			);
			return data;
		});
	}

	async setHeadmastersToClassroom(
		args: IAPUTHeadmastersToClassroom
	): Promise<void> {
		return this.Request.send(
			"PUT",
			"/api/classrooms/:classroomId/headmasters",
			args,
			undefined,
			{
				requestSchema: APUTHeadmastersToClassroomSchema,
			}
		);
	}

	async getHeadmastersOfClassroom(
		args: IAGETHeadmastersOfClassroom
	): Promise<IRGETHeadmastersOfClassroom> {
		return this.Request.send(
			"GET",
			"/api/classrooms/:classroomId/headmasters",
			args,
			undefined,
			{
				requestSchema: AGETHeadmastersOfClassroomSchema,
				responseSchema: RGETHeadmastersOfClassroomSchema,
			}
		);
	}
}

export type IRAffectingCoursesExistance = ReplaceIn<
	IRPOSTAffectingCoursesExistance,
	{ newHiddenClassroom?: Classroom }
>;
