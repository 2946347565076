import { IRequest } from "../requests";
import {
	IAPOSTMissingCourse,
	IRPOSTMissingCourse,
	IAPOSTMissingCourses,
	IRPOSTMissingCourses,
	IRGETUsersStats,
	IAGETExportStudentDb,
	IAGETStudentCount,
	IRGETStudentCount,
	IAPUTClassroomPackets,
	IAPUTClassroomPacketsBySchool,
	IAPUTClassroomPacketsByTeachers,
	IAPUTEnsureStudentsInfo,
	IAPOSTStudentWeeklyTestPayment,
	IAPUTImportWeeklyBalance,
} from "./validators";
import { inject } from "@app/modules";

export class AdminController {
	private readonly _CourseInfoModel = inject("CourseInfoModel");

	constructor(
		private readonly Request: IRequest,
		private readonly CoursesRequest: IRequest
	) {}

	createMissingCourse = async (
		args: IAPOSTMissingCourse
	): Promise<IRPOSTMissingCourse> => {
		return this.Request.send("POST", "/api/admin/courses/missing", args, {
			headers: {
				"courses-access-token": this.CoursesRequest.getAccessToken()!, // FIXME: handle when access token is expired
			},
		}).then((data: IRPOSTMissingCourse) => {
			if (!data) return data;
			return this._CourseInfoModel.loadOneSync(data);
		});
	};

	getStats = async (): Promise<IRGETUsersStats> => {
		return this.Request.send("GET", "/api/admin/stats");
	};

	bulkCreateMissingCourses = async (
		args: IAPOSTMissingCourses
	): Promise<IRPOSTMissingCourses> => {
		return this.Request.send(
			"POST",
			"/api/admin/courses/missing/bulk",
			args,
			{
				headers: {
					"courses-access-token":
						this.CoursesRequest.getAccessToken()!, // FIXME: handle when access token is expired
				},
			}
		).then((data: IRPOSTMissingCourses) => {
			return this._CourseInfoModel.loadManySync(data);
		});
	};

	exportStudentsDb = async (args: IAGETExportStudentDb): Promise<unknown> => {
		return this.Request.send("GET", "/api/admin/students", args);
	};

	getStudentCount = async (
		args: IAGETStudentCount
	): Promise<IRGETStudentCount> => {
		return this.Request.send("GET", "/api/admin/students/count", args);
	};

	public async updateClassroomPackets(
		args: IAPUTClassroomPackets
	): Promise<void> {
		return this.Request.send("PUT", "/api/admin/classrooms/packets", args);
	}

	public async updateClassroomPacketsByTeachers(
		args: IAPUTClassroomPacketsByTeachers
	): Promise<void> {
		return this.Request.send(
			"PUT",
			"/api/admin/classrooms/packets-by-teachers",
			args
		);
	}

	public async updateClassroomPacketsBySchool(
		args: IAPUTClassroomPacketsBySchool
	): Promise<void> {
		return this.Request.send(
			"PUT",
			"/api/admin/classrooms/packets-by-school",
			args
		);
	}
	public async importVideoLectureLinks(args: FormData): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/admin/video-lectures/links",
			args
		);
	}

	public async ensureStudentsInfo(
		args: IAPUTEnsureStudentsInfo
	): Promise<void> {
		return this.Request.send("POST", "/api/dev/ensure-students-info", args);
	}
	public async addWeeklyTestPayment(
		args: IAPOSTStudentWeeklyTestPayment
	): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/admin/weekly-tests/students/:studentId/payments",
			args
		);
	}
	public importWeeklyBalance(args: IAPUTImportWeeklyBalance): Promise<void> {
		return this.Request.send("PUT", "/api/weekly-balance/import", args);
	}
}
