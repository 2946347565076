import {
	ReservationSchema,
	IReservation,
	IStandardOffer,
} from "@app/api/reservations/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";
import { IAPOSTView } from "@app/api/reservations/validators";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IReservation;
export type IStateReservations = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(ReservationSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("reservation");
const storageSettings = getDefaultStorageSettings("reservations");
const metaInformationName = "reservationsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().reservations) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", ReservationSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Reservation extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Reservation.meta);

export const reservationsReducer = getDefaultReducer(
	storageSettings,
	() => Reservation
);

///
