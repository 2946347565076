import { useTeacherSelectedSchoolId } from "@app/components/teachers/contexts/teacher-school";
import { Classroom } from "@app/models/classroom";
import { uniquelize } from "@app/utils/array";
import { isNonNullable } from "@app/utils/common";
import { useModelDocs } from "m-model-react";
import { useMemo } from "react";
import { useUnstableCurrentChildId } from "./child";
import { useClassroomsUser } from "./users";

export const useUserCurrentSchool = () => {
	const user = useClassroomsUser();
	const teacherSelectedSchool = useTeacherSelectedSchoolId();
	if (!user) return null;
	return (user.isTeacher() ? teacherSelectedSchool : null) ?? user.school;
};

export const useUserOfficialSchools = (): number[] | null => {
	const classrooms = useModelDocs(Classroom, "raw");
	const user = useClassroomsUser();
	const childId = useUnstableCurrentChildId();

	return useMemo(() => {
		if (!user) return null;
		if (user.isHeadmaster()) {
			return user.school !== null ? [user.school] : null;
		}

		if (user.isTeacher()) {
			return uniquelize(
				classrooms
					.filter((e) => e.teacherId === user.id)
					.map((e) => e.schoolId)
					.filter(isNonNullable)
			);
		}

		if (user.isStudent() || user.isParent()) {
			const userId = user.isParent() ? childId : user.id;
			if (!userId) return null;
			return uniquelize(
				classrooms
					.filter((e) => e.studentIds.includes(userId))
					.map((e) => e.schoolId)
					.filter(isNonNullable)
			);
		}
		return null;
	}, [childId, classrooms, user]);
};

export const useUserCurrentOfficialSchools = (): number[] | null => {
	const userOfficialSchools = useUserOfficialSchools();
	const currentSchool = useUserCurrentSchool();

	const user = useClassroomsUser();

	return useMemo(() => {
		if (!user) return null;
		if (!userOfficialSchools) return null;
		if (user.isTeacher()) {
			if (
				!!currentSchool &&
				userOfficialSchools.includes(currentSchool)
			) {
				return [currentSchool];
			}
			return [];
		}
		return userOfficialSchools;
	}, [user, userOfficialSchools, currentSchool]);
};
