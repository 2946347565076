/* eslint-disable max-lines-per-function */
import { UserType } from "@app/api/helper-schemas";
import { MainAdminHeader } from "@app/components/admin/header";
import {
	GeneralContext,
	useGeneralContext,
	useSetIsHeaderHidden,
} from "@app/components/general-context";
import { HeadmasterHeader } from "@app/components/headmaster/header";
import { ParentHeader } from "@app/components/parents/parent-header";
import { TeacherHeader } from "@app/components/teachers/header/teacher-header";
import { GuestHeader } from "@app/components/tv-school-landing/header";
import { StudentHeader } from "@app/components/users/header";
import { SubWebsiteOrigin } from "@app/globals";
import { useClassroomsUser } from "@app/hooks/users";
import React, { useContext } from "react";

import { NewDesignBackground } from "@app/components/backgrounds";
import { HeaderTheme } from "@app/components/layouts/header/interfaces";
import {
	HeaderProviders,
	HeaderValueType,
	useHeaderColorsByTheme,
} from "@app/components/layouts/header/tree";
import { EduFairLine } from "@app/components/users/header/edu-fair";
import { useSubWebsite } from "@app/hooks/bc";
import { CreateCusto } from "custo";

export const HeaderDistributor: React.FC = React.memo(() => {
	const user = useClassroomsUser();
	const $$userType = user?.getRandomType() ?? null;

	const {
		data: { isHeaderHidden },
	} = useContext(GeneralContext);

	const headerTheme = useGeneralContext("headerTheme");
	const defaultTheme = useDefaultThemeByType($$userType);

	const theme = headerTheme || defaultTheme;
	const colors = useHeaderColorsByTheme(theme);
	const priorityContextValue = useHeaderSubWebsiteValues($$userType);

	if (isHeaderHidden) return null;

	let mainComp: JSX.Element;

	if ($$userType === UserType.student) {
		mainComp = <StudentHeader />;
	} else if ($$userType === UserType.teacher) {
		mainComp = <TeacherHeader />;
	} else if ($$userType === UserType.headmaster) {
		mainComp = <HeadmasterHeader />;
	} else if ($$userType === UserType.mainAdmin) {
		mainComp = <MainAdminHeader />;
	} else if ($$userType === UserType.parent) {
		mainComp = <ParentHeader />;
	} else {
		mainComp = <GuestHeader />;
	}

	return (
		<HeaderProviders.PartialMergingProvider
			value={{
				data: {
					colors: CreateCusto.functionOf.Data(() => colors),
				},
			}}
		>
			<HeaderProviders.PartialMergingProvider
				value={priorityContextValue}
				__order={10}
			>
				{theme === "light" && <NewDesignBackground />}
				{mainComp}
				<EduFairLine />
			</HeaderProviders.PartialMergingProvider>
		</HeaderProviders.PartialMergingProvider>
	);
});

const useDefaultThemeByType = (userType: UserType | null): HeaderTheme => {
	if (userType === UserType.teacher) return "light";
	if (userType === UserType.headmaster) return "light";
	if (!userType) return "white";
	return "dark";
};

export const HeaderHider = React.memo<{ hideHeader: boolean | undefined }>(
	({ hideHeader }) => {
		useSetIsHeaderHidden(hideHeader, typeof hideHeader !== "boolean");
		return null;
	}
);
HeaderHider.displayName = "HeaderHider";

export const useHeaderSubWebsiteValues = (
	userType: UserType | null
): HeaderValueType => {
	const subwebsite = useSubWebsite();
	if (subwebsite === SubWebsiteOrigin.britishCenter) {
		return {
			elements: {
				ProfileImg: CreateCusto.Component("img", {
					style: {
						margin: 0,
						transform: "scale(1.4)",
					},
				}),
			},
			data: {
				profileImg: "/imgs/BC-logo.png",
				showWarningIcon: false,
			},
		};
	}
	return {};
};

export const HeaderDistributorWrapper = HeaderDistributor;
