import {
	AssignmentSchema,
	IAssignment,
} from "@app/api/assignments/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
	IStorage,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage, JoiMetaInfo } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import Joi from "joi";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IAssignment;
export type IStateAssignments = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(AssignmentSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("assignment");
const storageSettings = getDefaultStorageSettings("assignments");
const metaInformationName = "assignmentsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().assignments) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", AssignmentSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

//
export class AssignmentMetaInfo extends JoiMetaInfo<IAssignmentsMetaInfo> {
	constructor(storage: IStorage, storageKey: string) {
		super(emptyMetaData, storage, storageKey, CommonMetaInfoSchema);
	}

	clear() {
		this.replaceData(emptyMetaData);
	}

	updateLoadTime() {
		this.setItem("lastFullLoadTime", new Date());
	}

	incrementCreatedAssignemnts() {
		const num = this.data.numOfCreatedAssignments || 0;
		this.setItem("numOfCreatedAssignments", num + 1);
	}
}

const CommonMetaInfoSchema = Joi.object({
	lastFullLoadTime: Joi.date(),
	numOfCreatedAssignments: Joi.number().integer(),
});

interface IAssignmentsMetaInfo {
	lastFullLoadTime?: Date;
	numOfCreatedAssignments?: number;
}
const emptyMetaData: IAssignmentsMetaInfo = {};

// ==============Main Model=================

export class Assignment extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static meta = new AssignmentMetaInfo(storage, metaInformationName);

	isArchived(dateToCompare?: Date) {
		if (this.archived) return true;
		const compDate = dateToCompare || new Date();
		return (
			compDate.getTime() - new Date(this.createdAt).getTime() >=
			14 * 24 * 60 * 60 * 1000
		);
	}
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Assignment.meta);

export const assignmentsReducer = getDefaultReducer(
	storageSettings,
	() => Assignment
);
