import { inject } from "@app/modules";
import { IRequest } from "../requests";
import {
	IAPOSTFeedback,
	IRPostFeedback,
	IAPOSTUpdateFeedback,
	IADeleteFeedback,
	IAGETOneFeedbackById,
	IRGETOneFeedbackById,
	IRGETManyFeedback,
	RGETManyFeedbackSchema,
} from "./validators";
import { FeedbackSchema, IFeedback } from "./helper-schemas";

export class FeedbackController {
	private readonly Request: IRequest;

	private readonly _FeedbackModel = inject("FeedbackModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	create = async (args: IAPOSTFeedback): Promise<IRPostFeedback> => {
		return this.Request.send(
			"POST",
			"/api/test-feedback/create",
			args,
			undefined,
			{
				responseSchema: FeedbackSchema,
			}
		).then((data: IFeedback) => {
			return this._FeedbackModel.loadOneSync(data);
		});
	};

	update = async (args: IAPOSTUpdateFeedback): Promise<void> => {
		this.Request.send("POST", "/api/test-feedback/update", args).then(
			() => {
				this._FeedbackModel.updateOneSync({ _id: args._id }, args);
			}
		);
	};

	delete = async (args: IADeleteFeedback): Promise<void> =>
		this.Request.send("DELETE", "/api/test-feedback/delete", args).then(
			() => this._FeedbackModel.deleteByIdSync(args._id)
		);

	getOneById = async (
		args: IAGETOneFeedbackById
	): Promise<IRGETOneFeedbackById> => {
		const feedback = this._FeedbackModel.findByIdSync(args._id);
		if (feedback) return feedback;

		return this.Request.send(
			"GET",
			"/api/test-feedback/get-one",
			args
		).then((data: IFeedback) => {
			return this._FeedbackModel.loadOneSync(data);
		});
	};

	getMany = async (): Promise<IRGETManyFeedback> => {
		return this.Request.send(
			"GET",
			"/api/test-feedback/get-many",
			undefined,
			undefined,
			{
				responseSchema: RGETManyFeedbackSchema,
			}
		).then((data: IFeedback[]) => {
			return this._FeedbackModel.loadManySync(data);
		});
	};
}
