import * as React from "react";
import * as ReactDOM from "react-dom";
import { VideoLessonResourceTypes } from "./api/video-lessons/helper-schemas";
import { VideoLesson } from "./models/video-lesson";
import { inject } from "./modules";
import { ObjectId } from "./utils/generics";

export function addLoader() {
	const loaderDiv = document.createElement("div");
	loaderDiv.setAttribute("class", "loaderDiv");
	ReactDOM.render(
		<div className="loaderChild" />,
		document.body.appendChild(loaderDiv)
	);
	return () => {
		if (
			typeof loaderDiv.parentNode !== "undefined" &&
			loaderDiv.parentNode !== null
		) {
			loaderDiv.parentNode.removeChild(loaderDiv);
		}
	};
}

export const funcToPromise = (func: Function) =>
	new Promise((resolve, reject) => {
		try {
			resolve(func());
		} catch (e) {
			reject();
		}
	});

export const animateHTMLElement = (
	element: HTMLElement,
	propertyName: string,
	endPosition: number,
	duration: number
) => {
	const startPosition = element[propertyName];
	const startTime = performance.now();
	const animate = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		element.scrollTo(
			0,
			percentage * (endPosition - startPosition) + startPosition
		);
		if (percentage < 1) window.requestAnimationFrame(animate);
	};
	window.requestAnimationFrame(animate);
};

export const animateHTMLElementStyle = (
	element: HTMLElement,
	propertyName: string,
	endValue: number,
	duration: number,
	initial: number | null = null
) => {
	const startValue =
		initial !== null ? initial : parseFloat(element.style[propertyName]);
	const startTime = performance.now();
	const animate = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		element.style[propertyName] =
			percentage * (endValue - startValue) + startValue + "px";
		if (percentage < 1) window.requestAnimationFrame(animate);
	};
	window.requestAnimationFrame(animate);
};

export const animateWindowScroll = (
	whereToScrollTop: number,
	duration: number
) => {
	const scrollTop = window.pageYOffset || document.documentElement!.scrollTop;
	const startTime = performance.now();
	const animateScroll = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		window.scrollTo(
			0,
			percentage * (whereToScrollTop - scrollTop) + scrollTop
		);
		if (percentage < 1) window.requestAnimationFrame(animateScroll);
	};
	window.requestAnimationFrame(animateScroll);
};

export const getQueryStringParams = (
	query: string
): { [key: string]: string } =>
	query
		? (/^[?#]/.test(query) ? query.slice(1) : query)
				.split("&")
				.reduce((params, param) => {
					const [key, value] = param.split("=");
					params[key] = value
						? decodeURIComponent(value.replace(/\+/g, " "))
						: "";
					return params;
				}, {})
		: {};

export const shortenName = (name: string, maxLength = 75): string => {
	return name.length <= maxLength
		? name
		: name.substr(0, maxLength - 2).trim() + "..";
};

export function getHTMLElementCoords(elem: HTMLElement) {
	// crossbrowser version
	const box = elem.getBoundingClientRect();

	const body = document.body;
	const docEl = document.documentElement;

	const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
	const scrollLeft =
		window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

	const clientTop = docEl.clientTop || body.clientTop || 0;
	const clientLeft = docEl.clientLeft || body.clientLeft || 0;

	const top = box.top + scrollTop - clientTop;
	const left = box.left + scrollLeft - clientLeft;

	return { top, left };
}
(window as any).getHTMLElementCoords = getHTMLElementCoords;

export function extractVideoIdFromYoutubeLink(link: string): string {
	if (link.indexOf("youtu.be") >= 0) {
		const endIndex = link.indexOf("?");
		return link.substring(
			link.lastIndexOf("/") + 1,
			endIndex > -1 ? endIndex : undefined
		);
	}
	if (link.indexOf("youtube") >= 0) {
		const endIndex = link.indexOf("&");
		return link.substring(
			link.indexOf("=") + 1,
			endIndex > -1 ? endIndex : undefined
		);
	}
	return "";
}

export function addLeadingZeroes(num: number, size: number): string {
	let s = num + "";
	while (s.length < size) s = "0" + s;
	return s;
}

export function onAuthorOpenVideoLesson(
	resourceId: ObjectId,
	resourceType: VideoLessonResourceTypes
) {
	const VideoLessonsController = inject("VideoLessonsController");
	const win = window.open(undefined);
	const timeOffsetMn = new Date().getTimezoneOffset();
	VideoLessonsController.saveAuthorVisitTime({
		resourceId,
		resourceType,
		timeOffsetMn,
	}).then((video) => {
		if (!video.url) return;
		if (!win) return;
		win.location.replace(video.url);
	});
}

export function onStudentOpenVideoLesson(
	videoLesson: VideoLesson | null | undefined
) {
	if (!videoLesson || videoLesson.url === "") {
		return;
	}
	window.open(videoLesson.url, "_blank", "noopener,noreferrer");
}

export const isPromise = (value: any): value is Promise<unknown> => {
	return !!(value && typeof (value as any).then === "function");
};
