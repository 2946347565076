import { useLocale } from "@app/hooks/intl";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import { useMemo } from "react";

export const useCountryOptions = (getAll = false) => {
	const locale = useLocale();
	const countries = useMemo(
		() =>
			getAll
				? inject("CountriesService").getAllCounties()
				: inject("CountriesService").getCountiesForLanguage(locale),
		[locale, getAll]
	);
	return useMemo(() => {
		return countries.map((e) => ({
			value: e,
			label: getFormattedMessage(`countries.${e}`),
		}));
	}, [countries]);
};

export const useLanguageOptions = () => {
	return useMemo((): { value: string; label: string }[] => {
		const langs = ["ka", "en", "nl"];
		return langs.map((lang) => ({
			value: lang,
			label: getFormattedMessage(`languages.${lang}`),
		}));
	}, []);
};
