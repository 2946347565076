import * as React from "react";
import FillingBlanksContainer, { IFBPassableProps } from "./filling-blanks";
import MultipleChoiceContainer, { IMCPassableProps } from "./multiple-choice";
import MultipleContentsContainer, {
	IMUCPassableProps,
} from "./multiple-contents";
import SortItemsContainer, { ISIPassableProps } from "./sort-items";
import { ContentType } from "../../../schemas/questions/contnets/common-schemas";
import { css } from "emotion";
import {
	IRawQuestionContent,
	IRQuestionContent,
} from "../../../schemas/questions/contnets/schemas";
import { IQuestionItemsAssessment } from "@tests-core/schemas/questions/helper-schemas";

interface IProps {
	content: IRQuestionContent | IRawQuestionContent;
	onUserAnswerChange: (userAnswer: any) => void;
	userAnswer?: any;
	displayAnswer: boolean;
	disableEditingAnswer?: boolean;
	customized?: IQContentPassableProps;
	questionBodyRef?: React.RefObject<HTMLDivElement>;
	shuffleKey?: number;
	itemsAssessments?: IQuestionItemsAssessment;
	displayItemAssessments?: boolean;
}

export interface IQContentPassableProps {
	mcProps?: IMCPassableProps;
	siProps?: ISIPassableProps;
	fbProps?: IFBPassableProps;
	mucProps?: IMUCPassableProps;
}

const QuestionContentTestMode: React.FC<IProps> = React.memo((props) => {
	const disableEditingAnswer =
		props.disableEditingAnswer !== undefined
			? props.disableEditingAnswer
			: props.displayAnswer;
	const mainProps = {
		content: props.content,
		onUserAnswerChange: props.onUserAnswerChange,
		userAnswer: props.userAnswer,
		displayAnswer: props.displayAnswer,
		disableEditingAnswer,
		shuffleKey: props.shuffleKey,
		itemsAssessments: props.itemsAssessments,
		displayItemAssessments: !!props.displayItemAssessments,
	};
	return (
		<>
			<div ref={props.questionBodyRef} className={questionBodyCSS}>
				{props.content.type === ContentType.MultipleChoice && (
					<MultipleChoiceContainer
						{...mainProps}
						{...(props.customized && props.customized.mcProps)}
						content={props.content}
					/>
				)}
				{props.content.type === ContentType.SortItems && (
					<SortItemsContainer
						{...mainProps}
						{...(props.customized && props.customized.siProps)}
						content={props.content}
					/>
				)}
				{props.content.type === ContentType.FillingBlanks && (
					<FillingBlanksContainer
						{...mainProps}
						{...(props.customized && props.customized.fbProps)}
						content={props.content}
					/>
				)}
				{props.content.type === ContentType.MultipleContents && (
					<MultipleContentsContainer
						{...mainProps}
						{...props.customized}
						content={props.content}
					/>
				)}
			</div>
		</>
	);
});

const questionBodyCSS = css`
	img {
		max-width: 100%;
		display: block;
	}
	audio {
		display: block;
		max-width: 100%;
	}
`;

export default QuestionContentTestMode;
