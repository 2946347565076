import { useHeaderOptions } from "@app/components/layouts/header/options";
import {
	HeaderProviders,
	HeaderTree,
} from "@app/components/layouts/header/tree";
import { IAnyObj } from "@app/utils/generics";
import { CreateCusto } from "custo";
import React from "react";

export const MainAdminHeader: React.FC<IAnyObj> = (props) => {
	return (
		<HeaderProviders.PartialNonMergingProvider
			value={{
				data: {
					profileImg: "/imgs/SchoolAvatar.svg",
					menuOptions: CreateCusto.functionOf.Data(useHeaderOptions),
				},
			}}
		>
			<HeaderTree.components.OuterContainer />
		</HeaderProviders.PartialNonMergingProvider>
	);
};
