import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export enum ItemType {
	folder = 1,
	question = 2,
	card = 3,
	file = 4,
	test = 6,
	text = 7,
}

export const itemTypes = [
	ItemType.folder,
	ItemType.file,
	ItemType.card,
	ItemType.question,
	ItemType.test,
	ItemType.text,
];

export const FolderItemGeneralSchema = Joi.object({
	id: Joi.objectId().required(),
	name: Joi.string().allow("").required(),
	isHidden: Joi.boolean(),
	isOptional: Joi.boolean(),
});
export interface IFolderItemGeneral {
	id: ObjectId;
	name: string;
	isHidden?: boolean;
	isOptional?: boolean;
}

export const FolderItemSchema = FolderItemGeneralSchema.keys({
	type: Joi.number().valid(ItemType.folder).required(),
});
export interface IFolderItem extends IFolderItemGeneral {
	type: ItemType.folder;
}

export const QuestionItemSchema = FolderItemGeneralSchema.keys({
	type: Joi.number().valid(ItemType.question).required(),
});
export interface IQuestionItem extends IFolderItemGeneral {
	type: ItemType.question;
}

export const CardItemSchema = FolderItemGeneralSchema.keys({
	type: Joi.number().valid(ItemType.card).required(),
	hiddenPropertyIds: Joi.array().items(Joi.number().integer()),
});
export interface ICardItem extends IFolderItemGeneral {
	type: ItemType.card;
	hiddenPropertyIds?: number[];
}

export const FileItemSchema = FolderItemGeneralSchema.keys({
	type: Joi.number().valid(ItemType.file).required(),
});
export interface IFileItem extends IFolderItemGeneral {
	type: ItemType.file;
}

export const TestItemSchema = FolderItemGeneralSchema.keys({
	type: Joi.number().valid(ItemType.test).required(),
});
export interface ITestItem extends IFolderItemGeneral {
	type: ItemType.test;
}
export const TextItemSchema = FolderItemGeneralSchema.keys({
	type: Joi.number().valid(ItemType.text).required(),
});
export interface ITextItem extends IFolderItemGeneral {
	type: ItemType.text;
}

export const FolderSingleItemSchema = Joi.alternatives([
	FolderItemSchema,
	QuestionItemSchema,
	CardItemSchema,
	FileItemSchema,
	TestItemSchema,
	TextItemSchema,
]);
export type IFolderSingleItem =
	| IFolderItem
	| IQuestionItem
	| ICardItem
	| IFileItem
	| ITestItem
	| ITextItem;

export const RFolderSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string(),
	items: Joi.array().items(FolderSingleItemSchema),
	originalCourseId: Joi.objectId().required(),
	originalId: Joi.objectId(),
	public: Joi.boolean().required(),
	author: Joi.number().integer().required(),
	photo: Joi.string(),
});
export interface IRFolder {
	_id: ObjectId;
	name: string;
	originalCourseId: ObjectId;
	items?: IFolderSingleItem[];
	public: boolean;
	author: number;
	description?: string;
	originalId?: ObjectId;
	photo?: string;
}

export const FileProgressSchema = Joi.object({
	type: Joi.number().valid(ItemType.file).required(),
	id: Joi.objectId().required(),
	firstTimeSpent: Joi.number().integer().required(),
	totalTimeSpent: Joi.number().integer().required(),
	startedAt: Joi.date().required(),
	progress: Joi.number().required(),
	attempts: Joi.array().items(
		Joi.object({
			progress: Joi.number().required(),
			firstTimeSpent: Joi.number().integer(),
			totalTimeSpent: Joi.number().integer(),
			resetAt: Joi.date().required(),
		})
	),
	lastUpdateAt: Joi.date().required(),
});
export interface IFileProgress {
	progress: number;
	firstTimeSpent: number;
	totalTimeSpent: number;
	type: ItemType.file;
	id: ObjectId;
	startedAt: Date;
	lastUpdateAt: Date;
	attempts?: {
		progress: number;
		resetAt: Date;
		firstTimeSpent?: number;
		totalTimeSpent?: number;
	}[];
}

export const TestProgressSchema = Joi.object({
	type: Joi.number().valid(ItemType.test).required(),
	id: Joi.objectId().required(),
	timeSpent: Joi.number().integer(),
	progress: Joi.number().required(),
	attempts: Joi.array().items(
		Joi.object({
			progress: Joi.number().required(),
			timeSpent: Joi.number().integer(),
			score: Joi.number(),
			resetAt: Joi.date().required(),
		})
	),
	score: Joi.number().required(),
});
export interface ITestProgress {
	progress: number;
	score: number;
	type: ItemType.test;
	id: ObjectId;
	timeSpent?: number;
	attempts?: {
		progress: number;
		resetAt: Date;
		timeSpent?: number;
		score?: number;
	}[];
}

export const FolderProgressSchema = Joi.object({
	type: Joi.number().valid(ItemType.folder).required(),
	id: Joi.objectId().required(),
	progress: Joi.number().required(),
	attempts: Joi.array().items(
		Joi.object({
			progress: Joi.number().required(),
			resetAt: Joi.date().required(),
		})
	),
});
export interface IFolderProgress {
	progress: number;
	type: ItemType.folder;
	id: ObjectId;
	attempts?: {
		progress: number;
		resetAt: Date;
	}[];
}

const ItemProgressSchema = Joi.alternatives([
	FileProgressSchema,
	TestProgressSchema,
	FolderProgressSchema,
]);
export type IItemProgress = IFileProgress | ITestProgress | IFolderProgress;

const TotalProgressInfoSchema = Joi.object({
	total: Joi.number().integer().required(),
	totalDone: Joi.number().required(),
});
export interface ITotalProgressInfo {
	total: number;
	totalDone: number;
}

export const TotalProgressByItemTypeSchema = Joi.object({
	[ItemType.file]: TotalProgressInfoSchema.required(),
	[ItemType.folder]: TotalProgressInfoSchema.required(),
	[ItemType.test]: TotalProgressInfoSchema.required(),
});
export type ITotalProgressByItemType = {
	[key in
		| ItemType.file
		| ItemType.folder
		| ItemType.test]: ITotalProgressInfo;
};

export const UserFolderProgressSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number().integer().required(),
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	progress: Joi.number().required(),
	itemsProgress: Joi.array().items(ItemProgressSchema).required(),
	totalProgressByItemTypes: TotalProgressByItemTypeSchema.required(),
	needsRecalculation: Joi.boolean(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IUserFolderProgress {
	_id: ObjectId;
	userId: number;
	courseId: ObjectId;
	folderId: ObjectId;
	progress: number;
	itemsProgress: IItemProgress[];
	totalProgressByItemTypes: ITotalProgressByItemType;
	createdAt: Date;
	updatedAt: Date;
	needsRecalculation?: boolean;
}

export const COMPLETED_FOLDER_PROGRESS_THRESHOLD = 0.95;

////

export const UserFolderLevelSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number().integer().required(),
	courseId: Joi.objectId().required(),
	levelsByFolderId: Joi.object()
		.pattern(/^[a-f\d]{24}$/i, {
			questionsLevel: Joi.number(),
			cardsLevel: Joi.number(),
			totalLevel: Joi.number().required(),
		})
		.required(),
});
export interface IUserFolderLevel {
	_id: ObjectId;
	userId: number;
	courseId: ObjectId;
	levelsByFolderId: {
		[x: string]:
			| {
					totalLevel: number;
					questionsLevel?: number;
					cardsLevel?: number;
			  }
			| undefined;
	};
}
