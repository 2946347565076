import UserProgressCalculationService from "./calculation";
import UserFolderProgressService from ".";
import { StrictOmit } from "../../utils/generics";
import { IAPUTReadFileInfo } from "@app/api/files/validators";
import { ItemType } from "@app/api/folders/helper-schemas";
import { IAPOSTFinishTest } from "@app/api/tests/validators";
import { inject } from "@app/modules";

export default class FolderItemProgressService {
	private readonly _UserProgress: UserFolderProgressService = inject(
		"UserFolderProgressService"
	);

	private readonly _UserProgressCalculation: UserProgressCalculationService =
		inject("UserProgressCalculationService");

	addReadFileSync = (args: IAPUTReadFileInfo, userId: number) => {
		const doc = this._UserProgress.getProgressDocSync({
			userId,
			courseId: args.courseId,
			folderId: args.folderId,
		});
		let hasHandled = false;
		if (doc) {
			const fileProgress = this._UserProgress.searchItemProgress(
				args.fileId,
				ItemType.file,
				doc
			);
			if (fileProgress) {
				// File has already been read
				const newProgresss = this._UserProgressCalculation.toValidProg(
					Math.max(args.progress, fileProgress.progress)
				);
				this._UserProgress.updateProgressSync(
					{
						courseId: args.courseId,
						folderId: args.folderId,
						itemProgress: {
							...fileProgress,
							totalTimeSpent:
								fileProgress.totalTimeSpent + args.timeSpent,
							lastUpdateAt: new Date(),
							progress: newProgresss,
						},
						itemProgressIncrement:
							newProgresss - fileProgress.progress,
						incrementedItemType: ItemType.file,
					},
					userId
				);
				hasHandled = true;
			}
		}
		if (!hasHandled) {
			const newProgress = this._UserProgressCalculation.toValidProg(
				args.progress
			);
			this._UserProgress.updateProgressSync(
				{
					courseId: args.courseId,
					folderId: args.folderId,
					itemProgress: {
						type: ItemType.file,
						id: args.fileId,
						firstTimeSpent: args.timeSpent,
						totalTimeSpent: args.timeSpent,
						startedAt: args.startedReadingAt,
						lastUpdateAt: new Date(),
						progress: newProgress,
					},
					itemProgressIncrement: newProgress,
					incrementedItemType: ItemType.file,
				},
				userId
			);
			hasHandled = true;
		}
	};

	addFinishedTestSync = (
		{
			courseId,
			folderId,
			testId,
			progress,
			score,
		}: StrictOmit<IAPOSTFinishTest, "questions">,
		userId: number
	) => {
		const userFolderProgressInstance =
			this._UserProgress.getProgressDocSync({
				userId,
				courseId,
				folderId,
			});
		let oldProgress = 0;
		if (userFolderProgressInstance) {
			const testProgress = this._UserProgress.searchItemProgress(
				testId,
				ItemType.test,
				userFolderProgressInstance
			);
			if (testProgress) {
				oldProgress = testProgress.progress;
			}
		}
		const newProgresss = this._UserProgressCalculation.toValidProg(
			Math.max(progress, oldProgress)
		);
		const progressIncrement = newProgresss - oldProgress;
		this._UserProgress.updateProgressSync(
			{
				courseId,
				folderId,
				itemProgress: {
					id: testId,
					type: ItemType.test,
					progress: newProgresss,
					score,
				},
				incrementedItemType: ItemType.test,
				itemProgressIncrement: progressIncrement,
			},
			userId
		);
	};
}
