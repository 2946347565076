import React, { useCallback } from "react";
import { ObjectId } from "@app/utils/generics";
import styles from "../../styles/index.module.scss";
import { ReactComponent as ComferenceSVG } from "../../../teachers/main/tvschool/styles/imgs/conference.svg";
import { useVideoLesson } from "@app/hooks/video-lessons";
import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import { CommonButtonStyles } from "@app/common-styles";
import { onAuthorOpenVideoLesson } from "@app/common-javascript";
import VideoLessonStyles from "../../../layouts/sidebar/styles/index.module.scss";
import { useBoolean } from "@app/hooks/general";
import { FormattedMessage } from "react-intl";
import { Conference } from "@app/models/conference";
import classNames from "classnames";
import { VideoLessonLink } from "@app/components/layouts/sidebar/video-link";

export const ConferenceBox: React.FC<{ conference: Conference }> = ({
	conference,
}) => {
	const conferenceId = conference._id;
	const videoLesson = useVideoLesson(
		conferenceId,
		VideoLessonResourceTypes.CONFERENCE
	);
	const handelVideoLinkClick = useCallback(() => {
		if (!videoLesson.doc) return;
		onAuthorOpenVideoLesson(
			videoLesson.doc.resourceId,
			videoLesson.doc.resourceType
		);
	}, [videoLesson.doc]);
	const { value: displayPopup, switchValue: switchPopupDisplayStatus } =
		useBoolean(false);
	return (
		<div className={classNames(styles.boxContainer, styles.conferenceBox)}>
			<span className="block text-center font-roboto-geo-nus mt-2">
				{conference.name ?? <FormattedMessage id={"teachersRoom"} />}
			</span>
			<ComferenceSVG height={120} />
			<button style={CommonButtonStyles} onClick={handelVideoLinkClick}>
				ვიდეოზარი
			</button>
			<div
				className={styles.changeLink}
				onMouseEnter={switchPopupDisplayStatus}
				onMouseLeave={switchPopupDisplayStatus}
			>
				ბმულის შეცვლა
				{displayPopup && (
					<VideoLinkChangePopup conferenceId={conferenceId} />
				)}
			</div>
		</div>
	);
};

const VideoLinkChangePopup: React.FC<{ conferenceId: ObjectId }> = ({
	conferenceId,
}) => (
	<div className={styles.changeLinkPopup}>
		<div className={styles.videoLinkBox}>
			<div
				className={VideoLessonStyles["teacher-sidebar-container"]}
				style={{ position: "relative" }}
			>
				<VideoLessonLink
					resourceId={conferenceId}
					resourceType={VideoLessonResourceTypes.CONFERENCE}
				/>
			</div>
		</div>
	</div>
);
