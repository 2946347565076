import {
	createFetchHook,
	createResourceLoadingHook,
} from "react-fetch-data-hooks";
import {
	createDependenciesInfoHook,
	createMemoHook,
	createDependenciesEqualityFn,
} from "react-dependency-hooks";
import deepEqual from "fast-deep-equal";
import { ResourceLoading } from "react-fetch-data-hooks/lib/data";
import {
	Dispatch,
	SetStateAction,
	useRef,
	useReducer,
	useCallback,
} from "react";

const useDeepDependencies = createDependenciesInfoHook(
	createDependenciesEqualityFn(deepEqual)
);

const defaultIsIdentificationKnownFn = (deps: readonly any[]) => {
	return deps.every((e) => e !== undefined && e !== null && e !== "");
};

export const useFetch = createFetchHook({
	resourceKey: "doc",
	dependenciesInfoHook: useDeepDependencies,
	defaultIsIdentificationKnownFn,
});

export const useRawFetch = createFetchHook({
	resourceKey: null,
	dependenciesInfoHook: useDeepDependencies,
	defaultIsIdentificationKnownFn,
});

export const useResourceLoading = createResourceLoadingHook({
	resourceKey: "doc",
	dependenciesInfoHook: useDeepDependencies,
	defaultIsIdentificationKnownFn,
	defaultForcefullyFetchFn: (resource, { isMounted }) =>
		!isMounted ? true : !resource,
});

export const useSettabeResource = <
	Key extends string,
	ResLoad extends ResourceLoading<{ [key in Key]: any }, any, any>,
	Res extends ResLoad extends ResourceLoading<
		{ [key in Key]: infer R },
		any,
		any
	>
		? NonNullable<R>
		: never,
>(
	doc: ResLoad,
	key: Key
): [Res | null, Dispatch<SetStateAction<Res | null>>] => {
	const data = useRef<Res | null>(doc[key ?? null]);
	const forceRender = useForceUpdate();
	seShallowMemo<ResLoad>(
		(prev, isFirstCall) => {
			if (isFirstCall || !prev) return doc;
			if (prev[key] === doc[key]) return doc;
			if (doc.isSuccessfullyLoaded) {
				data.current = doc[key] ?? null;
			} else {
				data.current = null;
			}
			return doc;
		},
		[doc]
	);
	const setStateRef = useRef<Dispatch<SetStateAction<Res | null>>>((args) => {
		const prev = data.current;
		if (typeof args === "function") {
			data.current = (args as (prevState: Res) => Res)(data.current!);
		} else {
			data.current = args;
		}
		if (prev !== data.current) {
			forceRender();
		}
	});
	return [data.current, setStateRef.current];
};
const seShallowMemo = createMemoHook();

const useForceUpdate = (): (() => void) => {
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	return forceUpdate as any;
};

export const useMemoizedResponse = <Fn extends (...args: any) => Promise<R>, R>(
	promiseFn: Fn
): Fn => {
	const promiseFnRef = useRef(promiseFn);
	promiseFnRef.current = promiseFn;
	const momoizedResultRef = useRef({ called: false } as
		| { called: false }
		| { called: true; serializedArgs: string; result: R });
	return useCallback(
		((...args) => {
			const serializedArgs = JSON.stringify(args);
			if (
				momoizedResultRef.current.called &&
				momoizedResultRef.current.serializedArgs === serializedArgs
			) {
				return Promise.resolve(momoizedResultRef.current.result);
			}
			return promiseFnRef.current(...args).then((data): R => {
				momoizedResultRef.current = {
					called: true,
					result: data,
					serializedArgs,
				};
				return data;
			});
		}) as Fn,
		[]
	);
};

export type FetchingDoc<T, DOCKey extends string = "doc"> =
	| ({ hasFound: true } & Record<DOCKey, T>)
	| ({ hasFound: false } & { [key in DOCKey]?: undefined });
