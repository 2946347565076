/* eslint-disable no-loop-func */
import FolderHierarchyService from "../hierarchy-info/folders";
import UserProgressCalculationService, {
	IRecalculatedSubfolder,
	toValidProg,
} from "./calculation";
import { arrayToObject, generateFakeObjectId } from "../../utils/common";
import {
	IAGETAllSubFoldersProgresses,
	IRGETAllSubFoldersProgresses,
} from "../../api/folders/validators";
import {
	IFileProgress,
	IFolderProgress,
	IItemProgress,
	ItemType,
	ITestProgress,
	IUserFolderProgress,
} from "../../api/folders/helper-schemas";
import { MError } from "../../utils/errors";
import { StrictOmit, ObjectId } from "../../utils/generics";
import { inject } from "@app/modules";
import { IFolderModel, IFolderInstance } from "@app/models/folder";
import {
	IUserFolderProgressModel,
	IUserFolderProgressInstance,
} from "@app/models/user-folder-progress";
import { User } from "@app/user";

interface IArgs {
	courseId: ObjectId;
	folderId: ObjectId;
	itemProgress: IItemProgress;
	itemProgressIncrement: number;
	incrementedItemType: ItemType;
}

interface IGetMinimumInfo {
	courseId: ObjectId;
	folderId: ObjectId;
	userId: number;
}

type IRSearchItemProgress<T extends IItemProgress> = T | undefined;

interface ISearchItemProgressResult {
	[ItemType.folder]: IRSearchItemProgress<IFolderProgress>;
	[ItemType.file]: IRSearchItemProgress<IFileProgress>;
	[ItemType.test]: IRSearchItemProgress<ITestProgress>;
}

export default class UserFolderProgressService {
	private readonly _UserFolderProgress: IUserFolderProgressModel = inject(
		"UserFolderProgressModel"
	);

	private readonly _Folder: IFolderModel = inject("FolderModel");

	private readonly _FolderHierarchy: FolderHierarchyService = inject(
		"FolderHierarchyService"
	);

	_UserProgressCalculation: UserProgressCalculationService;

	getProgressDocSync = ({ courseId, folderId, userId }: IGetMinimumInfo) => {
		const doc = this._UserFolderProgress.findUserDocSync({
			userId,
			courseId,
			folderId,
		});
		if (!doc) return null;
		if (doc.needsRecalculation) {
			this._UserProgressCalculation.recalculateUserFolderProgressSync(
				courseId,
				folderId,
				userId,
				{ [doc.folderId]: doc }
			);
		}
		return doc;
	};

	getManyProgressDocByFolderIdsSync = ({
		courseId,
		folderIds,
		userId,
	}: {
		courseId: ObjectId;
		folderIds: ObjectId[];
		userId: number;
	}) => {
		const progressDocs = this._UserFolderProgress.findUserDocsByFoldersSync(
			{ userId, courseId, folderIds }
		);
		const recalculationPromises: IRecalculatedSubfolder[] = [];
		const progDocsObject = arrayToObject(progressDocs, "folderId");
		const recalulatableFolderIds: ObjectId[] = []; // same as progressDocs.filter(e => e.needsRecalculation).map(e => e.folderId);
		for (let i = 0; i < progressDocs.length; ++i) {
			if (progressDocs[i].needsRecalculation) {
				recalulatableFolderIds.push(progressDocs[i].folderId);
			}
		}
		const recalculationPromisesObj: {
			[folderId: string]: IRecalculatedSubfolder;
		} = {};
		const folders =
			recalulatableFolderIds.length === 0
				? []
				: this._Folder.findManyByIdsSync(recalulatableFolderIds);
		const foldersObj = arrayToObject(folders, "_id");
		for (let i = 0; i < recalulatableFolderIds.length; ++i) {
			const promise: IRecalculatedSubfolder =
				this._UserProgressCalculation.recalculateUserFolderProgressSync(
					courseId,
					recalulatableFolderIds[i],
					userId,
					progDocsObject,
					foldersObj,
					recalculationPromisesObj
				);
			recalculationPromisesObj[recalulatableFolderIds[i]] = promise;
			recalculationPromises.push(promise);
		}
		return progressDocs;
	};

	// tslint:disable:max-line-length
	searchItemProgress<
		K extends ItemType.folder | ItemType.file | ItemType.test,
	>(
		itemId: ObjectId,
		itemType: K,
		doc: IUserFolderProgressInstance
	): ISearchItemProgressResult[K] {
		if (!doc.itemsProgress) return undefined;
		return doc.itemsProgress.find(
			(el) => el.id === itemId && el.type === itemType
		) as any;
	}

	polluteParentFoldersSync = (
		courseId: ObjectId,
		folderId: ObjectId,
		userId: number
	) => {
		const folderIds = this._FolderHierarchy.getAncestorIdsSync(
			courseId,
			folderId
		);
		folderIds.push(folderId);
		this._UserFolderProgress.polluteForUserSync({
			courseId,
			folderIds,
			userId,
		});
	};

	polluteParentFoldersForAllUsersSync = (
		courseId: ObjectId,
		folderId: ObjectId
	) => {
		const folderIds = this._FolderHierarchy.getAncestorIdsSync(
			courseId,
			folderId
		);
		folderIds.push(folderId);
		this._UserFolderProgress.polluteForAllUsersSync({
			courseId,
			folderIds,
		});
	};

	getAllSubFoldersProgressInfoSync = (
		args: IAGETAllSubFoldersProgresses,
		user: User
	): IRGETAllSubFoldersProgresses => {
		const folderIds = this._FolderHierarchy.getDescendantIdsSync(
			args.courseId,
			args.folderId,
			args.depth
		);
		folderIds.push(args.folderId);
		const progresses = this.getManyProgressDocByFolderIdsSync({
			courseId: args.courseId,
			folderIds,
			userId: user.id,
		});
		return arrayToObject(progresses, "folderId");
	};

	updateProgressSync = (args: IArgs, userId: number) => {
		const ancestorIds = [
			args.folderId,
			...this._FolderHierarchy.getAncestorIdsSync(
				args.courseId,
				args.folderId
			),
		];
		const progresses = arrayToObject(
			this.getManyProgressDocByFolderIdsSync({
				courseId: args.courseId,
				folderIds: ancestorIds,
				userId,
			}),
			"folderId"
		);

		const progressesFolderIds = Object.keys(progresses);
		const ancestorIdsWithoutProgressDocs: ObjectId[] = ancestorIds.filter(
			(id) => progressesFolderIds.indexOf(id) < 0
		);
		const ancestorFoldersWithoutProgressDocs =
			this._Folder.findManyByIdsSync(ancestorIdsWithoutProgressDocs);

		let { itemProgress } = args;

		for (let i = 0; i < ancestorIds.length; i++) {
			const folderId = ancestorIds[i];
			let doc = progresses[folderId];
			const isNewlyCreated = !doc;
			if (!doc) {
				const folderDoc = ancestorFoldersWithoutProgressDocs.find(
					(e) => e._id === folderId
				);
				if (!folderDoc) {
					throw new MError(404, `folder for ${folderId} not found`);
				}
				doc = this.createProgressDocumentSync(
					{
						courseId: args.courseId,
						folderId,
						itemProgress,
					},
					userId,
					folderDoc
				);
			}

			const index = doc.itemsProgress.findIndex(
				(e) => e.type === itemProgress.type && e.id === itemProgress.id
			);
			const newProgress = itemProgress.progress;
			let progressIncrement: number;
			if (index >= 0) {
				const currentFolderProgress = doc.itemsProgress[index];
				let oldProgress = currentFolderProgress.progress;
				if (i === 0 && isNewlyCreated) {
					oldProgress = 0;
				}
				doc.itemsProgress[index] = {
					...(currentFolderProgress as any),
					...itemProgress,
				};
				progressIncrement = newProgress - oldProgress;
			} else {
				doc.itemsProgress.push(itemProgress);
				progressIncrement = newProgress;
			}
			doc.totalProgressByItemTypes[itemProgress.type].totalDone +=
				progressIncrement;
			if (
				args.incrementedItemType &&
				args.incrementedItemType !== ItemType.folder &&
				i > 0 &&
				!isNewlyCreated
			) {
				doc.totalProgressByItemTypes[
					args.incrementedItemType
				].totalDone += args.itemProgressIncrement;
			}
			doc.progress =
				this._UserProgressCalculation.calculateOverallProgress(doc);
			doc.itemsProgress = [...doc.itemsProgress];
			doc.totalProgressByItemTypes = { ...doc.totalProgressByItemTypes };
			doc.saveSync();
			itemProgress = {
				id: folderId,
				type: ItemType.folder,
				progress: doc.progress,
			};
		}
	};

	copyItemProgressToAnotherFolderSync = (
		courseId: ObjectId,
		source: ObjectId,
		dest: ObjectId,
		itemId: ObjectId,
		itemType: ItemType
	) => {
		const folderProgressDocs = this._UserFolderProgress.findManySync({
			courseId,
			folderId: source,
		});
		for (const doc of folderProgressDocs) {
			const itemProgress = doc.itemsProgress.find(
				(el) => el.id === itemId && el.type === itemType
			);
			if (!itemProgress) continue;
			this.updateProgressSync(
				{
					courseId,
					folderId: dest,
					itemProgress,
					itemProgressIncrement: 0,
					incrementedItemType: itemType,
				},
				doc.userId
			);
		}
	};

	private createProgressDocumentSync = (
		args: Pick<IArgs, "courseId" | "folderId" | "itemProgress">,
		userId: number,
		curFolder: IFolderInstance
	): IUserFolderProgressInstance => {
		const itemCounts =
			this._UserProgressCalculation.countItemsRecursivelySync(
				args.courseId,
				userId,
				curFolder
			);
		const docObj: StrictOmit<
			IUserFolderProgress,
			"createdAt" | "updatedAt" | "progress"
		> = {
			_id: generateFakeObjectId(),
			userId,
			courseId: args.courseId,
			folderId: args.folderId,
			itemsProgress: [args.itemProgress],
			totalProgressByItemTypes: itemCounts,
		};
		const newDoc = new this._UserFolderProgress(
			docObj as IUserFolderProgress
		);
		newDoc.progress =
			this._UserProgressCalculation.calculateOverallProgress(newDoc);
		return newDoc;
	};

	getAttemptNumberSync(args: {
		courseId: ObjectId;
		folderId: ObjectId;
		userId: number;
		itemId: ObjectId;
		itemType: ItemType;
	}) {
		const progressInfo = this.getProgressDocSync(args);
		if (!progressInfo) return 1;
		const progress = progressInfo.itemsProgress.find(
			(itemProgress) =>
				itemProgress.id === args.itemId &&
				itemProgress.type === args.itemType
		);
		if (!progress || !progress.attempts) {
			return 1;
		}
		return progress.attempts.length + 1;
	}

	getProgressOfItemSync(args: {
		courseId: ObjectId;
		folderId: ObjectId;
		userId: number;
		itemId: ObjectId;
		itemType: ItemType;
	}): number {
		const progressInfo = this.getProgressDocSync(args);
		if (!progressInfo) return 0;
		const progress = progressInfo.itemsProgress.find(
			(itemProgress) =>
				itemProgress.id === args.itemId &&
				itemProgress.type === args.itemType
		);
		if (progress) return toValidProg(progress.progress);
		return 0;
	}

	isItemLockedSync(args: {
		courseId: ObjectId;
		itemId: ObjectId;
		itemType: ItemType;
		userId: number;
		folderId?: ObjectId;
	}): boolean {
		if (!args.folderId) return false; // root folder is never locked
		const folder = this._Folder.findByIdSync(args.folderId);
		if (!folder) return true;

		const myFoldersParentId = this._FolderHierarchy.getParentIdSync(
			args.courseId,
			folder._id
		);

		const items = folder.items;
		const progressInfo = this._UserFolderProgress.findUserDocSync({
			folderId: args.folderId,
			courseId: args.courseId,
			userId: args.userId,
		});
		if (progressInfo) {
			const myProgress = this.getProgressOfItemSync({
				itemId: args.itemId,
				itemType: args.itemType,
				folderId: args.folderId,
				courseId: args.courseId,
				userId: args.userId,
			});
			if (myProgress && myProgress > 0) return false;
		}

		if (items) {
			const myIndex = items.findIndex(
				(x) => x.id === args.itemId && x.type === args.itemType
			);
			let hasFoundFinishedSiblingBeforeMe = false;
			for (let i = 0; i < myIndex; i++) {
				const item = items[i];
				if (item.isHidden || item.isOptional) continue;
				if (
					item.type !== ItemType.file &&
					item.type !== ItemType.test &&
					item.type !== ItemType.folder
				) {
					continue;
				}
				if (
					this.getProgressOfItemSync({
						itemId: item.id,
						itemType: item.type,
						folderId: args.folderId,
						courseId: args.courseId,
						userId: args.userId,
					}) < 1
				) {
					return true;
				}
				hasFoundFinishedSiblingBeforeMe = true;
			}
			if (hasFoundFinishedSiblingBeforeMe) return false;
		}
		if (!myFoldersParentId) {
			return false;
		}
		if (!this._Folder.findByIdSync(myFoldersParentId)) {
			return true; // Schrödinger's cat
		}
		return this.isItemLockedSync({
			courseId: args.courseId,
			folderId: myFoldersParentId,
			userId: args.userId,
			itemId: args.folderId,
			itemType: ItemType.folder,
		});
	}
}
