import Joi from "../../../../utils/joi";
import {
	CommonQuestionPartsSchema,
	ContentType,
	forbiddenProperties,
	StatementSchema,
	ICommonQuestionParts,
	IStatement,
	forbiddenProperty,
} from "../common-schemas";
import { markKeysForbidden } from "../../../../schemas/helper";

export const SortItemsContentSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number().valid(ContentType.SortItems).required(),
	statement: StatementSchema.required(),
	items: Joi.array().items(StatementSchema).required(),
	correctOrder: Joi.array().items(Joi.number()).required(),
});
export interface ISortItemsContent extends ICommonQuestionParts {
	type: ContentType.SortItems;
	statement: IStatement;
	items: IStatement[];
	correctOrder: number[];
}

export const RSortItemsContentSchema = SortItemsContentSchema.fork(
	[...forbiddenProperties, "correctOrder"],
	markKeysForbidden
);
export type IRSortItemsContent = Omit<
	ISortItemsContent,
	forbiddenProperty | "correctOrder"
>;

export const SortItemsUserAnsSchema = Joi.array()
	.items(Joi.number())
	.allow(null);
export type ISortItemsUserAns = number[] | null;
