import { TaskType } from "@app/models/task-type";
import { TaskTypeHierarchy } from "@app/models/task-type-hierarchy";
import { UserTaskTypeLevel } from "@app/models/user-task-type-level";
import { inject } from "@app/modules";
import { generateFakeObjectId } from "@app/utils/common";
import { collmise } from "collmise";
import { createModelSingleCollmise } from "../collmise-helpers";
import { IRequest } from "../requests";
import {
	IADELETETaskType,
	IAGETALLCourseTaskTypes,
	IAGETTaskType,
	IAGETTaskTypeHierarchy,
	IAGETTaskTypeLevels,
	IAGETTaskTypesByQuestion,
	IAPOSTCreateTaskType,
	IAPUTTaskType,
	IRGETAllTaskTypes,
	IRGETTaskType,
	IRGETTaskTypeHierarchy,
	IRGETTaskTypeLevels,
	IRGETTaskTypesByQuestion,
	IRPOSTCreateTaskType,
	RGETAllTaskTypesSchema,
	RGETTaskTypeHierarchySchema,
	RGETTaskTypeLevelsSchema,
	RGETTaskTypeSchema,
	RPOSTCreateTaskTypeSchema,
} from "./validators";

export class TaskTypesController {
	private readonly Request: IRequest;

	private readonly _TaskTypeModel = inject("TaskTypeModel");
	private readonly _UserTaskTypeLevelModel = inject("UserTaskTypeLevelModel");
	private readonly _TaskTypeHierarchyModel = inject("TaskTypeHierarchyModel");
	private readonly _TaskTypeHierarchyService = inject(
		"TaskTypeHierarchyService"
	);

	private taskTypeCollmise = createModelSingleCollmise({
		model: this._TaskTypeModel,
		name: "TaskType",
	});

	private readonly assertAndGetCoursesUser = inject(
		"assertAndGetCoursesUser"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = (args: IAPOSTCreateTaskType): Promise<TaskType> =>
		this.Request.send("POST", "/api/task-types", args, null, {
			responseSchema: RPOSTCreateTaskTypeSchema,
		}).then((data: IRPOSTCreateTaskType) => {
			if (args.parentId) {
				this._TaskTypeHierarchyService.setItemParentSync(
					args.originalCourseId,
					data._id,
					args.parentId
				);
			}
			return this._TaskTypeModel.loadOneSync(data);
		});

	update = (args: IAPUTTaskType): Promise<TaskType | null> =>
		this.Request.send("PUT", "/api/task-types/:_id", args).then(() =>
			this._TaskTypeModel.updateOneSync({ _id: args._id }, args)
		);

	private taskTypeCourseCollmise = collmise();
	getAllByCourseId = async (
		args: IAGETALLCourseTaskTypes,
		loadFresh?: boolean
	): Promise<TaskType[]> => {
		if (!loadFresh) {
			const taskTypeHierarchy =
				this._TaskTypeHierarchyModel.findOneByCourseSync(args.courseId);
			if (taskTypeHierarchy) {
				const taskTypeIds =
					this._TaskTypeHierarchyService.getAllItemIdsSync(
						args.courseId
					);
				const taskTypes =
					this._TaskTypeModel.findManyByIdsSync(taskTypeIds);
				if (taskTypeIds.length === taskTypes.length) {
					return taskTypes;
				}
			}
		}
		return this.taskTypeCourseCollmise.on(args.courseId).requestAs(() => {
			return this.Request.send(
				"GET",
				"/api/task-types/by-course-id",
				args,
				null,
				{
					responseSchema: RGETAllTaskTypesSchema,
				}
			).then((data: IRGETAllTaskTypes) => {
				this._TaskTypeModel.meta.updateLoadTime(args.courseId);
				return this._TaskTypeModel.loadManySync(data);
			});
		});
	};

	getByItem = async (
		args: IAGETTaskTypesByQuestion
	): Promise<IRGETTaskTypesByQuestion> =>
		this.Request.send("GET", "/api/task-types/by-content", args).then(
			(data: IRGETTaskTypesByQuestion) => data
		);

	getById = async (
		args: IAGETTaskType,
		loadFresh?: boolean
	): Promise<TaskType> => {
		return this.taskTypeCollmise
			.on(args._id)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send<IRGETTaskType>(
					"GET",
					"/api/task-types/:_id",
					args,
					null,
					{ responseSchema: RGETTaskTypeSchema }
				)
			);
	};

	private taskTypeHierarchyCollmise = collmise();
	getHierarchy = async (
		args: IAGETTaskTypeHierarchy,
		loadFresh?: boolean
	): Promise<TaskTypeHierarchy> => {
		if (!loadFresh) {
			const hierarchy = this._TaskTypeHierarchyModel.findOneByCourseSync(
				args.courseId
			);
			if (hierarchy) return hierarchy;
		}
		return this.taskTypeHierarchyCollmise.on(args.courseId).request(() =>
			this.Request.send("GET", "/api/task-types/hierarchy", args, null, {
				responseSchema: RGETTaskTypeHierarchySchema,
			}).then((data: IRGETTaskTypeHierarchy) => {
				return this._TaskTypeHierarchyModel.loadOneSync(data);
			})
		);
	};

	private userTaskTypeLevelCollmise = collmise();

	getLevels = async (
		args: IAGETTaskTypeLevels,
		loadFresh?: boolean,
		forceLoadingIfNeedsRecalculation?: boolean
	): Promise<UserTaskTypeLevel> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		if (!loadFresh) {
			const levelsDoc = this._UserTaskTypeLevelModel.findOneSync({
				userId,
				courseId: args.courseId,
			});
			if (levelsDoc) {
				if (!levelsDoc.needsRecalculation) return levelsDoc;
				if (forceLoadingIfNeedsRecalculation) {
					return this.getLevels(args, true);
				}
				this.getLevels(args, true).then();
				return levelsDoc;
			}
		}
		return this.userTaskTypeLevelCollmise
			.on(`${userId}-${args.courseId}`)
			.requestAs(() =>
				this.Request.send(
					"GET",
					"/api/task-types/levels",
					args,
					undefined,
					{
						responseSchema: RGETTaskTypeLevelsSchema,
					}
				)
					.then((data: IRGETTaskTypeLevels) => {
						return this._UserTaskTypeLevelModel.loadOneSync(data);
					})
					.catch((e) => {
						if (!e.response || e.response.status !== 404) {
							throw e;
						}
						const emptyData: IRGETTaskTypeLevels = {
							_id: generateFakeObjectId(),
							userId,
							courseId: args.courseId,
							levelsByTaskTypeId: {},
						};
						return this._UserTaskTypeLevelModel.loadOneSync(
							emptyData
						);
					})
			);
	};

	deleteById = (args: IADELETETaskType): Promise<void> =>
		this.Request.send("DELETE", "/api/task-types/:_id", args).then(() => {
			this._TaskTypeHierarchyService.onItemDeleteSync(
				args.originalCourseId,
				args._id
			);
		});
}
