import { ParentChildrenSchema, IParentChildren } from "./helper-schemas";
import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import { RUserSchema, IRUser } from "../users/helper-schemas";

export const APOSTAddParentChildrenSchema = Joi.object().keys({
	childrenIds: Joi.array().items(Joi.number().integer()).required(),
});
export interface IAPOSTAddParentChildren {
	childrenIds: number[];
}
export const APOSTAddChildrenToParentSchema = Joi.object().keys({
	parentId: Joi.number().integer().required(),
	childrenIds: Joi.array().items(Joi.number().integer()).required(),
	isConfirmed: Joi.boolean().required(),
});
export interface IAPOSTAddChildrenToParent {
	parentId: number;
	childrenIds: number[];
	isConfirmed: boolean;
}

export const APUTConfirmedParentSchema = Joi.object().keys({
	parentId: Joi.number().integer().required(),
});
export interface IAPUTConfirmedParent {
	parentId: number;
}

export const AGETParentChildrenSchema = Joi.object({
	userId: UnsignedIntegerSchema.optional(),
});
export interface IAGETParentChildren {
	userId?: number;
}
export const RGETParentChildrenSchema = ParentChildrenSchema;
export type IRGETParentChildren = IParentChildren;

export const AGETChildParentsSchema = Joi.object({
	userId: UnsignedIntegerSchema.optional(),
});
export interface IAGETChildParents {
	userId?: number;
}
export const RGETChildParentsSchema = Joi.object().keys({
	confirmedParentIds: Joi.array().items(Joi.number().integer()).required(),
	unConfirmedParentIds: Joi.array().items(Joi.number().integer()).required(),
});
export interface IRGETChildParents {
	confirmedParentIds: number[];
	unConfirmedParentIds: number[];
}

export const AGETChildTokenSchema = Joi.object().keys({
	childUserId: UnsignedIntegerSchema.required(),
});
export interface IAGETChildToken {
	childUserId: number;
}

export const RGETChildTokenSchema = Joi.object({
	userData: RUserSchema.required(),
	accessToken: Joi.string().required(),
});
export interface IRGETChildToken {
	userData: IRUser;
	accessToken: string;
}

export const ADELETEChildrenFromParentSchema = Joi.object().keys({
	parentId: Joi.number().integer().required(),
	childrenIds: Joi.array().items(Joi.number().integer()).required(),
	isConfirmed: Joi.boolean().required(),
});
export interface IADELETEChildrenFromParent {
	parentId: number;
	childrenIds: number[];
	isConfirmed: boolean;
}
