import * as React from "react";
import memoizeOne from "memoize-one";
import { css, cx } from "emotion";
import { mergeComponentObjects, mergeStylesObjects } from "../../../../utils";
import { NotUndefinedAtAll } from "../../../../utils/generics";

export type ViewMode = "userAnswer" | "correctAnswer";

export interface IViewModeStyles {
	container?: string;
	innerMode?: IModeStyles;
}

export interface IViewModeTexts {
	userAnswer?: string;
	correctAnswer?: string;
}

export interface IViewModeChangeComponentProps {
	mode: ViewMode;
	onChange: (newMode: ViewMode) => void;
	styles?: IViewModeStyles;
	texts?: IViewModeTexts;
}

export class ViewModeChangeComponent extends React.PureComponent<IViewModeChangeComponentProps> {
	defaultStyles = {
		container: "",
		innerMode: {
			general: modeCSS,
			selected: selectedModeCSS,
		},
	} as NotUndefinedAtAll<IViewModeChangeComponentProps["styles"]>;

	getStyles = memoizeOne(
		(styles: IViewModeChangeComponentProps["styles"]) => {
			return mergeStylesObjects(styles || {}, this.defaultStyles);
		}
	);

	defaultTexts = {
		userAnswer: "My Answer",
		correctAnswer: "Correct Answer",
	} as NotUndefinedAtAll<IViewModeChangeComponentProps["texts"]>;

	getTexts = memoizeOne((texts: IViewModeChangeComponentProps["texts"]) => {
		return mergeComponentObjects(texts || {}, this.defaultTexts);
	});

	render() {
		const styles = this.getStyles(this.props.styles);
		const texts = this.getTexts(this.props.texts);
		return (
			<div className={styles.container}>
				<Mode
					onClick={() => this.props.onChange("userAnswer")}
					styles={styles.innerMode}
					isSelected={this.props.mode === "userAnswer"}
					text={texts.userAnswer}
				/>
				<Mode
					onClick={() => this.props.onChange("correctAnswer")}
					styles={styles.innerMode}
					isSelected={this.props.mode === "correctAnswer"}
					text={texts.correctAnswer}
				/>
			</div>
		);
	}
}

export interface IModeStyles {
	general?: string;
	selected?: string;
}

export interface IModeProps {
	onClick: () => void;
	isSelected: boolean;
	text: string;
	styles: NotUndefinedAtAll<IModeStyles>;
}
const Mode: React.FC<IModeProps> = (props) => {
	let styles = props.styles.general;
	if (props.isSelected) {
		styles = cx(styles, props.styles.selected);
	}
	return (
		<div onClick={props.onClick} className={styles}>
			{props.text}
		</div>
	);
};

const modeCSS = css`
	display: inline-block;
	padding: 10px;
	border-radius: 25px;
	border: 1px solid #ccc;
	margin: 10px;
	cursor: pointer;
`;
const selectedModeCSS = css`
	background: #eee;
`;
