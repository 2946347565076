import React from "react";
import termStyles from "./styles/terms.module.css";

export const EngTerms = () => {
	return (
		<>
			<div className={termStyles.container}>
				<h1 className={termStyles.dutchTitle}>
					This document defines the General Terms of Use of the
					internet platform Edufy.pro
				</h1>
				<p className={termStyles.primary}>
					For the purposes of these General Terms of Use, the
					following terms have the following meanings:
				</p>
				<p>
					User: any legal/natural person (registered or not) who uses
					the Website or in any way uses edufy.pro, such as viewing
					and placing (learning) Materials.
				</p>
				<p>Website: www.edufy.pro and all of its components.</p>
				<p>
					(Learning) Materials: All materials that the User places on
					the Website, or assignments that the User creates himself.
					<br />
					‘We’, ‘Our’, ‘us’: Team of edufy.pro
					<br />
					The rules in these General Terms of Use are a legal
					agreement between the company that owns the Website on the
					one hand - "Edufy A-I LLC" (hereinafter - edufy.pro) and on
					the other hand the Users of the Website.
				</p>
				<p className={termStyles.primary}>
					By using the Website, the User confirms that he/she is at
					least 18 years old or, if underage, has permission from a
					parent or a legal guardian. The User must indicate that
					he/she has read the General Terms of Use, agrees and takes
					full responsibility for the rules in these General Terms of
					Use.
				</p>
				<p className={termStyles.termHeader}>1. Registration</p>
				<div className={termStyles.termText}>
					<p>
						1.1. The User is obliged to enter correct information
						when creating his/her account, during registration on
						the Website.
					</p>
					<p>
						1.2. The User does not share his/her account information
						with third parties or allow anyone outside of themselves
						to gain or use access to his/her account.
					</p>
					<p>
						1.3. The User may not disclose the information indicated
						during his/her registration or transfer that information
						to third parties, which allows the Website to be
						authorized.
					</p>
					<p>
						1.4. The information stored in the user profile must be
						only updated by the User.
					</p>
					<p>
						1.5. The User confirms that the use of the Website does
						not violate the standards of local, national and/or
						international law, and does not infringe the rights of
						third parties.
					</p>
				</div>
				<p className={termStyles.termHeader}>
					2. Posting (learning) materials on the Website
				</p>
				<div className={termStyles.termText}>
					<p>
						2.1. Users, registered as teachers (hereinafter -
						teachers) on the Website can create assignments by
						themselves and post them on the Website.
					</p>
					<p>
						2.2. The User (hereinafter - teacher) is responsible for
						the content of assignments he/she creates and/or places
						on edufy.pro.
					</p>
					<p>
						2.3. When adding (learning) Materials on the Website,
						the User confirms and warrants that:
					</p>
					<p>
						2.3.1. Placing/creating his/her (learning) Materials on
						the Website does not violate the standards of
						territorial, national or international law;
					</p>
					<p>
						2.3.2. Placing/creating his/her (learning) Materials on
						the Website does not infringe the rights of third
						parties (including other rights attributed to
						copyrights, merchandise, patents, intellectual property,
						personal property rights, personal non-proprietary or
						personal data rights);
					</p>
					<p>
						2.3.3. (Learning) Materials does not include defamation,
						insult, threats or calls to violence;
					</p>
					<p>
						2.4. When adding (learning) Materials, the User
						(teacher) allows edufy.pro to use those Materials for
						any purpose, also for commercial purposes. Edufy.pro is
						authorized to transfer this right to third parties.
					</p>
				</div>
				<p className={termStyles.termHeader}>3. Copyrights</p>
				<div className={termStyles.termText}>
					<p>
						3.1 (Learning) Materials on the Website, which are
						available to the User is the intellectual property of
						edufy.pro which is subject to the copyright, all
						proprietary and non-proprietary rights of edufy.pro,
						provided by the legislation of Georgia.
					</p>
					<p>
						3.2. Using of (learning) Materials on edufy.pro is only
						allowed on the Website, for non-commercial purposes.
					</p>
					<p>
						3.3. The User does not have the right to reproduce,
						publish, modify, transfer or use the (learning)
						materials on the Website in any form whatsoever without
						the prior permission from edufy.pro.
					</p>
				</div>
				<p className={termStyles.termHeader}>4. Responsibility</p>
				<div className={termStyles.termText}>
					<p>
						4.1. Edufy.pro is not responsible for the damage caused
						by a delay of the Website or by an individual component
						thereof or by the suspension of the Website.
					</p>
					<p>
						4.2. Edufy.pro is not responsible for the damage caused
						by the use of the Website by the User.
					</p>
					<p>
						4.3. The User is responsible for any damage he/she
						causes by using the Website.
					</p>
					<p>
						4.4. If the User violates any of these rules, edufy.pro
						has the right to restrict the use of the Website for
						this User, and delete his/her account.
					</p>
					<p>
						4.5. In case the Article 4.4. takes place, edufy.pro
						does not lose any of its rights under these General
						Terms of Use.
					</p>
					<p>
						4.6. In case the Article 4.4. takes place, it does not
						release the User from his obligations, including
						liability for damage. The User may not claim any
						compensation that may be caused by using of the
						provisions from the Article 4.4.
					</p>
				</div>
				<p className={termStyles.termHeader}>5. Privacy Statement</p>
				<div className={termStyles.termText}>
					<p>
						5.1. By using the Website, the User confirms that he/she
						is aware of and agrees to the practice of collecting
						personal data, which is defined in the Privacy
						Statement.
					</p>
					<p>
						5.2. The Privacy Statement of our Website is part of
						these General Terms of Use and all the rules in these
						Terms of Use are fully applicable.
					</p>
				</div>
				<p className={termStyles.termHeader}>6. Compensation</p>
				<div className={termStyles.termText}>
					<p>
						6.1. The User must compensate all damage to edufy.pro
						caused by the use of the Website by him/her or by
						non-compliance or incorrect compliance of the rules in
						these General Terms of Use.
					</p>
				</div>
				<p className={termStyles.termHeader}>
					7. Changing of the Terms of Use
				</p>
				<div className={termStyles.termText}>
					<p>
						7.1. Edufy.pro is entitled to make changes and/or
						additions to the General Terms Use or to the Privacy
						Statement at any time without prior notification to the
						Users.
					</p>
				</div>
				<p className={termStyles.termHeader}>8. Additional terms</p>
				<div className={termStyles.termText}>
					<p>
						8.1. The rules in these Terms of Use are mandated by the
						law of Georgia and any dispute arising from the use of
						the Website must be heard by the Tbilisi city court.
					</p>
					<p>
						8.2. The Website is intended for use in the territories
						of India, Bangladesh and Pakistan. The User is
						responsible for complying with local laws and is
						responsible for all damages caused by the violation of
						this legislation that arises when he/she uses the
						Website.
						<br />
						If the User uses the Website outside this territory,
						then he/she is responsible for compliance with local law
						and any damage caused by violation of it.
					</p>
					<p>
						8.3. Invalidity and/or impossibility of implementing
						individual provisions of these General Terms of Use does
						not affect the rest of the provisions of these Terms of
						Use. The unenforceable rule or provision will be
						replaced by the enforceable rule or provision that is
						closest to the purpose of an unenforceable rule or
						provision.
					</p>
				</div>
			</div>
		</>
	);
};
