import { css } from "emotion";

export const containerCSS = css`
	font-size: 18px;
`;

export const statementTextCSS = css`
	font-family: "Roboto Geo Nus";
`;

export const SaveButtonCSS = css`
	height: 40px;
	border: none;
	border-radius: 20px;
	color: white;
	background: #a500db;
	font-size: 18px;
	box-shadow:
		0 0 0 1px inset #a500db,
		0 0 0 3px inset white;
	font-family: "Roboto Geo Nus";
	cursor: pointer;
	outline: none;
	padding: 0px 40px;
`;
