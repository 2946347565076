import FlagIcon from "@material-ui/icons/Flag";
import StarIcon from "@material-ui/icons/StarRate";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { IInnerItemContainerProps } from "@tests-core/components/tests/navigation";
import React from "react";
import { FormattedMessage } from "react-intl";

export const TestNavigationInnerItemComponent: React.FC<IInnerItemContainerProps> =
	React.memo((props) => {
		const display = props.display;
		switch (display.type) {
			case "startPage":
				return (
					<span>
						<PlayArrowIcon />
					</span>
				);
			case "finishPage":
				return (
					<span>
						<FlagIcon />
					</span>
				);
			case "specialPage":
				if (
					typeof display.pageId === "string" &&
					display.pageId.indexOf("star") === 0
				) {
					return (
						<span>
							<StarIcon />
						</span>
					);
				}
				return (
					<span>
						<FlagIcon />
					</span>
				);
			case "text":
				return (
					<span>
						<FormattedMessage id="student:test.text" />
					</span>
				);
			case "question":
				return <span>{display.index + 1}</span>;
		}
		return null;
	});
