import {
	ClassroomPacket,
	ClassroomSchema,
	IClassroom,
} from "@app/api/classrooms/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";
import { ObjectId } from "@app/utils/generics";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IClassroom;
export type IStateClassrooms = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(ClassroomSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("classroom");
const storageSettings = getDefaultStorageSettings("classrooms");
const metaInformationName = "classroomsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().classrooms) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", ClassroomSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Classroom extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static addStudentToClassroomSync(studentId: number, classroomId: ObjectId) {
		const classroom = this.findByIdSync(classroomId);
		if (!classroom) return;
		if (classroom.studentIds.indexOf(studentId) !== -1) return;
		classroom.studentIds = [...classroom.studentIds, studentId];
		classroom.saveSync();
	}

	getLessonRootFolder = () => {
		return this.course.customFolderIds.lessons;
	};

	getRootTaskType = () => {
		return this.course.rootTaskType;
	};

	isVisible = () => {
		return !this.isProgrammaticallyHidden && !this.isArchived;
	};

	matchesSchoolId = (schoolId: number | null) => {
		return (
			this.schoolId === schoolId || (schoolId === null && !this.schoolId)
		);
	};

	isPremium = () => {
		return this.packet === ClassroomPacket.premium;
		// return true;
	};

	isEnrollable = () => {
		return !this.isPremium();
	};

	isTeacher(teacherId: number) {
		return this.teacherId === teacherId;
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Classroom.meta);

export const classroomsReducer = getDefaultReducer(
	storageSettings,
	() => Classroom
);
