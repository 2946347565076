import { UserFullInfoSchema, IUserFullInfo } from "../../helper-schemas";
import { IUserProfile } from "../helper-schemas";
import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import { UserTypes } from "@app/api/helper-schemas";

export const UserVirtualProfileSchema = Joi.object({
	userId: UnsignedIntegerSchema.required(),
	userType: Joi.string()
		.valid(...UserTypes)
		.required(),
	school: UnsignedIntegerSchema.allow(null).required(),
});
export type IUserVirtualProfile = Pick<
	IUserProfile,
	"school" | "userId" | "userType"
>;

export const UserWithProfilesSchema = UserFullInfoSchema.keys({
	profiles: Joi.array().items(UserVirtualProfileSchema).required(),
});
export interface IUserWithProfiles extends IUserFullInfo {
	profiles: IUserVirtualProfile[];
}
