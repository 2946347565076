import React from "react";
import styles from "../styles/index.module.scss";
import classNames from "classnames";
import { useGoToUrl } from "@app/hooks/front";
import { SubWebsiteOrigin } from "@app/globals";
import { useSubWebsite } from "@app/hooks/bc";
import { ISemester } from "@app/api/classrooms/helper-schemas";
import { romanize } from "@app/utils/common";

export const HeadmasterBox = React.memo<{
	onClick?: () => void;
	title?: React.ReactNode | string;
	img?: React.ReactNode;
}>(function HeadmasterBox({ onClick, title, img }) {
	return (
		<section
			className={classNames(styles.boxContainer, styles.twoPartBox)}
			onClick={onClick}
		>
			<div className={styles.innerContainer}>
				<span className={styles.imgContainer}>{img}</span>
				<span className={styles.title}>{title}</span>
			</div>
		</section>
	);
});

export const HeadmasterArchive: React.FC<{
	goto: string | (() => string);
	semester?: ISemester;
}> = React.memo(function HeadmasterArchivedClassroomBoxes({ goto, semester }) {
	const handleRedirection = useGoToUrl(goto);
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;

	return (
		<HeadmasterBox
			onClick={handleRedirection}
			title={
				isBritishCenter || !semester ? (
					"ჯგუფების არქივი"
				) : (
					<span style={{ fontSize: 20 }}>
						{semester.startYear}-{semester.startYear + 1} სასწავლო
						წლის <br /> {romanize(semester.ordinal + 1)} სემესტრის
						არქივი
					</span>
				)
			}
			img={<img src="/imgs/archive.svg" style={{ height: 135 }} />}
		/>
	);
});

export const HeadmasterArchiveBoxes: React.FC<{
	route: (semester: ISemester) => string;
	semesters: ISemester[] | null | undefined;
}> = React.memo(function HeadmasterArchiveBoxes({ route, semesters }) {
	if (!semesters) return null;

	return (
		<React.Fragment>
			{semesters.map((semester, i) => (
				<HeadmasterArchive
					key={i}
					goto={route(semester)}
					semester={semester}
				/>
			))}
		</React.Fragment>
	);
});
