import profileStyles from "@app/components/profile/styles/certificate.module.css";
import { useHistory } from "@app/hooks/front";
import React, { useCallback } from "react";

export const StudentCertificatesBox: React.FC<{
	text?: string;
	certificateBoxClassname?: string;
	textWrapperClassname?: string;
	imgSize?: number;
	imgClassname?: string;
}> = React.memo((props) => {
	const history = useHistory();
	const onClick = useCallback(() => {
		history.push("/certificate-candidates");
	}, [history]);

	return (
		// <div className={profileStyles.certificateContainer} onClick={onClick}>
		<div
			className={
				props.certificateBoxClassname || profileStyles.certificateBox
			}
			onClick={onClick}
		>
			<img
				src="/imgs/StudentsAward.svg"
				// height={props.imgSize || 110}
				alt="award"
				className={props.imgClassname}
			/>
			<p
				className={
					props.textWrapperClassname ||
					profileStyles.openCertificateStudent
				}
			>
				{props.text || "ჩემი მოსწავლეების სერტიფიკატები"}
			</p>
		</div>
		// </div>
	);
});

// const testingTeacherIds = [5, 101854, 12047, 103671, 201266];
