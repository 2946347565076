import { useHeaderThemeSetter } from "@app/components/general-context";
import React from "react";
import { ParentProfileContent } from "./parent-profile-content";

const ParentProfile: React.FC<{}> = (props) => {
	useHeaderThemeSetter("white");
	return (
		<section>
			<ParentProfileContent />
		</section>
	);
};

export default ParentProfile;
