import { IAGETSubjects } from "@app/api/subjects/validators";
import { Subject } from "@app/models/subject";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useModelDocById, useModelDocsByIds } from "m-model-react";
import { useEffect, useMemo, useState } from "react";
import { useResourceLoading } from "./fetch";

export const useSubjectOptions = (
	query: IAGETSubjects | null,
	filter?: (subject: Subject) => boolean
) => {
	const subjects = useSubjects(query);
	return useMemo(() => {
		if (!subjects.doc) return null;
		const filtered = filter ? subjects.doc.filter(filter) : subjects.doc;
		return filtered.map((subject) => ({
			label: subject.name,
			value: subject._id,
		}));
	}, [subjects.doc, filter]);
};

export const useSubjects = (query: IAGETSubjects | null) => {
	const SubjectModel = inject("SubjectModel");

	const [subjects, setSubjects] = useState(() =>
		!query ? null : SubjectModel.searchSync(query)
	);

	const queryStr = !query ? null : JSON.stringify(query);

	useEffect(() => {
		if (!query) {
			setSubjects(null);
			return;
		}
		setSubjects(SubjectModel.searchSync(query));
		return SubjectModel.subscribeChange(() => {
			setSubjects(SubjectModel.searchSync(query));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryStr]);

	return useResourceLoading(
		subjects,
		() => inject("SubjectsController").getAll(query!),
		[queryStr]
	);
};

export function useSubject(subjectId: ObjectId | null) {
	const subject = useModelDocById(inject("SubjectModel"), subjectId);

	return useResourceLoading(
		subject,
		() => inject("SubjectsController").getById({ _id: subjectId! }),
		[subjectId]
	);
}

export function useSubjectsByIds(subjectIds: ObjectId[] | null) {
	const subjects = useModelDocsByIds(inject("SubjectModel"), subjectIds);

	return useResourceLoading(
		subjects,
		() => inject("SubjectsController").getManyByIds(subjectIds!),
		[subjectIds]
	);
}
