import { IAssignmentSettings } from "@app/api/assignments/helper-schemas";
import { ItemType } from "@app/api/folders/helper-schemas";
import {
	ITestTypeSettings,
	TestTypeAlgorithms,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import {
	IEditorAssessments,
	IRTest,
	IUserTestQuestionInfo,
} from "@app/api/tests/helper-schemas";
import { IRGETTestContents } from "@app/api/tests/validators";
import { getQuestionsCustomizedProps } from "@app/components/admin/questions/custom-props";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { useBoolean } from "@app/hooks/general";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import FlagIcon from "@material-ui/icons/Flag";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {
	IAGetTestQuestion,
	IFinishPageProps,
	INextButtonProps,
	IRGetTestQuestion,
	ITestComponentProps,
	ITestFinishArgs,
	TestComponent,
} from "@tests-core/components/tests";
import {
	IInnerItemContainerProps,
	ITestNavigationProps,
	TestNavigation,
} from "@tests-core/components/tests/navigation";
import { css } from "emotion";
import memoizeOne from "memoize-one";
import React from "react";
import styled from "react-emotion";
import { ReactComponent as SentSVG } from "../styles/imgs/sent.svg";
import testStyles from "../styles/test-styles.module.css";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { getLocale } from "@app/hooks/intl";
import { getDecomposedContentIds } from "../functions/decompose";
import { AppealButton } from "../../appeal";
import { WebsiteOrigin, getCurrentWebsite } from "@app/globals";
import { useWindowSize } from "@app/hooks/front";
import { mergeComponentObjects } from "@tests-core/utils";
import triggerEvent from "@app/utils/events";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import { PublicAssignmentSentPage } from "./sent";
import { CountDown } from "@app/components/widgets/count-down";
import { getTimerForAssignment } from "../functions/timer";
import { TestNavigationInnerItemComponent } from "../navigation";

interface IProps {
	test: IRTest;
	courseId: ObjectId;
	testTypeSettings: ITestTypeSettings;
	testFeedBack?: ObjectId;
	onFinish: (args: ITestFinishArgs) => Promise<{ submitted: boolean }>;
	onSave: (args: ITestFinishArgs) => Promise<{ submitted: boolean }>;
	onGotoNext?: () => void;
	content: IRGETTestContents;
	defaultUserAnswers: IUserTestQuestionInfo[];
	editorAssessments?: IEditorAssessments;
	deadline: Date | undefined;
	deadlinePassed: boolean;
	isSubmitted: boolean;
	settings: IAssignmentSettings;
	disableShufflingAnswers: boolean;
	testNavigationProps?: Partial<ITestNavigationProps>;
	dontSubmitOnFirstNextClick?: boolean;
	onTimeRunOut?: (args: ITestFinishArgs) => void;
}

interface IState {
	isLoading: boolean;
	isFinished: boolean;
	totalCredit?: number;
	hasBeenCalledToOnGotoNext: boolean;
}

export class AssignmentTestWrapper extends React.PureComponent<IProps, IState> {
	state: IState = {
		isLoading: false,
		isFinished: this.props.deadlinePassed || this.props.isSubmitted,
		hasBeenCalledToOnGotoNext: false,
	};

	testComponentRef = React.createRef<TestComponent>();

	componentWillUnmount() {
		if (
			this.testComponentRef.current &&
			!this.state.isFinished &&
			!this.state.hasBeenCalledToOnGotoNext
		) {
			const args = this.testComponentRef.current.getFinishArgs();
			if (this.props.settings.timer && this.props.onTimeRunOut) {
				this.props.onTimeRunOut(args);
				return;
			}
			this.props.onSave(args).catch(() => null);
		}
	}

	componentDidMount() {
		if (!this.testComponentRef.current) return;
		const unansweredQuestions = this.getUnansweredQuestionInfo(
			this.testComponentRef.current.getFinishArgs()
		);
		if (unansweredQuestions.length === 0) {
			this.testComponentRef.current.onPageSelect(
				{ type: "finishPage" },
				true
			);
			this.testComponentRef.current.dangerouslySetFinishState();
		}
	}

	getNextQuestion = async (
		args: IAGetTestQuestion
	): Promise<IRGetTestQuestion> => {
		const { test, testTypeSettings } = this.props;
		if (
			testTypeSettings.algorithm ===
			TestTypeAlgorithms.catAndSpacedRepetition
		) {
		}
		if (args.unknownQuestionIds.length === 0) {
			return {
				isFinished: true,
			};
		}

		if (
			test.settings &&
			test.settings.contentIds &&
			test.settings.isContentSorted
		) {
			const decomposedContentIds = getDecomposedContentIds(
				test.settings.contentIds,
				this.props.content.texts
			);
			const q = decomposedContentIds[args.questionIndexToRequest];
			if (
				q !== undefined &&
				q.type === ItemType.question &&
				args.selectedQuestionIds.indexOf(q.id) === -1
			) {
				return {
					isFinished: false,
					questionId: q.id,
				};
			}
		}
		const index = Math.floor(
			Math.random() * args.unknownQuestionIds.length
		);
		return {
			isFinished: false,
			questionId: args.unknownQuestionIds[index],
		};
	};

	isActivelySubmittable = (currentQuestionIndex: number | null): boolean => {
		const testComp = this.testComponentRef.current;
		if (!testComp) return false;
		const finishArgs = testComp.getFinishArgs();
		if (
			finishArgs.questionIds.length === finishArgs.numOfAnsweredQuestions
		) {
			return true;
		}
		if (currentQuestionIndex === null) return false;
		return finishArgs.questionIds.length - 1 === currentQuestionIndex;
	};

	nextButton: React.FC<INextButtonProps> = (props) => {
		const SubmitButton = this.isActivelySubmittable(props.questionIndex)
			? PrimaryButton
			: SecondaryButton;
		const isLast =
			props.questionIndex === this.props.content.questions.length - 1;

		const { showAnswersAt } = this.props.testTypeSettings;
		const shouldShowAnswersImmediately =
			showAnswersAt === TestTypeAnswersShowTime.immediately;
		const isEditingEnabled = !(
			this.props.isSubmitted || this.props.deadlinePassed
		);
		const isAnswerSubmitted = props.userAnswerInfo.submitted;

		const shouldShowNextButton =
			!isLast ||
			(shouldShowAnswersImmediately &&
				isEditingEnabled &&
				!isAnswerSubmitted);

		return (
			<>
				{shouldShowNextButton && (
					<div>
						<PrimaryButton
							onClick={() => {
								props.onClick(
									this.props.dontSubmitOnFirstNextClick &&
										props.userAnswerInfo.isFullyAnswered
										? props.userAnswerInfo.submitted
											? {
													gotoNext: true,
												}
											: { gotoNext: false, submit: true }
										: {
												gotoNext: true,
											}
								);
							}}
						>
							<FormattedMessage
								id={`student:test.${
									props.userAnswerInfo.isFullyAnswered &&
									!props.userAnswerInfo.submitted
										? "submitQuestion"
										: !isLast
											? "gotoNextQuestion"
											: "finishLastQuestion"
								}`}
							/>
						</PrimaryButton>
					</div>
				)}
				<div>
					{!this.state.isFinished &&
						this.props.onGotoNext &&
						!this.props.settings.timer && (
							<SecondaryButton onClick={this.saveAndReturn}>
								<FormattedMessage id="student:test.saveAndExit" />
							</SecondaryButton>
						)}
					{this.state.isFinished && this.props.onGotoNext && (
						<SecondaryButton onClick={this.props.onGotoNext}>
							<FormattedMessage id="student:test.returnFromTestAfterFinish" />
						</SecondaryButton>
					)}
					{!this.state.isFinished && (
						<SubmitButton onClick={this.gotoLastPage}>
							{this.props.testFeedBack ? (
								<FormattedMessage id="student:test.finishTest" />
							) : (
								<FormattedMessage id="student:test.finishAssignment" />
							)}
						</SubmitButton>
					)}
					<AppealButton
						itemType={ItemType.question}
						itemId={props.questionId}
						courseId={this.props.courseId}
						style={{ width: "210px", marginTop: "30px" }}
					/>
				</div>
			</>
		);
	};

	saveAndReturn = () => {
		if (!this.testComponentRef.current) return;
		const isTVscool =
			getCurrentWebsite() === WebsiteOrigin.tvSchool &&
			getLocale() === "ka";

		this.props
			.onSave(this.testComponentRef.current.getFinishArgs())
			.then(() => {
				this.setState(
					{
						hasBeenCalledToOnGotoNext: true,
					},
					this.props.onGotoNext
				);
				isTVscool &&
					triggerEvent(
						{
							category: "Button",
							action: "sendHomework",
							label: "TVsendHomework",
						},
						{ success: true }
					);
			})
			.catch(() => null);
	};

	shouldSubmit = false;

	gotoLastPage = () => {
		if (!this.testComponentRef.current) return;
		const unansweredQuestions = this.getUnansweredQuestionInfo(
			this.testComponentRef.current.getFinishArgs()
		);
		if (unansweredQuestions.length === 0) {
			this.forcefullySubmit().catch(() => null);
			return;
		}
		this.testComponentRef.current.onPageSelect(
			{ type: "finishPage" },
			true
		);
	};

	forcefullySubmit = async (): Promise<{ submitted: true }> => {
		if (!this.testComponentRef.current) {
			throw new Error("test ref not found");
		}
		this.shouldSubmit = true;
		return this.testComponentRef.current.onFinish() as any;
	};

	getUnansweredQuestionInfo = (
		args: ITestFinishArgs
	): { questionId: ObjectId | undefined; index: number }[] => {
		const unansweredQuestions: {
			questionId: ObjectId | undefined;
			index: number;
		}[] = [];
		for (let i = 0; i < args.userAnswers.length; ++i) {
			const ans = args.userAnswers[i];
			if (
				!ans ||
				(!ans.isFullyAnswered &&
					(ans.userAnswer === null || ans.userAnswer === undefined))
			) {
				unansweredQuestions.push({
					index: i,
					questionId: args.questionIds[i],
				});
			}
		}
		return unansweredQuestions;
	};

	getMaxCredit = () => {
		try {
			let sum = 0;
			for (const q of this.props.content.questions) {
				sum += newContent((q as IFullQuestion).content).getMaxCredit();
			}
			return sum;
		} catch (e) {
			console.warn(e);
		}
		return 0;
	};

	onFinish = async (
		args: ITestFinishArgs
	): Promise<{
		submitted: boolean;
	}> => {
		this.setState({ totalCredit: args.totalCredit });
		const mash = <T extends { submitted: boolean }>(
			data: T
		): Promise<T> => {
			return new Promise((resolve) => {
				if (data.submitted) {
					this.setState(
						{
							isFinished: true,
						},
						() => resolve(data)
					);
				} else {
					resolve(data);
				}
			});
		};
		if (this.shouldSubmit) {
			return this.props.onFinish(args).then(mash);
		}
		return this.props.onSave(args).then(mash);
	};

	FinishPage = (props: IFinishPageProps) => {
		const { value: isSubmittedByButton, setTrue: setSubmittedByButton } =
			useBoolean();
		if (this.props.isSubmitted || isSubmittedByButton) {
			if (getCurrentWebsite() === WebsiteOrigin.tvSchool) {
				return (
					<PublicAssignmentSentPage
						maxCredit={this.getMaxCredit()}
						testFeedBack={this.props.testFeedBack}
						totalCredit={this.state.totalCredit}
						onGotoNext={this.props.onGotoNext}
					/>
				);
			}
			return <AssignmentSentPage onGotoNext={this.props.onGotoNext} />;
		}
		if (this.props.deadlinePassed) {
			if (getCurrentWebsite() === WebsiteOrigin.tvSchool) {
				return (
					<div style={{ textAlign: "center" }}>
						<FormattedMessage id="lookQuestionsToSeeAnswers" />{" "}
					</div>
				);
			}
			return (
				<div>
					<FormattedMessage id="student:test.deadlineIsPassed" />
				</div>
			);
		}
		const unAnswered = this.getUnansweredQuestionInfo(props.info);
		const forcefullySybmit = () => {
			this.forcefullySubmit()
				.then(() => {
					setSubmittedByButton();
				})
				.catch(() => null);
		};
		if (unAnswered.length > 0) {
			const props = this.props.testFeedBack
				? {
						sendButtonText: "ტესტის გაგზავნა",
						isCognitive: true,
					}
				: undefined;
			return (
				<UnAnsweredFinishedPage
					onSubmit={forcefullySybmit}
					numOfUnansweredQuestions={unAnswered.length}
					{...props}
				/>
			);
		}
		return (
			<SubmitComponent
				onSubmit={forcefullySybmit}
				buttonText={
					this.props.testFeedBack ? "ტესტის გაგზავნა" : undefined
				}
			/>
		);
	};

	components: ITestComponentProps["components"] = {
		FinishPage: this.FinishPage,
		Navigation: TestNavigation,
		NextButton: this.nextButton,
		Loading: FancyLoading,
	};

	getInitialQuestionsInfo = memoizeOne(
		(): ITestComponentProps["initialQuestionsInfo"] => {
			const isFinished =
				this.props.deadlinePassed || this.props.isSubmitted;
			const shouldMarkAnsweredQuestionsAsSubmitted =
				!!this.props.testTypeSettings.submitAnswerAfterAnswering;
			const editorAssessmentsOfQuestions = this.props.editorAssessments
				? this.props.editorAssessments.questions
				: {};
			const userAnswers: ITestComponentProps["initialQuestionsInfo"] =
				this.props.defaultUserAnswers.map((ans) => ({
					questionId: ans.id,
					userAnswer: ans.userAnswer,
					submitted:
						isFinished || shouldMarkAnsweredQuestionsAsSubmitted,
					assessment: editorAssessmentsOfQuestions[ans.id],
				}));
			const { test } = this.props;
			const contentIds = test.settings!.contentIds!;
			const sortedQuestionInfos = contentIds.filter(
				(e) => e.type === ItemType.question
			);
			const sortedUserAnswers: ITestComponentProps["initialQuestionsInfo"] =
				[];
			for (let i = 0; i < sortedQuestionInfos.length; ++i) {
				const questionId = sortedQuestionInfos[i].id;
				const ans = userAnswers.find(
					(e) => e && e.questionId === questionId
				);
				if (ans !== undefined) {
					sortedUserAnswers[i] = ans;
				} else {
					sortedUserAnswers[i] = {
						questionId,
						userAnswer: undefined,
						submitted: isFinished,
						assessment: editorAssessmentsOfQuestions[questionId],
					};
				}
			}
			return sortedUserAnswers;
		}
	);

	getNavigationProps = memoizeOne(
		(
			testNavigationProps?: Partial<ITestNavigationProps>
		): Partial<ITestNavigationProps> => {
			const navProps: Partial<ITestNavigationProps> = {
				displayItemsInline: true,
				components: {
					itemComponent: {
						InnerItemContainer: TestNavigationInnerItemComponent,
					},
				},
				styles: {
					item: {
						container: testStyles.itemContainer,
						isFinishPage: testStyles.finishItem,
						isStartPage: testStyles.startItem,
						isSelected: testStyles.isSelected,
						containsGradableItemsByEditor:
							testStyles.containsGradableItemsByEditor,
						isAccessible: testStyles.isAccessible,
						isNotAccessible: testStyles.isNotAccessible,
						hasAnswered: testStyles.hasAnswered,
						hasAnsweredFully: testStyles.hasAnsweredFully,
						hasAnsweredCorreclty: testStyles.hasAnsweredCorreclty,
						hasAnsweredPartiallyCorreclty:
							testStyles.hasAnsweredPartiallyCorreclty,
						hasAnsweredIncorreclty:
							testStyles.hasAnsweredIncorreclty,
					},
				},
			};
			return mergeComponentObjects(testNavigationProps || {}, navProps);
		}
	);

	AssignmentPermissionsService = inject("AssignmentPermissionsService");

	getQuestionsCustomizedProps = memoizeOne((locale: string) => {
		return getQuestionsCustomizedProps(locale, true);
	});

	onTimeRunoutWrapper = () => {
		if (!this.testComponentRef.current || !this.props.onTimeRunOut) return;
		this.props.onTimeRunOut(this.testComponentRef.current.getFinishArgs());
	};

	showCountDown = () =>
		!this.props.deadlinePassed &&
		!this.props.isSubmitted &&
		this.props.settings.timer &&
		this.props.onTimeRunOut;

	render() {
		if (this.state.isLoading) {
			return <FancyLoading />;
		}

		const {
			allowSwitchingToSubmittedQuestions,
			allowSwitchingToUnsubmittedQuestions,
		} = this.props.testTypeSettings;
		const showAnswers =
			this.AssignmentPermissionsService.getAnswerVisibilityStatus({
				deadlinePassed: this.props.deadlinePassed,
				isSubmitted: this.props.isSubmitted,
				settings: this.props.settings,
			});
		return (
			<React.Fragment>
				{this.showCountDown() && (
					<CountDown
						time={getTimerForAssignment(
							this.props.settings.timer!,
							this.props.settings.deadline
						)}
						onTimeRunOut={this.onTimeRunoutWrapper}
						containerStyles={{
							justifyContent: "flex-end",
							margin: "20px 20px 0px 0px",
						}}
					/>
				)}
				<div className="main">
					<div className={containerCSS}>
						<TestComponent
							ref={this.testComponentRef}
							key={this.props.test._id}
							allowSwitchingToSubmittedQuestions={
								this.state.isFinished
									? true
									: allowSwitchingToSubmittedQuestions
							}
							allowSwitchingToUnsubmittedQuestions={
								this.state.isFinished
									? true
									: allowSwitchingToUnsubmittedQuestions
							}
							showAnswersOfSubmittedQuestions={showAnswers}
							disableEditingAnswer={
								this.props.isSubmitted ||
								this.props.deadlinePassed
							}
							questions={this.props.content.questions}
							onFinish={this.onFinish}
							getQuestion={this.getNextQuestion}
							components={this.components}
							texts={this.props.content.texts}
							knownNumberOfQuestions={
								this.props.content.questions.length
							}
							showTextsOnSeparatePage={true}
							initialQuestionsInfo={this.getInitialQuestionsInfo()}
							questionsCustomizedProps={this.getQuestionsCustomizedProps(
								getLocale()
							)}
							NavigationProps={this.getNavigationProps(
								this.props.testNavigationProps
							)}
							disableShufflingAnswers={
								this.props.disableShufflingAnswers
							}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const AssignmentSentPage: React.FC<{ onGotoNext?: () => void }> = (props) => {
	const { width } = useWindowSize();
	return (
		<div style={{ textAlign: "center" }}>
			<h2
				style={{
					fontFamily: "Roboto Geo Nus",
					color: "#5273e6",
					fontSize: width < 450 ? "28px" : "32px",
				}}
			>
				{/* <FormattedMessage id="student:test.assignmentIsSent" /> */}
				<FormattedMessage id="AssignmentIsSent" />
			</h2>
			{props.onGotoNext && (
				<SecondaryButton
					onClick={props.onGotoNext}
					style={{ marginTop: 10 }}
				>
					<FormattedMessage id="student:test.returnFromTestAfterFinish" />
				</SecondaryButton>
			)}
			<br />
			<SentSVG style={{ width: "100%", maxWidth: "600px" }} />
		</div>
	);
};

export const EmptyButton = styled("button")(`
	border-radius: 20px;
	border: 1px solid #5273e6;
	color: #5273e6;
	font-family: "Roboto Geo Caps";
	text-align: center;
	width: 300px;
	height: 60px;
	font-size: 17px;
	margin-right: 20px;
	background-color: white;
	outline: none;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
	}
`);

export const FilledButton = styled("button")(`
	border-radius: 20px;
	background-color: #5273e6;
	color: white;
	font-family: "Roboto Geo Caps";
	text-align: center;
	width: 300px;
	height: 60px;
	border: none;
	font-size: 17px;
	outline: none;
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
	}
`);

const SubmitComponent: React.FC<{
	onSubmit: () => void;
	buttonText?: string;
}> = React.memo((props) => {
	return (
		<div style={{ textAlign: "center" }}>
			<h2 style={{ fontFamily: "Roboto Geo Nus", color: "#d66c8e" }}>
				<FormattedMessage id="student:test.answeredAllQuestions" />
			</h2>
			<PrimaryButton onClick={props.onSubmit}>
				{props.buttonText || (
					<FormattedMessage id="student:test.sendAssignment" />
				)}
			</PrimaryButton>
		</div>
	);
});

const UnAnsweredFinishedPage: React.FC<{
	onSubmit: () => void;
	numOfUnansweredQuestions: number;
	isCognitive?: boolean;
	sendButtonText?: string;
}> = (props) => {
	console.log(props.isCognitive);
	return (
		<div style={{ textAlign: "center", fontFamily: "Roboto Geo Nus" }}>
			<h2 style={{ color: "red" }}>
				<FormattedMessage
					id="student:test.unansweredQuestionsCount"
					values={{
						count: props.numOfUnansweredQuestions,
					}}
				/>
			</h2>
			<br />

			<div style={{ textAlign: "center" }}>
				<div style={{ marginBottom: "15px" }}>
					<FormattedHTMLMessage id="student:test.unansweredQuestionsSuggestion1" />
				</div>
				<div>
					<FormattedHTMLMessage
						id={`student:test.unansweredQuestionsSuggestion2${
							props.isCognitive ? "Cognitive" : ""
						}`}
					/>
				</div>
			</div>

			<br />
			<SecondaryButton onClick={props.onSubmit}>
				{props.sendButtonText || (
					<FormattedMessage id="student:test.sendAssignment" />
				)}
			</SecondaryButton>
		</div>
	);
};

const containerCSS = css`
	font-family: "Roboto Geo Nus";
	padding: 20px 20px 70px 70px;
	@media screen and (max-width: 600px) {
		padding: 20px 10px 70px 10px;
	}
`;

export const PrimaryButton = styled("button")(
	`
    border: none;
    border-radius: 4px;
    color: white;
    background: #5273e6;
    font-size: 18px;
    font-family: "Roboto Geo Caps";
    cursor: pointer;
    outline: none;
    padding: 12px 35px;
	margin-top: 30px;
	margin-right: 10px;
    box-shadow: 0 11px 10px -7px rgba(57, 58, 167, 0.5);
    transition: 0.3s;
	&:hover {
		box-shadow: 0 11px 16px -7px rgba(57, 58, 167, 1);
		background: #5068be;
	}
`,
	(props: { isFaded?: boolean; isPalitraBannerVissible?: boolean }) => {
		if (props.isFaded && props.isPalitraBannerVissible) {
			return { background: "#839fff" };
		}
		if (props.isFaded) return { opacity: 0.2 };

		return;
	}
);

export const SecondaryButton = styled("button")(`
	border-radius: 4px;
	background: white;
	border: 1px solid #ccc;
	font-size: 15px;
	font-family: "Roboto Geo Caps";
	cursor: pointer;
	outline: none;
	padding: 9px 15px;
	margin-top: 30px;
	margin-right: 10px;
	box-shadow: 0 11px 10px -7px rgba(0,0,0,0.1);
	transition: 0.3s;
	&:hover {
		box-shadow: 0 11px 16px -7px rgba(214, 108, 142, 0.5);
		background: rgb(214, 108, 142);
		border-color: transparent;
		color: white;
	}
`);
