import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import { markKeysForbidden } from "@app/api/helper-schemas";

export const TargetConceptSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	originalCourseIds: Joi.array().items(Joi.objectId().required()).required(),
	resultIds: Joi.array().items(Joi.objectId()).required(),
	coreTasks: Joi.array().items(Joi.string()).optional(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface ITargetConcept {
	_id: ObjectId;
	name: string;
	originalCourseIds: ObjectId[];
	resultIds: ObjectId[];
	coreTasks?: string[];
	createdAt: Date;
	updatedAt: Date;
}

export const ComplexAssignmentTopicSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	originalCourseIds: Joi.array().items(Joi.objectId().required()).required(),
	targetConcepts: Joi.array().items(Joi.objectId()).required(),
	author: Joi.number().optional(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IComplexAssignmentTopic {
	_id: ObjectId;
	name: string;
	originalCourseIds: ObjectId[];
	targetConcepts: ObjectId[];
	author?: number;
	createdAt: Date;
	updatedAt: Date;
}

export const ResultSchema = Joi.object({
	_id: Joi.objectId().required(),
	abbr: Joi.string().required(),
	name: Joi.string().required(),
});
export interface IResult {
	_id: ObjectId;
	abbr: string;
	name: string;
}

export const ComplexAssignmentResultsSchema = Joi.object({
	_id: Joi.objectId().required(),
	originalCourseIds: Joi.array().items(Joi.objectId().required()).required(),
	results: Joi.array().items(ResultSchema).required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IComplexAssignmentResults {
	_id: ObjectId;
	originalCourseIds: ObjectId[];
	results: IResult[];
	createdAt: Date;
	updatedAt: Date;
}

export enum ComplexAssignmentStatementType {
	text = "TEXT",
	file = "FILE",
}
export const ComplexAssignmentStatementTypes = [
	ComplexAssignmentStatementType.file,
	ComplexAssignmentStatementType.text,
];

export const ComplexAssignmentTextStatementSchema = Joi.object({
	type: Joi.string().valid(ComplexAssignmentStatementType.text).required(),
	text: Joi.string().required(),
});
export interface IComplexAssignmentTextStatement {
	type: ComplexAssignmentStatementType.text;
	text: string;
}

export const ComplexAssignmentFileStatementSchema = Joi.object({
	type: Joi.string().valid(ComplexAssignmentStatementType.file).required(),
	filename: Joi.string().required(),
	url: Joi.string().required(),
});
export interface IComplexAssignmentFileStatement {
	type: ComplexAssignmentStatementType.file;
	filename: string;
	url: string;
}

export const ComplexAssignmentStatementSchema = Joi.alternatives([
	Joi.object({
		[ComplexAssignmentStatementType.text]:
			ComplexAssignmentTextStatementSchema.required(),
		[ComplexAssignmentStatementType.file]: Joi.array().items(
			ComplexAssignmentFileStatementSchema
		),
	}),
	Joi.object({
		[ComplexAssignmentStatementType.text]:
			ComplexAssignmentTextStatementSchema.keys({
				text: Joi.string().allow("").required(),
			}),
		[ComplexAssignmentStatementType.file]: Joi.array()
			.items(ComplexAssignmentFileStatementSchema)
			.required(),
	}),
]);

export type IComplexAssignmentStatement =
	| {
			[ComplexAssignmentStatementType.text]: IComplexAssignmentTextStatement;
			[ComplexAssignmentStatementType.file]?: IComplexAssignmentFileStatement[];
	  }
	| {
			[ComplexAssignmentStatementType.text]?: IComplexAssignmentTextStatement;
			[ComplexAssignmentStatementType.file]: IComplexAssignmentFileStatement[];
	  };

export const ComplexAssignmentSchema = Joi.object({
	_id: Joi.objectId().required(),
	originalCourseId: Joi.objectId().required(),
	name: Joi.string().optional(),
	targetConceptIds: Joi.array().items(Joi.objectId()).required(),
	topicId: Joi.objectId().required(),
	statement: Joi.array()
		.items(ComplexAssignmentStatementSchema)
		.length(1)
		.required(),
	coreTasks: Joi.array()
		.min(1)
		.items(ComplexAssignmentStatementSchema)
		.required(),
	practicalSuggestions: Joi.array()
		.items(ComplexAssignmentStatementSchema)
		.optional(),
	author: Joi.number().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IComplexAssignment {
	_id: ObjectId;
	originalCourseId: ObjectId;
	name?: string;
	targetConceptIds: ObjectId[];
	topicId: ObjectId;
	statement: IComplexAssignmentStatement[];
	coreTasks: IComplexAssignmentStatement[];
	practicalSuggestions?: IComplexAssignmentStatement[];
	author: number;
	createdAt: Date;
	updatedAt: Date;
}

// response schemas

export const RComplexAssignmentSchema = ComplexAssignmentSchema.fork(
	["targetConceptIds", "topicId"],
	markKeysForbidden
).keys({
	targetConcepts: Joi.array().items(TargetConceptSchema).required(),
	topic: ComplexAssignmentTopicSchema.required(),
});
export type IRComplexAssignment = Omit<
	IComplexAssignment,
	"targetConceptIds" | "topicId"
> & {
	targetConcepts: ITargetConcept[];
	topic: IComplexAssignmentTopic;
};
