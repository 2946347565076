import React from "react";
import headerStyles from "../../users/header/styles/header.module.css";
import { useClassroomsUser } from "@app/hooks/users";
import { useSchoolById } from "@app/hooks/cities-and-schools";
import { subWebsiteDistributor } from "@app/components/subwebsites";
import { LabelSelector } from "./label-selector";
import { Calls } from "@app/components/widgets/calls";

const SchoolTitle = React.memo<{}>(function Txa({}) {
	const user = useClassroomsUser()!;
	const school = useSchoolById(user.school);
	return <SimpleTitleNextToLogoMarkup title={school.doc?.name} />;
});

export const SimpleTitleNextToLogoMarkup = React.memo<{
	title?: string | number | JSX.Element | null;
}>(function Txa({ title }) {
	return <div className={headerStyles.schoolContainer}>| {title}</div>;
});

export const TitleNextToLogo = subWebsiteDistributor({
	main: SchoolTitle,
	britishCenter: (props) => (
		<div className="flex items-center">
			<LabelSelector {...props} />
			<div className="ml-5">
				<Calls />
			</div>
		</div>
	),
});
