import {
	IWeeklyTestReservation,
	WeeklyTestPaymentType,
	WeeklyTestReservationSchema,
	WeekType,
} from "@app/api/weekly-tests/helper-schemas";
import { getSubjectsTotalPrice } from "@app/components/users/finals/prices";
import { ObjectId } from "@app/utils/generics";
import { store } from "index";
import {
	filterByLoadTime,
	getDefaultReducer,
	getDefaultStorageSettings,
	listenToLocalStorageChange,
	loadFromStorage,
} from "m-model-common";
import { createCRUDActionTypes, createModel, RawInstances } from "m-model-core";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IWeeklyTestReservation;
export type IStateWeeklyTestReservations = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(WeeklyTestReservationSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("weeklyTestReservation");
const storageSettings = getDefaultStorageSettings("weeklyTestReservations");
const metaInformationName = "weeklyTestReservationsMetaInformation";

const MAX_LOAD_TIME_DIFF = 5 * 60 * 60 * 1000;
const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().weeklyTestReservations) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"number",
				WeeklyTestReservationSchema
			),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class WeeklyTestReservation extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static findByStudent(studentId: number, weekType: WeekType) {
		return this.findOneSync({ studentId, weekType });
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);

	isVisible = () => {
		return true;
	};
	isPremium = () => {
		return this.payment?.type === WeeklyTestPaymentType.Premium;
	};
	hasFinishedRegistration = () => {
		return this.hasPartlyFinishedRegistration() && !!this.parent;
	};
	hasPartlyFinishedRegistration = () => {
		return !!this.paymentSchedule && !!this.subjectIds;
	};

	getPrice(): number {
		return getSubjectsTotalPrice(
			this.subjectIds?.length || 0,
			this.paymentRate
		);
	}

	getPriceAfterAddingSubject(): number {
		if (this.payment?.type === WeeklyTestPaymentType.Premium) return 0;
		return getSubjectsTotalPrice(
			(this.subjectIds?.length || 0) + 1,
			this.paymentRate
		);
	}

	getAmountPaid(): number {
		if (!this.payment) return 0;
		if (this.payment.type === WeeklyTestPaymentType.Premium) return 0;
		return this.payment.amountPaid;
	}

	getRemainingAmount(): number {
		const price = this.getPrice();
		if (this.payment?.type === WeeklyTestPaymentType.Premium) return 0;
		return Math.max(0, price - this.getAmountPaid());
	}

	isSubjectBought(subjectId: ObjectId): boolean {
		if (!this.payment) return false;
		if (this.payment.type === WeeklyTestPaymentType.Premium) return true;
		return !!this.payment.availableSubjectIds?.includes(subjectId);
	}

	getCourseForSubject(subjectId: ObjectId): ObjectId | null {
		if (!this.courses) return null;
		return this.courses[subjectId] ?? null;
	}
}

// ==============ETC=================

listenToLocalStorageChange(
	storage,
	metaInformationName,
	WeeklyTestReservation.meta
);

export const weeklyTestReservationsReducer = getDefaultReducer(
	storageSettings,
	() => WeeklyTestReservation
);
