import React from "react";
const defaultKey = "_default";

export function createComponentDistributor<T extends string | symbol>(
	hook: () => T | typeof defaultKey
) {
	return function <Props extends Record<any, any>>(obj: {
		[key in T | typeof defaultKey]?: React.ComponentType<Props>;
	}): React.ComponentType<Props> {
		return React.forwardRef((props, ref) => {
			const key = hook();
			const component = (obj[key as any] ?? obj[defaultKey]) as
				| React.ComponentType<Props>
				| undefined;
			if (!component) {
				throw new Error(
					"ComponentDistributor Error: cannot find component for key " +
						key
				);
			}
			return React.createElement(component, { ...props, ref } as any);
		}) as any;
	};
}
