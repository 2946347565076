import { inject } from "@app/modules";
import { ObjectId } from "@tests-core/utils/joi";
import { collmise } from "collmise";
import { UserType } from "../../helper-schemas";
import { IRequest } from "../../requests";
import { IClassroom } from "../helper-schemas";
import {
	APOSTSendSimpleInvitationSchema,
	IADELETECancelJoinRequest,
	IADELETERejectJoinRequest,
	IAPOSTApproveJoinRequest,
	IAPOSTSendSimpleInvitation,
	IAPUTSendJoinRequest,
	IAPUTSendManyJoinRequests,
	IRGETJoinRequests,
	IRPOSTApproveJoinRequest,
	IRPUTSendJoinRequest,
	IRPUTSendManyJoinRequests,
} from "./validators";

export class ClassroomJoinRequestsController {
	private readonly Request: IRequest;

	private readonly _ClassroomModel = inject("ClassroomModel");
	private readonly _ClassroomJoinRequest = inject(
		"ClassroomJoinRequestModel"
	);
	private readonly _ClassroomJoinRequestsService = inject(
		"ClassroomJoinRequestsService"
	);
	private readonly assertAndGetClassroomsUser = inject(
		"assertAndGetClassroomsUser"
	);

	private joinRequestPromises = collmise<number, any>({
		collectingTimeoutMS: 10,
	});

	constructor(request: IRequest) {
		this.Request = request;
	}

	private requestsLoadTresholdTime = 1000 * 60 * 1;
	private constructTime = Date.now();

	getAll = async (loadFresh?: boolean): Promise<IRGETJoinRequests> => {
		const user = this.assertAndGetClassroomsUser();
		if (
			!loadFresh &&
			this._ClassroomJoinRequest.meta.data.lastFullLoadTime
		) {
			const { data, hasFoundAllClassrooms } =
				this._ClassroomJoinRequestsService.getForUserSync(user);
			if (hasFoundAllClassrooms) {
				if (
					this._ClassroomJoinRequest.meta.data.lastFullLoadTime.getTime() <
						this.constructTime ||
					Date.now() -
						this._ClassroomJoinRequest.meta.data.lastFullLoadTime.getTime() >
						this.requestsLoadTresholdTime
				) {
					this.getAll(true).then();
				}
				return data;
			}
		}
		return this.joinRequestPromises
			.on(user.id)
			.fresh(loadFresh)
			.requestAs(() =>
				this.Request.send("GET", "/api/classrooms/join-requests").then(
					(data: IRGETJoinRequests) => {
						const classrooms = data
							.map((e) => e.classroom)
							.filter((e) => !!e) as IClassroom[];
						const requests = data.map((e) => e.request);
						this._ClassroomJoinRequest.clearAllSync();
						this._ClassroomJoinRequest.loadManySync(requests);
						this._ClassroomJoinRequest.meta.updateLoadTime();
						this._ClassroomModel.loadManySync(classrooms);
						return data;
					}
				)
			);
	};

	sendJoinRequest = (
		args: IAPUTSendJoinRequest
	): Promise<IRPUTSendJoinRequest> =>
		this.Request.send(
			"POST",
			"/api/classrooms/join-requests/send",
			args
		).then((data: IRPUTSendJoinRequest) => {
			this._ClassroomJoinRequest.loadOneSync(data);
			return data;
		});

	approve = (
		joinRequestId: ObjectId,
		classroomId?: ObjectId
	): Promise<IRPOSTApproveJoinRequest> => {
		const user = this.assertAndGetClassroomsUser();
		const joinRequest =
			this._ClassroomJoinRequest.findByIdSync(joinRequestId);
		if (!joinRequest) {
			throw new Error("joinRequest not found");
		}
		const clId = joinRequest.classroomId || classroomId;
		if (!clId) {
			throw new Error(
				"classroomId is required when approving request without classroomId"
			);
		}
		const args: IAPOSTApproveJoinRequest = {
			classroomId: clId,
			requesterId: joinRequest.requester.id,
		};
		return this.Request.send(
			"PUT",
			"/api/classrooms/join-requests/approve",
			args
		).then((data: IRPOSTApproveJoinRequest) => {
			this._ClassroomJoinRequest.deleteByIdSync(joinRequestId);
			const UsersController = inject("UsersController");
			if (
				joinRequest.requester.type === UserType.student &&
				user.isTeacher()
			) {
				this._ClassroomModel.addStudentToClassroomSync(
					joinRequest.requester.id,
					clId
				);
			}
			if (user.isStudent()) {
				return UsersController.getMyInfo(UserType.student, true).then();
			}
			return data;
		});
	};

	cancel = (joinRequestId: ObjectId): Promise<void> => {
		const joinRequest =
			this._ClassroomJoinRequest.findByIdSync(joinRequestId);
		if (!joinRequest) {
			throw new Error("joinRequest not found");
		}
		const args: IADELETECancelJoinRequest = {
			classroomId: joinRequest.classroomId,
			requesteeInfo: joinRequest.requesteeInfo,
		};
		return this.Request.send(
			"PUT",
			"/api/classrooms/join-requests/cancel",
			args
		).then(() => {
			this._ClassroomJoinRequest.deleteByIdSync(joinRequestId);
		});
	};

	reject = (joinRequestId: ObjectId): Promise<void> => {
		const joinRequest =
			this._ClassroomJoinRequest.findByIdSync(joinRequestId);
		if (!joinRequest) {
			throw new Error("joinRequest not found");
		}
		const args: IADELETERejectJoinRequest = {
			classroomId: joinRequest.classroomId,
			requesterId: joinRequest.requester.id,
		};
		return this.Request.send(
			"DELETE",
			"/api/classrooms/join-requests/reject",
			args
		).then(() => {
			this._ClassroomJoinRequest.deleteByIdSync(joinRequestId);
		});
	};

	public async sendMany(
		args: IAPUTSendManyJoinRequests
	): Promise<IRPUTSendManyJoinRequests> {
		return this.Request.send(
			"POST",
			"/api/classrooms/join-requests/send-many",
			args
		).then((data: IRPUTSendManyJoinRequests) => {
			this._ClassroomJoinRequest.loadManySync(data);
			return data;
		});
	}

	async sendSimpleInvitation(
		args: IAPOSTSendSimpleInvitation
	): Promise<void> {
		return this.Request.send(
			"POST",
			"/api/classrooms/join-requests/send-simple",
			args,
			undefined,
			{ requestSchema: APOSTSendSimpleInvitationSchema }
		);
	}
}
