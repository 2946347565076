import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import {
	APOSTWeeklyTestReservationSchema,
	IAPOSTWeeklyTestReservation,
} from "../weekly-tests/validators";
import { StatusValues, UserTestStatus } from "../users/helper-schemas";

export const TokensSchema = Joi.object({
	accessToken: Joi.string().required(),
	refreshToken: Joi.string().required(),
});
export interface ITokens {
	accessToken: string;
	refreshToken: string;
}

export const AuthMetaDataSchema = Joi.object({
	assignmentCode: Joi.string().allow(""),
	touSpecialCode: Joi.string().allow(""),
	writtenAssignmentsInfo: Joi.array().items(
		Joi.object({
			assignmentCode: Joi.string().required(),
			writtenAssignmentId: Joi.objectId().required(),
			assignmentId: Joi.objectId(), // TODO: make required after some time
			classroomIds: Joi.array().items().allow(null), // TODO: make required after some time
		})
	),
	classroomEnrollmentCodes: Joi.array().items(Joi.string()),
	weeklyTestInfo: APOSTWeeklyTestReservationSchema,
	userStatus: Joi.string().valid(...StatusValues),
});

export interface IAuthMetaData {
	assignmentCode?: string;
	touSpecialCode?: string;
	classroomEnrollmentCodes?: string[];
	writtenAssignmentsInfo?: {
		assignmentCode: string;
		writtenAssignmentId: ObjectId;
		assignmentId?: ObjectId; // TODO: make required after some time
		classroomIds?: ObjectId[] | null; // TODO: make required after some time
	}[];
	weeklyTestInfo?: IAPOSTWeeklyTestReservation;
	userStatus?: UserTestStatus;
}
