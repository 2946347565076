import { MarginContainer } from "@app/components/ui/containers";
import {
	ChangeIcon,
	DeleteIcon,
	EditOutlinedIcon,
	BulbIcon,
	SigmaIcon,
	CloseIcon,
} from "@app/icons";
import {
	IItemContainerProps,
	ItemText,
} from "@tests-core/components/questions/contents/common/stats";
import { IChoiceProps } from "@tests-core/components/questions/contents/multiple-choice/choices";
import React, {
	useContext,
	useMemo,
	useCallback,
	useRef,
	useState,
} from "react";
import customStyles from "./styles/questions.module.scss";
import classNames from "classnames";
import { IExplanationProps } from "@tests-core/components/questions/contents/common/explanation";
import { FormattedMessage } from "react-intl";
import { ItemEdit } from "@tests-core/components/questions/contents/common/items-edit";
import { MCEditContext } from "@tests-core/components/questions/contents/multiple-choice/edit";
import { MCContentDesignStructure } from "@tests-core/schemas/questions/contnets/multiple-choice/schema";
import { ReactComponent as QuestionIcon } from "@app/components/styles/img/icons/question.svg";
import { IIconProps } from "@tests-core/components/questions/contents/sort-items/items";
import {
	IMultipleContentsContProps,
	MultipleContentsCont,
} from "@tests-core/components/questions/contents/multiple-contents";
import { IChooseQuestionContentTypeProps } from "@tests-core/components/questions/contents/edit-wrapper";
import { ContentType } from "@tests-core/schemas/questions/contnets/common-schemas";
import {
	FBContentDesignStructure,
	IFBFile,
	IFBTextItem,
	INonCheckableInputItem,
} from "@tests-core/schemas/questions/contnets/filling-blanks/schema";
import { TabNav } from "@app/components/widgets/tab-nav";
import { FBEditContext } from "@tests-core/components/questions/contents/filling-blanks/edit";
import {
	IFBNonCheckableInputContainerProps,
	FBNonCheckableInputContainer,
	IFBFilesContainerProps,
} from "@tests-core/components/questions/contents/filling-blanks";
import { FileViewerAndUpdater } from "@app/components/ui/files/uploader";
import { getFormattedMessage } from "@app/utils/locale";

export interface QuestionViewContextProps {
	onEdit?: () => void;
	onDelete?: () => void;
	onReplace?: () => void;
	hideEdit?: boolean;
	hideDelete?: boolean;
	hideReplace?: boolean;
	globalIndex?: number;
}

export const QuestionViewContext = React.createContext(
	{} as QuestionViewContextProps
);

export interface QuestionEditContextProps {
	globalIndex: number | null;
}

export const getAnyQuestionContainer = (Comp: any) => {
	const AnyQuestionContainer: React.FC<any> = (props) => {
		const {
			globalIndex,
			onDelete,
			onEdit,
			onReplace,
			hideEdit,
			hideDelete,
			hideReplace,
		} = useContext(QuestionViewContext);

		const editIcon = (
			<EditOutlinedIcon
				width={20}
				onClick={(e) => {
					e.stopPropagation();
					onEdit?.();
				}}
				className={customStyles.editIcon}
				style={{
					color: onEdit ? undefined : "#dbdbdb",
					cursor: onEdit ? "pointer" : "not-allowed",
				}}
			/>
		);

		const deleteIcon = (
			<DeleteIcon
				width={17}
				onClick={(e) => {
					e.stopPropagation();
					onDelete?.();
				}}
				className={customStyles.deleteIcon}
			/>
		);

		const replaceIcon = (
			<ChangeIcon
				width={20}
				onClick={(e) => {
					e.stopPropagation();
					onReplace?.();
				}}
				className={customStyles.changeIcon}
				style={{
					color: onReplace ? undefined : "#dbdbdb",
					cursor: onReplace ? "pointer" : "not-allowed",
				}}
			/>
		);

		return (
			<>
				<div className={customStyles.qUpperIcons}>
					{!hideEdit && (
						<button
							className={
								!onEdit ? customStyles.disabled : undefined
							}
							onClick={onEdit}
						>
							{editIcon}{" "}
							{getFormattedMessage("teacher:editClassroomCTA")}
						</button>
					)}
					{!hideReplace && (
						<button
							className={
								!onReplace ? customStyles.disabled : undefined
							}
							onClick={onReplace}
						>
							{replaceIcon} {getFormattedMessage("replace")}
						</button>
					)}
					{!hideDelete && (
						<button
							className={
								!onDelete ? customStyles.disabled : undefined
							}
							onClick={onDelete}
						>
							{deleteIcon} {getFormattedMessage("deleteDif")}
						</button>
					)}
				</div>
				<div className={customStyles["stat-container"]}>
					<span className={customStyles.statementNumber}>
						{globalIndex !== undefined ? globalIndex + 1 : null}
					</span>

					<div className={customStyles.statement}>
						<Comp {...props} />
					</div>

					<MarginContainer
						itemsMargin={10}
						className={customStyles.qInlineIcons}
					>
						{!hideEdit && editIcon}
						{!hideDelete && deleteIcon}
						{!hideReplace && (
							<div className={customStyles.changeContainer}>
								{replaceIcon}
								{onReplace && (
									<div
										className={customStyles.changeTitle}
										onClick={onReplace}
									>
										{getFormattedMessage("replace")}
									</div>
								)}
							</div>
						)}
					</MarginContainer>
				</div>
			</>
		);
	};
	return AnyQuestionContainer;
};

export const QuestionEditContext = React.createContext({
	globalIndex: null,
} as QuestionEditContextProps);

export const MultipleChoiceComp: React.FC<IChoiceProps> = ({
	components,
	choice,
	styles,
}) => {
	const TextComponent = components?.text || ItemText;

	const isCorrect = ((choice as { score?: number }).score || 0) > 0;

	return (
		<div
			className={classNames(
				customStyles.mcChoice,
				isCorrect ? customStyles.correct : customStyles.incorrect
			)}
		>
			<TextComponent stat={choice} className={styles?.text} />
		</div>
	);
};

export const ExplanationComp: React.FC<IExplanationProps> = ({
	explanation,
	show,
}) => {
	if (!show || !explanation || !explanation.text) return null;

	const TextComponent = ItemText;

	return (
		<div className={customStyles.explanation}>
			<span className={customStyles.title}>
				<BulbIcon width="15" />
				<FormattedMessage id="admin:qEdit.common.explanation.title" />
			</span>
			<span>
				<TextComponent stat={explanation} />
			</span>
		</div>
	);
};

export const QuestionEditStatement: React.FC<IItemContainerProps> = (props) => {
	const { globalIndex } = useContext(QuestionEditContext);

	const TextComponent = props.components?.text || ItemText;
	return (
		<div className={customStyles["stat-edit-container"]}>
			<span className={customStyles.statementNumber}>
				{globalIndex !== null && globalIndex + 1}
				{globalIndex === null && (
					<QuestionIcon className={customStyles.newQIcon} />
				)}
			</span>

			<TextComponent
				extraInfo={props.extraInfo}
				stat={props.statement}
				className={customStyles.statement}
			/>
		</div>
	);
};

export const MultipleChoiceEditComp: React.FC<IChoiceProps> = (props) => {
	const TextComponent = (props.components?.text ||
		ItemEdit) as typeof ItemEdit;

	const isCorrect = ((props.choice as { score?: number }).score || 0) > 0;

	let disableEditing = false;
	const { texts, galleryComponent, onChoiceChange, designStructure } =
		useContext(MCEditContext);
	if (
		designStructure === MCContentDesignStructure.twoColumns ||
		designStructure === MCContentDesignStructure.dataSufficiency
	) {
		disableEditing = true;
	}

	return (
		<div
			className={classNames(
				customStyles.mcChoiceEdit,
				isCorrect ? customStyles.correct : customStyles.incorrect
			)}
		>
			<TextComponent
				{...(props as any)}
				components={props.components?.text as any}
				stat={props.choice}
				onChange={onChoiceChange}
				placeholder={
					(props.choice as any).score > 0
						? texts.choices!.checkedAnswer
						: texts.choices!.otherAnswer
				}
				disableEditing={disableEditing}
				galleryComponent={galleryComponent}
				styles={{
					container: customStyles.choiceStatEditContainer,
					text: customStyles.statText,
				}}
			/>
		</div>
	);
};

export const ExplanationEdit: React.FC<IExplanationProps> = React.memo(
	({ explanation, styles }) => {
		const { texts, onExplanationChange, galleryComponent } =
			useContext(MCEditContext);
		if (!explanation) return null;
		return (
			<div className={customStyles.explanationEdit}>
				<span className={customStyles.title}>
					<BulbIcon width="15" />
					<FormattedMessage id="admin:qEdit.common.explanation.title" />
				</span>
				<span>
					<ItemEdit
						stat={explanation as any}
						onChange={onExplanationChange}
						styles={styles}
						placeholder={texts.explanation!.placeholder}
						galleryComponent={galleryComponent}
					/>
				</span>
			</div>
		);
	}
);

export const SIItemIcon: React.FC<IIconProps> = ({ index, styles }) => {
	return <span className={styles?.container}>{index + 1}</span>;
};

export const CustomMultipleContentsCont: React.FC<
	IMultipleContentsContProps
> = (props) => {
	return (
		<QuestionViewContext.Provider
			value={{
				hideDelete: true,
				hideReplace: true,
				hideEdit: true,
			}}
		>
			<MultipleContentsCont {...props} />
		</QuestionViewContext.Provider>
	);
};

export const ContentTypeSelector: React.FC<IChooseQuestionContentTypeProps> =
	React.memo(
		({
			contentTypeTexts,
			selectedDesignStructure,
			selectedType,
			onChange,
		}) => {
			interface Option {
				value: number;
				label: string;
				contentType: ContentType;
				designStructure?: string;
			}
			const options = useMemo((): Option[] => {
				return [
					{
						value: 1,
						label: contentTypeTexts?.MultipleChoice?.main || "MC",
						contentType: ContentType.MultipleChoice,
					},
					{
						value: 2,
						label:
							contentTypeTexts?.FillingBlanks?.essayWithFiles ||
							"Open question",
						contentType: ContentType.FillingBlanks,
						designStructure:
							FBContentDesignStructure.essayWithFiles,
					},
				];
			}, [contentTypeTexts]);

			const onSelect = useCallback(
				(option: Option) => {
					onChange(option.contentType, option.designStructure);
				},
				[onChange]
			);

			const value = useMemo(() => {
				return (
					options.find(
						(e) =>
							e.contentType === selectedType &&
							e.designStructure === selectedDesignStructure
					)?.value || null
				);
			}, [options, selectedType, selectedDesignStructure]);

			return (
				<div style={{ textAlign: "center" }}>
					<TabNav
						options={options}
						onChange={onSelect}
						value={value}
					/>{" "}
				</div>
			);
		}
	);

export const FBStatementEdit: React.FC<IItemContainerProps> = React.memo(
	({ statement, components, styles }) => {
		const { texts, onChange } = useContext(FBEditContext);
		return (
			<ItemEdit
				key={statement.id}
				stat={statement}
				onChange={onChange}
				placeholder={texts.statement.placeholder}
				styles={styles}
			/>
		);
	}
);

export const FBNonCheckableInputViewContainer: React.FC<IFBNonCheckableInputContainerProps> =
	React.memo((props) => {
		if (
			props.designStructure === FBContentDesignStructure.essay ||
			props.designStructure === FBContentDesignStructure.essayWithFiles
		) {
			return null;
		}
		return <FBNonCheckableInputContainer {...props} />;
	});

export const FBFilesEditorContainer: React.FC<IFBFilesContainerProps> =
	React.memo(({ item: currentItem }) => {
		const item = currentItem as IFBTextItem;
		const { onChange } = useContext(FBEditContext);
		const onFilesChange = useCallback(
			(files: IFBFile[]) => {
				onChange({ ...item, files });
			},
			[item, onChange]
		);

		return (
			<FileViewerAndUpdater
				files={item.files || []}
				onChange={onFilesChange}
				direction="right"
				twoStepUpload
			/>
		);
	});

export const FBFilesViewContainer: React.FC<IFBFilesContainerProps> =
	React.memo(({ item: currentItem }) => {
		const item = currentItem as IFBTextItem;
		if (!item.files || item.files.length === 0) return null;

		return (
			<div className={customStyles.filesContainer}>
				<div className={customStyles.filesTitle}>
					{getFormattedMessage("viewAttachedFiles")}
				</div>
				<FileViewerAndUpdater files={item.files || []} />
			</div>
		);
	});
