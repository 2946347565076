import { useBoolean } from "@app/hooks/general";
import React, { useCallback } from "react";
import StudentRegisterStyles from "../auth/styles/student-register-page.module.css";
import { LoginPopup } from "./login";
import { RegisterPopup } from "./register-type-selection";

export const SpecialRegistration: React.FC<{}> = (props) => {
	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(true);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(() => {
		closeLoginPopup();
		openRegistrationPopup();
	}, [closeLoginPopup, openRegistrationPopup]);
	return (
		<>
			{isRegistrationPopupOn && (
				<RegisterPopup
					onClose={closeRegistrationPopup}
					isOnlyPopup={true}
					hideLogin={closeLoginPopup}
					isLoginPopupOpen={isLoginPopupOn}
					openLogin={openLogin}
					inputClassname={StudentRegisterStyles.inputInPopup}
					registrationButtonClassname={
						StudentRegisterStyles.registrationButtoInPopup
					}
					isCustomStylesInvissible={true}
					isRegisterIllustrationVissible={true}
					isCodeInputVissible={true}
				/>
			)}
			{isLoginPopupOn && (
				<LoginPopup
					onClose={closeLoginPopup}
					hideRegisterPage={closeRegistrationPopup}
					isRegisterPageOpen={isRegistrationPopupOn}
					openRegisterPage={openRegisterPage}
					isIconVissible={false}
					isUpperTextVissible={true}
					placeholder={"   "}
					inputClassname={StudentRegisterStyles.inputForLogin}
					isLowerMainQuestionVissible={true}
				/>
			)}
		</>
	);
};
