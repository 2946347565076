import { UserType } from "@app/api/helper-schemas";
import { IRGETMyInfoAsStudent } from "@app/api/users/validators";
import { inject } from "@app/modules";
import { useEffect, useState } from "react";
import { useCurrentChildId } from "./child";
import { useClassroomsUser, useClassroomsUserId } from "./users";

const useMyInfoAsStudent = () => {
	const classroomUserId = useClassroomsUserId();
	const [studentInfo, setStudentInfo] = useState<IRGETMyInfoAsStudent | null>(
		null
	);

	useEffect(() => {
		if (!classroomUserId) return;
		const UsersController = inject("UsersController");
		UsersController.getMyInfo(UserType.student);
		return UsersController.myInfoSubscription.subscribe<IRGETMyInfoAsStudent>(
			setStudentInfo
		);
	}, [classroomUserId]);

	return studentInfo;
};

export const useChildInfoAsParent = (childId: number) => {
	const [studentInfo, setStudentInfo] = useState<IRGETMyInfoAsStudent | null>(
		null
	);

	useEffect(() => {
		const UsersController = inject("UsersController");
		UsersController.getChildInfo(childId).then((data) =>
			setStudentInfo(data)
		);
	}, [childId]);

	return studentInfo;
};

export const useMyInfoAdStudentOrChild = (passedChildId?: number) => {
	const globalChild = useCurrentChildId();
	const childId = passedChildId ?? globalChild;
	const loggedUser = useClassroomsUser();
	if (loggedUser?.isParent()) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useChildInfoAsParent(+childId!);
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useMyInfoAsStudent();
};
