import Joi from "@app/utils/joi";

export const UserViewSchema = Joi.object({
	userId: Joi.number().integer().required(),
	studentMainPage: Joi.object({
		currentLesson: Joi.date(),
		classroomJoin: Joi.date(),
		taskTypes: Joi.date(),
		nExams: Joi.date(),
		greeting: Joi.date(),
	}),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IUserView {
	userId: number;
	createdAt: Date;
	updatedAt: Date;
	studentMainPage?: {
		currentLesson?: Date;
		classroomJoin?: Date;
		taskTypes?: Date;
		nExams?: Date;
		greeting?: Date;
	};
}
