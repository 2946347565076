import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import {
	FeedbackInfo,
	FeedbackInfoSchema,
	IFeedback,
	FeedbackSchema,
} from "./helper-schemas";
import { insertStripKeys, ToInsertKeys } from "../helper-schemas";

export const APOSTFeedbackSchema = FeedbackSchema.keys(insertStripKeys);
export type IAPOSTFeedback = ToInsertKeys<IFeedback>;

export interface IRPostFeedback {
	_id: ObjectId;
	author: number;
	info: FeedbackInfo;
	name: string;
}

export const RPostFeedbackSchema = Joi.object({
	_id: Joi.objectId().required(),
	author: Joi.number().required(),
	info: FeedbackInfoSchema.required(),
	name: Joi.string().required(),
});

export const APOSTUpdateFeedbackSchema = Joi.object({
	_id: Joi.objectId().required(),
	info: FeedbackInfoSchema.required(),
	name: Joi.string().required(),
});

export interface IAPOSTUpdateFeedback {
	_id: ObjectId;
	info: FeedbackInfo;
	name: string;
}

export const ADeleteFeedbackSchema = Joi.object({
	_id: Joi.objectId().required(),
});

export interface IADeleteFeedback {
	_id: ObjectId;
}

export const AGETOneFeedbackByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});

export interface IAGETOneFeedbackById {
	_id: ObjectId;
}

export type IRGETOneFeedbackById = IFeedback;

export const AGETManyFeedbackByIdSchema = Joi.object({
	_ids: Joi.array().items(Joi.objectId().required()).required(),
});

export interface IAGETManyFeedbackById {
	_ids: ObjectId[];
}

export type IRGETManyFeedbackById = IFeedback[];

export type IRGETManyFeedback = IFeedback[];

export const RGETManyFeedbackSchema = Joi.array()
	.items(FeedbackSchema.required())
	.required();
