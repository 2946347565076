import React, { useMemo, useState } from "react";
import Popup, { PopupContentWithClose } from "../popup";
import { useWindowSize } from "@app/hooks/front";
import { GoogleDocsViewerWrapper } from "./google-docs-viewer";
import Loading from "../raw-loading";
import styles from "./styles/index.module.css";
import { WhiteDownloadIcon } from "@app/icons";
import { VideoPlayer } from "../video-player";
import {
	canBeOpenedWithGoogleDocsViewer,
	canBeOpenedWithImageViewer,
	getFileExtension,
	canBeOpenedWithVideoPlayer,
} from "./helpers";
import axios from "axios";

export const FilePreview: React.FC<{
	fileUrl: string;
	fileName?: string;
	onClose: () => void;
	disableDownload?: boolean;
}> = React.memo(({ fileUrl, onClose, fileName, disableDownload }) => {
	const handleDownload = () => {
		/// if success -> we do not popup anymore
		// if -> error  we open new popup
		onClose();
		axios
			.get(fileUrl, {
				headers: { "Access-Control-Allow-Origin": "*" },
				responseType: "blob",
				crossdomain: true,
			} as any)
			.then(async (res) => {
				const blob = res.data;
				const blobURL = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = blobURL;
				a.style["display"] = "none";

				if (fileName && fileName.length) a.download = fileName;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			})
			.catch(() => {
				window.open(fileUrl, "__blank");
			});
	};

	console.log(disableDownload);

	return (
		<Popup onClose={onClose}>
			<PopupContentWithClose onClose={onClose}>
				<div className={styles.filePreviewContainer}>
					{!disableDownload && (
						<div
							className={styles.downloadButton}
							onClick={handleDownload}
						>
							<WhiteDownloadIcon className={styles.icon} />{" "}
							გადმოწერა
						</div>
					)}
					<FilePreviewWithoutPopup
						fileName={fileName}
						fileUrl={fileUrl}
					/>
				</div>
			</PopupContentWithClose>
		</Popup>
	);
});

export const FilePreviewWithoutPopup: React.FC<{
	fileUrl: string;
	fileName?: string;
}> = React.memo(({ fileUrl, fileName }) => {
	const fileExtension = useMemo(() => getFileExtension(fileUrl), [fileUrl]);

	const openWithGoogleDocs = useMemo(
		() => canBeOpenedWithGoogleDocsViewer(fileExtension),
		[fileExtension]
	);

	const isPdf = useMemo(() => fileExtension === "pdf", [fileExtension]);

	const isImage = useMemo(
		() => canBeOpenedWithImageViewer(fileExtension),
		[fileExtension]
	);

	const isSupportedVideo = useMemo(
		() => canBeOpenedWithVideoPlayer(fileExtension),
		[fileExtension]
	);

	return useMemo(() => {
		if (isPdf) {
			return <Pdf fileUrl={fileUrl} fileName={fileName} />;
		}
		if (openWithGoogleDocs) {
			return <GoogleDocsViewerWrapper fileUrl={fileUrl} />;
		}
		if (isImage) {
			return <Photo photoUrl={fileUrl} />;
		}
		if (isSupportedVideo) {
			return <VideoPlayer fileUrl={fileUrl} format={fileExtension} />;
		}
		return (
			<div className={styles.noPreview}>
				{`${fileExtension} ფორმატის ფაილის სანახავად, გთხოვთ, გადმოიწეროთ ფაილი`}
			</div>
		);
	}, [
		fileExtension,
		fileName,
		fileUrl,
		isImage,
		isPdf,
		isSupportedVideo,
		openWithGoogleDocs,
	]);
});

const Photo: React.FC<{ photoUrl: string }> = React.memo(({ photoUrl }) => {
	const [loaded, setLoaded] = useState<boolean>(false);
	const { width, height } = useWindowSize();
	return (
		<div>
			{!loaded && <Loading />}
			<img
				alt="preview"
				src={photoUrl}
				style={{
					display: loaded ? undefined : "none",
					maxWidth: `${(width * 3) / 5}px`,
					maxHeight: `${(height * 4) / 5}px`,
				}}
				onLoad={() => setLoaded(true)}
			/>
		</div>
	);
});

const Pdf: React.FC<{ fileUrl: string; fileName?: string }> = ({
	fileUrl,
	fileName,
}) => {
	return (
		<div
			style={{
				width: "1000px",
				height: "500px",
			}}
		>
			<iframe
				src={fileUrl}
				title={fileName}
				width="100%"
				height="100%"
			></iframe>
		</div>
	);
};
