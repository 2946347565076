import { CourseType } from "@app/api/courses-info/helper-schemas";
import { MarginContainer } from "@app/components/ui/containers";
import { useFetch } from "@app/hooks/fetch";
import { useBoolean } from "@app/hooks/general";
import { useSchoolAndLabelFiltererFn } from "@app/hooks/school-structure";
import { useClassroomsUserId } from "@app/hooks/users";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import classNames from "classnames";
import { useModelDocsByIds } from "m-model-react";
import React, { useMemo } from "react";
import {
	useSelectedSchoolLabelId,
	useTeacherSelectedSchoolId,
} from "../../contexts/teacher-school";
import { TVSchoolClassroomBox } from "./classrooms";
import styles from "./styles/tvschool-classroom.module.css";

function ArchivedClassroomsContainer() {
	const isOpen = useBoolean();
	const userId = useClassroomsUserId()!;
	const classrooms = useAllClassroomsOfTeacher(userId);

	const teacherSchoolId = useTeacherSelectedSchoolId();
	const schoolLabelId = useSelectedSchoolLabelId();
	const classroomsFiltererFn = useSchoolAndLabelFiltererFn({
		schoolId: teacherSchoolId,
		schoolLabelId,
	});

	const archivedClassroomIds = useMemo(() => {
		if (!classrooms) return null;
		return classrooms
			.filter(
				(e) =>
					e.isArchived &&
					e.course.type !== CourseType.cognitive &&
					classroomsFiltererFn(e) &&
					!e.semester
			)
			.map((e) => e._id);
	}, [classrooms, classroomsFiltererFn]);

	return (
		<div className="main text-center" style={{ zIndex: 2 }}>
			{archivedClassroomIds && archivedClassroomIds.length > 0 && (
				<ArchivedClassroomsLoader
					isOpen={isOpen.value}
					onClick={isOpen.switchValue}
				/>
			)}
			{isOpen.value && archivedClassroomIds && (
				<ArchivedClassroomsDisplay
					archivedClassroomIds={archivedClassroomIds}
					enableArchive={true}
				/>
			)}
			{isOpen.value && <br />}
			{isOpen.value && <br />}
		</div>
	);
}

const useAllClassroomsOfTeacher = (userId: number | null) => {
	const classroomIds = useFetch(
		() =>
			inject("ClassroomsController")
				.getAll({ teacherId: userId! })
				.then((allClassroomsOfTeacher) =>
					allClassroomsOfTeacher.map((e) => e._id)
				),
		[userId]
	);
	return useModelDocsByIds(
		inject("ClassroomModel"),
		classroomIds.doc ?? null
	);
};

const ArchivedClassroomsContainerMemo = React.memo(ArchivedClassroomsContainer);
export { ArchivedClassroomsContainerMemo as ArchivedClassroomsContainer };

const ArchivedClassroomsLoader: React.FC<{
	isOpen: boolean;
	onClick: () => void;
}> = React.memo(({ isOpen, onClick }) => {
	return (
		<div>
			<div
				className={classNames(
					styles.archivedClassrooms,
					isOpen
						? styles.archivedClassroomsOpen
						: styles.archivedClassroomsClosed
				)}
				onClick={onClick}
			>
				<span>{getFormattedMessage("classroomsArchive")}</span>
				{isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
			</div>
		</div>
	);
});

const ArchivedClassroomsDisplay: React.FC<{
	archivedClassroomIds: ObjectId[];
	enableArchive?: boolean;
}> = React.memo(({ archivedClassroomIds, enableArchive = false }) => {
	return (
		<div>
			<div>
				<MarginContainer
					itemsMargin={20}
					style={{ textAlign: "center" }}
				>
					{archivedClassroomIds.map((e) => (
						<TVSchoolClassroomBox
							classroomId={e}
							key={e}
							enableArchive={enableArchive}
						/>
					))}
				</MarginContainer>
			</div>
		</div>
	);
});
