import React, { useState, useMemo } from "react";
import { SigmaIcon, LessEqualIcon, MoreEqualIcon } from "@app/icons";
import styles from "./styles/math-instructions.module.scss";
import { covertASCIIMathToLaTex } from "@tests-core/components/editor/math";
import { useWindowSize } from "@app/hooks/front";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { toMemoizedHook } from "@app/hooks/general";

const SubjectNamesToShowMathInstructions: string[] = ["მათემატიკა", "ფიზიკა"];

export const showMathInstructionForSubject = (subjectName: string): boolean =>
	!!SubjectNamesToShowMathInstructions.find((e) => e === subjectName);

interface Instruction {
	name: string | JSX.Element;
	equation: string;
	direct?: boolean;
}

const InstructionBox: React.FC<{
	instruction: Instruction;
	showDivider: boolean;
}> = ({ instruction, showDivider }) => {
	return (
		<div className={styles.instructionBoxContainer}>
			<div className={styles.instructionBox}>
				<div className={styles.instructionBoxContainer}>
					<div className={styles.name}>{instruction.name}</div>
					{!instruction.direct && (
						<div
							className={styles.equationContainer}
							dangerouslySetInnerHTML={{
								__html: covertASCIIMathToLaTex(
									instruction.equation
								),
							}}
						/>
					)}
				</div>
				<div>{instruction.equation}</div>
			</div>
			{showDivider && <div className={styles.divider} />}
		</div>
	);
};

const getInstructions = (): Instruction[] => [
	{
		equation: "<<x/y>>",
		name: "წილადი:",
	},
	{
		equation: "<<x_2>>",
		name: "ინდექსი:",
	},
	{
		equation: "<<root(2)(x)>>",
		name: "ფესვი:",
	},
	{
		equation: "<<x^2>>",
		name: "ხარისხი:",
	},
	{
		equation: "<<(-oo; -5)>>",
		name: "უსასრულობა:",
	},
	{
		equation: "<<x>=2>> ან <<x<=2>>",
		name: (
			<div style={{ display: "flex" }}>
				<LessEqualIcon width={10} style={{ marginRight: "5px" }} />
				უტოლობის ნიშნები
				<MoreEqualIcon width={10} style={{ marginLeft: "5px" }} />
			</div>
		),
		direct: true,
	},
];

const useInstructions = toMemoizedHook(getInstructions);

export const MathEquationsInstruction: React.FC<{
	containerStyles?: React.CSSProperties;
}> = React.memo(({ containerStyles }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { width } = useWindowSize();
	const instructions = useInstructions();

	if (width <= 900) return null;

	return (
		<div style={containerStyles}>
			{!isOpen ? (
				<div
					className={styles.mathEquationsExplanationClosed}
					onClick={() => setIsOpen(true)}
				>
					<SigmaIcon className={styles.sigmaIcon} />
					<span>გამოსახულებების ჩაწერის ინსტრუქცია</span>
					<ArrowDownIcon className={styles.downIcon} />
				</div>
			) : (
				<div className={styles.mathEquationsExplanation}>
					<ArrowUpIcon
						onClick={() => setIsOpen(false)}
						className={styles.closeIcon}
					/>
					<div className={styles.explanation}>
						გამოსახულებების ჩაწერის ინსტრუქცია
					</div>
					<div className={styles.instructions}>
						{instructions.map((e, i) => (
							<InstructionBox
								key={i}
								instruction={e}
								showDivider={i !== instructions.length - 1}
							/>
						))}
					</div>
					<div>
						<div
							className={styles.explanation}
							style={{ marginBottom: 0 }}
						>
							სპეციალური სიმბოლოები დასაკოპირებლად
						</div>
						<div className="text-center">α β π ρ ε λ φ ν μ θ ω</div>
					</div>
				</div>
			)}
		</div>
	);
});
