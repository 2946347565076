/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect, useEffect } from "react";
import {
	SchedulerOptions,
	SingleThreadScheduler,
	SchedulerTaskOption,
	SchedulerTask,
} from "./scheduler";

export const crateSchedulerComponent = (options: SchedulerOptions) => {
	const scheduler = new SingleThreadScheduler(options);

	const TaskComponent: React.FC<
		SchedulerTaskOption & {
			onShow?: () => void;
			onHide?: () => void;
			onVisibilityChange?: (visible: boolean) => void;
		}
	> = ({ onShow, onHide, onVisibilityChange, children, ...taskOptions }) => {
		const [isRunning, setIsActive] = useState(false);
		const [task, setTask] = useState<SchedulerTask | null>(null);

		useLayoutEffect(() => {
			task?.setOptions(taskOptions);
		}, [JSON.stringify(taskOptions)]);

		useLayoutEffect(() => {
			const task = scheduler.addTask(taskOptions);
			setTask(task);
			task.subscribe(setIsActive);
			return () => {
				task?.destroy();
			};
		}, []);

		const visible = taskOptions.enabled && isRunning;

		useEffect(() => {
			if (!visible) return;
			onShow?.();
		}, [visible, onShow]);

		useEffect(() => {
			if (visible) return;
			onHide?.();
		}, [visible, onHide]);

		useEffect(() => {
			onVisibilityChange?.(visible);
		}, [visible, onVisibilityChange]);

		if (!visible) return null;

		return <React.Fragment>{children}</React.Fragment>;
	};

	return TaskComponent;
};
