import Joi from "@app/utils/joi";
import {
	RequesteeInfoSchema,
	ClassroomJoinRequestSchema,
	IRequesteeInfo,
	IClassroomJoinRequest,
} from "./helper-schemas";
import { ClassroomSchema, IClassroom } from "../helper-schemas";
import { ObjectId } from "@tests-core/utils/joi";

/* send join requests */
export const APUTSendJoinRequestSchema = Joi.object({
	classroomId: Joi.objectId(),
	requesteeInfo: RequesteeInfoSchema.required(),
});
export interface IAPUTSendJoinRequest {
	classroomId?: ObjectId;
	requesteeInfo: IRequesteeInfo;
}

export const RPUTSendJoinRequestSchema = ClassroomJoinRequestSchema;
export type IRPUTSendJoinRequest = IClassroomJoinRequest;

export const APUTSendManyJoinRequestsSchema = Joi.array().items(
	APUTSendJoinRequestSchema
);
export type IAPUTSendManyJoinRequests = IAPUTSendJoinRequest[];

export const RPUTSendManyJoinRequestsSchema = Joi.array().items(
	RPUTSendJoinRequestSchema
);
export type IRPUTSendManyJoinRequests = IRPUTSendJoinRequest[];

/* cancel join requests */
export const ADELETECancelJoinRequest = APUTSendJoinRequestSchema;
export type IADELETECancelJoinRequest = IAPUTSendJoinRequest;

export type IRDELETECancelJoinRequest = void;

/* get all requests for user */
export const RGETJoinRequestsSchema = Joi.array().items(
	Joi.object({
		classroom: ClassroomSchema,
		request: ClassroomJoinRequestSchema.required(),
	})
);
export type IRGETJoinRequests = {
	classroom?: IClassroom;
	request: IClassroomJoinRequest;
}[];

/* approve join request */
export const APOSTApproveJoinRequestSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	requesterId: Joi.number().integer().required(),
});
export interface IAPOSTApproveJoinRequest {
	classroomId: ObjectId;
	requesterId: number;
}

export type IRPOSTApproveJoinRequest = void;

/* reject join request */
export const ADELETERejectJoinRequestSchema = Joi.object({
	classroomId: Joi.objectId(),
	requesterId: Joi.number().integer().required(),
});
export interface IADELETERejectJoinRequest {
	requesterId: number;
	classroomId?: ObjectId;
}

export type IRDELETERejectJoinRequest = void;

///

export const APOSTSendSimpleInvitationSchema = Joi.object({
	classroomId: Joi.objectId().required(),
	mobiles: Joi.array().items(Joi.string()).max(50).required(),
});

export interface IAPOSTSendSimpleInvitation {
	classroomId: ObjectId;
	mobiles: string[];
}
