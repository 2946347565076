import * as origianljoi from "joi";
import Joi, { getJoiObjectKeys } from "@app/utils/joi";

export enum UserType {
	student = "STUDENT",
	teacher = "TEACHER",
	parent = "PARENT",
	developer = "DEVELOPER",
	mainAdmin = "MAIN_ADMIN",
	school = "SCHOOL",
	headmaster = "HEADMASTER",
}

export const UserTypes = [
	UserType.student,
	UserType.teacher,
	UserType.developer,
	UserType.mainAdmin,
	UserType.school,
	UserType.headmaster,
	UserType.parent,
];

type LooseOptionalKeys<T, K extends string | number | symbol> = Omit<T, K> &
	Partial<{ [key in K & keyof T]: T[key] }>;

export const updateStripKeys = {
	createdAt: Joi.any().strip(),
	updatedAt: Joi.any().strip(),
	author: Joi.any().strip(),
	authorId: Joi.any().strip(),
	created_at: Joi.any().strip(),
	updated_at: Joi.any().strip(),
	author_id: Joi.any().strip(),
};

type metaKeys =
	| "createdAt"
	| "updatedAt"
	| "author"
	| "authorId"
	| "created_at"
	| "updated_at"
	| "author_id";

export type ToUpdateKeys<
	T,
	OptionalKeys extends string | never = never,
	OmitKeys extends string = never,
> = Omit<LooseOptionalKeys<T, OptionalKeys>, metaKeys | OmitKeys>;

export type ToLooseUpdateKeys<
	T,
	RequiredlKeys extends keyof T = never,
	OmitKeys extends keyof T = never,
> = Omit<
	LooseOptionalKeys<
		Omit<T, Exclude<metaKeys, RequiredlKeys>>,
		Exclude<keyof T, RequiredlKeys | "_id" | "id">
	>,
	OmitKeys
>;

export const insertStripKeys = {
	_id: Joi.forbidden(),
	createdAt: Joi.any().strip(),
	updatedAt: Joi.any().strip(),
	author: Joi.any().strip(),
	authorId: Joi.any().strip(),
	id: Joi.forbidden(),
	created_at: Joi.any().strip(),
	updated_at: Joi.any().strip(),
	author_id: Joi.any().strip(),
};
export type ToInsertKeys<
	T,
	OptionalKeys extends string | never = never,
	OmitKeys extends string = never,
> = Omit<
	LooseOptionalKeys<T, OptionalKeys>,
	| "_id"
	| "createdAt"
	| "updatedAt"
	| "author"
	| "authorId"
	| "id"
	| "created_at"
	| "updated_at"
	| "author_id"
	| OmitKeys
>;

export const markKeysOptional = (schema: origianljoi.Schema) =>
	schema.optional();
export const markKeysForbidden = (schema: origianljoi.Schema) =>
	schema.forbidden();
export const markKeysStripped = (schema: origianljoi.Schema) =>
	schema.optional().strip();

export const LocSchema = Joi.object({
	language: Joi.string().allow(null).empty(["", "null"]).default(null),
	country: Joi.string().allow(null).empty(["", "null"]).default(null),
	website: Joi.string().allow(null).empty(["", "null"]).default(null),
});

export interface ILoc {
	language: string | null;
	country: string | null;
	website: string | null;
}

export const toLooseUpdateSchema = <T extends origianljoi.ObjectSchema>(
	schema: T
): T => {
	return schema
		.fork(
			getJoiObjectKeys(schema).filter((e) => e !== "id" && e !== "_id"),
			markKeysOptional
		)
		.keys(updateStripKeys);
};

///
