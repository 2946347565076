import { IRequest } from "../requests";
import {
	IAPOSTGenerateCertificate,
	APOSTGenerateCertificateSchema,
	IRGETUserCertificateStatus,
	IAGETCertificateCandidateStudents,
	IRGETCertificateCandidateStudents,
	AGETCertificateCandidateStudentsSchema,
	RGETCertificateCandidateStudentsSchema,
	IAPUTCertificateCandidateStudentsStatus,
	APUTCertificateCandidateStudentsStatusSchema,
	IRPUTCertificateCandidateStudentsStatus,
	IAGETPreviewStudentCertificateForTeacher,
	AGETPreviewStudentCertificateForTeacherSchema,
	RGETPreviewStudentCertificateForTeacherSchema,
	IRGETPreviewStudentCertificateForTeacher,
	IADELETEStudentCertificateDecision,
	IRDeleteStudentCertificateDecision,
	IRGETCertificatesForStudent,
	IAGETSpecialCertificate,
	IRGETSpecialCertificate,
	IAPUTSpecialCertificateAction,
} from "./validators";
import { ICertificate } from "./helper-schemas";

export class CertificatesController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	generateCerificate = async (
		args: IAPOSTGenerateCertificate
	): Promise<ICertificate> =>
		this.Request.send("POST", "/api/certificates/generate", args, null, {
			requestSchema: APOSTGenerateCertificateSchema,
		});

	getStatus = async (): Promise<IRGETUserCertificateStatus> =>
		this.Request.send("GET", "/api/certificates/status");

	getStudentCanditates = async (args: IAGETCertificateCandidateStudents) =>
		this.Request.send(
			"GET",
			"/api/certificates/student-candidates",
			args,
			null,
			{
				requestSchema: AGETCertificateCandidateStudentsSchema,
				responseSchema: RGETCertificateCandidateStudentsSchema,
			}
		).then((data: IRGETCertificateCandidateStudents) => data);

	updateStudentCandidateStatus = async (
		args: IAPUTCertificateCandidateStudentsStatus
	): Promise<IRPUTCertificateCandidateStudentsStatus> =>
		this.Request.send(
			"PUT",
			"/api/certificates/student-candidates/statuses",
			args,
			null,
			{
				requestSchema: APUTCertificateCandidateStudentsStatusSchema,
			}
		);

	previewForTeacher = async (
		args: IAGETPreviewStudentCertificateForTeacher
	): Promise<IRGETPreviewStudentCertificateForTeacher> =>
		this.Request.send(
			"GET",
			"/api/certificates/students/preview",
			args,
			null,
			{
				requestSchema: AGETPreviewStudentCertificateForTeacherSchema,
				responseSchema: RGETPreviewStudentCertificateForTeacherSchema,
			}
		);

	deleteCandidateDecision = async (
		args: IADELETEStudentCertificateDecision
	): Promise<IRDeleteStudentCertificateDecision> =>
		this.Request.send(
			"DELETE",
			"/api/certificates/student-candidates/decisions",
			args
		);

	getCertificatesForStudent =
		async (): Promise<IRGETCertificatesForStudent> =>
			this.Request.send("GET", "/api/certificates/students");

	marStudentCertificateSeen = async () =>
		this.Request.send("PUT", "/api/certificates/students/seen");

	getSpecialCertificateUrl(
		args: IAGETSpecialCertificate
	): Promise<IRGETSpecialCertificate> {
		return this.Request.send("GET", "/api/certificates/special", args);
	}

	performSpecialCertificateActions(
		args: IAPUTSpecialCertificateAction
	): Promise<void> {
		return this.Request.send(
			"PUT",
			"/api/certificates/special/actions",
			args
		);
	}
}
