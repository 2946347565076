import { IRequest } from "@app/api/requests";
import { inject } from "@app/modules";
import { GradebookCategoryModel } from "@app/models/grade-book-category";
import { ObjectId } from "@app/utils/generics";
import {
	IAGETManyStudentGradeCategoriesByIds,
	RGETManyStudentGradeCategoriesByIdsSchema,
	IRGETManyStudentGradeCategoriesByIds,
	IAGETStudentGradeCategoryById,
	RGETStudentGradeCategoryByIdSchema,
	IRGETStudentGradeCategoryById,
	IAGETStudentGradeCategories,
	IRGETStudentGradeCategories,
	RGETStudentGradeCategoriesSchema,
	IADELETEStudentGradeCategoryById,
	IAPOSTClassroomStudentGradeCategory,
	IRPOSTClassroomStudentGradeCategory,
	RPOSTClassroomStudentGradeCategorySchema,
	IAPUTClassroomStudentGradeCategory,
	IRPUTClassroomStudentGradeCategory,
} from "./validators";

export class StudentGradeCategoryController {
	private readonly Request: IRequest;
	private readonly _GradebookCategoryModel = inject("GradeBookCategoryModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	create = async (
		args: IAPOSTClassroomStudentGradeCategory
	): Promise<GradebookCategoryModel> => {
		return this.Request.send(
			"POST",
			"/api/student-grades/categories",
			args,
			null,
			{
				responseSchema: RPOSTClassroomStudentGradeCategorySchema,
			}
		).then((group: IRPOSTClassroomStudentGradeCategory) => {
			return this._GradebookCategoryModel.loadOneSync(group);
		});
	};

	update = async (
		args: IAPUTClassroomStudentGradeCategory
	): Promise<GradebookCategoryModel> => {
		return this.Request.send(
			"PUT",
			"/api/student-grades/categories/:_id",
			args,
			null,
			{
				responseSchema: RPOSTClassroomStudentGradeCategorySchema,
			}
		).then((group: IRPUTClassroomStudentGradeCategory) => {
			return this._GradebookCategoryModel.loadOneSync(group);
		});
	};

	getManyByIds = async (
		args: IAGETManyStudentGradeCategoriesByIds,
		loadFresh?: boolean
	): Promise<GradebookCategoryModel[]> => {
		if (!loadFresh) {
			const studentGradeCategories =
				this._GradebookCategoryModel.findManyByIdsSync(args.ids);
			if (studentGradeCategories.length === args.ids.length) {
				return studentGradeCategories;
			}
		}
		return this.Request.send(
			"POST",
			"/api/student-grades/categories/get-by-ids",
			args,
			null,
			{
				responseSchema: RGETManyStudentGradeCategoriesByIdsSchema,
			}
		).then(
			(studentGradeCategories: IRGETManyStudentGradeCategoriesByIds) => {
				return this._GradebookCategoryModel.loadManySync(
					studentGradeCategories
				);
			}
		);
	};

	getById = async (
		_id: ObjectId,
		loadFresh?: boolean
	): Promise<GradebookCategoryModel> => {
		if (!loadFresh) {
			const group = this._GradebookCategoryModel.findByIdSync(_id);
			if (group) return group;
		}
		return this.Request.send(
			"GET",
			"/api/student-grades/categories/:_id",
			{ _id } as IAGETStudentGradeCategoryById,
			null,
			{
				responseSchema: RGETStudentGradeCategoryByIdSchema,
			}
		).then((group: IRGETStudentGradeCategoryById) => {
			return this._GradebookCategoryModel.loadOneSync(group);
		});
	};

	getMany = async (
		args: IAGETStudentGradeCategories = {}
	): Promise<GradebookCategoryModel[]> =>
		this.Request.send(
			"GET",
			"/api/student-grades/categories",
			args,
			undefined,
			{
				responseSchema: RGETStudentGradeCategoriesSchema,
			}
		).then((e: IRGETStudentGradeCategories) =>
			this._GradebookCategoryModel.loadManySync(e)
		);

	delete = async (_id: ObjectId): Promise<void> => {
		const args: IADELETEStudentGradeCategoryById = { _id };
		return this.Request.send(
			"DELETE",
			"/api/student-grades/categories/:_id",
			args
		);
	};
}
