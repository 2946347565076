import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export enum FeedbackType {
	static = "STATIC",
	interval = "INTERVAL",
}
export const FeedbackTypes = [FeedbackType.static, FeedbackType.interval];

export interface FeedbackSingleInterval {
	from: number;
	leftInclusive: boolean;
	to: number;
	rightInclusive: boolean;
	text: string;
}

const FeedbackSingleIntervalSchema = Joi.object({
	from: Joi.number().required(),
	leftInclusive: Joi.boolean().required(),
	to: Joi.number().required(),
	rightInclusive: Joi.boolean().required(),
	text: Joi.string().required(),
});

export interface IntervalFeedback {
	type: FeedbackType.interval;
	intervals: FeedbackSingleInterval[];
}

const IntervalFeedbackSchema = Joi.object({
	type: FeedbackType.interval,
	intervals: Joi.array()
		.items(FeedbackSingleIntervalSchema.required())
		.required(),
});

export interface StaticFeedback {
	type: FeedbackType.static;
	text: string;
}

const StaticFeedbackSchema = Joi.object({
	type: FeedbackType.static,
	text: Joi.string().required(),
});

export type FeedbackInfo = IntervalFeedback | StaticFeedback;

export const FeedbackInfoSchema = Joi.alternatives(
	IntervalFeedbackSchema,
	StaticFeedbackSchema
);

export interface IFeedback {
	_id: ObjectId;
	name: string;
	info: FeedbackInfo;
	author: number;
	createdAt: Date;
	updatedAt: Date;
}

export const FeedbackSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	info: FeedbackInfoSchema.required(),
	author: Joi.number().integer().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
