import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import {
	IRCoursesUser,
	RCoursesUserSchema,
	ICoursesAdminPermissions,
	ICourseAdminPermissions,
	ICoursesMainAdminPermissions,
	ICoursesStudentPermissions,
	ICoursesUserPermissions,
} from "@app/api/users/helper-schemas";

export abstract class CoursesUser implements IRCoursesUser {
	public static createUserInstance(user: IRCoursesUser): CoursesUser {
		const validationResult = this.validateUserObject(user);
		if (validationResult.error) {
			throw validationResult.error;
		}
		const { permissions } = validationResult.value!;
		if (permissions) {
			if ((permissions as ICoursesAdminPermissions).isAdmin) {
				const adminPermissions =
					permissions as ICoursesAdminPermissions;
				if (adminPermissions.isMainAdmin) {
					return new CoursesMainAdmin(user);
				}
				if (
					adminPermissions.isAdmin &&
					adminPermissions.courseIds !== undefined
				) {
					return new CourseAdmin(user);
				}
			}
			return new CoursesStudent(user);
		}
		return new CoursesStudent(user);
	}

	private static validateUserObject(userObject: IRCoursesUser) {
		return RCoursesUserSchema.keys({
			iat: Joi.number().integer().optional(),
			exp: Joi.number().integer().optional(),
		}).validate(userObject, { stripUnknown: true });
	}

	id: number;
	murtskuId: number | null;
	mobile: string | null;
	mail: string | null;
	username?: string;
	firstname?: string | null;
	lastname?: string | null;
	permissions?: ICoursesUserPermissions;

	constructor(user: IRCoursesUser) {
		const validationResult = CoursesUser.validateUserObject(user);
		if (validationResult.error) {
			throw validationResult.error;
		}
		user = validationResult.value!;

		this.id = user.id;
		this.murtskuId = user.murtskuId;
		this.mobile = user.mobile;
		this.mail = user.mail || null;
		this.username = user.username;
		this.firstname = user.firstname;
		this.lastname = user.lastname;
		this.permissions = user.permissions as ICoursesUserPermissions;
	}

	abstract canAccessAllCourses(): boolean;
	abstract canAccessCourse(courseId: ObjectId): boolean;
	abstract canStudyCourse(courseId: ObjectId): boolean;
}

export abstract class CoursesAdmin extends CoursesUser {
	permissions: ICoursesAdminPermissions;

	constructor(user: IRCoursesUser) {
		super(user);
		this.permissions = user.permissions! as ICoursesAdminPermissions;
		if (!this.permissions) {
			throw new Error(
				"cannot create an admin without permissions; expected permissions, got: " +
					this.permissions
			);
		}
	}
}

export class CoursesMainAdmin extends CoursesAdmin {
	permissions: ICoursesMainAdminPermissions;

	constructor(user: IRCoursesUser) {
		super(user);
		this.permissions = user.permissions! as ICoursesMainAdminPermissions;
		if (!this.permissions || !this.permissions.isMainAdmin) {
			throw new Error(
				`cannot create main admin with permissions ${JSON.stringify(
					user.permissions
				)}`
			);
		}
	}

	canAccessAllCourses(): boolean {
		return true;
	}

	canAccessCourse(courseId: ObjectId): boolean {
		return true;
	}

	canStudyCourse(courseId: ObjectId): boolean {
		return true;
	}
}

export class CourseAdmin extends CoursesAdmin {
	permissions: ICourseAdminPermissions;

	constructor(user: IRCoursesUser) {
		super(user);
		this.permissions = user.permissions! as ICourseAdminPermissions;
		if (
			!this.permissions ||
			!this.permissions.isAdmin ||
			this.permissions.courseIds === undefined
		) {
			throw new Error(
				`cannot create course admin with permissions ${JSON.stringify(
					user.permissions
				)}`
			);
		}
	}

	getCourseIds(): ObjectId[] {
		return this.permissions.courseIds;
	}

	canAccessAllCourses(): boolean {
		return false;
	}

	canAccessCourse(courseId: ObjectId): boolean {
		return this.permissions.courseIds.some((id) => id === courseId);
	}

	canStudyCourse(courseId: ObjectId): boolean {
		return this.canAccessCourse(courseId);
	}
}

export class CoursesStudent extends CoursesUser {
	permissions: ICoursesStudentPermissions;

	canAccessAllCourses(): boolean {
		return false;
	}

	canAccessCourse(courseId: ObjectId): boolean {
		return false;
	}

	canStudyCourse(courseId: ObjectId): boolean {
		return (
			!!this.permissions &&
			this.permissions.availableCourses.some((id) => id === courseId)
		);
	}
}

export class Developer extends CoursesMainAdmin {}
