import Joi, { ObjectId } from "../../utils/joi";
import {
	FullQuestionSchema,
	FullQuestionWithNameSchema,
	IFullQuestionWithName,
	IQuestion,
	IShortQuestion,
	QuestionSchema,
	ShortQuestionSchema,
} from "../questions/helper-schemas";

export const TextSchema = Joi.object({
	_id: Joi.objectId().required(),
	text: Joi.string().required(),
	questions: Joi.array().items(Joi.objectId()).required(),
	authorId: Joi.number().integer().required(),
	numberOfQuestions: Joi.number().integer().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IText {
	_id: ObjectId;
	text: string;
	questions: ObjectId[];
	numberOfQuestions: number;
	authorId: number;
	createdAt: Date;
	updatedAt: Date;
}

export const TextWithQuestionsSchema = Joi.object({
	text: TextSchema.required(),
	questions: Joi.array().items(QuestionSchema).required(),
});

export interface ITextWithQuestions {
	text: IText;
	questions: IQuestion[];
}

export const FullTextWithQuestionsSchema = TextWithQuestionsSchema;
export type IFullTextWithQuestions = ITextWithQuestions;

export const FullTextWithQuestionsAndNames = Joi.object({
	text: TextSchema.required(),
	questions: Joi.array().items(FullQuestionWithNameSchema).required(),
});
export interface IFullTextWithQuestionsAndNames {
	text: IText;
	questions: IFullQuestionWithName[];
}

export const ShortTextWithQuestionsSchema = Joi.object({
	text: TextSchema.required(),
	question: Joi.array().items(ShortQuestionSchema).required(),
});

export interface IShortTextWithQuestions {
	text: IText;
	questions: IShortQuestion[];
}

export const RTextWithQuestionsSchema = Joi.object({
	text: TextSchema.required(),
	question: Joi.alternatives([
		Joi.array().items(FullQuestionSchema).required() as any,
		Joi.array().items(ShortQuestionSchema).required() as any,
	]).required(),
}) as any;

export interface IRTextWithQuestions {
	text: IText;
	questions: IShortQuestion[] | IQuestion[];
}
