import { IRootState } from "@app/redux";
import { History } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { UserType } from "./api/helper-schemas";
import { GeneralContextProvider } from "./components/general-context";
import { HeadmasterMainPage } from "./components/headmaster/main";
import { MainLayout } from "./components/layouts";
import { ComponentsPlayground } from "./components/playground";
import { MissingProfilePopups } from "./components/profile/missing-profile-popup";
import ParentProfile from "./components/profiles/parent_profile";
import { StudentProfile } from "./components/profiles/student-profile";
import TeacherProfileNew from "./components/profiles/teacher-profile/profile";
import { TeacherClassroomsPage } from "./components/teachers/main";
import ChooseChild from "./components/parents/choose_child";
import { StudentLoadingPage } from "./components/users/student-loading-page";
import { TermsPopupWrapper } from "./components/users/terms/terms-popup";
import { Prioritizer } from "./components/widgets/prioritizer";
import { TopGe } from "./components/widgets/top-ge";
import { getCurrentWebsite, WebsiteOrigin } from "./globals";
import { changeActiveTypeAction } from "./redux/actions/user";
import { HeaderDistributorWrapper } from "./routes/containers/common";
import UserRoute from "./routes/containers/user-route";
import { isActiveUserType } from "./user/active-user-type";
import { ParentChildObserver } from "./components/users/parents/parent-child-observer";
import { StudentGlobalPopups } from "./components/users/popups/global";

export const HistoryContext = React.createContext<History>(
	null as any as History
);
const emptyComponent: React.FC = () => {
	return null;
};

interface AppProps {
	location: History["location"];
	isTeacher: boolean;
	isStudent: boolean;
	isParent: boolean;
	isMainAdmin: boolean;
	isHeadmaster: boolean;
	isAuthenticated: boolean;
	history: History;
	locale: string;
}

const App = ({
	location,
	isTeacher,
	isStudent,
	isParent,
	isMainAdmin,
	isHeadmaster,
	isAuthenticated,
	history,
	locale,
}: AppProps) => {
	return (
		<HistoryContext.Provider value={history}>
			<GeneralContextProvider>
				<Prioritizer>
					<ComponentsPlayground>
						<Route
							location={location}
							path="/"
							component={HeaderDistributorWrapper}
						/>
						<TermsPopupWrapper />
						<Route
							location={location}
							path=""
							exact={false}
							component={MainLayout}
						/>
						<UnresolvedRouted
							location={location}
							isAuthenticated={isAuthenticated}
							isStudent={isStudent}
							isTeacher={isTeacher}
							isParent={isParent}
							isHeadmaster={isHeadmaster}
							isMainAdmin={isMainAdmin}
						/>

						{isAuthenticated && <MissingProfilePopups />}
						{getCurrentWebsite() === WebsiteOrigin.tvSchool &&
							locale === "ka" && (
								<>
									<TopGe />
								</>
							)}
						{isParent && <ParentChildObserver />}

						{getCurrentWebsite() === WebsiteOrigin.tvSchool &&
							isStudent &&
							locale === "ka" && <StudentGlobalPopups />}
					</ComponentsPlayground>
				</Prioritizer>
			</GeneralContextProvider>
		</HistoryContext.Provider>
	);
};

const UnresolvedRouted = ({
	location,
	isAuthenticated,
	isStudent,
	isTeacher,
	isParent,
	isMainAdmin,
	isHeadmaster,
}: Pick<
	AppProps,
	| "location"
	| "isAuthenticated"
	| "isStudent"
	| "isTeacher"
	| "isParent"
	| "isMainAdmin"
	| "isHeadmaster"
>) => {
	let mainComponent: React.ComponentType<any>;
	if (isStudent) {
		mainComponent = StudentLoadingPage;
	} else if (isTeacher) {
		mainComponent = TeacherClassroomsPage;
	} else if (isParent) {
		mainComponent = ChooseChild;
	} else if (isHeadmaster) {
		mainComponent = HeadmasterMainPage;
	} else {
		mainComponent = emptyComponent;
	}
	let ProfileComponent: React.ComponentType<any>;
	if (isStudent) ProfileComponent = StudentProfile;
	else if (isTeacher) ProfileComponent = TeacherProfileNew;
	else ProfileComponent = ParentProfile;
	return (
		<>
			<UserRoute
				location={location}
				path="/"
				exact={true}
				dontRedirectIfNotAuthenticated={true}
				component={mainComponent}
			/>

			<UserRoute
				location={location}
				path="/profile"
				exact={true}
				component={ProfileComponent}
			/>
		</>
	);
};

const mapStateToProps = (state: IRootState) => {
	return {
		isAuthenticated: !!state.user.userId,
		isTeacher: isActiveUserType(UserType.teacher),
		isStudent: isActiveUserType(UserType.student),
		isParent: isActiveUserType(UserType.parent),
		isMainAdmin: isActiveUserType(UserType.mainAdmin),
		isHeadmaster: isActiveUserType(UserType.headmaster),
		locale: state.intl.locale,
	};
};

export default connect(mapStateToProps, {
	changeActiveType: changeActiveTypeAction,
})(App);
