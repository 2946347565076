import { inject } from "@app/modules";
import { Requests } from "@app/api";

export interface IChildCredentials {
	userId: number;
	accessToken: string;
}

export const getCurrentChild = (): number | undefined => {
	const raw =
		sessionStorage.getItem("currentChild") ||
		localStorage.getItem("currentChild");
	if (raw === null) {
		return;
	}
	return +raw || undefined;
};

export const isCurrentChild = (userId: number): boolean => {
	return userId === getCurrentChild();
};

export const updateChildAccessToken = async (args: {
	userId: number;
	forceFetch?: boolean;
}): Promise<void> => {
	const promise = getChildAccessTokenAsync(args);
	Requests.addChildTokenPromise(promise.then(Requests.renewChildAccessToken));
	await promise.then((accessToken) => {
		updateChildCredentials({ userId: args.userId, accessToken });
		return accessToken;
	});
};

export const getChildAccessTokenAsync = async ({
	userId,
	forceFetch,
}: {
	userId: number;
	forceFetch?: boolean;
}): Promise<string> => {
	const localToken = getChildAccessTokenSync(userId);
	if (!forceFetch && localToken) {
		return localToken;
	}
	return inject("ParentChildrenController")
		.getChildToken({
			childUserId: userId,
		})
		.then(({ accessToken }) => accessToken);
};

export const getChildAccessTokenSync = (
	userId?: number
): string | undefined => {
	userId = userId || getCurrentChild();
	if (!userId) {
		return;
	}
	return getAllChildrenCredentials()[userId]?.accessToken;
};

export const updateChildCredentials = (credentials: IChildCredentials) => {
	const allCredentials = getAllChildrenCredentials();
	allCredentials[credentials.userId] = credentials;
	updateAllChildrenCredentials(allCredentials);
};

export const getAllChildrenCredentials = (): Record<
	number,
	IChildCredentials | undefined
> => {
	const raw = localStorage.getItem("childCredentials");
	return raw ? JSON.parse(raw) : {};
};

export const updateAllChildrenCredentials = (
	newData: Record<number, IChildCredentials | undefined>
) => {
	localStorage.setItem("childCredentials", JSON.stringify(newData));
};

window.addEventListener("load", () => {
	if (sessionStorage.getItem("currentChild") === null) {
		const childId = localStorage.getItem("currentChild");
		if (childId !== null) {
			sessionStorage.setItem("currentChild", childId);
		}
	}
});
