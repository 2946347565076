import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import { ObjectId, OptionalKeys } from "@app/utils/generics";
import {
	StudentGardeAttendanceSchema,
	StudentGardeNumericalSchema,
	StudentGardeCategoricalSchema,
	StudentGardeTextualSchema,
	IStudentGardeAttendance,
	IStudentGardeCategorical,
	IStudentGardeNumerical,
	IStudentGardeTextual,
} from "../categories/helper-schemas";
import {
	FileShortInfoSchema,
	IFileShortInfo,
} from "@app/api/file-attachments/helper-schemas";

const StudentGradeValueSchema = Joi.alternatives(
	StudentGardeAttendanceSchema,
	StudentGardeNumericalSchema,
	StudentGardeCategoricalSchema,
	StudentGardeTextualSchema
)
	.allow(null)
	.optional();

export type IStudentGradeEmptyValue = undefined | null;

export type IStudentGradeValue =
	| IStudentGardeAttendance
	| IStudentGardeNumerical
	| IStudentGardeCategorical
	| IStudentGardeTextual
	| IStudentGradeEmptyValue;

export const StudentGradeValueHistoryItemSchema = Joi.object({
	value: StudentGradeValueSchema.required(),
	gradedAt: Joi.date().required(),
});
export interface IStudentGradeValueHistoryItem {
	value: IStudentGradeValue;
	gradedAt: Date;
}

export const StudentGradeSchema = Joi.object({
	id: Joi.objectId().required(),
	categoryId: Joi.objectId().required(),
	isDisabled: Joi.boolean().optional(),
	assignmentId: Joi.objectId().optional(),

	value: StudentGradeValueSchema,
	seen: Joi.boolean().optional(),

	comment: Joi.object({
		value: Joi.string().allow("").required(),
		seen: Joi.boolean().optional(),
		attachedFiles: Joi.array().items(FileShortInfoSchema).optional(),
	}).optional(),

	reaction: Joi.object({
		value: Joi.string().required(),
		seen: Joi.boolean().optional(),
	}).optional(),

	date: Joi.date().required(),
	gradedAt: Joi.date().required(),
	gradedBy: UnsignedIntegerSchema.required(),

	history: Joi.array().items(StudentGradeValueHistoryItemSchema).optional(),

	classroomId: Joi.objectId(),
	index: UnsignedIntegerSchema,
});
export interface IStudentGrade {
	id: ObjectId;
	categoryId: ObjectId;
	isDisabled?: boolean;
	assignmentId?: ObjectId;

	value?: IStudentGradeValue;
	seen?: boolean;

	comment?: {
		value: string;
		seen?: boolean;
		attachedFiles?: IFileShortInfo[];
	};
	reaction?: {
		value: string;
		seen?: boolean;
	};

	date: Date;
	gradedAt: Date;
	gradedBy: number;

	history?: IStudentGradeValueHistoryItem[];

	classroomId?: ObjectId;
	/** will be used for making possible to grade user multiple times per day  */
	index: number | undefined;
}

export const ASaveStudentGradeSchema = Joi.object({
	id: Joi.objectId().optional(),
	categoryId: Joi.objectId().required(),
	value: StudentGradeValueSchema,
	comment: Joi.object({
		value: Joi.string().allow("").required(),
		seen: Joi.boolean().optional(),
		attachedFiles: Joi.array().items(FileShortInfoSchema).optional(),
	}).optional(),

	reaction: Joi.object({
		value: Joi.string().required(),
		seen: Joi.boolean().optional(),
	}).optional(),

	date: Joi.date().required(),

	classroomId: Joi.objectId(),
	index: UnsignedIntegerSchema,
});
export type IASaveStudentGrade = OptionalKeys<
	Pick<
		IStudentGrade,
		| "id"
		| "categoryId"
		| "value"
		| "comment"
		| "reaction"
		| "date"
		| "classroomId"
		| "index"
	>,
	"id"
>;
