import { useWindowSize } from "@app/hooks/front";
import { useClassroomsUserId } from "@app/hooks/users";
import React, { useEffect, useState } from "react";
import { useSubWebsite } from "@app/hooks/bc";
import { SubWebsiteOrigin } from "@app/globals";

export const TestAd = React.memo<{}>(function AdButler({}) {
	const { width } = useWindowSize();
	const isWide = width > 800;
	const [show, setShow] = useState(false);
	useEffect(() => {
		setTimeout(() => setShow(true), 10);
	}, []);
	return (
		<div className="main main2">
			<div className="border-2 border-primary rounded-xl p-4 bg-primary-100">
				ტექსტი
			</div>
			{show && <DesktopAd />}
		</div>
	);
});

export const DesktopAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function DesktopAd({ style, className }) {
	return (
		<iframe
			title="tvvvv"
			src={"data:text/html;charset=utf-8," + encodeURI(desktopAd)}
			className={"bulterAdiFrame mx-auto " + (className || "")}
			style={{
				width: 1058,
				height: 127,
				...style,
			}}
		/>
	);
});

export const HeaderDesktopAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function HeaderDesktopAd({ style, className }) {
	return (
		<iframe
			title="tvvvv"
			src={"data:text/html;charset=utf-8," + encodeURI(desktopHeaderAd)}
			className={"bulterAdiFrame mx-auto " + (className || "")}
			style={{
				width: 567,
				height: 74,
				...style,
			}}
		/>
	);
});

export const MobileAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function MobileAd({ style, className }) {
	return (
		<iframe
			title="tvvvv2"
			src={"data:text/html;charset=utf-8," + encodeURI(mobileAd)}
			className={"bulterAdiFrame mx-auto " + (className || "")}
			style={{ width: 330, height: 187, ...style }}
		/>
	);
});

export const BulterAd = React.memo<{
	desktopStyle?: React.CSSProperties;
	desktopClassName?: string;
	mobileStyle?: React.CSSProperties;
	mobileClassName?: string;
}>(function BulterAd({
	desktopStyle,
	mobileStyle,
	desktopClassName,
	mobileClassName,
}) {
	const hideAd = useIsAdHiiden();
	const { width } = useWindowSize();
	if (hideAd) return null;
	if (width > 1050) {
		return <DesktopAd style={desktopStyle} className={desktopClassName} />;
	}
	return <MobileAd style={mobileStyle} className={mobileClassName} />;
});

export const HeaderBulterAd = React.memo<{
	desktopStyle?: React.CSSProperties;
	desktopClassName?: string;
}>(function HeaderBulterAd({ desktopStyle, desktopClassName }) {
	const hideAd = useIsAdHiiden();
	const [key, setKey] = useState(0);
	const { width } = useWindowSize();

	useEffect(() => {
		const timeout = setTimeout(
			() => {
				setKey((x) => x + 1);
			},
			1000 * 60 + Math.floor(Math.random() * 10000) - 5000
		);
		return () => {
			clearTimeout(timeout);
		};
	}, [key]);

	if (hideAd) return null;
	if (width > 1050) {
		return (
			<HeaderDesktopAd
				key={key}
				style={desktopStyle}
				className={desktopClassName}
			/>
		);
	}
	return null;
});

export const FooterBulterAd = React.memo<{
	desktopStyle?: React.CSSProperties;
	desktopClassName?: string;
	mobileStyle?: React.CSSProperties;
	mobileClassName?: string;
}>(function FooterBulterAd({
	desktopStyle,
	mobileStyle,
	desktopClassName,
	mobileClassName,
}) {
	const hideAd = useIsAdHiiden();
	const { width } = useWindowSize();
	if (hideAd) return null;
	if (width > 1050) {
		return (
			<FooterDesktopAd
				style={desktopStyle}
				className={desktopClassName}
			/>
		);
	}
	return <FooterMobileAd style={mobileStyle} className={mobileClassName} />;
});

const addStyles = `<style>body{margin: 0px;}</style>`;

const desktopAd = `<!-- Desktop Advert [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc468016 = window.plc468016 || 0;
document.write('<'+'div id="placement_468016_'+plc468016+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 468016, [1050,110], 'placement_468016_'+opt.place, opt); }, opt: { place: plc468016++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;

const mobileAd = `<!-- Mobile Advert [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc468018 = window.plc468018 || 0;
document.write('<'+'div id="placement_468018_'+plc468018+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 468018, [320,170], 'placement_468018_'+opt.place, opt); }, opt: { place: plc468018++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;

const desktopHeaderAd = `<!-- Desktop Leaderboard [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc464509 = window.plc464509 || 0;
document.write('<'+'div id="placement_464509_'+plc464509+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 464509, [728,90], 'placement_464509_'+opt.place, opt); }, opt: { place: plc464509++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>${addStyles}<style>a img {height: 70px !important; width: 567px !important;}</style>`;

const desktopFooterAd = `<!-- Dynaimc Catfish Desktop [javascript] -->
<script type="text/javascript">
var rnd = window.rnd || Math.floor(Math.random()*10e6);
var pid483874 = window.pid483874 || rnd;
var plc483874 = window.plc483874 || 0;
var abkw = window.abkw || '';
var absrc = 'https://servedbyadbutler.com/adserve/;ID=180004;size=0x0;setID=483874;type=js;sw='+screen.width+';sh='+screen.height+';spr='+window.devicePixelRatio+';kw='+abkw+';pid='+pid483874+';place='+(plc483874++)+';rnd='+rnd+';click=CLICK_MACRO_PLACEHOLDER';
document.write('<scr'+'ipt src="'+absrc+'" type="text/javascript"></scr'+'ipt>');
</script>${addStyles}`;

const mobileFooterAd = `<!-- Right Sideibar [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc483876 = window.plc483876 || 0;
document.write('<'+'div id="placement_483876_'+plc483876+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 483876, [300,250], 'placement_483876_'+opt.place, opt); }, opt: { place: plc483876++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>${addStyles}`;

export const FooterDesktopAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function FooterDesktopAd({ style, className }) {
	return (
		<iframe
			title="tvvvv3"
			src={"data:text/html;charset=utf-8," + encodeURI(desktopFooterAd)}
			className={"bulterAdiFrame mx-auto " + (className || "")}
			style={{
				width: 728,
				height: 94,
				...style,
			}}
		/>
	);
});

export const FooterMobileAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function FooterMobileAd({ style, className }) {
	return (
		<iframe
			title="tvvvv3"
			src={"data:text/html;charset=utf-8," + encodeURI(mobileFooterAd)}
			className={"bulterAdiFrame mx-auto " + (className || "")}
			style={{
				width: 300,
				height: 254,
				...style,
			}}
		/>
	);
});

const disabledAdUserIds: number[] = [398589, 398571, 398596];
const useIsAdHiiden = () => {
	const subWebsite = useSubWebsite();
	const userId = useClassroomsUserId();
	if (subWebsite === SubWebsiteOrigin.britishCenter) return true;
	if (!userId) return false;
	return disabledAdUserIds.indexOf(userId) > -1;
};
