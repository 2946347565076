import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";
import {
	IStudentGradeCategory,
	StudentGradeCategorySchema,
} from "@app/api/gradebooks/categories/helper-schemas";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IStudentGradeCategory;
export type IStateStudentGradeCategories = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<IStudentGradeCategory>(
	StudentGradeCategorySchema
);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("gradebookCategory");
const storageSettings = getDefaultStorageSettings("gradebookCategory");
const metaInformationName = "gradebookCategoryMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().feedback) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"ObjectId",
				StudentGradeCategorySchema
			),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class GradebookCategoryModel extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	showCategory = () => this.name !== "დასწრება";

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(
	storage,
	metaInformationName,
	GradebookCategoryModel.meta
);

export const gradeBookCategoryReducer = getDefaultReducer(
	storageSettings,
	() => GradebookCategoryModel
);
