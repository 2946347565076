import { IRequest } from "@app/api/requests";
import {
	RGETCognitiveTestAuthorTeacherStatsSchema,
	RGETCognitiveTestWriterStudentStatsSchema,
	IAGETCognitiveTestStats,
	IRGETCognitiveTestAuthorTeacherStats,
	IRGETCognitiveTestWriterStudentStats,
	AGETCognitiveTestStatsSchema,
} from "./validators";

export class AdminDatabaseController {
	constructor(private readonly Request: IRequest) {}

	getStudentCertificateStats = async (): Promise<any> => {
		return this.Request.send(
			"GET",
			"/api/admin/databases/student-certificates",
			undefined,
			{
				responseType: "blob",
			}
		);
	};

	getTeachersWithoutSentCertificatesAndWithClassrooms =
		async (): Promise<any> => {
			return this.Request.send(
				"GET",
				"/api/admin/databases/tv-teachers",
				undefined,
				{
					responseType: "blob",
				}
			);
		};

	getCognitiveTestTeacherStats(
		args: IAGETCognitiveTestStats
	): Promise<IRGETCognitiveTestAuthorTeacherStats> {
		return this.Request.send(
			"GET",
			"/api/admin/databases/cognitive-test-stats/teachers",
			args,
			undefined,
			{
				requestSchema: AGETCognitiveTestStatsSchema,
				responseSchema: RGETCognitiveTestAuthorTeacherStatsSchema,
			}
		);
	}

	getCognitiveTestStudentStats(
		args: IAGETCognitiveTestStats
	): Promise<IRGETCognitiveTestWriterStudentStats> {
		return this.Request.send(
			"GET",
			"/api/admin/databases/cognitive-test-stats/students",
			args,
			undefined,
			{
				requestSchema: AGETCognitiveTestStatsSchema,
				responseSchema: RGETCognitiveTestWriterStudentStatsSchema,
			}
		);
	}
}
