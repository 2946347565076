import React from "react";
import { Link } from "react-router-dom";
import genStyles from "./styles/general_styles.module.css";

const BrandLogo = () => {
	return (
		<Link to="/" className={genStyles.brandLogo}>
			<img src="/imgs/edufy-icon-logo.svg" alt="Edufy Logo" />
		</Link>
	);
};

export default BrandLogo;
