import { ObjectId } from "@app/utils/generics";
import { ItemType } from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import { subtractSet } from "@app/utils/common";
import { ITestSettingContentId } from "@app/api/tests/helper-schemas";

interface IAPUTItemToTests {
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType.question | ItemType.card | ItemType.text;
	testIds: ObjectId[];
}

export default class TestItemsService {
	private readonly _Test = inject("TestModel");

	private readonly _FolderItems = inject("FolderItemsService");

	private readonly _FolderHierarchyService = inject("FolderHierarchyService");

	public setItemTestsInCourseSync(args: IAPUTItemToTests): void {
		const testDocs = this._Test.findManyByIdsSync(args.testIds);

		const hierarcyInfo =
			this._FolderHierarchyService.getHierarchyInfoObjectSync(
				args.courseId
			);

		const { tests: courseTests } = this._FolderItems.itemsSearchSync(
			args.courseId,
			hierarcyInfo.rootId,
			hierarcyInfo
		);

		const courseTestIds = new Set(Object.keys(courseTests));

		const testIdsOtherThanInput = subtractSet.call(
			new Set(courseTestIds),
			new Set(args.testIds)
		) as Set<string>;

		const otherTestDocsWhereItemHasBeenBefore = this._Test
			.findManyByItem({
				type: args.itemType,
				id: args.itemId,
			})
			.filter((e) => testIdsOtherThanInput.has(e._id));

		const testIdsToPush: ObjectId[] = [];
		for (let i = 0; i < testDocs.length; i++) {
			const doc = testDocs[i];
			if (!courseTestIds.has(doc._id)) {
				continue;
			}
			if (!doc.settings) doc.settings = {};
			if (
				!doc.settings.contentIds ||
				doc.settings.contentIds.findIndex(
					(el) => el.id === args.itemId && el.type === args.itemType
				) < 0
			) {
				testIdsToPush.push(doc._id);
			}
		}
		const testIdsToPop: ObjectId[] =
			otherTestDocsWhereItemHasBeenBefore.map((el) => el._id);
		const contentObj: ITestSettingContentId = {
			type: args.itemType,
			id: args.itemId,
		};
		testIdsToPush.length > 0 &&
			this._Test.findManyByIdsSync(testIdsToPush).forEach((test) => {
				test.addContentItem(contentObj);
				test.saveSync();
			});
		testIdsToPop.length > 0 &&
			this._Test.findManyByIdsSync(testIdsToPop).forEach((test) => {
				test.removeContentItem(contentObj);
				test.saveSync();
			});
	}
}
