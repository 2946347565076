import { ItemType } from "@app/api/folders/helper-schemas";
import { Appeal } from "@app/models/appeal";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { useModelDocByQuery } from "m-model-react";
import { useMemo } from "react";
import { ResourceLoading } from "react-fetch-data-hooks/lib/data";

export const useQuestionAppeal = (
	questionId: ObjectId | null,
	courseId: ObjectId | null
) => {
	const query = useMemo(
		() =>
			!questionId || !courseId
				? null
				: {
						itemId: questionId,
						itemType: ItemType.question,
						courseId,
					},
		[courseId, questionId]
	);
	const appeal = useModelDocByQuery(inject("AppealModel"), query);

	return useMemo((): ResourceLoading<{ doc: Appeal }, undefined> => {
		if (!questionId || !courseId) {
			return {
				doc: undefined,
				isIdentificationKnown: false,
				isSuccessfullyLoaded: false,
				hasFoundError: false,
				loadAgain: undefined,
			};
		}
		return {
			isSuccessfullyLoaded: true,
			isIdentificationKnown: true,
			hasFoundError: false,
			doc: (appeal || undefined) as Appeal,
			loadAgain: undefined,
		};
	}, [courseId, appeal, questionId]);
};
