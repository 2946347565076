import React, { useState, useEffect, useCallback } from "react";
import { inject } from "@app/modules";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { HalfMoon } from "@app/components/widgets/half-moon";
import { CertificateCandidatesList } from "./candidates-list";
import styles from "./styles/index.module.scss";
import { useClassroomsUserId } from "@app/hooks/users";
import { CertificateCandidatesListUpdated } from "./candidate-list-v2";
import { useWindowSize, useHistory } from "@app/hooks/front";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import { css } from "emotion";

export const StudentCertificateCandidatesFrontPage: React.FC = (props) => {
	const [isLoaded, setIsLoaded] = useState<boolean>();
	const { width } = useWindowSize();
	const history = useHistory();

	useEffect(() => {
		const loadData = async () => {
			const actions = (
				await inject("UserActionsController").getUserAction()
			).actions;
			if (actions.passedCandidatesFirstStep) {
				history.push("/certificate-candidates-list");
			} else {
				setIsLoaded(true);
			}
		};
		loadData().catch((e) =>
			openConfirmationPopup({ text: getFormattedMessage("errorAlert") })
		);
	}, [history]);

	if (!isLoaded) {
		return <FancyLoadingCenter />;
	}

	return (
		<div>
			<HalfMoon
				title={"სერტიფიკატები მოსწავლეებისთვის"}
				style={{ marginBottom: "5px" }}
			/>
			<div>
				<div
					className={styles.mainContainer}
					style={
						width < 1280
							? {
									flexDirection: "column",
									marginTop: width < 600 ? "0px" : undefined,
								}
							: undefined
					}
				>
					<div
						className={styles.awardContainer}
						style={
							width < 600 ? { marginBottom: "20px" } : undefined
						}
					>
						<img
							src="/imgs/StudentsAward.svg"
							height={180}
							alt="award"
						/>
					</div>
				</div>
				<MainText />
			</div>
		</div>
	);
};

export const MainTextPopup: React.FC<{ onClose: () => void }> = React.memo(
	({ onClose }) => {
		useEffect(() => {
			inject("UserActionsController").performUserAction({
				name: "seenStCertificatePopup2",
				data: true,
			});
		}, []);
		return (
			<Popup>
				<PopupContentWithClose
					onClose={onClose}
					style={{ width: 900, maxWidth: "90vw", padding: 20 }}
				>
					<div className={titleCSS}>გილოცავთ!</div>
					<img
						src="/imgs/StudentsAward.svg"
						height={120}
						alt="award"
					/>
					<MainText />
				</PopupContentWithClose>
			</Popup>
		);
	}
);

const titleCSS = css`
	font-size: 28px;
	text-align: center;
	color: #5273e6;
	font-family: "Roboto Geo MT Bold";
`;

const MainText = () => {
	const showNewVersion = useShouldShowNewVersionForTeacher();
	const history = useHistory();
	const handleNext = useCallback(() => {
		inject("UserActionsController").performUserAction({
			name: "passedCandidatesFirstStep",
			data: true,
		});
		history.push("/certificate-candidates-list");
	}, [history]);

	return (
		<div className={"main"}>
			<div className={styles.firstStepContainer}>
				{!showNewVersion && (
					<div className={styles.firstStepText}>
						Tvschool.ge-ს ადმინისტრაციამ გაითვალისწინა პედაგოგთა
						ინიციატივა და გაძლევთ საშუალებას, ელექტრონული
						სერტიფიკატებით დააჯილდოვოთ ყველა მოსწავლე, ვინც ჩართული
						იყო დისტანციურ სასწავლო პროცესში.
					</div>
				)}
				{showNewVersion && (
					<div>
						<div
							className={styles.firstStepText}
							style={{ textAlign: "center" }}
						>
							tvschool.ge-ზე მოსწავლეების სერტიფიკატებით
							დაჯილდოვება დაიწყო!
						</div>
						<div
							className={styles.firstStepText}
							style={{ textAlign: "center" }}
						>
							თქვენ შეგიძლიათ დააჯილდოვოთ თქვენი მოსწავლეები
							ახლავე!
						</div>
					</div>
				)}
				{!showNewVersion && (
					<>
						<div
							className={styles.firstStepText}
							style={{
								textAlign: "justify",
								paddingLeft: showNewVersion
									? undefined
									: "12px",
							}}
						>
							იმისათვის, რომ მოსწავლემ მიიღოს სერტიფიკატი,
							საჭიროა, მხოლოდ რეგისტრირებული იყოს პლატფორმაზე
							tvschool.ge
						</div>
						<div
							className={styles.firstStepText}
							style={{
								textAlign: "justify",
								paddingLeft: "12px",
							}}
						>
							• დარეგისტრირებული მოსწავლეებისთვის სერტიფიკატების
							გაგზავნა შეგეძლებათ 6 ივნისიდან.
						</div>
						<div
							className={styles.firstStepText}
							style={{
								textAlign: "justify",
								fontWeight: "bold",
							}}
						>
							გთხოვთ, დღესვე გადაამოწმოთ თქვენი მოსწავლეების სია,
							რათა შეძლოთ დაურეგისტრირებელი მოსწავლეების
							რეგისტრაცია, სპეციალური სარეგისტრაციო ბმულით,
							რომელსაც იხილავთ მოსწავლეთა სიის გვერდზე.
						</div>
					</>
				)}
			</div>

			<div style={{ display: "flex", justifyContent: "center" }}>
				<div className={styles.undestoodButton} onClick={handleNext}>
					{showNewVersion
						? "სერტიფიკატების ნახვა"
						: "სიის გადამოწმება"}
				</div>
			</div>
		</div>
	);
};

export const StudentCertificateCandidatesListPage = () => {
	const showNewVersion = useShouldShowNewVersionForTeacher();
	return (
		<div>
			<HalfMoon
				title={"სერტიფიკატები მოსწავლეებისთვის"}
				style={{ marginBottom: "5px" }}
			/>
			{showNewVersion ? (
				<CertificateCandidatesListUpdated />
			) : (
				<CertificateCandidatesList />
			)}
		</div>
	);
};

export const testingTeacherIds = [
	5, 101854, 12047, 103671, 201266, 12101, 388, 9621, 18075, 12129,
];

export const useShouldShowNewVersionForTeacher = () => {
	return true;
};
