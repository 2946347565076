import { MGradeSchema, IMGrade } from "@app/api/grades/model-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
	IStorage,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage, JoiMetaInfo } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { IGradeEdge, IGrade } from "@app/api/grades/helper-schemas";
import {
	removeKeys,
	arrayToObject,
	sortObjKeys,
	romanize,
} from "@app/utils/common";
import Joi from "@app/utils/joi";
import { IAGETGradesWithCategories } from "@app/api/grades/validators";
import { isSuitable } from "./utils/match";
import { getFormattedMessage } from "@app/utils/locale";

const keyOfId = "id";
type IdKey = typeof keyOfId;
type DOC = IMGrade;
export type IStateGrades = RawInstances<IdKey, DOC>;

// ==============Meta Information===========

const CommonMetaInfoSchema = Joi.object({
	lastFullLoadTimes: Joi.object().pattern(/./, Joi.date()),
});

interface IGradesMetaInfo {
	lastFullLoadTimes?: Record<string, Date>;
}
const emptyMetaData: IGradesMetaInfo = {};

export class GradeMetaInfo extends JoiMetaInfo<IGradesMetaInfo> {
	constructor(storage: IStorage, storageKey: string) {
		super(emptyMetaData, storage, storageKey, CommonMetaInfoSchema);
	}

	clear() {
		this.replaceData(emptyMetaData);
	}

	updateLoadTime(args: IAGETGradesWithCategories) {
		const key = JSON.stringify(sortObjKeys(args));
		this.setItem("lastFullLoadTimes", {
			...this.data.lastFullLoadTimes,
			[key]: new Date(),
		});
	}

	isLoaded(args: IAGETGradesWithCategories) {
		const key = JSON.stringify(sortObjKeys(args));
		return !!this.data.lastFullLoadTimes?.[key];
	}
}

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(MGradeSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("grade");
const storageSettings = getDefaultStorageSettings("grades");
const metaInformationName = "gradesMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().grades) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("number", MGradeSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Grade extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static loadOneWithEdges({
		grade,
		edges,
	}: {
		grade: IGrade;
		edges: IGradeEdge[];
	}) {
		return this.loadOneSync({
			...removeKeys(grade, "name"),
			originalName: grade.name,
			originalFullName: grade.fullName,
			edges,
		});
	}

	mainQuery?: IAGETGradesWithCategories;

	static loadManyWithEdges({
		grades,
		edges,
	}: {
		grades: IGrade[];
		edges: IGradeEdge[];
	}) {
		const edgesByGrades = arrayToObject(edges, "gradeId", true);

		return this.loadManySync(
			grades.map((grade) => ({
				...removeKeys(grade, "name"),
				originalName: grade.name,
				originalFullName: grade.fullName,
				edges: edgesByGrades[grade.id] || [],
			}))
		);
	}
	static findManyByLocations(args: IAGETGradesWithCategories): Grade[] {
		return this.getAllSync().filter((e) => e.isMatched(args));
	}

	getCategoryIds() {
		return this.edges
			.map((e) => e.categoryId)
			.filter((e) => !!e) as number[];
	}

	toRawGrade(): IGrade {
		const obj = this.toObject();
		return {
			...removeKeys(obj, "originalName", "edges"),
			name: obj.originalName,
			fullName: obj.originalFullName,
		};
	}

	isMatched(args: IAGETGradesWithCategories): boolean {
		return !!this.getMatchedEdge(args);
	}

	private getMatchedEdge(args: IAGETGradesWithCategories): IGradeEdge | null {
		return (
			this.edges.find((edge) => {
				if (!isSuitable(args.language, edge.language)) return false;
				if (!isSuitable(args.country, edge.country)) return false;
				if (!isSuitable(args.website, edge.website)) return false;
				return true;
			}) || null
		);
	}

	setMainQuery(args?: IAGETGradesWithCategories): this {
		this.mainQuery = args;
		return this;
	}

	private getMainEdge(args?: IAGETGradesWithCategories) {
		const q = args || this.mainQuery;
		if (!q) {
			throw new Error(
				"either mainQuery must be set or args must be passed to getName"
			);
		}
		const isMainDocPreferred = isEmptyQuery(q);
		const edge = this.getMatchedEdge(q);
		if (!isMainDocPreferred && !edge) {
			throw new Error("no matching edge found");
		}
		return { edge, isMainDocPreferred, query: q };
	}

	getName(args?: IAGETGradesWithCategories) {
		const { edge, isMainDocPreferred, query } = this.getMainEdge(args);
		if (query.language === "ka" && typeof this.num === "number") {
			return `${romanize(this.num)} ${getFormattedMessage(
				"inputs.grade"
			)}`;
		}
		if (isMainDocPreferred || edge!.name === null) return this.originalName;
		return edge!.name;
	}

	getFullName(args?: IAGETGradesWithCategories) {
		const { edge, isMainDocPreferred, query } = this.getMainEdge(args);
		if (query.language === "ka" && typeof this.num === "number") {
			return `${romanize(this.num)} ${getFormattedMessage(
				"inputs.grade"
			)}`;
		}
		if (isMainDocPreferred || edge!.fullName === null) {
			return this.originalFullName || this.getName(args);
		}
		return edge!.fullName;
	}

	static meta = new GradeMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Grade.meta);

export const gradesReducer = getDefaultReducer(storageSettings, () => Grade);

///

const isEmptyQuery = (query: IAGETGradesWithCategories) => {
	if (
		query.country === null &&
		query.language === null &&
		query.website === null
	) {
		return true;
	}
	return false;
};
