import {
	GradeSchema,
	GradeEdgeSchema,
	IGrade,
	IGradeEdge,
} from "./helper-schemas";
import Joi from "@app/utils/joi";

export const MGradeSchema = GradeSchema.keys({
	name: Joi.any().strip(),
	fullName: Joi.any().strip(),
	originalName: Joi.string().allow(null).required(),
	originalFullName: Joi.string().allow(null).required(),
	edges: Joi.array().items(GradeEdgeSchema).required(),
});

export interface IMGrade extends Omit<IGrade, "name" | "fullName"> {
	originalName: IGrade["name"];
	originalFullName: IGrade["fullName"];
	edges: IGradeEdge[];
}
