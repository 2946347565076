import Joi from "@app/utils/joi";

export const FileShortInfoSchema = Joi.object({
	fileName: Joi.string().required(),
	fileUrl: Joi.string().required(),
});

export interface IFileShortInfo {
	fileName: string;
	fileUrl: string;
}
