import { CourseInfo } from "@app/models/course-info";
import { CourseSettings } from "@app/models/course-settings";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { collmise } from "collmise";
import { IRequest } from "../requests";
import { CourseInfoSchema, CourseType, ICourseInfo } from "./helper-schemas";
import {
	APOSTCourseInfoAndSettingsSchema,
	APUTCourseInfoAndSettingsSchema,
	IAGETAllCoursesInfo,
	IAGETCourseInfo,
	IAPOSTCourseInfoAndSettings,
	IAPUTCourseInfoAndSettings,
	IRGETAllCoursesInfo,
	IRGETCourseInfo,
	IRGETCourseSettings,
	IRPOSTCourseInfoAndSettings,
	IRPUTCourseInfoAndSettings,
	RGETAllCoursesInfoSchema,
	RPOSTCourseInfoAndSettingsSchema,
	RPUTCourseInfoAndSettingsSchema,
} from "./validators";

export class CoursesInfoController {
	private readonly Request: IRequest;

	private readonly _CourseInfoModel = inject("CourseInfoModel");
	private readonly _CourseSettingsModel = inject("CourseSettingsModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	private courseInfoCollmise = collmise<
		ObjectId,
		IRGETCourseInfo,
		CourseInfo
	>({
		findCachedData: (courseId) =>
			this._CourseInfoModel.findByCourseIdSync(courseId),
		dataTransformer: (data) => this._CourseInfoModel.loadOneSync(data),
	});
	private courseSettingsCollmise = collmise<
		ObjectId,
		IRGETCourseSettings,
		CourseSettings
	>({
		findCachedData: (courseId) =>
			this._CourseSettingsModel.findByCourseIdSync(courseId),
		dataTransformer: (data) => this._CourseSettingsModel.loadOneSync(data),
	});

	getAll = async (
		query: IAGETAllCoursesInfo,
		loadFresh = false
	): Promise<CourseInfo[]> => {
		if (!loadFresh && this._CourseInfoModel.meta.isLoaded(query)) {
			return this._CourseInfoModel.searchSync(query);
		}
		return this.Request.send("GET", "/api/courses-info", query, null, {
			responseSchema: RGETAllCoursesInfoSchema,
		}).then((data: IRGETAllCoursesInfo) => {
			const oldIds = this._CourseInfoModel
				.searchSync(query)
				.map((e) => e.courseId);
			this._CourseInfoModel.meta.updateLoadTime(query);
			return this._CourseInfoModel.loadManySync(data, oldIds);
		});
	};

	getCognitiveCourseInfo = async (): Promise<ICourseInfo> => {
		const existingCourses = this._CourseInfoModel.findManySync({
			type: CourseType.cognitive,
		});
		if (existingCourses.length > 0) {
			return existingCourses[0];
		}
		return this.Request.send(
			"GET",
			"/api/courses-info/cognitive-course",
			undefined,
			null,
			{
				responseSchema: CourseInfoSchema,
			}
		).then((data: ICourseInfo) => this._CourseInfoModel.loadOneSync(data));
	};

	getCourseInfo = async (
		args: IAGETCourseInfo,
		loadFresh?: boolean
	): Promise<CourseInfo> => {
		return this.courseInfoCollmise
			.on(args.courseId)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send(
					"GET",
					"/api/courses-info/:courseId/info",
					args
				)
			);
	};

	getCourseSettings = async (
		args: IAGETCourseInfo,
		loadFresh?: boolean
	): Promise<CourseSettings> => {
		return this.courseSettingsCollmise
			.on(args.courseId)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send(
					"GET",
					"/api/courses-info/:courseId/settings",
					args
				)
			);
	};

	create = (args: IAPOSTCourseInfoAndSettings) =>
		this.Request.send("POST", "/api/courses-info", args, null, {
			requestSchema: APOSTCourseInfoAndSettingsSchema,
			responseSchema: RPOSTCourseInfoAndSettingsSchema,
		}).then((data: IRPOSTCourseInfoAndSettings) => {
			const courseInfo = this._CourseInfoModel.loadOneSync(
				data.courseInfo
			);
			const settings = this._CourseSettingsModel.loadOneSync(
				data.settings
			);
			return {
				courseInfo,
				settings,
			};
		});

	update = (args: IAPUTCourseInfoAndSettings) =>
		this.Request.send("PUT", "/api/courses-info", args, null, {
			requestSchema: APUTCourseInfoAndSettingsSchema,
			responseSchema: RPUTCourseInfoAndSettingsSchema,
		}).then((data: IRPUTCourseInfoAndSettings) => {
			const courseInfo = this._CourseInfoModel.loadOneSync(
				data.courseInfo
			);
			const settings = this._CourseSettingsModel.loadOneSync(
				data.settings
			);
			return {
				courseInfo,
				settings,
			};
		});
}
