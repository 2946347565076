import { useHistory } from "@app/hooks/front";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { StudentLogin } from "../auth/login";
import Popup, { PopupContent } from "../widgets/popup";
import styles from "./styles/landing-header.module.css";

export interface ILoginPopupProps {
	onClose?: () => void;
	isRegisterPageOpen: boolean;
	hideRegisterPage: () => void;
	openRegisterPage: () => void;
	isIconVissible?: boolean;
	defaultValue?: string;
	placeholder?: string;
	welcomeTitle?: string | JSX.Element;
	isUpperTextVissible?: boolean;
	inputClassname?: string;
	isLowerMainQuestionVissible?: boolean;
	isFromTvSchoolPopUp?: boolean;
	isDivTitleHidden?: boolean;
}

export const LoginPopup: React.FC<ILoginPopupProps> = (props) => {
	const history = useHistory();
	const redirectToLogin = useCallback(() => {
		history.push(`/login`);
	}, [history]);

	const [isForgotPassword, setForgotPassword] = useState(false);

	const switchForgotPassword = useCallback((e) => {
		setForgotPassword(!e);
	}, []);

	return (
		<Popup onClose={props.onClose}>
			<PopupContent
				style={
					isForgotPassword
						? {
								backgroundColor: "#eaecf4",
								color: "#252423",
								marginTop: "4vh",
							}
						: {
								marginTop: "4vh",
							}
				}
			>
				<StudentLogin
					onSuccess={redirectToLogin}
					welcomeTitle={props.welcomeTitle}
					placeholder={props.placeholder}
					isUpperTextVissible={props.isUpperTextVissible}
					isIconVissible={props.isIconVissible}
					isWelcomeTitleVissible={true}
					isRegisterPageOpen={props.isRegisterPageOpen}
					hideRegisterPage={props.hideRegisterPage}
					openRegisterPage={props.openRegisterPage}
					inputClassname={props.inputClassname}
					isLowerMainQuestionVissible={
						props.isLowerMainQuestionVissible
					}
					isIllustrationVissible={true}
					isMessageIllustrationVissible={true}
					switchForgotPassword={switchForgotPassword}
					isForgotPassword={isForgotPassword}
					isFromTvSchoolPopUp={props.isFromTvSchoolPopUp}
					isDivTitleHidden={props.isDivTitleHidden}
				/>
			</PopupContent>
		</Popup>
	);
};

export const LoginButton: React.FC<{
	buttonClassname?: string;
	openLoginPopup: () => void;
	text?: string;
}> = (props) => {
	return (
		<button
			className={
				props.buttonClassname
					? props.buttonClassname
					: styles.logInButton
			}
			onClick={props.openLoginPopup}
		>
			{props.text ? props.text : <FormattedMessage id="signIn" />}
		</button>
	);
};
