import { Grade } from "@app/models/grade";
import { GradeCategory } from "@app/models/grade-category";
import { inject } from "@app/modules";
import { flatten, sortObjKeys } from "@app/utils/common";
import { collmise } from "collmise";
import { IRequest } from "../requests";
import {
	IADELETECategoryById,
	IADELETEGradeById,
	IAGETCategoryById,
	IAGETGradesWithCategories,
	IAGETGradeWithEdges,
	IAPOSTGrade,
	IAPOSTGradeCategory,
	IAPUTGrade,
	IAPUTGradeCategory,
	IRGETCategoryById,
	IRGETGradesWithCategories,
	IRGETGradeWithEdges,
	IRGETRawCategories,
	IRGETRawGrades,
	IRPOSTGrade,
	IRPOSTGradeCategory,
	IRPUTGrade,
	RGETCategoryByIdSchema,
	RGETGradesWithCategoriesSchema,
	RGETGradeWithEdgesSchema,
	RGETRawCategoriesSchema,
	RPOSTGradeCategorySchema,
	RPOSTGradeSchema,
	RPUTGradeCategorySchema,
	RPUTGradeSchema,
} from "./validators";

export class GradesController {
	private readonly Request: IRequest;

	private readonly _GradeModel = inject("GradeModel");
	private readonly _GradeCategoryModel = inject("GradeCategoryModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	private gradeCollmise = collmise<
		IAGETGradesWithCategories,
		IFinalRGETGradesWithCategories
	>({
		findCachedData: (query) => {
			if (
				this._GradeCategoryModel.meta.isLoaded() &&
				this._GradeModel.meta.isLoaded(query)
			) {
				const grades: Grade[] = this._GradeModel
					.findManyByLocations(query)
					.map((e) => e.setMainQuery(query));
				const categoryIds = [
					...new Set(flatten(grades.map((e) => e.getCategoryIds()))),
				];
				return {
					grades,
					categories:
						this._GradeCategoryModel.getCategoriesWithAncestorsSync(
							categoryIds
						),
				};
			}
		},
		serializeId: (args) => JSON.stringify(sortObjKeys(args)),
	});

	get = async (
		args: IAGETGradesWithCategories,
		loadFresh?: boolean
	): Promise<IFinalRGETGradesWithCategories> => {
		return this.gradeCollmise
			.on(args)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/grades", args, null, {
					responseSchema: RGETGradesWithCategoriesSchema,
				}).then((data: IRGETGradesWithCategories) => {
					this._GradeCategoryModel.meta.updateLoadTime();
					this._GradeModel.meta.updateLoadTime(args);
					return {
						grades: this._GradeModel
							.loadManyWithEdges(data)
							.map((e) => e.setMainQuery(args)),
						categories: this._GradeCategoryModel.loadManySync(
							data.categories
						),
					};
				})
			);
	};

	getRawGrades = (): Promise<IRGETRawGrades> =>
		this.Request.send("GET", "/api/grades/raw");

	getGrade = async (
		args: IAGETGradeWithEdges,
		loadFresh?: boolean
	): Promise<Grade> => {
		if (!loadFresh) {
			const grade = this._GradeModel.findByIdSync(args.id);
			if (grade) return grade;
		}
		return this.Request.send("GET", "/api/grades/:id/edges", args, null, {
			responseSchema: RGETGradeWithEdgesSchema,
		}).then((data: IRGETGradeWithEdges) => {
			return this._GradeModel.loadOneWithEdges(data);
		});
	};

	createGrade = async (args: IAPOSTGrade): Promise<Grade> =>
		this.Request.send("POST", "/api/grades", args, null, {
			responseSchema: RPOSTGradeSchema,
		}).then((data: IRPOSTGrade) => {
			return this._GradeModel.loadOneWithEdges(data);
		});

	updateGrade = async (args: IAPUTGrade): Promise<Grade> =>
		this.Request.send("PUT", "/api/grades", args, null, {
			responseSchema: RPUTGradeSchema,
		}).then((data: IRPUTGrade) => {
			return this._GradeModel.loadOneWithEdges(data);
		});

	deleteGrade = (args: IADELETEGradeById): Promise<void> =>
		this.Request.send("DELETE", "/api/grades/:id", args).then(() => {
			this._GradeModel.deleteByIdSync(args.id);
		});

	getCategories = async (loadFresh?: boolean): Promise<GradeCategory[]> => {
		if (!loadFresh && this._GradeCategoryModel.meta.isLoaded()) {
			return this._GradeCategoryModel.getAllSync();
		}
		return this.Request.send(
			"GET",
			"/api/grades/categories",
			undefined,
			null,
			{
				responseSchema: RGETRawCategoriesSchema,
			}
		).then((data: IRGETRawCategories) => {
			this._GradeCategoryModel.meta.updateLoadTime();
			return this._GradeCategoryModel.loadManySync(data);
		});
	};

	getCategory = async (
		args: IAGETCategoryById,
		loadFresh?: boolean
	): Promise<GradeCategory> => {
		if (!loadFresh) {
			const category = this._GradeCategoryModel.findByIdSync(args.id);
			if (category) return category;
		}
		return this.Request.send(
			"GET",
			"/api/grades/categories/:id",
			args,
			null,
			{
				responseSchema: RGETCategoryByIdSchema,
			}
		).then((data: IRGETCategoryById) => {
			return this._GradeCategoryModel.loadOneSync(data);
		});
	};

	createCategory = async (
		args: IAPOSTGradeCategory
	): Promise<GradeCategory> =>
		this.Request.send("POST", "/api/grades/categories", args, null, {
			responseSchema: RPOSTGradeCategorySchema,
		}).then((data: IRPOSTGradeCategory) => {
			return this._GradeCategoryModel.loadOneSync(data);
		});

	updateCategory = async (args: IAPUTGradeCategory): Promise<GradeCategory> =>
		this.Request.send("PUT", "/api/grades/categories/:id", args, null, {
			responseSchema: RPUTGradeCategorySchema,
		}).then((data: IRPOSTGradeCategory) => {
			return this._GradeCategoryModel.loadOneSync(data);
		});

	deleteCategory = (args: IADELETECategoryById): Promise<void> =>
		this.Request.send("DELETE", "/api/grades/categories/:id", args).then(
			() => {
				this._GradeCategoryModel.deleteByIdSync(args.id);
			}
		);
}

interface IFinalRGETGradesWithCategories {
	grades: Grade[];
	categories: GradeCategory[];
}
