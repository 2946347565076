import { ItemType } from "@app/api/folders/helper-schemas";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { IRTest, IUserTestQuestionInfo } from "@app/api/tests/helper-schemas";
import { IRGETTestContents } from "@app/api/tests/validators";
import { getQuestionsCustomizedProps } from "@app/components/admin/questions/custom-props";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { getLocale } from "@app/hooks/intl";
import { getFormattedMessage } from "@app/utils/locale";
import { css } from "emotion";
import memoizeOne from "memoize-one";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
	IAGetTestQuestion,
	IFinishPageProps,
	INextButtonProps,
	IRGetTestQuestion,
	ITestComponentProps,
	ITestFinishArgs,
	TestComponent,
} from "../../../../../@tests-core/components/tests";
import {
	ITestNavigationProps,
	TestNavigation,
} from "../../../../../@tests-core/components/tests/navigation";
import { PrimaryButton } from "../assignment/wrapper";
import NextQuestionService from "../functions/next-question";
import { getQuestionIdsOfFirstNotFullyAskedText } from "../functions/text-questions";
import { ModellingFinishComponent, StandardFinishComponent } from "../standard";
import testStyles from "../styles/test-styles.module.css";
import { mergeComponentObjects } from "@tests-core/utils";

interface IProps {
	test: IRTest;
	testTypeSettings: ITestTypeSettings;
	currentAttempt: number;
	onFinish: (args: ITestFinishArgs) => Promise<void>;
	onSave: (args: ITestFinishArgs) => Promise<void>;
	onGotoNext: () => void;
	content: IRGETTestContents;
	defaultUserAnswers: IUserTestQuestionInfo[];
	getNextQuestion?: (args: IAGetTestQuestion) => Promise<IRGetTestQuestion>;
	FinishPageComponent?: React.ComponentType<IFinishPageProps>;
	testNavigationProps?: Partial<ITestNavigationProps>;
}

interface IState {
	isLoading: boolean;
	isFinished: boolean;
}

export class UniversalTestWrapper extends React.PureComponent<IProps, IState> {
	state: IState = {
		isLoading: false,
		isFinished: false,
	};
	private catAndSpacedRepetition: NextQuestionService | null = null;

	testComponentRef = React.createRef<TestComponent>();

	componentWillUnmount() {
		if (this.testComponentRef.current && !this.state.isFinished) {
			this.props
				.onSave(this.testComponentRef.current.getFinishArgs())
				.catch(() => null);
		}
	}

	getNextQuestion = async (
		args: IAGetTestQuestion
	): Promise<IRGetTestQuestion> => {
		const { test, testTypeSettings } = this.props;
		if (args.unknownQuestionIds.length === 0) {
			return {
				isFinished: true,
			};
		}
		if (this.props.getNextQuestion) {
			return this.props.getNextQuestion(args);
		}
		if (
			test.settings &&
			test.settings.contentIds &&
			test.settings.isContentSorted
		) {
			const q = test.settings.contentIds.filter(
				(e) => e.type === ItemType.question
			)[args.questionIndexToRequest];
			if (
				q !== undefined &&
				args.selectedQuestionIds.indexOf(q.id) === -1
			) {
				return {
					isFinished: false,
					questionId: q.id,
				};
			}
		}
		if (
			this.props.testTypeSettings.maxNumOfQuestions &&
			args.answers.filter((e) => e !== null && e !== undefined).length >=
				this.props.testTypeSettings.maxNumOfQuestions
		) {
			return {
				isFinished: true,
			};
		}
		const textQuestionIds = getQuestionIdsOfFirstNotFullyAskedText(args);
		if (textQuestionIds.length > 0) {
			return {
				isFinished: false,
				questionId: textQuestionIds[0],
			};
		}
		const index = Math.floor(
			Math.random() * args.unknownQuestionIds.length
		);
		return {
			isFinished: false,
			questionId: args.unknownQuestionIds[index],
		};
	};

	nextButton: React.FC<INextButtonProps> = (props) => {
		const warned = (): boolean => {
			if (props.isFinished) return false;
			if (props.userAnswerInfo.isFullyAnswered) return false;
			openConfirmationPopup({
				text: getFormattedMessage("student:test.alertOnEmptyAnswer"),
			});
			return true;
		};
		const isAlreadySubmitted = props.userAnswerInfo.submitted;
		if (
			!props.userAnswerInfo.submitted &&
			this.props.testTypeSettings.submitAnswerAfterAnswering &&
			this.props.testTypeSettings.showAnswersAt ===
				TestTypeAnswersShowTime.immediately
		) {
			return (
				<div>
					<div>
						<PrimaryButton
							onClick={() => {
								if (warned()) return;
								const submit = isAlreadySubmitted
									? undefined
									: !!this.props.testTypeSettings
											.submitAnswerAfterAnswering;
								if (submit && this.catAndSpacedRepetition) {
									this.catAndSpacedRepetition.onAnswer(
										props.userAnswerInfo,
										props.questionId
									);
								}
								props.onClick({
									gotoNext: false,
									submit,
								});
							}}
							isFaded={
								!props.isFinished &&
								!props.userAnswerInfo.isFullyAnswered
							}
						>
							<FormattedMessage id="student:test.submitQuestion" />
						</PrimaryButton>
					</div>
				</div>
			);
		}
		return (
			<div>
				<div>
					<PrimaryButton
						onClick={() => {
							if (warned()) return;
							props.onClick({
								gotoNext: true,
								submit: isAlreadySubmitted
									? undefined
									: !!this.props.testTypeSettings
											.submitAnswerAfterAnswering,
							});
						}}
						isFaded={
							!props.isFinished &&
							!props.userAnswerInfo.isFullyAnswered
						}
					>
						{!props.userAnswerInfo.submitted &&
						this.props.testTypeSettings.submitAnswerAfterAnswering
							? getFormattedMessage("student:test.submitQuestion")
							: getFormattedMessage(
									"student:test.gotoNextQuestion"
								)}
					</PrimaryButton>
				</div>
			</div>
		);
	};

	onFinish = (args: ITestFinishArgs) => {
		this.setState({
			isFinished: true,
		});
		return this.props.onFinish(args);
	};

	FinishPage = (props: IFinishPageProps) => {
		if (this.props.FinishPageComponent) {
			const FinishPageComponent = this.props.FinishPageComponent;
			return <FinishPageComponent {...props} />;
		}
		let FinishComponent = StandardFinishComponent;
		if (this.props.test.name === "მოდელირება") {
			FinishComponent = ModellingFinishComponent;
		}
		return (
			<FinishComponent
				{...props}
				test={this.props.test}
				testTypeSettings={this.props.testTypeSettings}
				onGotoNext={this.props.onGotoNext}
				currentAttempt={this.props.currentAttempt}
			/>
		);
	};

	components: ITestComponentProps["components"] = {
		FinishPage: this.FinishPage,
		Navigation: TestNavigation,
		NextButton: this.nextButton,
		Loading: FancyLoading,
	};

	getInitialQuestionsInfo = memoizeOne(
		(): ITestComponentProps["initialQuestionsInfo"] => {
			const uniqueAnswersLength = [
				...new Set(this.props.defaultUserAnswers.map((q) => q.id)),
			].length;
			const questionsLength = this.props.content.questions.length;
			const isFinished = uniqueAnswersLength >= questionsLength;
			const submitInitialAnswers = !isFinished
				? !!this.props.testTypeSettings.submitAnswerAfterAnswering
				: true;
			const editorAssessmentsOfQuestions = {};
			const userAnswers: ITestComponentProps["initialQuestionsInfo"] = [];
			const leavOnlyUniqueAnswers = true;
			const answeredQuestionsObj: Record<string, true | undefined> = {};
			for (const ans of this.props.defaultUserAnswers) {
				if (leavOnlyUniqueAnswers && answeredQuestionsObj[ans.id]) {
					continue;
				}
				answeredQuestionsObj[ans.id] = true;
				userAnswers.push({
					questionId: ans.id,
					userAnswer: ans.userAnswer,
					submitted:
						ans.userAnswer !== undefined
							? submitInitialAnswers
							: false,
					assessment: editorAssessmentsOfQuestions[ans.id],
				});
			}
			const { test } = this.props;
			if (
				test.settings &&
				test.settings.contentIds &&
				test.settings.isContentSorted
			) {
				const sortedQuestionInfos = test.settings.contentIds.filter(
					(e) => e.type === ItemType.question
				);
				const sortedUserAnswers: ITestComponentProps["initialQuestionsInfo"] =
					[];
				for (let i = 0; i < sortedQuestionInfos.length; ++i) {
					const questionId = sortedQuestionInfos[i].id;
					const ans = userAnswers.find(
						(e) => e && e.questionId === questionId
					);
					if (ans) {
						sortedUserAnswers[i] = ans;
					}
				}
				return sortedUserAnswers;
			}
			return userAnswers;
		}
	);

	getNavigationProps = memoizeOne(
		(
			testNavigationProps?: Partial<ITestNavigationProps>
		): Partial<ITestNavigationProps> => {
			const navProps: Partial<ITestNavigationProps> = {
				displayItemsInline: true,
			};
			navProps.styles = {
				item: {
					container: testStyles.itemContainer,
					isFinishPage: testStyles.finishItem,
					isStartPage: testStyles.startItem,
					isSelected: testStyles.isSelected,
					containsGradableItemsByEditor:
						testStyles.containsGradableItemsByEditor,
					isAccessible: testStyles.isAccessible,
					isNotAccessible: testStyles.isNotAccessible,
					hasAnswered: testStyles.hasAnswered,
					hasAnsweredFully: testStyles.hasAnsweredFully,
					hasAnsweredCorreclty: testStyles.hasAnsweredCorreclty,
					hasAnsweredPartiallyCorreclty:
						testStyles.hasAnsweredPartiallyCorreclty,
					hasAnsweredIncorreclty: testStyles.hasAnsweredIncorreclty,
				},
			};
			return mergeComponentObjects(testNavigationProps || {}, navProps);
		}
	);

	getQuestionsCustomizedProps = memoizeOne((locale: string) => {
		return getQuestionsCustomizedProps(locale, true);
	});

	render() {
		if (this.state.isLoading) {
			return <FancyLoading />;
		}
		const {
			allowSwitchingToSubmittedQuestions,
			allowSwitchingToUnsubmittedQuestions,
		} = this.props.testTypeSettings;
		let showAnswersAfterAnswering = true;
		if (
			this.props.testTypeSettings.showAnswersAt ===
				TestTypeAnswersShowTime.afterFinish &&
			!this.state.isFinished
		) {
			showAnswersAfterAnswering = false;
		}
		if (
			this.props.testTypeSettings.showAnswersAt ===
			TestTypeAnswersShowTime.afterDeadline
		) {
			showAnswersAfterAnswering = false; // TODO: check if deadline is passed
		}
		return (
			<div className="main">
				<div className={containerCSS}>
					<TestComponent
						ref={this.testComponentRef}
						key={this.props.test._id}
						allowSwitchingToSubmittedQuestions={
							this.state.isFinished
								? true
								: allowSwitchingToSubmittedQuestions
						}
						allowSwitchingToUnsubmittedQuestions={
							this.state.isFinished
								? true
								: allowSwitchingToUnsubmittedQuestions
						}
						showAnswersOfSubmittedQuestions={
							showAnswersAfterAnswering
						}
						questions={this.props.content.questions}
						onFinish={this.onFinish}
						getQuestion={this.getNextQuestion}
						components={this.components}
						texts={this.props.content.texts}
						knownNumberOfQuestions={
							this.props.testTypeSettings.maxNumOfQuestions
								? Math.min(
										this.props.testTypeSettings
											.maxNumOfQuestions,
										this.props.content.questions.length
									)
								: this.props.content.questions.length
						}
						showTextsOnSeparatePage={false}
						initialQuestionsInfo={this.getInitialQuestionsInfo()}
						questionsCustomizedProps={this.getQuestionsCustomizedProps(
							getLocale()
						)}
						NavigationProps={this.getNavigationProps(
							this.props.testNavigationProps
						)}
					/>
				</div>
			</div>
		);
	}
}

const isMobile = window.innerWidth < 1200;

const containerCSS = isMobile
	? css`
			font-family: "Roboto Geo Nus";
			padding: 64px 20px 10px 13px;
		`
	: css`
			font-family: "Roboto Geo Nus";
			padding: 20px 20px 70px 70px;
		`;
