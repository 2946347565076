import { ICard, CardSchema } from "@tests-core/schemas/cards";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = ICard;
export type IStateCards = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(CardSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("card");
const storageSettings = getDefaultStorageSettings("cards");
const metaInformationName = "cardsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().cards) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", CardSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class Card extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

export type ICardInstance = Card;
export type ICardModel = typeof Card;

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, Card.meta);

export const cardsReducer = getDefaultReducer(storageSettings, () => Card);
