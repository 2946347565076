import {
	ITestSettings,
	ITestSettingContentId,
} from "@app/api/tests/helper-schemas";
import { IRGETTestContents } from "@app/api/tests/validators";
import { ObjectId } from "@app/utils/generics";
import { ItemType } from "@app/api/folders/helper-schemas";
import { shuffleArrayByKey } from "@tests-core/utils/shuffle";
import { arrayToObject } from "@app/utils/common";
import { sortArrayByKeys } from "@app/utils/shuffle";

type ContentIds = NonNullable<ITestSettings["contentIds"]>;

/**
 * shuffle contents such that questions with same textId will be next to each other
 */
export const suffleContentIdsForTest = (
	contentIds: ContentIds,
	content: Pick<IRGETTestContents, "questions" | "cards">,
	shuffleQuestionsInText = false,
	shuffleKey?: number
): ContentIds => {
	const textQuestions: Record<string, ObjectId[] | undefined> = {};
	const questionsObj = arrayToObject(content.questions, "_id");
	for (const question of content.questions) {
		if (!question.textId) continue;
		if (!textQuestions[question.textId]) {
			textQuestions[question.textId] = [];
		}
		textQuestions[question.textId]!.push(question._id);
	}
	const chosenContentIds: Record<
		ContentIds[number]["type"],
		Record<string, true | undefined>
	> = {
		[ItemType.question]: {},
		[ItemType.card]: {},
		[ItemType.text]: {},
	};
	const rawShuffledContent = shuffleArrayByKey(
		contentIds,
		shuffleKey ? shuffleKey : Math.floor(Math.random() * 1e9)
	);
	const shuffledContent: ContentIds = [];
	for (const contentInfo of rawShuffledContent) {
		if (chosenContentIds[contentInfo.type][contentInfo.id]) continue;
		if (contentInfo.type !== ItemType.question) {
			shuffledContent.push(contentInfo);
			chosenContentIds[contentInfo.type][contentInfo.id] = true;
			continue;
		}
		const question = questionsObj[contentInfo.id];
		if (
			question &&
			question.textId &&
			chosenContentIds[ItemType.text][question.textId]
		) {
			continue;
		}
		if (!question || !question.textId || !textQuestions[question.textId]) {
			shuffledContent.push(contentInfo);
			chosenContentIds[contentInfo.type][contentInfo.id] = true;
			continue;
		}
		const coTextualQuestionIds = !shuffleQuestionsInText
			? textQuestions[question.textId]!
			: shuffleArrayByKey(
					textQuestions[question.textId]!,
					shuffleKey ? shuffleKey : Math.floor(Math.random() * 1e9)
				);
		for (const questionId of coTextualQuestionIds) {
			shuffledContent.push({ type: ItemType.question, id: questionId });
			chosenContentIds[ItemType.question][questionId] = true;
		}
		chosenContentIds[ItemType.text][question.textId] = true;
	}
	return shuffledContent;
};

interface GetShuffledNQuestionsReturnType<Q> {
	shuffledQuestions: Q[];
	reserve: Q[];
}

const getShuffledNQuestionsWithRest = <
	Q extends IRGETTestContents["questions"][number],
>(
	shuffled: Q[],
	starting: Q[]
): GetShuffledNQuestionsReturnType<Q> => {
	const reserve: Q[] = [];

	for (const q of starting) {
		if (!shuffled.find((cur) => cur._id === q._id)) reserve.push(q);
	}

	return { shuffledQuestions: shuffled, reserve };
};

export const getShuffledNQuestions = <
	Q extends IRGETTestContents["questions"][number],
>(
	questions: Q[],
	desiredNumOfQuestions: number,
	strict = false,
	priorityQuestionIds?: ObjectId[]
): GetShuffledNQuestionsReturnType<Q> => {
	const shuffled = !priorityQuestionIds
		? shuffleArrayByKey(questions, Math.floor(Math.random() * 1e9))
		: sortArrayByKeys({
				array: questions,
				keys: priorityQuestionIds,
				fn: (q) => q._id,
				unmatchedMapFn: (arr) =>
					shuffleArrayByKey(arr, Math.floor(Math.random() * 1e9)),
			});

	const questionsByTextIds = arrayToObject(
		questions.filter((e) => !!e.textId),
		"textId",
		true
	);
	const chosenQIds = new Set<string>();
	const res: Q[] = [];
	for (const q of shuffled) {
		if (res.length >= desiredNumOfQuestions) break;
		if (chosenQIds.has(q._id)) {
			continue;
		}
		if (!q.textId) {
			chosenQIds.add(q._id);
			res.push(q);
		} else {
			const questionsOfSametext = questionsByTextIds[q.textId] || [];
			for (const tQ of questionsOfSametext) {
				if (chosenQIds.has(tQ._id)) {
					continue;
				}
				chosenQIds.add(tQ._id);
				res.push(tQ);
			}
		}
	}
	return getShuffledNQuestionsWithRest(
		strict ? res.slice(0, desiredNumOfQuestions) : res,
		questions
	);
};

export const sortContentIdsBySortedTestContentIds = <
	T extends ITestSettingContentId,
>(
	contentIds: T[],
	sortedContentIds: ITestSettingContentId[]
): T[] => {
	const contentToKey = (e: ITestSettingContentId) => {
		return e.type + ":--:" + e.id;
	};
	const originalContentIds = contentIds.map((e) => ({
		val: e,
		isTaken: false,
	}));
	const contentByKeys = arrayToObject(originalContentIds, (e, i) => ({
		key: contentToKey(e.val),
		value: e,
	}));

	const result: T[] = [];

	for (const item of sortedContentIds) {
		const key = contentToKey(item);
		const cont = contentByKeys[key];
		if (!cont) continue;
		cont.isTaken = true;
		result.push(cont.val);
	}

	for (const cont of originalContentIds) {
		if (cont.isTaken) continue;
		result.push(cont.val);
	}

	return result;
};
