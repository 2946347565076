import { UserType } from "@app/api/helper-schemas";
import { ISubject } from "@app/api/subjects/helper-schemas";
import { IRGETProfileInfoAsTeacher } from "@app/api/users/validators";
import { CertificateBox } from "@app/components/profile/certificate";
import SvgloadingWhite from "@app/components/styles/img/loading-white";
import { StudentCertificatesBox } from "@app/components/teachers/certificates/certificate-candidates/student-certificates-box";
import { isCertificateServicesAvaliable } from "@app/consts";
import { useWindowSize } from "@app/hooks/front";
import { useLocale } from "@app/hooks/intl";
import { inject } from "@app/modules";
import { uniquelize } from "@app/utils/array";
import { ObjectId } from "@app/utils/generics";
import { wrapInLoading } from "@app/utils/promise";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { BottomSideBar } from "../general_components/BottomSideBar";
import DesktopLogOut from "../general_components/desktop_logOut";
import { useSubjectsForProfileWithPagnation } from "../general_components/hooks";
import genStyles from "../general_components/styles/general_styles.module.css";
import { ProfileSubjectPagnations } from "../student-profile/right-panel";
import { useSubWebsite } from "../../../hooks/bc";
import { SubWebsiteOrigin } from "@app/globals";

export const RightSideDesktop: React.FC<{
	subjects: ISubject[];
	teacherInfo: IRGETProfileInfoAsTeacher;
}> = ({ subjects, teacherInfo }) => {
	const isGeo = useLocale() === "ka";
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	const { canShiftLeft, canShiftRight, currentChunk, shiftLeft, shiftRigth } =
		useSubjectsForProfileWithPagnation(subjects, 2);

	return (
		<section className={genStyles.profile_right__sidebar}>
			<DesktopLogOut />
			<section
				className={genStyles.teacher_right__sidebar_content_wrapper}
			>
				{!isBritishCenter && currentChunk && (
					<>
						<ProfileSubjectPagnations
							canShiftLeft={canShiftLeft}
							canShiftRight={canShiftRight}
							shiftLeft={shiftLeft}
							shiftRight={shiftRigth}
						/>
						<section className={genStyles.right__sidebar_box}>
							{currentChunk.map((subj) => (
								<section
									className={
										genStyles.teacher_subject_box__item
									}
									key={subj._id}
								>
									<img
										src={
											subj.photo
												? subj.photo
												: "/imgs/GeorgianLanguage.svg"
										}
										alt=""
									/>
									<p>{subj.name}</p>
								</section>
							))}
						</section>
					</>
				)}
				<div className={genStyles.title}>
					<FormattedMessage id="myAssignments" />
				</div>
				<section
					className={genStyles.right__sidebar_box}
					style={{ backgroundColor: "#fff" }}
				>
					<section className={genStyles.right__sidebar_box__item}>
						<p className={genStyles.secondary_text}>
							<FormattedMessage id="allSentAssignments" />
						</p>
						<span>{teacherInfo.sentAssignments}</span>
						<p className={genStyles.blue_text}>
							<FormattedMessage id="teacher:Assignment" />
						</p>
					</section>
					<section className={genStyles.vertical_line}></section>
					<section className={genStyles.right__sidebar_box__item}>
						<p className={genStyles.secondary_text}>
							<FormattedMessage id="myCreatedAssignments" />
						</p>
						<span>{teacherInfo.createAssignments}</span>
						<p className={genStyles.blue_text}>
							<FormattedMessage id="teacher:Assignment" />
						</p>
					</section>
				</section>
				{isGeo && isCertificateServicesAvaliable && (
					<>
						<div className={genStyles.title}>სერტიფიკატები</div>
						<section className={genStyles.right__sidebar_box}>
							<CertificateBox
								containerClassname={
									genStyles.sertificate_box__item
								}
								textContaier={genStyles.sertification}
								imgClassname={genStyles.sertification_img}
							/>
							<StudentCertificatesBox
								text={"სერტიფიკატი მოსწავლეებისთვის"}
								certificateBoxClassname={
									genStyles.sertificate_box__item
								}
								imgClassname={genStyles.sertification_img}
								textWrapperClassname={genStyles.sertification}
							/>
						</section>
					</>
				)}
			</section>
		</section>
	);
};

export const RightSide: React.FC<{}> = (props) => {
	const { width } = useWindowSize();

	const [teacherInfo, setTeacherInfo] = useState<
		IRGETProfileInfoAsTeacher | undefined
	>({} as IRGETProfileInfoAsTeacher);
	const [subjects, setSubjects] = useState<ISubject[] | undefined>();

	const subjectIds: string[] | null = useMemo(() => {
		if (!teacherInfo?.classrooms) return null;
		const subjectIds: ObjectId[] = [];
		for (const classroom of teacherInfo.classrooms!) {
			for (const subject of classroom.subjects) {
				subjectIds.push(subject);
			}
		}
		return uniquelize(subjectIds);
	}, [teacherInfo]);

	useEffect(() => {
		setTeacherInfo(undefined);
		const UsersController = inject("UsersController");
		wrapInLoading(
			UsersController.getProfileInfo(UserType.teacher).then(
				setTeacherInfo
			)
		);
	}, []);

	useEffect(() => {
		setSubjects(undefined);
		if (!subjectIds) return;
		inject("SubjectsController")
			.getManyByIds(subjectIds)
			.then((data) => setSubjects(data));
	}, [subjectIds]);

	const isResponsive = width <= 1000;

	if (!teacherInfo || !subjects) {
		return <SvgloadingWhite />;
	}
	return (
		<>
			{isResponsive ? (
				<BottomSideBar teacherInfo={teacherInfo} subjects={subjects} />
			) : (
				<RightSideDesktop
					teacherInfo={teacherInfo}
					subjects={subjects}
				/>
			)}
		</>
	);
};
