import FolderHierarchyService from "../hierarchy-info/folders";
import FolderItemsService from "../folder-items";
import UserFolderProgressService from ".";
import {
	IRFolder,
	IFolderProgress,
	IItemProgress,
	ItemType,
	ITotalProgressByItemType,
	IUserFolderProgress,
} from "../../api/folders/helper-schemas";
import { MError } from "../../utils/errors";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { IFolderModel, IFolderInstance } from "@app/models/folder";
import { IUserFolderProgressInstance } from "@app/models/user-folder-progress";
import { IRGETAllSubFoldersProgresses } from "@app/api/folders/validators";

export interface IRecalculatedSubfolder {
	id: ObjectId;
	totalProgressByItemTypes: ITotalProgressByItemType;
	progress: number;
}

export default class UserProgressCalculationService {
	private readonly _Folder: IFolderModel = inject("FolderModel");

	private readonly _FolderHierarchy: FolderHierarchyService = inject(
		"FolderHierarchyService"
	);

	private readonly _FolderItems: FolderItemsService =
		inject("FolderItemsService");

	private readonly _UserProgress: UserFolderProgressService = inject(
		"UserFolderProgressService"
	);

	toValidProg = toValidProg;

	recalculateUserFolderProgressSync = (
		courseId: ObjectId,
		folderId: ObjectId,
		userId: number,
		progressDocs: {
			[folderId: string]: IUserFolderProgressInstance | undefined;
		} = {},
		folders: { [folderId: string]: IFolderInstance | undefined } = {},
		recalculationPromises: {
			[folderId: string]: IRecalculatedSubfolder;
		} = {},
		forceRecalculation = false
	): IRecalculatedSubfolder => {
		const progressDoc = progressDocs[folderId];
		const folderProgressInstance =
			progressDoc ||
			this._UserProgress.getProgressDocSync({
				userId,
				courseId,
				folderId,
			});

		if (
			!!folderProgressInstance &&
			!folderProgressInstance.needsRecalculation &&
			!forceRecalculation
		) {
			return {
				id: folderId,
				progress: folderProgressInstance.progress,
				totalProgressByItemTypes:
					folderProgressInstance.totalProgressByItemTypes,
			};
		}

		const curFolder = folders[folderId];
		const folder = curFolder || this._Folder.findByIdSync(folderId);
		if (!folder) {
			throw new MError(400, `the folder with id "${folderId}" not found`);
		}
		folders[folderId] = folder;

		if (!folderProgressInstance) {
			const res = this.countItemsRecursivelySync(
				courseId,
				userId,
				folder
			);
			return {
				id: folderId,
				progress: 0,
				totalProgressByItemTypes: res,
			};
		}
		progressDocs[folderId] = folderProgressInstance;

		/* recursively recalculate the progress for subfolders and save their total progress */
		const updatedSubfoldersProgress: IRecalculatedSubfolder[] = [];

		const folderItems = folder.items || [];

		const subfolderIds = folderItems
			.filter((i) => i.type === ItemType.folder)
			.map((e) => e.id);
		const childFolderProgresses =
			this._UserProgress.getManyProgressDocByFolderIdsSync({
				courseId,
				userId,
				folderIds: subfolderIds.filter((fId) => {
					const subFolderId = fId;
					return (
						progressDocs[subFolderId] === undefined ||
						(progressDocs[subFolderId]!.needsRecalculation &&
							recalculationPromises[subFolderId] === undefined)
					);
				}),
			});
		// .filter(folderProgress => folderProgress.needsRecalculation);
		for (let i = 0; i < childFolderProgresses.length; ++i) {
			progressDocs[childFolderProgresses[i].folderId] =
				childFolderProgresses[i];
		}

		for (let i = 0; i < subfolderIds.length; i++) {
			const subFolderId = subfolderIds[i];
			if (recalculationPromises[subFolderId] !== undefined) {
				updatedSubfoldersProgress.push(
					recalculationPromises[subFolderId]!
				);
			} else {
				const promise = this.recalculateUserFolderProgressSync(
					courseId,
					subfolderIds[i],
					userId,
					progressDocs,
					folders,
					recalculationPromises,
					forceRecalculation
				);
				recalculationPromises[subFolderId] = promise;
				updatedSubfoldersProgress.push(promise);
			}
		}

		/* recalculate the progress of current folder */
		const removedItems: IItemProgress[] =
			folderProgressInstance.itemsProgress.filter(
				(item) =>
					folderItems.findIndex(
						(e) => e.id === item.id && e.type === item.type
					) === -1
			);
		const newItems = folderItems.filter(
			(item) =>
				!item.isHidden &&
				!item.isOptional &&
				folderProgressInstance.itemsProgress.findIndex(
					(e) => e.id === item.id
				) === -1
		);

		// edit subfolders' progress
		updatedSubfoldersProgress.forEach((updatedProgress) => {
			const itemProgress = folderProgressInstance.itemsProgress.find(
				(i) => i.id === updatedProgress.id
			);
			if (itemProgress !== undefined) {
				itemProgress.progress = updatedProgress.progress;
			}
		});

		// remove old items from user folder progress instance;
		// update "totalProgressByItemTypes"
		folderProgressInstance.itemsProgress =
			folderProgressInstance.itemsProgress.filter(
				(i) => removedItems.findIndex((item) => item.id === i.id) === -1
			);

		// calculate current value of "totalProgressByItemTypes"
		folderProgressInstance.totalProgressByItemTypes =
			this.calculateTotalProgressByItemTypes(
				folderProgressInstance.itemsProgress,
				updatedSubfoldersProgress
			);

		// add new items to user folder progress instance
		newItems.forEach((newItem) => {
			const { totalProgressByItemTypes } = folderProgressInstance;
			if (!totalProgressByItemTypes[newItem.type]) {
				return;
			}
			totalProgressByItemTypes[newItem.type].total++;
		});

		// TODO: do something about missing folders that will appear after moving folder

		// after items are set calculate "progress"
		folderProgressInstance.progress = this.calculateOverallProgress(
			folderProgressInstance
		);
		folderProgressInstance.needsRecalculation = false;
		// save
		folderProgressInstance.totalProgressByItemTypes = {
			...folderProgressInstance.totalProgressByItemTypes,
		};
		folderProgressInstance.itemsProgress = [
			...folderProgressInstance.itemsProgress,
		];
		folderProgressInstance.saveSync();

		return {
			id: folderId,
			totalProgressByItemTypes:
				folderProgressInstance.totalProgressByItemTypes,
			progress: folderProgressInstance.progress,
		};
	};

	countItemsRecursivelySync = (
		courseId: ObjectId,
		userId: number,
		folder: IRFolder
	): ITotalProgressByItemType => {
		const folderItems = folder.items || [];
		const childFolderIds = folderItems
			.filter((i) => i.type === ItemType.folder)
			.map((e) => e.id);
		const childFolderProgressInstances =
			this._UserProgress.getManyProgressDocByFolderIdsSync({
				userId,
				courseId,
				folderIds: childFolderIds,
			});
		const itemsProgress: IFolderProgress[] =
			childFolderProgressInstances.map((progInstance) => ({
				id: progInstance.folderId,
				type: ItemType.folder as ItemType.folder,
				progress: progInstance.progress,
			}));
		const subfoldersProgress: IRecalculatedSubfolder[] =
			childFolderProgressInstances.map((progInstance) => ({
				id: progInstance.folderId,
				progress: progInstance.progress,
				totalProgressByItemTypes: progInstance.totalProgressByItemTypes,
			}));
		const subFoldersResults = this.calculateTotalProgressByItemTypes(
			itemsProgress,
			subfoldersProgress
		);

		const otherChildFolderIds: ObjectId[] = [];
		folderItems.forEach((item) => {
			if (item.isHidden || item.isOptional) return;
			if (item.type === ItemType.folder) {
				if (
					childFolderProgressInstances.findIndex((e) => {
						return e.folderId === item.id;
					}) > -1
				) {
					return;
				}
				otherChildFolderIds.push(item.id);
				subFoldersResults[item.type].total++;
			} else if (
				item.type === ItemType.file ||
				item.type === ItemType.test
			) {
				subFoldersResults[item.type].total++;
			}
		});

		if (otherChildFolderIds.length === 0) {
			return subFoldersResults;
		}

		const folderHierarchy =
			this._FolderHierarchy.getHierarchyInfoObjectSync(courseId);

		// calculate recusrively item by item
		const items = this._FolderItems.itemsSearchByMyltipleFoldersSync(
			courseId,
			folderHierarchy,
			otherChildFolderIds
		);

		const folders = Object.keys(items.folders).map(
			(key) => items.folders[key]
		);
		for (let i = 0; i < folders.length; ++i) {
			const descFolder = folders[i];
			if (!descFolder) continue;
			if (!descFolder.items) continue;
			const descItems = descFolder.items;
			for (let j = 0; j < descItems.length; ++j) {
				const item = descItems[j];
				if (item.isHidden || item.isOptional) continue;
				if (
					item.type === ItemType.file ||
					item.type === ItemType.test
				) {
					subFoldersResults[item.type].total++;
				}
			}
		}
		return subFoldersResults;
	};

	calculateOverallProgress = (
		doc: IUserFolderProgressInstance,
		calledRecursively = false
	): number => {
		const progresses = doc.totalProgressByItemTypes;
		try {
			const totalDones =
				progresses[ItemType.file].totalDone +
				progresses[ItemType.test].totalDone;
			const totals =
				progresses[ItemType.file].total +
				progresses[ItemType.test].total;
			return totalDones === 0 && totals === 0
				? 0
				: this.toValidProg(totalDones / totals);
		} catch (e) {
			// tslint:disable-next-line:no-console
			console.log("==============================", progresses, doc);
			// tslint:disable-next-line:no-console
			console.log(
				progresses[ItemType.file].totalDone +
					progresses[ItemType.test].totalDone,
				progresses[ItemType.file].total +
					progresses[ItemType.test].total
			);
			if (calledRecursively) {
				// tslint:disable-next-line:no-console
				console.log(
					"already called calculateOverallProgress recursively"
				);
				throw e;
			}
			const correctedProgresses = { ...progresses };
			for (const i in correctedProgresses) {
				if (
					correctedProgresses.hasOwnProperty(i) &&
					correctedProgresses[i].totalDone >
						correctedProgresses[i].total
				) {
					correctedProgresses[i] = {
						...correctedProgresses[i],
						totalDone: progresses[i].total,
					};
				}
			}
			doc.needsRecalculation = true;
			doc.totalProgressByItemTypes = correctedProgresses;
			return this.calculateOverallProgress(doc, true);
		}
	};

	// tslint:disable-next-line:max-line-length
	recursiveSearchSync = (
		folder: IUserFolderProgress,
		progresses: IRGETAllSubFoldersProgresses,
		userId: number,
		depth?: number
	): void => {
		if (depth !== undefined) {
			depth--;
			if (depth < 0) return;
		}
		if (!folder) return;
		const subFolders = folder.itemsProgress.filter(
			(e) => e.type === ItemType.folder
		);
		if (subFolders.length === 0) return;
		const childFolderProgressIds: ObjectId[] = [];
		for (let i = 0; i < subFolders.length; ++i) {
			const subFolder = subFolders[i];
			if (progresses[subFolder.id] !== undefined) return;
			childFolderProgressIds.push(subFolder.id);
		}
		const userSubFolderProgresses =
			this._UserProgress.getManyProgressDocByFolderIdsSync({
				courseId: folder.courseId,
				folderIds: childFolderProgressIds,
				userId,
			});
		for (let i = 0; i < userSubFolderProgresses.length; ++i) {
			const childFolderProgress = userSubFolderProgresses[i];
			progresses[childFolderProgress.folderId] = childFolderProgress;
			this.recursiveSearchSync(
				childFolderProgress,
				progresses,
				userId,
				depth
			);
		}
	};

	private calculateTotalProgressByItemTypes(
		itemsProgress: IItemProgress[],
		subfoldersProgress: IRecalculatedSubfolder[]
	): ITotalProgressByItemType {
		const accumulated =
			this.accumulateSubfoldersProgress(subfoldersProgress);
		// folder item type progress should only depend on direct subfolders
		accumulated[ItemType.folder] = {
			total: 0,
			totalDone: 0,
		};

		itemsProgress.forEach((itemProgress) => {
			if (!accumulated[itemProgress.type]) return;
			accumulated[itemProgress.type].total++;
			accumulated[itemProgress.type].totalDone =
				(accumulated[itemProgress.type].totalDone || 0) +
				itemProgress.progress;
		});

		let subFolderProgressSum = 0;
		for (const subProgress of subfoldersProgress) {
			subFolderProgressSum += subProgress.progress;
		}
		accumulated[ItemType.folder].totalDone = subFolderProgressSum;

		return accumulated;
	}

	private accumulateSubfoldersProgress(
		subfoldersProgress: IRecalculatedSubfolder[]
	) {
		const calculated: ITotalProgressByItemType = {
			[ItemType.folder]: {
				total: 0,
				totalDone: 0,
			},
			[ItemType.file]: {
				total: 0,
				totalDone: 0,
			},
			[ItemType.test]: {
				total: 0,
				totalDone: 0,
			},
		};
		subfoldersProgress.forEach((p) => {
			const itemTypes = Object.keys(p.totalProgressByItemTypes);
			itemTypes.forEach((t) => {
				if (!calculated[t]) {
					return;
				}
				calculated[t].total =
					((calculated[t] && calculated[t].total) || 0) +
					p.totalProgressByItemTypes[t].total;
				calculated[t].totalDone =
					((calculated[t].totalDone && calculated[t].totalDone) ||
						0) + p.totalProgressByItemTypes[t].totalDone;
			});
		});
		return calculated;
	}
}

export const toValidProg = (prog: number) => {
	if (typeof prog !== "number" || Number.isNaN(prog)) {
		throw new MError(400, `incorrect progress ${prog}`);
	}
	if (Math.abs(prog) < 1e-5) prog = 0;
	if (Math.abs(prog - 1) < 1e-5) prog = 1;
	if (prog < 0 || prog > 1) {
		throw new MError(400, `incorrect progress ${prog}`);
	}
	return prog;
};
