/* eslint-disable max-lines-per-function */
interface StorageSetEvent extends Event {
	action: "set";
	key: string;
	value: string;
	oldValue: string | null;
}
interface StorageRemoveEvent extends Event {
	action: "remove";
	key: string;
	value: null;
	oldValue: string | null;
}
interface StorageClearEvent extends Event {
	action: "clear";
	key: null;
	value: null;
	oldStorage: Storage;
}

type StorageEvent = StorageSetEvent | StorageRemoveEvent | StorageClearEvent;

const decorate = ({
	storage,
	SET_EVENT_NAME,
	REMOVE_EVENT_NAME,
	CLEAR_EVENT_NAME,
}: {
	storage: Storage;
	SET_EVENT_NAME: string;
	REMOVE_EVENT_NAME: string;
	CLEAR_EVENT_NAME: string;
}) => {
	const originalSetItem = storage.setItem;
	const originalRemoveItem = storage.removeItem;
	const originalClear = storage.clear;
	storage.setItem = function (key, value) {
		const event = new Event(SET_EVENT_NAME) as StorageSetEvent;

		event.action = "set";
		event.value = value + "";
		event.key = key;
		event.oldValue = storage.getItem(key);
		// eslint-disable-next-line prefer-rest-params
		originalSetItem.apply(this, arguments as any);

		document.dispatchEvent(event);
	};
	storage.removeItem = function (key) {
		const event = new Event(REMOVE_EVENT_NAME) as StorageRemoveEvent;

		event.action = "remove";
		event.key = key;
		event.value = null;
		event.oldValue = storage.getItem(key);
		// eslint-disable-next-line prefer-rest-params
		originalRemoveItem.apply(this, arguments as any);

		document.dispatchEvent(event);
	};
	storage.clear = function () {
		const event = new Event(CLEAR_EVENT_NAME) as StorageClearEvent;

		event.action = "clear";
		event.key = null;
		event.value = null;
		event.oldStorage = Object.assign({}, storage);
		// eslint-disable-next-line prefer-rest-params
		originalClear.apply(this, arguments as any);

		document.dispatchEvent(event);
	};

	const subscribeToStorageChange = (
		callback: (event: StorageSetEvent) => void
	): Unsubscribe => {
		document.addEventListener(SET_EVENT_NAME, callback, false);
		document.addEventListener(REMOVE_EVENT_NAME, callback, false);
		document.addEventListener(CLEAR_EVENT_NAME, callback, false);
		return () => {
			document.removeEventListener(SET_EVENT_NAME, callback, false);
			document.removeEventListener(REMOVE_EVENT_NAME, callback, false);
			document.removeEventListener(CLEAR_EVENT_NAME, callback, false);
		};
	};

	const subscribeToStorageKeyValueChange = (
		key: string,
		callback: (value: string | null) => void
	): Unsubscribe => {
		return subscribeToStorageChange((event: StorageEvent) => {
			if (event.action === "clear") {
				if (event.oldStorage[key] === undefined) return;
				callback(null);
				return;
			}
			if (event.key !== key) return;
			if (event.value === event.oldValue) return;
			callback(event.value);
		});
	};

	return {
		subscribeToStorageChange,
		subscribeToStorageKeyValueChange,
	};
};

type Unsubscribe = () => void;

export const {
	subscribeToStorageChange: subscribeToLocalStorageChange,
	subscribeToStorageKeyValueChange: subscribeToLocalStorageKeyValueChange,
} = decorate({
	storage: localStorage,
	SET_EVENT_NAME: "localStorageSetItem",
	CLEAR_EVENT_NAME: "localStorageClear",
	REMOVE_EVENT_NAME: "localStorageRemoveItem",
});

export const {
	subscribeToStorageChange: subscribeToSessionStorageChange,
	subscribeToStorageKeyValueChange: subscribeToSessionStorageKeyValueChange,
} = decorate({
	storage: sessionStorage,
	SET_EVENT_NAME: "sessionStorageSetItem",
	CLEAR_EVENT_NAME: "sessionStorageClear",
	REMOVE_EVENT_NAME: "sessionStorageRemoveItem",
});
