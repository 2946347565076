import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export type IResource = IStandardOffer | IOtherOffer;

export enum ReserveResourceType {
	standardOffer = "so",
	otherOffer = "oo",
}

export const StandardOfferSchema = Joi.object({
	resourceType: Joi.string()
		.valid(ReserveResourceType.standardOffer)
		.required(),
	resourceId: Joi.objectId(),
	views: Joi.number(),
	products: Joi.array().items(
		Joi.object({
			id: Joi.objectId(),
			date: Joi.date(),
		})
	),
});

export const OtherOfferSchema = Joi.object({
	resourceType: Joi.string().valid(ReserveResourceType.otherOffer).required(),
	resourceId: Joi.objectId(),
});

const ResourceItemSchema = Joi.alternatives(
	StandardOfferSchema,
	OtherOfferSchema
);

export const ReservationSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number().required(),
	resources: Joi.array().items(ResourceItemSchema).required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IReservation {
	_id: ObjectId;
	userId: number;
	resources: IResource[];
	createdAt: Date;
	updatedAt: Date;
}

export interface IStandardOffer {
	resourceType: ReserveResourceType.standardOffer;
	views: number;
	resourceId: ObjectId;
	products?: IStandardProduct[];
}

export interface IOtherOffer {
	resourceType: ReserveResourceType.otherOffer;
	resourceId: ObjectId;
}
export interface IStandardProduct {
	id: ObjectId;
	date: Date;
}
