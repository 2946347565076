/* eslint-disable max-lines */
import { useClassroomsUser } from "@app/hooks/users";
import { parentLinks } from "@app/routes/parent/links";
import { studentLinks } from "@app/routes/student/links";
import { useCurrentChildId } from "@app/hooks/child";

type Normalize<T> = T extends (args: { childId: number }) => any
	? ReturnType<T>
	: T;

export function useChildLink<
	Fn extends (
		a: typeof parentLinks | typeof studentLinks
	) => (...args: any) => any,
>(fn: Fn, passedChildId?: number): Normalize<ReturnType<Fn>>;
export function useChildLink<
	Fn extends (
		a: typeof parentLinks | typeof studentLinks
	) => (...args: any) => any,
>(passedChildId: number, fn: Fn): Normalize<ReturnType<Fn>>;
export function useChildLink(...args: any[]): any {
	const fn = args[args.length - 1];
	const passedChildId: number | undefined =
		args.length === 2 ? args[0] : undefined;
	const globalChild = useCurrentChildId();
	const childId = passedChildId ?? globalChild;
	const loggedUser = useClassroomsUser();
	if (loggedUser?.isParent()) {
		return fn(parentLinks)({
			childId: +childId!,
		});
	}
	return fn(studentLinks);
}
