import { HeaderBulterAd } from "@app/components/dev/ads";
import { useHeaderOptions } from "@app/components/layouts/header/options";
import {
	HeaderProviders,
	HeaderTree,
} from "@app/components/layouts/header/tree";
import { subWebsiteDistributor } from "@app/components/subwebsites";
import { IAnyObj } from "@app/utils/generics";
import { CreateCusto } from "custo";
import React from "react";
import { Calls } from "../../widgets/calls";

export const StudentHeader: React.FC<IAnyObj> = (props) => {
	return (
		<HeaderProviders.PartialMergingProvider
			value={{
				components: {
					NextToLogo: TitleNextToLogo,
					LeftContainer,
				},
				data: {
					profileImg: "/imgs/StudentAvatar.svg",
					menuOptions: CreateCusto.functionOf.Data(useHeaderOptions),
				},
			}}
		>
			<HeaderTree.components.OuterContainer />
		</HeaderProviders.PartialMergingProvider>
	);
};

const LeftContainer = CreateCusto.Component(null, null, {
	outerAfterComponents: CreateCusto.Component(() => (
		<div style={{ marginTop: 3 }}>
			<HeaderBulterAd />
		</div>
	)),
});

const TitleNextToLogo = subWebsiteDistributor({
	main: () => null,
	britishCenter: () => (
		<div style={{ marginLeft: 20 }}>
			<Calls />
		</div>
	),
});
