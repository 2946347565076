import Joi from "@app/utils/joi";
import { ItemType } from "../folders/helper-schemas";
import {
	RTestTypeSchema,
	TestTypeKeys,
	TestTypeSchema,
	IRTestType,
	ITestType,
} from "./helper-schemas";
import {
	insertStripKeys,
	ToInsertKeys,
	markKeysOptional,
	updateStripKeys,
	ToUpdateKeys,
} from "../helper-schemas";
import { ObjectId } from "@app/utils/generics";

export const APOSTCreateTestTypeSchema = RTestTypeSchema.keys(insertStripKeys);
export type IAPOSTCreateTestType = ToInsertKeys<IRTestType>;

export const RPOSTCreateTestTypeSchema = RTestTypeSchema;
export type IRPOSTCreateTestType = IRTestType;

///

export const APUTTestTypeSchema = RTestTypeSchema.fork(
	TestTypeKeys,
	markKeysOptional
)
	.keys(updateStripKeys)
	.keys({
		_id: Joi.objectId().required(),
	});

export type IAPUTTestType = ToUpdateKeys<
	IRTestType,
	(typeof TestTypeKeys)[number]
> & { _id: ObjectId };

///

export const AGETTestTypeSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETTestType {
	_id: ObjectId;
}

export const RGETTestTypeSchema = TestTypeSchema;
export type IRGETTestType = ITestType;

///

export const ADELETETestTypeSchema = AGETTestTypeSchema;
export type IADELETETestType = IAGETTestType;

///

export const RGETAllTestTypesSchema = Joi.array()
	.items(TestTypeSchema)
	.required();
export type IRGETAllTestTypes = ITestType[];

///

export const AGETTestTypesByItemSchema = Joi.object({
	courseId: Joi.objectId().required(),
	itemId: Joi.objectId().required(),
	itemType: Joi.number()
		.valid(ItemType.question, ItemType.card, ItemType.text)
		.required(),
});
export interface IAGETTestTypesByItem {
	courseId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType.question | ItemType.card | ItemType.text;
}

export const RGETTestTypesByItemSchema = Joi.array().items(
	Joi.objectId().required()
);
export type IRGETTestTypesByItem = ObjectId[];

///

export const AGETItemsSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folderId: Joi.objectId().required(),
	testTypeId: Joi.objectId().required(),
});
export interface IAGETItems {
	courseId: ObjectId;
	folderId: ObjectId;
	testTypeId: ObjectId;
}

export const RGETItemsSchema = Joi.object({
	questionIds: Joi.array().items(Joi.objectId()),
	cardIds: Joi.array().items(Joi.objectId()),
});
export interface IRGETItems {
	questionIds?: ObjectId[];
	cardIds?: ObjectId[];
}

///

export const AGETManyTestTypesByIdsSchema = Joi.object({
	testTypeIds: Joi.array().items(Joi.objectId().required()).required(),
	courseId: Joi.objectId().required(),
});
export interface IAGETManyTestTypesByIds {
	testTypeIds: ObjectId[];
	courseId: ObjectId;
}

export const RGETManyTestTypesByIdsSchema = RGETAllTestTypesSchema;
export type IRGETManyTestTypesByIds = IRGETAllTestTypes;
