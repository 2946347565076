import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import { BasicDocument } from "@app/api/interface";
import { ItemType } from "../helper-schemas";

export const ItemStatsSchema = Joi.object({
	halfLife: Joi.number().unsafe().required(),
	lastPracticed: Joi.date().required(),
	totalCredit: Joi.number().required(),
	userCredit: Joi.number().required(),
});
export interface IItemStats {
	halfLife: number;
	lastPracticed: Date;
	totalCredit: number;
	userCredit: number;
}

export const PropertiesStatsSchema = Joi.object().pattern(
	/^[a-f\d]{24}$/i,
	ItemStatsSchema
);
export type IPropertiesStats = Record<string, IItemStats | undefined>;

export const QuestionStatsSchema = Joi.object().pattern(
	/^[a-f\d]{24}$/i,
	ItemStatsSchema
);
export type IQuestionStats = Record<string, IItemStats | undefined>;

export const CardStatsSchema = Joi.object().pattern(
	/^[a-f\d]{24}$/i,
	PropertiesStatsSchema
);
export type ICardStats = Record<string, IPropertiesStats | undefined>;

export const UserItemStatsSchema = Joi.alternatives(
	QuestionStatsSchema,
	CardStatsSchema
);
export type IUserItemStatsStats = IQuestionStats | ICardStats;

interface IUserItemStatsCommon {
	userId: number;
	courseId: ObjectId;
}

export interface IUserItemQuestionStats
	extends BasicDocument,
		IUserItemStatsCommon {
	itemType: ItemType.question;
	stats: IQuestionStats;
}

export interface IUserItemCardStats
	extends BasicDocument,
		IUserItemStatsCommon {
	itemType: ItemType.card;
	stats: ICardStats;
}

export type IUserItemStats = IUserItemQuestionStats | IUserItemCardStats;
