import { SubWebsiteOrigin } from "@app/globals";
import { useGoToUrl } from "@app/hooks/front";
import React, { useEffect, useState } from "react";
import { ISemester } from "@app/api/classrooms/helper-schemas";
import { useSubWebsite } from "@app/hooks/bc";
import { useClassroomsUser } from "@app/hooks/users";
import { romanize } from "@app/utils/common";
import newStyles from "../headmaster/styles/index.module.scss";
import studentStyles from "../users/subjects/styles/user-subject-list.module.css";
import styles from "../teachers/main/tvschool/styles/tvschool-classroom.module.css";

export const ArchiveBoxes: React.FC<{
	route: (semester: ISemester) => string;
	semesters: ISemester[] | null | undefined;
}> = React.memo(function ArchiveBoxes({ route, semesters }) {
	if (!semesters || semesters.length === 0) return null;
	return (
		<React.Fragment>
			{semesters.map((semester, i) => (
				<ArchivedClassroomBoxesComponentBox
					route={route(semester)}
					semester={semester}
					key={i}
				/>
			))}
		</React.Fragment>
	);
});

export const ArchivedClassroomBoxesComponentBox: React.FC<{
	route: string | (() => string);
	semester: ISemester;
}> = React.memo(function ArchivedClassroomBoxesComponentBox({
	route,
	semester,
}) {
	const handleRedirection = useGoToUrl(route);
	const [divStyle, setDivStyle] = useState<any>();
	const [spanStyle, setSpanStyle] = useState<any>();
	const [imgSize, setImgSize] = useState<{
		width: number;
		height: number;
		marginTop: number;
	}>({ width: 170, height: 170, marginTop: 0 });
	const isBritishCenter = useSubWebsite() === SubWebsiteOrigin.britishCenter;
	const user = useClassroomsUser();
	useEffect(() => {
		if (!user) return;
		if (user.isStudent() || user.isParent()) {
			setDivStyle(studentStyles.userSubjectListBox);
			setImgSize({ width: 170, height: 170, marginTop: 0 });
		} else if (user.isHeadmaster()) {
			setDivStyle(newStyles.boxContainer);
			setImgSize({ width: 150, height: 150, marginTop: 20 });
			setSpanStyle({ fontSize: 18 });
		} else if (user.isTeacher()) {
			setDivStyle(styles.classroomBox);
			setImgSize({ width: 100, height: 100, marginTop: 0 });
			setSpanStyle({ fontSize: 15 });
		}
	}, [user]);

	return (
		<div
			className={divStyle}
			onClick={handleRedirection}
			style={{ cursor: "pointer" }}
		>
			<img style={imgSize} src={"/imgs/archive.svg"} alt="archive" />
			{!isBritishCenter ? (
				<span className={studentStyles.awardText} style={spanStyle}>
					{semester.startYear}-{semester.startYear + 1} სასწავლო წლის{" "}
					<br /> {romanize(semester.ordinal + 1)} სემესტრის არქივი
				</span>
			) : (
				<span className={studentStyles.awardText} style={spanStyle}>
					ჯგუფების არქივი
				</span>
			)}
		</div>
	);
});
