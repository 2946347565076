import { UserViewSchema, IUserView } from "@app/api/user-views/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";
import { IAPUTView } from "@app/api/user-views/validators";

const keyOfId = "userId";
type IdKey = typeof keyOfId;
type DOC = IUserView;
export type IStateUserViews = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(UserViewSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("userView");
const storageSettings = getDefaultStorageSettings("userViews");
const metaInformationName = "userViewsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().userViews) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("number", UserViewSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class UserView extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static updateView(userId: number, args: IAPUTView) {
		const userView = this.findByIdSync(userId);
		if (!userView) {
			this.loadOneSync({
				...args,
				userId,
				createdAt: new Date(),
				updatedAt: new Date(),
			});
		} else {
			for (const key in args) {
				userView[key] = {
					...userView[key],
					...args[key],
				};
			}
			userView.saveSync();
		}
	}

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, UserView.meta);

export const userViewsReducer = getDefaultReducer(
	storageSettings,
	() => UserView
);
