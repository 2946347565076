import * as React from "react";
import styles from "./styles/loading.module.css";
import { css } from "emotion";

const FancyLoading: React.FC = (props) => (
	<div style={{ textAlign: "center" }}>
		<svg className={styles.loading} viewBox="0 0 100 80">
			<defs>
				<linearGradient
					id={styles.gradient}
					x1="100%"
					y1="0%"
					x2="0%"
					y2="100%"
				>
					<stop offset="0%" stopColor="#4383b8" />
					<stop offset="100%" stopColor="#4aa06c" />
				</linearGradient>

				<clipPath id={styles.rects}>
					<rect
						className={`${styles.rect} ${styles.rect1}`}
						x={0}
						y={0}
						width={30}
						height={30}
						rx={2}
						ry={2}
					/>
					<rect
						className={`${styles.rect} ${styles.rect2}`}
						x={0}
						y={0}
						width={30}
						height={30}
						rx={2}
						ry={2}
					/>
					<rect
						className={`${styles.rect} ${styles.rect3}`}
						x={0}
						y={0}
						width={30}
						height={30}
						rx={2}
						ry={2}
					/>
					<rect
						className={`${styles.rect} ${styles.rect4}`}
						x={0}
						y={0}
						width={30}
						height={30}
						rx={2}
						ry={2}
					/>
					<rect
						className={`${styles.rect} ${styles.rect5}`}
						x={0}
						y={0}
						width={30}
						height={30}
						rx={2}
						ry={2}
					/>
					<rect
						className={`${styles.rect} ${styles.rect6}`}
						x={0}
						y={0}
						width={30}
						height={30}
						rx={2}
						ry={2}
					/>
					<rect
						className={`${styles.rect} ${styles.rect7}`}
						x={0}
						y={0}
						width={30}
						height={30}
						rx={2}
						ry={2}
					/>
				</clipPath>
			</defs>
			<rect
				className={styles.container}
				transform="translate(50) scale(0.707, 0.707) rotate(45)"
				x={0}
				y={0}
				width={100}
				height={100}
				fill={`url(#${styles.gradient})`}
				clipPath={`url(#${styles.rects})`}
			/>
		</svg>
	</div>
);

export default FancyLoading;

export const FancyLoadingCenter = () => {
	return (
		<div className={center}>
			<FancyLoading />
		</div>
	);
};

const center = css`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
