import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import { useContext } from "react";
import { MCContentDesignStructure } from "../../../../schemas/questions/contnets/multiple-choice/schema";
import { EditorText } from "../../../../utils";
import { IExplanationProps } from "../common/explanation";
import { ItemAfterLabel } from "../common/items";
import { addButtonClassName, ItemEdit } from "../common/items-edit";
import { IItemTextProps } from "../common/stats";
import { IMCChoiceAfterProps } from "./";
import { IChoiceMainProps } from "./choices";
import { MCEditContext } from "./edit";

const ChoiceAfter: React.FC<IMCChoiceAfterProps> = React.memo(() => {
	const { onAddChoice, texts } = useContext(MCEditContext);
	return (
		<div>
			<button onClick={onAddChoice} className={addButtonClassName}>
				<AddIcon />
				<span>{texts.choices?.add}</span>
			</button>
		</div>
	);
});

const Explanation: React.FC<IExplanationProps> = React.memo(
	({ explanation, styles }) => {
		const { texts, onExplanationChange, galleryComponent } =
			useContext(MCEditContext);
		if (!explanation) return null;
		return (
			<ItemEdit
				stat={explanation as any}
				onChange={onExplanationChange}
				styles={styles}
				placeholder={texts.explanation!.placeholder}
				galleryComponent={galleryComponent}
			/>
		);
	}
);

const InnerChoiceContainerAfter: React.FC<IChoiceMainProps> = React.memo(
	(props) => {
		const { texts, onChoiceDelete } = useContext(MCEditContext);
		return (
			<ItemAfterLabel
				{...props}
				onDelete={onChoiceDelete}
				deleteText={texts.choices!.delete!}
			/>
		);
	}
);

const InnerChoiceText: React.FC<IItemTextProps> = React.memo((props) => {
	let disableEditing = false;
	const { texts, galleryComponent, onChoiceChange, designStructure } =
		useContext(MCEditContext);
	if (
		designStructure === MCContentDesignStructure.twoColumns ||
		designStructure === MCContentDesignStructure.dataSufficiency
	) {
		disableEditing = true;
	}
	return (
		<ItemEdit
			{...props}
			onChange={onChoiceChange}
			placeholder={
				(props.stat as any).score > 0
					? texts.choices!.checkedAnswer
					: texts.choices!.otherAnswer
			}
			disableEditing={disableEditing}
			galleryComponent={galleryComponent}
		/>
	);
});

const StatementText: React.FC<IItemTextProps> = React.memo((props) => {
	const {
		texts,
		styles,
		galleryComponent,
		onStatementChange,
		onStatementTextAChange,
		onStatementTextBChange,
		onPaste,
		designStructure,
	} = useContext(MCEditContext);
	if (designStructure === MCContentDesignStructure.twoColumns) {
		if (!props.extraInfo) return null;
		let placeholder: string | undefined;
		let onChange: (stat: EditorText) => void;
		if (props.extraInfo.isColMainText) {
			placeholder = texts.statement!.twoColumns!.mainPlaceholder;
			onChange = onStatementChange;
		} else if (props.extraInfo.isColAText) {
			placeholder = texts.statement!.twoColumns!.APlaceholder;
			onChange = onStatementTextAChange;
		} else if (props.extraInfo.isColBText) {
			placeholder = texts.statement!.twoColumns!.BPlaceholder;
			onChange = onStatementTextBChange;
		} else return null;
		return (
			<ItemEdit
				{...props}
				onChange={onChange}
				placeholder={placeholder}
				styles={{
					container: styles?.statement?.text,
				}}
				galleryComponent={galleryComponent}
			/>
		);
	}
	return (
		<ItemEdit
			{...props}
			onChange={onStatementChange}
			placeholder={texts.statement!.placeholder}
			styles={{
				container: styles?.statement?.text,
			}}
			galleryComponent={galleryComponent}
			onPaste={onPaste}
		/>
	);
});

export const MCEditComponents = {
	ChoiceAfter,
	Explanation,
	InnerChoiceContainerAfter,
	InnerChoiceText,
	StatementText,
};
