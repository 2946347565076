import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export enum TestTypeAlgorithms {
	catAndSpacedRepetition = "CATANDSPACEDREPETITION",
	spacedRepetition = "SPACEDREPETITION",
}

export enum TestTypeAnswersShowTime {
	immediately = "IMMEDIATELY",
	afterFinish = "AFTERFINISH",
	afterDeadline = "AFTERDEADLINE",
}

export const testTypeAlgorithmsArray: TestTypeAlgorithms[] = [
	TestTypeAlgorithms.catAndSpacedRepetition,
	TestTypeAlgorithms.spacedRepetition,
];
export const testTypeAnswersShowTimeArray: TestTypeAnswersShowTime[] = [
	TestTypeAnswersShowTime.immediately,
	TestTypeAnswersShowTime.afterFinish,
	TestTypeAnswersShowTime.afterDeadline,
];

export const TestTypeSettingsSchema = Joi.object({
	showStatsAfterEnd: Joi.boolean(),
	checkInBackEnd: Joi.boolean(),
	submitAnswerAfterAnswering: Joi.boolean(),
	allowSwitchingToSubmittedQuestions: Joi.boolean(),
	allowSwitchingToUnsubmittedQuestions: Joi.boolean(),
	maxNumOfWritingTests: Joi.number().allow(null).default(null),
	showAnswersAt: Joi.string()
		.valid(...testTypeAnswersShowTimeArray)
		.required(),
	algorithm: Joi.string().valid(...testTypeAlgorithmsArray),
	maxNumOfQuestions: Joi.number(),
});
export interface ITestTypeSettings {
	maxNumOfWritingTests: number | null;
	showAnswersAt: TestTypeAnswersShowTime;
	showStatsAfterEnd?: boolean;
	checkInBackEnd?: boolean;
	submitAnswerAfterAnswering?: boolean;
	allowSwitchingToSubmittedQuestions?: boolean;
	allowSwitchingToUnsubmittedQuestions?: boolean;
	algorithm?: TestTypeAlgorithms;
	maxNumOfQuestions?: number;
}

export const RTestTypeSchema = Joi.object({
	_id: Joi.objectId().required(),
	name: Joi.string().required(),
	description: Joi.string(),
	public: Joi.boolean().required(),
	settings: TestTypeSettingsSchema.required(),
	author: Joi.number().integer().required(),
});
export interface IRTestType {
	_id: ObjectId;
	name: string;
	public: boolean;
	settings: ITestTypeSettings;
	author: number;
	description?: string;
}

export const TestTypeSchema = RTestTypeSchema.keys({
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface ITestType extends IRTestType {
	createdAt: Date;
	updatedAt: Date;
}

export const TestTypeKeys: (keyof IRTestType)[] = [
	"name",
	"description",
	"public",
	"settings",
	"author",
];
