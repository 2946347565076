import Joi from "@app/utils/joi";

export const GradeSchema = Joi.object({
	id: Joi.number().required(),
	name: Joi.string().allow(null).required(),
	fullName: Joi.string().allow(null).required(),
	num: Joi.number().integer().allow(null).required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IGrade {
	id: number;
	name: string | null;
	fullName: string | null;
	num: number | null;
	createdAt: Date;
	updatedAt: Date;
}

export const GradeEdgeSchema = Joi.object({
	id: Joi.number().required(),
	gradeId: Joi.number().required(),
	categoryId: Joi.number().allow(null).required(),
	website: Joi.string().allow(null).required(),
	language: Joi.string().allow(null).required(),
	country: Joi.string().allow(null).required(),
	name: Joi.string().allow(null).required(),
	fullName: Joi.string().allow(null).required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IGradeEdge {
	id: number;
	gradeId: number;
	categoryId: number | null;
	website: string | null;
	language: string | null;
	country: string | null;
	name: string | null;
	fullName: string | null;
	createdAt: Date;
	updatedAt: Date;
}

export const GradeCategorySchema = Joi.object({
	id: Joi.number().required(),
	parentId: Joi.number().allow(null).required(),
	name: Joi.string().required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});
export interface IGradeCategory {
	id: number;
	parentId: number | null;
	name: string;
	createdAt: Date;
	updatedAt: Date;
}
