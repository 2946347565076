import { File } from "@app/models/file";
import { inject } from "@app/modules";
import { createCoursedModelCollmise } from "../collmise-helpers";
import { ItemType } from "../folders/helper-schemas";
import { IRequest } from "../requests";
import {
	IADELETEFile,
	IAGETFile,
	IAGETFileWithFullContent,
	IAGETManyFilesByIds,
	IAPOSTCreateFile,
	IAPUTFile,
	IAPUTReadFileInfo,
	IRGETFileWithFullContent,
	IRPOSTCreateFile,
	RGETAllFilesSchema,
	RGETFileSchema,
	RGETManyFilesByIdsSchema,
	RPOSTCreateFileSchema,
} from "./validators";

export class FilesController {
	private readonly Request: IRequest;

	private readonly _FileModel = inject("FileModel");
	private readonly _FolderItemsService = inject("FolderItemsService");
	private readonly _FolderItemProgressService = inject(
		"FolderItemProgressService"
	);

	private filePromises = createCoursedModelCollmise({
		model: this._FileModel,
		name: "File",
		multiId: "fileIds" as const,
		getMany: (query: IAGETManyFilesByIds) =>
			this.Request.send(
				"POST",
				"/api/files/get-many-by-ids",
				query,
				null,
				{ responseSchema: RGETManyFilesByIdsSchema }
			),
	});

	private readonly assertAndGetCoursesUser = inject(
		"assertAndGetCoursesUser"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateFile): Promise<File> =>
		this.Request.send("POST", "/api/files", args, null, {
			responseSchema: RPOSTCreateFileSchema,
		}).then((data: IRPOSTCreateFile) => {
			this._FolderItemsService.addItemInParentSync({
				parentFolderId: args.folderId,
				item: { id: data._id, name: data.name, type: ItemType.file },
				courseId: args.courseId,
			});
			return this._FileModel.loadOneSync(data);
		});

	update = async (args: IAPUTFile): Promise<File | null> =>
		this.Request.send("PUT", "/api/files/:_id", args).then(() => {
			const file = this._FileModel.findByIdSync(args._id);
			// TODO: what happens when file is not found locally
			if (file && file.folderId) {
				this._FolderItemsService.updateItemSync({
					courseId: args.courseId,
					item: {
						id: args._id,
						type: ItemType.file,
						name: args.name,
					},
					parentFolderId: file.folderId,
				});
			}
			return this._FileModel.updateOneSync({ _id: args._id }, args);
		});

	getById = async (args: IAGETFile, loadFresh?: boolean): Promise<File> => {
		return this.filePromises
			.on(args)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/files/:_id", args, null, {
					responseSchema: RGETFileSchema,
				})
			);
	};

	getAll = async (): Promise<File[]> =>
		this.Request.send("GET", "/api/files/", undefined, null, {
			responseSchema: RGETAllFilesSchema,
		}).then((data) => {
			return this._FileModel.loadManySync(data, "replaceAll");
		});

	deleteById = async (args: IADELETEFile): Promise<void> =>
		this.Request.send("DELETE", "/api/files/:_id", args).then(() => {
			this._FolderItemsService.deleteItemSync({
				itemId: args._id,
				type: ItemType.file,
				courseId: args.courseId,
				bypassUniqueness: true,
			});
			this._FileModel.deleteByIdSync(args._id);
		});

	onReadFile = async (args: IAPUTReadFileInfo): Promise<void> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		return this.Request.send(
			"PUT",
			"/api/files/:fileId/reading-info",
			args
		).then(() => {
			this._FolderItemProgressService.addReadFileSync(args, userId);
		});
	};

	getWithFullContent = async (
		args: IAGETFileWithFullContent
	): Promise<IRGETFileWithFullContent> =>
		this.Request.send("GET", "/api/files/:_id/full-content", args).then(
			(data: IRGETFileWithFullContent) => {
				this._FileModel.loadOneSync(data.file);
				return data;
			}
		);

	getManyByIds = async (args: IAGETManyFilesByIds): Promise<File[]> => {
		return this.filePromises.collectors.many(args).request();
	};
}
