import { inject } from "@app/modules";
import { useModelDocById } from "m-model-react";
import { useResourceLoading } from "./fetch";
import { useClassroomsUserId } from "./users";

export function useUserActions() {
	const userId = useClassroomsUserId() ?? null;
	const userAction = useModelDocById(inject("UserActionModel"), userId);
	return useResourceLoading(userAction, () => {
		const UserActionsController = inject("UserActionsController");
		return UserActionsController.getUserAction();
	}, [userId]);
}
