import { ObjectId } from "@app/utils/generics";
import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import { insertStripKeys, ToInsertKeys } from "../helper-schemas";
import {
	GroupClassroomInfoSchema,
	GroupSchema,
	IGroup,
	IGroupClassroomInfo,
} from "./helper-schemas";
import { ISemester, SemesterSchema } from "../classrooms/helper-schemas";

export const APOSTClassroomGroupSchema = GroupSchema.keys(insertStripKeys);
export type IAPOSTClassroomGroup = ToInsertKeys<IGroup>;

export const RPOSTClassroomGroupSchema = GroupSchema;
export type IRPOSTClassroomGroup = IGroup;

///

export const AGETGroupByIdSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETGroupById {
	_id: ObjectId;
}

export const RGETGroupByIdSchema = GroupSchema;
export type IRGETGroupById = IGroup;

///

export const AGETManyGroupsByIdsSchema = Joi.object({
	ids: Joi.array().items(Joi.objectId()).required(),
});
export interface IAGETManyGroupsByIds {
	ids: ObjectId[];
}

export const RGETManyGroupsByIdsSchema = Joi.array().items(GroupSchema);
export type IRGETManyGroupsByIds = IGroup[];

///

export const AGETGroupClassroomsInfoSchema = Joi.object({
	groupId: Joi.objectId().required(),
});
export interface IAGETGroupClassroomsInfo {
	groupId: ObjectId;
}

export const RGETGroupClassroomsInfoSchema = Joi.array().items(
	GroupClassroomInfoSchema
);
export type IRGETGroupClassroomsInfo = IGroupClassroomInfo[];

///

export const AGETGroupsBySchoolId = Joi.object({
	schoolId: Joi.number().required(),
});
export interface IAGETGroupsBySchoolId {
	schoolId: number;
}

export const RGETGroupsBySchoolIdSchema = Joi.array().items(GroupSchema);
export type IRGETGroupsBySchoolId = IGroup[];

///

export const AGETArchivedGroupsForHeadmaster = Joi.object({
	schoolId: Joi.number().required(),
	semester: SemesterSchema,
	isArchived: Joi.boolean().required(),
	mentorIds: Joi.array().items(UnsignedIntegerSchema),
});

export interface IAGETArchivedGroupsForHeadmaster {
	schoolId: number;
	semester?: ISemester;
	isArchived: boolean;
	mentorIds?: number[];
}

export const RGETArchivedGroupsForHeadmaster = Joi.array().items(GroupSchema);
export type IRGETArchivedGroupsForHeadmaster = IGroup[];

///
