import React, { useState, useCallback, useEffect, useMemo } from "react";
import Popup, { PopupContentWithClose } from "@app/components/widgets/popup";
import styled from "@emotion/styled";
import { useWindowSize } from "@app/hooks/front";
import styles from "./edit-assignments/styles/index.module.scss";
import { inject } from "@app/modules";
import { IRGETPublicTestChangesByAuthor } from "@app/api/public-tests/validators";
import { useClassroomsUser } from "@app/hooks/users";
import { UserType } from "@app/api/helper-schemas";
import { PublicTestType } from "@app/api/public-tests/helper-schemas";

type PublicTestChanges = IRGETPublicTestChangesByAuthor;

export const ApprovedPublicAssignmentPopup: React.FC = React.memo((props) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [changes, setChanges] = useState<PublicTestChanges>([]);
	const { width } = useWindowSize();
	const user = useClassroomsUser();

	useEffect(() => {
		if (!user) return;
		const checkForChanges = async () => {
			try {
				const changes = await inject(
					"PublicTestsController"
				).getChangesByAuthor();
				const filtered = changes.filter(
					(e) => e.publicTest.type !== PublicTestType.complex
				);
				if (filtered.length === 0) return;
				setChanges(filtered);
				setIsOpen(true);
			} catch (error) {}
		};
		checkForChanges();
	}, [user]);

	const handleClose = useCallback(() => {
		if (changes.length !== 0) {
			try {
				inject("PublicTestsController").markChangeSeenForMany({
					changeIds: changes.map((e) => e.changeId),
				});
			} catch (error) {}
		}
		setIsOpen(false);
	}, [changes]);

	const getApprovedTestNames = useMemo(() => {
		return changes.reduce(
			(p, c, i) =>
				(p =
					p +
					`${c.publicTest.name}${
						i === changes.length - 1 ? "" : ", "
					}`),
			""
		);
	}, [changes]);

	const style = width < 630 ? { fontSize: "15px" } : undefined;

	return isOpen && user ? (
		<div>
			<Popup onClose={() => handleClose()}>
				<PopupContentWithClose
					onClose={() => handleClose()}
					popupBodyClassname={styles.approvedPopup}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							padding: "0px 12px",
							margin: "5px",
						}}
					>
						<PopupText style={style}>
							<span style={{ fontFamily: "Roboto Geo Caps" }}>
								{`${user.firstname} მასწავლებელო,`}
							</span>
						</PopupText>
						<PopupText style={style}>
							{"TV სკოლის ადმინისტრაცია "}
							<BoldText>მადლობას გიხდით </BoldText>
							ხარისხიანი და საინტერესო ტესტების შექმნისთვის.
						</PopupText>
						<PopupText style={style}>
							{`თქვენ მიერ შექმნილი დავალებ${
								changes.length === 1 ? "ა" : "ები"
							}: `}
							<BoldText>{getApprovedTestNames}</BoldText> -
							მოიწონა TV სკოლის შესაბამისი საგნის გუნდმა და
							დაამატა მზა დავალებების ჩამონათვალში ავტორის ველში
							თქვენი სახელისა და გვარის მითითებით.
						</PopupText>
						<PopupText
							style={{ fontSize: "12px", fontStyle: "italic" }}
						>
							დავალების გასაჯაროებაზე უარის თქმა შეგიძლიათ
							ნებისმიერ დროს - დავალების შექმნისას, ან შექმნის
							შემდეგ "ჩემი დავალებების" რედაქტირებიდან.
						</PopupText>
						<PopupButton
							style={
								width < 630
									? {
											width: "210px",
											fontSize: "16px",
										}
									: undefined
							}
							onClick={() => handleClose()}
						>
							გასაგებია
						</PopupButton>
					</div>
				</PopupContentWithClose>
			</Popup>
		</div>
	) : null;
});

const PopupText = styled.p`
	font-family: "Roboto Geo Nus";
	font-size: 18px;
	text-align: justify;
	padding: 10px;
	margin: 0;
`;
const BoldText = styled.span`
	font-weight: bold;
`;

const PopupButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 300px;
	height: 55px;
	color: white;
	border-radius: 15px;
	background-color: #5273e6;
	font-family: "Roboto Geo MT Bold";
	font-size: 20px;
	font-weight: bold;
	margin-top: 8px;
	margin-bottom: 8px;
	cursor: pointer;
`;

export const ApprovedPublicAssignments = () => {
	const user = useClassroomsUser();

	if (!user) return null;
	if (!user.isTeacher()) return null;

	return <ApprovedPublicAssignmentPopup />;
};
