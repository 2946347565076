import React from "react";

export class ScriptInBody extends React.PureComponent<
	Pick<
		React.ScriptHTMLAttributes<HTMLScriptElement>,
		"src" | "async" | "defer" | "crossOrigin" | "nonce"
	> & {
		onLoad?: () => void;
	}
> {
	ref: React.RefObject<HTMLDivElement> = React.createRef();

	componentDidMount() {
		const { onLoad, children, ...rest } = this.props;
		const s = document.createElement("script");
		s.type = "text/javascript";
		s.async = !!rest.async;
		s.defer = !!rest.defer;
		s.nonce = rest.nonce;
		if (typeof rest.src === "string") {
			s.src = rest.src;
		}
		s.crossOrigin = rest.crossOrigin || null;
		if (typeof this.props.children === "string") {
			s.innerHTML = this.props.children as string;
		}
		if (onLoad) {
			s.onload = () => {
				onLoad();
			};
		}
		s.onerror = (e) => {
			console.log("error", e);
		};
		this.ref.current?.appendChild(s);
	}

	render() {
		return (
			<div
				style={{
					width: 1,
					height: 1,
					position: "absolute",
					top: -1000000,
					left: -1000000,
				}}
				ref={this.ref}
			/>
		);
	}
}
