/* eslint-disable max-lines-per-function */
import { playground1Id } from "@app/components/playground";
import SvgloadingWhite from "@app/components/styles/img/loading-white";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { FancyPopup } from "@app/components/widgets/popup";
import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUser } from "@app/hooks/users";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { MainButton } from "../styles/styledComponents";

const disabledPagesForTermsAndConditions = ["/terms"];

export const TermsPopupWrapper = React.memo(() => {
	const user = useClassroomsUser();
	const hasAgreedOnTerms = user?.hasAgreedOnTerms;
	const { value: isClosed, setTrue: close } = useBoolean(
		!!hasAgreedOnTerms ||
			window.localStorage.getItem("showTermsPopup") !== null
	);
	const location = useLocation();
	const locale = useLocale();

	const handleClose = useCallback(() => {
		close();
		window.localStorage.setItem("showTermsPopup", "0");
	}, [close]);

	if (isClosed || !user || hasAgreedOnTerms === true) {
		return null;
	}

	if (disabledPagesForTermsAndConditions.indexOf(location.pathname) > -1) {
		return null;
	}

	if (locale !== "ka" || getCurrentWebsite() !== WebsiteOrigin.tvSchool) {
		return null;
	}

	return <TermsPopup onReject={handleClose} onSuccess={close} />;
});

export const TermsPopup: React.FC<{
	onReject: () => void;
	onSuccess: () => void;
}> = React.memo(({ onReject, onSuccess }) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleAgree = useCallback(() => {
		const isCancelled = false;
		setIsLoading(true);
		const updateHasAgreed = async () => {
			try {
				await inject("UsersController").updateHasAgreedOnTerms({
					hasAgreedOnTerms: true,
				});
				if (isCancelled) return;
				setIsLoading(false);
				if (onSuccess) onSuccess();
			} catch (error) {
				if (isCancelled) return;
				setIsLoading(false);
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			}
		};
		updateHasAgreed();
	}, [onSuccess]);

	const handleRedirection = useCallback(() => {
		window.open("/terms", "__blank");
	}, []);

	return (
		<FancyPopup onClose={onReject}>
			<div>
				<div style={{ fontFamily: "Roboto Geo Nus" }}>
					<div
						style={{
							fontSize: "16px",
							textAlign: "center",
							color: "#626a84",
							marginBottom: "25px",
						}}
					>
						გაცნობებთ, რომ ვებგვერდზე მოქმედებს საიტის მოხმარების
						წესები და პირობები, რომელსაც შეგიძლიათ გაეცნოთ აქ:
					</div>
					<div
						style={{
							textDecoration: "underline",
							fontSize: "18px",
							color: "#5273e6",
							cursor: "pointer",
						}}
						onClick={handleRedirection}
					>
						წესები და პირობები
					</div>
				</div>
				<MainButton
					style={{
						width: "300px",
						height: "55px",
						borderRadius: "15px",
						backgroundColor: "#5273e6",
						fontFamily: "Roboto Geo MT Bold",
						fontSize: "20px",
						fontWeight: "bold",
						cursor: "pointer",
						display: "inline-flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					onClick={handleAgree}
				>
					ვეთანხმები
					{isLoading && (
						<SvgloadingWhite
							style={{
								width: 20,
								height: 20,
								verticalAlign: "middle",
								marginLeft: 6,
							}}
						/>
					)}
				</MainButton>
			</div>
		</FancyPopup>
	);
});

export const requireTermsAndConditions = (): Promise<void> => {
	return new Promise<void>((resolve, reject) => {
		const store = inject("store");
		const hasAgreedOnTerms =
			store.getState().user.userData?.hasAgreedOnTerms;

		if (hasAgreedOnTerms) {
			resolve();
			return;
		}

		let hasFinished = false;
		const unsubscribe = store.subscribe(() => {
			// in case terms are agreed outside this component; for instance, in another tab
			const hasAgreedOnTerms =
				store.getState().user.userData?.hasAgreedOnTerms;
			if (hasAgreedOnTerms) {
				onSuccess();
			}
		});

		const onSuccess = () => {
			if (hasFinished) return;
			resolve();
			onFinish();
		};

		const container = document.createElement("div");
		const playground = document.getElementById(playground1Id)!;
		playground.appendChild(container);

		const onReject = () => {
			if (hasFinished) return;
			reject("not agreed on terms");
			onFinish();
		};

		const onFinish = () => {
			hasFinished = true;
			unsubscribe();
			ReactDOM.unmountComponentAtNode(container);
			container.remove();
		};

		ReactDOM.render(
			<TermsPopup onReject={onReject} onSuccess={onSuccess} />,
			container
		);
	});
};
