import { ReactComponent as TVSchoolLogo } from "@app/components/tv-school-landing/styles/imgs/edufy-logo.svg";
import { useGoToUrl } from "@app/hooks/front";
import React from "react";
import styles from "./styles.module.scss";

export const HeaderDefaultLogo = React.memo(function HeaderDefaultLogo() {
	const gotoMainPage = useGoToUrl("/");

	return (
		<div onClick={gotoMainPage}>
			<TVSchoolLogo className={styles.logo} />
		</div>
	);
});
