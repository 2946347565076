import { ILoc } from "@app/api/helper-schemas";
import { getLocale } from "@app/hooks/intl";
import { getCurrentWebsite } from "@app/globals";
import { inject } from "@app/modules";

export const getLoc = (): ILoc => {
	const store = inject("store");
	const userData = store.getState()["user"]?.userData;
	const userCountry = userData?.country || null;

	const locale = getLocale();

	const CountriesService = inject("CountriesService");
	const countries = CountriesService.getCountiesForLanguage(locale);

	const country = userCountry
		? userCountry
		: countries.length === 1
			? countries[0]
			: null;

	return {
		language: locale,
		website: getCurrentWebsite(),
		country,
	};
};

export function addLoc<T extends {}, K extends string>(
	data: T,
	key: K
): T & { [key in K]: ILoc };
export function addLoc<T extends {}>(data: T): T & { loc: ILoc };
export function addLoc(data: any, key?: string): any {
	const k = key || "loc";
	const getLoc = inject("getLoc");
	if (data[k] === undefined) {
		return {
			...data,
			[k]: getLoc(),
		};
	}
	return {
		[k]: getLoc(),
		...data,
	};
}
