import { StudentInfoSchema, IStudentInfo } from "@app/api/users/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CommonMetaInfo } from "./meta-info";

const keyOfId = "userId";
type IdKey = typeof keyOfId;
type DOC = IStudentInfo;
export type IStateStudentsInfo = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(StudentInfoSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("studentInfo", "studentsInfo");
const storageSettings = getDefaultStorageSettings("studentsInfo");
const metaInformationName = "studentsInfoMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().studentsInfo) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("number", StudentInfoSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class StudentInfo extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static findByUserIdSync = Model.findByIdSync;

	static meta = new CommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, StudentInfo.meta);

export const studentsInfoReducer = getDefaultReducer(
	storageSettings,
	() => StudentInfo
);
