import { History } from "history";
import * as React from "react";
import * as ReactGA from "react-ga";
import { withRouter } from "react-router";
import { useClassroomsUserId } from "./hooks/users";
import { inject } from "./modules";

let globalHistory: any = null;

interface IProps {
	history: History;
}

class Spy extends React.Component<IProps> {
	constructor(props: IProps) {
		super(props);
		globalHistory = props.history;
		ReactGA.pageview(window.location.pathname + window.location.search);

		props.history.listen(() => {
			window.scroll(0, 0);
			ReactGA.pageview(window.location.pathname + window.location.search);
		});
	}

	render() {
		return (
			<>
				<UserLogger />
			</>
		);
	}
}

export const GlobalHistory = withRouter(Spy as any);

// export react-router history
export default function getHistory(): History | null {
	return globalHistory;
}

const UserSessionTreshold = 10 * 60 * 1000;

const getKeyByUserId = (userId: number) => {
	return `user-${userId}-session`;
};

const UserLogger = () => {
	const userId = useClassroomsUserId();
	const userIdRef = React.useRef<number | null>(userId || null);

	React.useEffect(() => {
		if (!userId) {
			if (!userIdRef.current) return;
			localStorage.removeItem(getKeyByUserId(userIdRef.current));
			userIdRef.current = null;
			return;
		}
		userIdRef.current = userId;
		const lastDateStr = localStorage.getItem(getKeyByUserId(userId));
		const lastDate = !lastDateStr
			? null
			: new Date(JSON.parse(lastDateStr));
		if (
			!lastDate ||
			Date.now() - lastDate.getTime() > UserSessionTreshold
		) {
			const UserActionsController = inject("UserActionsController");
			UserActionsController.logVisit()
				.then()
				.catch((e) => {
					console.log(e);
				});
		}
		localStorage.setItem(
			getKeyByUserId(userId),
			JSON.stringify(new Date())
		);
	}, [userId]);

	React.useEffect(() => {
		if (!userId) return;
		const planned = setInterval(() => {
			localStorage.setItem(
				getKeyByUserId(userId),
				JSON.stringify(new Date())
			);
		}, 1000 * 60);
		return () => clearInterval(planned);
	}, [userId]);

	return null;
};
