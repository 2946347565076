import { ITime, TimeSchema } from "@app/api/classrooms/helper-schemas-2";
import { BasicDocumentSchema, BasicDocument } from "@app/api/interface";
import Joi, { UnsignedIntegerSchema } from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const LessonTimeSchema = Joi.object({
	ordinal: Joi.number().integer().min(0).required(),
	grades: Joi.array().items(UnsignedIntegerSchema).allow(null).required(),
	schoolLabelIds: Joi.array().items(Joi.objectId()).allow(null),
	startTime: TimeSchema.required(),
	endTime: TimeSchema.required(),
});

export interface ILessonTime {
	ordinal: number;
	grades: number[] | null;
	schoolLabelIds?: ObjectId[] | null;
	startTime: ITime;
	endTime: ITime;
}

export const SchoolLessonScheduleSchema = BasicDocumentSchema.keys({
	schoolId: UnsignedIntegerSchema.required(),
	times: Joi.array().items(LessonTimeSchema).required(),
});
export interface ISchoolLessonSchedule extends BasicDocument {
	schoolId: number;
	times: ILessonTime[];
}
