/* eslint-disable max-lines-per-function */
import * as React from "react";
import {
	display,
	IUserExtendedAnswer,
	ITextDisplay,
	FinishState,
	TestSpecialPage,
} from ".";
import FlagIcon from "@material-ui/icons/Flag";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import classNames from "classnames";
import { NotUndefinedAtAll, AnyComponent } from "../../utils/generics";
import { mergeComponentObjects, mergeStylesObjects } from "../../utils";
import navigationStyles from "./styles/styles.module.css";
import { ObjectId } from "../../utils/joi";
import { removeKeys, getOldIfUnchanged, deepEqual } from "../../utils/common";
import { Scrollbars } from "react-custom-scrollbars";
import { animateCallback } from "@tests-core/utils/dom";

interface ITestNavigationStyles {
	OuterContainer?: string;
	IsFinished?: string;
	ContainerWithoutScrollbar?: string;
	ContainerWithScrollbar?: string;
	item?: ITestNavigationItemStyles;
}

export interface ITestNavigationProps {
	currentDisplay: display;
	questionsInfo: ({
		canBeSwitchedTo: boolean;
	} & (
		| { isUnknown: true }
		| {
				isUnknown: false;
				userAnswerInfo:
					| (Pick<
							IUserExtendedAnswer,
							| "assessment"
							| "credit"
							| "isFullyAnswered"
							| "maxCredit"
							| "submitted"
					  > & {
							hasAnswered: boolean;
					  })
					| undefined;
				numOfGradableItemsByEditor: number;
				textId?: ObjectId;
		  }
	))[];
	textsInfo: {
		id: ObjectId;
		canBeSwitchedTo: boolean;
		indexOfQuestionToWhichItIsDisplayedBefore: number;
	}[];
	onPageSelect: (display: display) => void;
	showTextsAsSeparate: boolean;
	showStartPageIcon: boolean;
	canClickStartPage: boolean;
	canClickFinishPage: boolean;
	showFinishPageIcon: boolean;
	testFinishState: FinishState;
	styles?: ITestNavigationStyles;
	showAnswersOfSubmittedQuestions: boolean;
	showAnswersOfUnsubmittedQuestions: boolean;
	components?: ITestNavigationComponents;
	displayItemsInline?: boolean;
	specialPages?: TestSpecialPage[];
}

const startPage: display = {
	type: "startPage",
};

const finishPage: display = {
	type: "finishPage",
};

const navStyles = {
	OuterContainer: navigationStyles.outerContainer,
	ContainerWithoutScrollbar: navigationStyles.containerWithoutScrollbar,
	ContainerWithScrollbar: navigationStyles.containerWithScrollbar,
	IsFinished: navigationStyles.isFinished,
} as NotUndefinedAtAll<ITestNavigationProps["styles"]>;

export const TestNavigation: React.FC<ITestNavigationProps> = React.memo(
	(props) => {
		const lastBalls = React.useRef([] as display[]);
		const scrollBars = React.useRef<Scrollbars | null>(null);
		let balls: display[] = [];
		let selectedBallIndex = -1;
		if (props.showStartPageIcon) {
			balls.push(startPage);
		}
		if (props.currentDisplay.type === "startPage") {
			selectedBallIndex = balls.findIndex((e) => e.type === "startPage");
		}
		let lastTextId: undefined | string;
		let index = -1;
		for (const qInfo of props.questionsInfo) {
			index++;
			if (
				props.showTextsAsSeparate &&
				!qInfo.isUnknown &&
				qInfo.textId !== undefined &&
				qInfo.textId !== lastTextId
			) {
				const obj: display = {
					type: "text",
					id: qInfo.textId,
					indexOfQuestionToWhichItIsDisplayedBefore: index,
				};
				balls.push(
					getOldIfUnchanged(obj, lastBalls.current[balls.length])
				);
				if (
					props.currentDisplay.type === "text" &&
					props.currentDisplay.id === obj.id &&
					props.currentDisplay
						.indexOfQuestionToWhichItIsDisplayedBefore ===
						obj.indexOfQuestionToWhichItIsDisplayedBefore
				) {
					selectedBallIndex = balls.length - 1;
				}
			}
			const obj: display = {
				type: "question",
				index,
			};
			balls.push(getOldIfUnchanged(obj, lastBalls.current[balls.length]));
			if (
				props.currentDisplay.type === "question" &&
				props.currentDisplay.index === obj.index
			) {
				selectedBallIndex = balls.length - 1;
			}
			lastTextId =
				!qInfo.isUnknown && qInfo.textId !== undefined
					? qInfo.textId
					: undefined;
		}
		if (props.showFinishPageIcon) {
			balls.push(finishPage);
		}
		if (props.specialPages) {
			({ balls, selectedBallIndex } = getInsertedSpecialPages(
				balls,
				props.specialPages,
				selectedBallIndex
			));
			if (props.currentDisplay.type === "specialPage") {
				const currentDisplay = props.currentDisplay;
				selectedBallIndex = balls.findIndex(
					(e) =>
						e.type === "specialPage" &&
						e.pageId === currentDisplay.pageId
				);
			}
		}
		if (props.currentDisplay.type === "finishPage") {
			selectedBallIndex = balls.findIndex((e) => e.type === "finishPage");
		}
		lastBalls.current = balls;
		const ballRefs: React.RefObject<HTMLElement>[] = React.useMemo(
			() => new Array(balls.length).fill(0).map((e) => React.createRef()),
			[balls.length]
		);

		React.useEffect(() => {
			const scrollBar = scrollBars.current;
			if (!scrollBar) return;
			const currentBall = ballRefs[selectedBallIndex];
			if (!currentBall || !currentBall.current) return;

			let hasCancelled = false;

			const offsetLeft = currentBall.current.offsetLeft;
			const ballWidth = currentBall.current.clientWidth;
			const scrollWidth = scrollBar.getClientWidth();
			const scrollLeft = Math.max(
				0,
				offsetLeft + ballWidth / 2 - scrollWidth / 2
			);
			animateCallback(
				scrollBar.getScrollLeft(),
				scrollLeft,
				200,
				(val) => {
					if (hasCancelled) return;
					scrollBar.scrollLeft(val);
				}
			);
			return () => {
				hasCancelled = true;
			};
		}, [ballRefs, selectedBallIndex]);

		const { onPageSelect, canClickStartPage, canClickFinishPage } = props;

		const onStartPageClick = React.useCallback(() => {
			if (canClickStartPage) {
				onPageSelect({
					type: "startPage",
				});
			}
		}, [onPageSelect, canClickStartPage]);

		const onFinishPageClick = React.useCallback(() => {
			if (canClickFinishPage) {
				onPageSelect({
					type: "finishPage",
				});
			}
		}, [onPageSelect, canClickFinishPage]);

		const onQuestionClick = React.useCallback(
			(display: display) => {
				if (display.type !== "question") return;
				const qInfo = props.questionsInfo[display.index];
				if (qInfo && qInfo.canBeSwitchedTo) {
					onPageSelect(display);
				}
			},
			[props.questionsInfo, onPageSelect]
		);

		const isTextAccessible = React.useCallback(
			(display: ITextDisplay) => {
				const text = props.textsInfo.find(
					(e) =>
						e.id === display.id &&
						e.indexOfQuestionToWhichItIsDisplayedBefore ===
							display.indexOfQuestionToWhichItIsDisplayedBefore
				);
				if (text && text.canBeSwitchedTo) return true;
				return false;
			},
			[props.textsInfo]
		);

		const onTextClick = React.useCallback(
			(display: display) => {
				if (display.type !== "text") return;
				if (!isTextAccessible(display)) return;
				onPageSelect(display);
			},
			[isTextAccessible, onPageSelect]
		);
		const onSpecialPageClick = React.useCallback(
			(display: display) => {
				onPageSelect(display);
			},
			[onPageSelect]
		);

		const getStyles = React.useCallback(() => {
			return mergeStylesObjects(
				props.styles || {},
				navStyles as NotUndefinedAtAll<ITestNavigationProps["styles"]>
			);
		}, [props.styles]);

		const getComponents = React.useCallback(() => {
			return mergeComponentObjects(
				props.components || {},
				defaultComponents
			);
		}, [props.components]);

		const styles = React.useMemo(getStyles, [getStyles]);
		const components = React.useMemo(getComponents, [getComponents]);

		const outerComponentStyles = classNames(
			styles.OuterContainer,
			props.testFinishState ? styles.IsFinished : undefined
		);

		const ItemContainer = components.itemComponent!.Container!;
		const InnerItemContainer = components.itemComponent!.InnerItemContainer;
		const ContainerBefore = components.ContainerBefore;
		const ContainerAfter = components.ContainerAfter;

		const common: Pick<
			ITestNavigationItemComponentProps,
			"styles" | "innerItemContainer" | "isTestFinished"
		> = {
			styles: styles.item,
			innerItemContainer: InnerItemContainer,
			isTestFinished: props.testFinishState === FinishState.saved,
		};

		const InnerComponent = props.displayItemsInline
			? Scrollbars
			: React.Fragment;
		const innerComponentProps = props.displayItemsInline
			? {
					autoHeight: true,
					style: { width: "100%" },
					ref: scrollBars,
					autoHide: true,
				}
			: undefined;

		return (
			<div className={outerComponentStyles}>
				{ContainerBefore && <ContainerBefore {...props} />}
				<InnerComponent {...innerComponentProps}>
					<div
						className={
							props.displayItemsInline
								? styles.ContainerWithScrollbar
								: styles.ContainerWithoutScrollbar
						}
					>
						{balls.map((ball, i) => {
							const innerRef = ballRefs[i];
							if (ball.type === "startPage") {
								return (
									<ItemContainer
										key="startPage"
										{...common}
										innerRef={innerRef}
										display={ball}
										isAccessible={canClickStartPage}
										isSelected={selectedBallIndex === i}
										onClick={onStartPageClick}
									/>
								);
							}
							if (ball.type === "finishPage") {
								return (
									<ItemContainer
										key="Finish"
										{...common}
										innerRef={innerRef}
										display={ball}
										isAccessible={canClickFinishPage}
										isSelected={selectedBallIndex === i}
										onClick={onFinishPageClick}
									/>
								);
							}
							if (ball.type === "text") {
								return (
									<ItemContainer
										key={i}
										{...common}
										innerRef={innerRef}
										display={ball}
										isAccessible={isTextAccessible(ball)}
										isSelected={selectedBallIndex === i}
										onClick={onTextClick}
									/>
								);
							}
							if (ball.type === "specialPage") {
								return (
									<ItemContainer
										key={i}
										{...common}
										innerRef={innerRef}
										display={ball}
										isAccessible={true}
										isSelected={selectedBallIndex === i}
										onClick={onSpecialPageClick}
									/>
								);
							}
							if (ball.type === "question") {
								const qInfo = props.questionsInfo[ball.index];
								const additional: Partial<ITestNavigationItemComponentProps> =
									{
										hasAnswered: false,
									};
								if (!qInfo.isUnknown && qInfo.userAnswerInfo) {
									const userAnswerInfo = qInfo.userAnswerInfo;
									additional.hasAnsweredFully =
										userAnswerInfo.isFullyAnswered;
									additional.hasAnswered =
										userAnswerInfo.hasAnswered;
									additional.containsGradableItemsByEditor =
										qInfo.numOfGradableItemsByEditor > 0;
									if (
										userAnswerInfo.credit !== undefined &&
										userAnswerInfo.maxCredit !== undefined
									) {
										if (
											(userAnswerInfo.submitted &&
												props.showAnswersOfSubmittedQuestions) ||
											(!userAnswerInfo.submitted &&
												props.showAnswersOfUnsubmittedQuestions)
										) {
											additional.hasAnsweredCorreclty =
												userAnswerInfo.credit >=
												userAnswerInfo.maxCredit;
											additional.hasAnsweredIncorreclty =
												userAnswerInfo.credit === 0;
											additional.hasAnsweredPartiallyCorreclty =
												userAnswerInfo.credit > 0 &&
												userAnswerInfo.credit <
													userAnswerInfo.maxCredit;
										}
									}
								}
								return (
									<ItemContainer
										key={i}
										{...common}
										innerRef={innerRef}
										display={ball}
										isAccessible={qInfo.canBeSwitchedTo}
										isSelected={selectedBallIndex === i}
										onClick={onQuestionClick}
										{...additional}
									/>
								);
							}
							return null;
						})}
					</div>
				</InnerComponent>
				{ContainerAfter && <ContainerAfter {...props} />}
			</div>
		);
	}
);
TestNavigation.displayName = "TestNavigation";

export interface ITestNavigationItemStyles {
	container?: string;
	isAccessible?: string;
	isNotAccessible?: string;
	isSelected?: string;
	isStartPage?: string;
	isFinishPage?: string;
	containsGradableItemsByEditor?: string;
	isQuestion?: string;
	isText?: string;
	hasAnswered?: string;
	hasAnsweredFully?: string;
	hasAnsweredCorreclty?: string;
	hasAnsweredPartiallyCorreclty?: string;
	hasAnsweredIncorreclty?: string;
}

export interface IInnerItemContainerProps {
	display: display;
	isAccessible: boolean;
	isSelected: boolean;
	containsGradableItemsByEditor?: boolean;
	hasAnswered?: boolean;
	hasAnsweredFully?: boolean;
	hasAnsweredCorreclty?: boolean;
	hasAnsweredPartiallyCorreclty?: boolean;
	hasAnsweredIncorreclty?: boolean;
	isTestFinished: boolean;
}

const itemStyles = {
	container: navigationStyles.itemContainer,
	isFinishPage: navigationStyles.finishItem,
	isStartPage: navigationStyles.startItem,
	isSelected: navigationStyles.isSelected,
	containsGradableItemsByEditor:
		navigationStyles.containsGradableItemsByEditor,
	isAccessible: navigationStyles.isAccessible,
	isNotAccessible: navigationStyles.isNotAccessible,
	hasAnswered: navigationStyles.hasAnswered,
	hasAnsweredCorreclty: navigationStyles.hasAnsweredCorreclty,
	hasAnsweredPartiallyCorreclty:
		navigationStyles.hasAnsweredPartiallyCorreclty,
	hasAnsweredIncorreclty: navigationStyles.hasAnsweredIncorreclty,
} as NotUndefinedAtAll<ITestNavigationItemStyles>;

export type NavigationContainerNearProps = Pick<
	ITestNavigationProps,
	"currentDisplay"
>;

interface ITestNavigationComponents {
	ContainerBefore?: AnyComponent<NavigationContainerNearProps>;
	ContainerAfter?: AnyComponent<NavigationContainerNearProps>;
	itemComponent?: {
		Container?: AnyComponent<ITestNavigationItemComponentProps>;
		InnerItemContainer?: AnyComponent<IInnerItemContainerProps>;
	};
}

interface ITestNavigationItemComponentProps extends IInnerItemContainerProps {
	onClick: (display: display) => void;
	styles?: ITestNavigationItemStyles;
	innerItemContainer?: AnyComponent<IInnerItemContainerProps>;
	innerRef: React.RefObject<HTMLElement>;
}
const TestNavigationItemComponent: React.FC<ITestNavigationItemComponentProps> =
	React.memo((props) => {
		const getStyles = React.useCallback(() => {
			return mergeComponentObjects(
				props.styles as NotUndefinedAtAll<ITestNavigationItemStyles>,
				itemStyles
			);
		}, [props.styles]);

		const styles = getStyles();
		const classes = classNames(
			styles.container,
			props.isAccessible ? styles.isAccessible : styles.isNotAccessible,
			props.isSelected ? styles.isSelected : undefined,
			props.display.type === "startPage" ? styles.isStartPage : undefined,
			props.display.type === "finishPage"
				? styles.isFinishPage
				: undefined,
			props.display.type === "question" ? styles.isQuestion : undefined,
			props.containsGradableItemsByEditor
				? styles.containsGradableItemsByEditor
				: undefined,
			props.display.type === "text" ? styles.isText : undefined,
			props.hasAnswered ? styles.hasAnswered : undefined,
			props.hasAnsweredFully ? styles.hasAnsweredFully : undefined,
			props.hasAnsweredCorreclty
				? styles.hasAnsweredCorreclty
				: undefined,
			props.hasAnsweredPartiallyCorreclty
				? styles.hasAnsweredPartiallyCorreclty
				: undefined,
			props.hasAnsweredIncorreclty
				? styles.hasAnsweredIncorreclty
				: undefined
		);
		const Component =
			props.innerItemContainer || TestNavigationInnerItemComponent;
		const containerProps = removeKeys(props, "styles", "onClick");
		return (
			<div
				ref={props.innerRef as React.RefObject<HTMLDivElement>}
				className={classes}
				onClick={() => {
					props.onClick(props.display);
				}}
			>
				<Component {...containerProps} />
			</div>
		);
	});
TestNavigationItemComponent.displayName = "TestNavigationItemComponent";

export const TestNavigationInnerItemComponent: React.FC<IInnerItemContainerProps> =
	React.memo((props) => {
		switch (props.display.type) {
			case "startPage":
				return (
					<span>
						<PlayArrowIcon />
					</span>
				);
			case "finishPage":
				return (
					<span>
						<FlagIcon />
					</span>
				);
			case "text":
				return <span>Text</span>;
			case "question":
				return <span>{props.display.index + 1}</span>;
		}
		return null;
	});
TestNavigationInnerItemComponent.displayName =
	"TestNavigationInnerItemComponent";

const defaultComponents = {
	itemComponent: {
		Container: TestNavigationItemComponent,
		InnerItemContainer: TestNavigationInnerItemComponent,
	},
} as ITestNavigationComponents;

const getInsertedSpecialPages = (
	balls: display[],
	specialPages: TestSpecialPage[],
	selectedBallIndex: number
): { balls: display[]; selectedBallIndex: number } => {
	const newBalls = [...balls];
	for (const page of specialPages) {
		const index = newBalls.findIndex((e) => deepEqual(e, page.display));
		if (index === -1) continue;
		const insertBefore = page.location === "after" ? index + 1 : index;
		if (insertBefore <= selectedBallIndex) {
			selectedBallIndex++;
		}
		newBalls.splice(insertBefore, 0, {
			type: "specialPage",
			pageId: page.pageId,
		});
	}
	return { balls: newBalls, selectedBallIndex };
};
