import Joi from "../../../../utils/joi";
import {
	CommonQuestionPartsSchema,
	ContentType,
	forbiddenProperties,
	IStatement,
	StatementSchema,
	ICommonQuestionParts,
	forbiddenProperty,
} from "../common-schemas";
import { IAnyObj } from "../../../../utils";
import { markKeysForbidden } from "../../../../schemas/helper";

export enum MCContentDesignStructure {
	twoColumns = "2Cols",
	dataSufficiency = "DataSufficiency",
}

const MCContentDesignStructures = [
	MCContentDesignStructure.twoColumns,
	MCContentDesignStructure.dataSufficiency,
];

export const MultipleChoiceContentSchema = CommonQuestionPartsSchema.keys({
	type: Joi.number().valid(ContentType.MultipleChoice).required(),
	statement: StatementSchema.unknown(true).required(),
	choices: Joi.array()
		.items(
			StatementSchema.keys({
				score: Joi.number().optional(),
			})
		)
		.required(),
	canSelectMultiple: Joi.boolean(),
	disableShuffle: Joi.boolean(),
	designStructure: Joi.string().valid(...MCContentDesignStructures),
}).unknown(true);
export interface IMultipleChoiceContent extends ICommonQuestionParts {
	type: ContentType.MultipleChoice;
	statement: IStatement & IAnyObj;
	choices: (IStatement & {
		score?: number;
	})[];
	canSelectMultiple?: boolean;
	disableShuffle?: boolean;
	designStructure?: MCContentDesignStructure;
}

export const RMultipleChoiceContentSchema = MultipleChoiceContentSchema.fork(
	[...forbiddenProperties, "choices"],
	markKeysForbidden
).keys({
	choices: Joi.array().items(StatementSchema).required(),
});
export type IRMultipleChoiceContent = Omit<
	IMultipleChoiceContent,
	forbiddenProperty | "choices"
> & {
	choices: IStatement[];
};

export const MultipleChoiceUserAnsSchema = Joi.alternatives(
	Joi.array().items(Joi.number()).allow(null),
	Joi.number().allow(null)
);
export type IMultipleChoiceUserAns = number | number[] | null;
