import { useHeaderThemeSetter } from "@app/components/general-context";
import { useWindowSize } from "@app/hooks/front";
import { HeaderHider } from "@app/routes/containers/common";
import React from "react";
import BrandLogo from "../general_components/logo";
import genStyles from "../general_components/styles/general_styles.module.css";
import { RightSide } from "./right-side";
import { TeacherLeftSide } from "./teacher-left-side";

const TeacherProfile: React.FC<{}> = (props) => {
	const { width } = useWindowSize();
	const isMobile = width <= 1000;
	useHeaderThemeSetter("white");
	return (
		<section className={genStyles.profile_container}>
			<HeaderHider hideHeader={!isMobile} />
			{!isMobile && <BrandLogo />}
			<TeacherLeftSide />
			<RightSide />
		</section>
	);
};

export default TeacherProfile;
