/* eslint-disable max-lines-per-function */
/* eslint-disable max-params */
import React from "react";
import classnames from "classnames";
import { pickHTMLProps } from "react-sanitize-dom-props";

type Styles = React.CSSProperties | undefined | null | boolean;

export interface ClassNamedFn {
	<EL extends keyof JSX.IntrinsicElements>(
		element: EL,
		propName?: "className"
	): ClassNamedMainFn<{}, JSX.IntrinsicElements[EL]>;
	<EL extends keyof JSX.IntrinsicElements>(
		element: EL,
		propName: "style"
	): ClassNamedMainFn<{}, JSX.IntrinsicElements[EL], {}, Styles>;
}

type mode = "add" | "subtract";

const mapWithMode =
	<mode extends any>(mode: mode) =>
	<T extends any>(value: T) => ({ mode, value });

interface PropDecorator {
	priority: boolean;
	fn: (props: any) => any;
}

export interface ClassNamedTransformationFn {
	(args: {
		element: any;
		rawProps: Record<any, any>;
		modifiedProps: Record<any, any>;
		finalProps: Record<any, any>;
	}): {
		element: any;
		finalProps: Record<any, any>;
		modifiedProps?: Record<any, any>;
	};
}

// eslint-disable-next-line max-lines-per-function
const classNamedRaw = <EL extends keyof JSX.IntrinsicElements>(
	element: EL,
	propName: "className" | "style" = "className",
	defPropsDecorators?: PropDecorator[],
	name?: { value: string; permanent: boolean },
	defClassNames?: any[],
	defStyles?: any[],
	transformationFn?: ClassNamedTransformationFn
): ClassNamedMainFn<any, any, any> => {
	const orign = (mode: mode, ...args: any[]): any => {
		const { unifiedStyles, unifiedClassNames, unifiedPropsDecorators } =
			getUnified(
				propName,
				mode,
				args,
				defPropsDecorators,
				defClassNames,
				defStyles
			);
		const Comp = React.forwardRef((props: any, ref: any) => {
			let modifiedProps = getModifiedProps(
				{ ...props, ref },
				defPropsDecorators
			);
			const finalClassName = combineAllClassNames(
				unifiedClassNames,
				modifiedProps
			);
			const finalStyle = combineAllStyles(unifiedStyles, modifiedProps);
			let finalProps = {
				ref: modifiedProps.ref,
				...pickHTMLProps(modifiedProps, false),
				className: finalClassName,
				style: finalStyle,
			};
			if (transformationFn) {
				({ element, finalProps, modifiedProps } = transformationFn({
					element,
					rawProps: props,
					finalProps,
					modifiedProps,
				}) as any);
				if (!modifiedProps) {
					modifiedProps = finalProps;
				}
			}
			return React.createElement(element, finalProps);
		}) as any as ClassNamed<any, any>;
		if (name) {
			Comp.displayName = name.value;
		}
		const normalizedName = name && name.permanent ? name : undefined;
		Comp.extendClassNamed = classNamedRaw(
			element,
			"className",
			unifiedPropsDecorators,
			normalizedName,
			unifiedClassNames,
			unifiedStyles,
			transformationFn
		);
		Comp.extendStyled = classNamedRaw(
			element,
			"style",
			unifiedPropsDecorators,
			normalizedName,
			unifiedClassNames,
			unifiedStyles,
			transformationFn
		);
		Comp.extendProps = (fn) => {
			return classNamedRaw(
				element,
				propName,
				[...(unifiedPropsDecorators || []), { priority: true, fn }],
				normalizedName,
				unifiedClassNames,
				unifiedStyles,
				transformationFn
			)(...args);
		};
		Comp.extendDefaultProps = (fn) => {
			return classNamedRaw(
				element,
				propName,
				[...(unifiedPropsDecorators || []), { priority: false, fn }],
				normalizedName,
				unifiedClassNames,
				unifiedStyles,
				transformationFn
			)(...args);
		};
		Comp.extendAs = (el) =>
			classNamedRaw(
				el,
				"className",
				unifiedPropsDecorators,
				normalizedName,
				unifiedClassNames,
				unifiedStyles,
				transformationFn
			)(...args);
		Comp.getElement = () => element;
		Comp.useClassName = (props = {}, omitDecorator = false) =>
			combineAllClassNames(
				unifiedClassNames,
				omitDecorator === true
					? props
					: getModifiedProps(props, unifiedPropsDecorators)
			);
		Comp.useStyle = (props = {}, omitDecorator = false) =>
			combineAllStyles(
				unifiedStyles,
				omitDecorator === true
					? props
					: getModifiedProps(props, unifiedPropsDecorators)
			);
		Comp.useProps = (props = {}) => {
			const modifiedProps = getModifiedProps(
				props,
				unifiedPropsDecorators
			);
			return {
				...modifiedProps,
				className: (Comp.useClassName as any)(modifiedProps, true),
				style: (Comp.useClassName as any)(modifiedProps, true),
			};
		};
		Comp.setName = (value: string) =>
			classNamedRaw(
				element,
				propName,
				unifiedPropsDecorators,
				{ value, permanent: true },
				unifiedClassNames,
				unifiedStyles,
				transformationFn
			)(...args);
		Comp.setTempName = (value: string) =>
			classNamedRaw(
				element,
				propName,
				unifiedPropsDecorators,
				{ value, permanent: false },
				unifiedClassNames,
				unifiedStyles,
				transformationFn
			)(...args);
		Comp.setTransformationFn = (fn: ClassNamedTransformationFn) =>
			classNamedRaw(
				element,
				propName,
				unifiedPropsDecorators,
				normalizedName,
				unifiedClassNames,
				unifiedStyles,
				fn
			)(...args);
		Comp.replaceTransformationFn = (
			oldFn: ClassNamedTransformationFn,
			newFn: ClassNamedTransformationFn
		) => {
			if (transformationFn === oldFn) {
				transformationFn = newFn;
			}
		};
		return Comp;
	};
	const mainFn = orign.bind(undefined, "add") as ClassNamedMainFn<
		any,
		any,
		any
	>;
	mainFn.delete = orign.bind(undefined, "subtract") as any;
	return mainFn;
};

export const isClassNamedElement = (
	element: any
): element is { type: ClassNamed<{}, {}, {}> } => {
	if (!element || !element.type) return false;
	const comp = element.type;
	return (
		typeof comp.setTransformationFn === "function" &&
		typeof comp.setTempName === "function" &&
		typeof comp.extendAs === "function" &&
		typeof comp.extendProps === "function" &&
		typeof comp.extendStyled === "function" &&
		typeof comp.extendClassNamed === "function"
	);
};

export const classNamed: ClassNamedFn = classNamedRaw;

const getUnified = (
	propName: "className" | "style" = "className",
	mode: mode,
	args: any[],
	defPropsDecorators?: PropDecorator[],
	defClassNames?: any[],
	defStyles?: any[]
) => {
	let unifiedClassNames: { mode: mode; value: any }[] | undefined = undefined;
	let unifiedStyles: { mode: mode; value: any }[] | undefined = undefined;
	const withMode = args.map(mapWithMode(mode));
	if (propName === "className") {
		unifiedClassNames = defClassNames
			? defClassNames.concat(withMode)
			: withMode;
		unifiedStyles = defStyles;
	} else if (propName === "style") {
		unifiedClassNames = defClassNames;
		unifiedStyles = defStyles ? defStyles.concat(withMode) : withMode;
	}
	return {
		unifiedStyles,
		unifiedClassNames,
		unifiedPropsDecorators: defPropsDecorators,
	};
};

const getModifiedProps = <T extends Record<any, any>>(
	props: T,
	defPropsDecorators: PropDecorator[] | undefined
): T => {
	if (!defPropsDecorators || defPropsDecorators.length === 0) return props;
	let modifiedProps = props;
	for (const dec of defPropsDecorators) {
		const extraProps = dec.fn(modifiedProps);
		if (dec.priority) {
			modifiedProps = { ...modifiedProps, ...extraProps };
		} else {
			modifiedProps = { ...extraProps, ...modifiedProps };
		}
	}
	return modifiedProps;
};

const combineAllClassNames = (
	unifiedClassNames: { mode: mode; value: any }[] | undefined,
	props: Record<any, any>
): string => {
	if (!unifiedClassNames || !unifiedClassNames.length) {
		return props.className;
	}
	let combinedClassNames = "";
	for (const cls of unifiedClassNames) {
		const clsName = classnames(
			typeof cls.value === "function" ? cls.value(props) : cls.value
		);
		if (!clsName) continue;
		if (cls.mode === "subtract") {
			combinedClassNames = subtractClassNamesTo(
				combinedClassNames,
				clsName
			);
		} else {
			combinedClassNames += " ";
			combinedClassNames += clsName;
		}
	}
	combinedClassNames = classnames(combinedClassNames, props.className);
	return combinedClassNames;
};

const subtractClassNamesTo = (combinedClassNames: string, clsName: string) => {
	clsName.split(" ").forEach((each) => {
		if (!each) return;
		combinedClassNames = combinedClassNames.replace(
			new RegExp("(?:^|\\s)" + each + "(?!\\S)", "g"),
			""
		);
	});
	return combinedClassNames;
};

const combineAllStyles = (
	unifiedStyles: { mode: mode; value: any }[] | undefined,
	props: Record<any, any>
): React.CSSProperties | undefined => {
	if (!unifiedStyles || !unifiedStyles.length) {
		return props.style;
	}
	const combinedStyles: Record<any, any> = {};
	for (const stl of unifiedStyles) {
		const style =
			typeof stl.value === "function" ? stl.value(props) : stl.value;
		if (!style || (stl.mode === "add" && typeof style !== "object")) {
			continue;
		}
		if (stl.mode === "subtract") {
			deleteKeys(
				combinedStyles,
				typeof style === "string" ? [style] : style
			);
		} else {
			Object.assign(combinedStyles, style);
		}
	}
	Object.assign(combinedStyles, props.style);
	return combinedStyles;
};

const deleteKeys = (obj: Record<any, any>, keys: string[]) => {
	for (const key of keys) {
		delete obj[key];
	}
};

type ClassArray = Array<ClassValue>; // tslint:disable-line no-empty-interface

interface ClassDictionary {
	[id: string]: any;
}

type ClassValue =
	| string
	| number
	| ClassDictionary
	| ClassArray
	| undefined
	| null
	| boolean;

type StyleKeys = keyof React.CSSProperties;

export interface ClassNamedMainFn<
	PassableProps extends Record<any, any> = {},
	Props extends Record<any, any> = React.HTMLProps<any>,
	InnerProps extends Record<any, any> = {},
	Arg = ClassValue,
> {
	<ExtraProps extends unknown>(
		...classNames: (
			| Arg
			| ((
					extraProps: ExtraProps & Props & PassableProps & InnerProps
			  ) => Arg)
		)[]
	): ClassNamed<ExtraProps & PassableProps, Props, InnerProps>;
	(
		...classNames: (Arg | (() => Arg))[]
	): ClassNamed<PassableProps, Props, InnerProps>;
	(...classNames: Arg[]): ClassNamed<PassableProps, Props, InnerProps>;
	InnerProps;
	delete: ClassNamedMainFn<
		PassableProps,
		Props,
		InnerProps,
		Arg extends Styles
			? Styles extends Arg
				? StyleKeys | StyleKeys[]
				: Arg
			: Arg
	>;
}

export type ClassNamed<
	PassableProps extends Record<any, any> = {},
	Props = React.HTMLProps<any>,
	InnerProps extends Record<any, any> = {},
> = React.ComponentType<Props & PassableProps> & {
	extendClassNamed: ClassNamedMainFn<PassableProps, Props, InnerProps>;
	extendStyled: ClassNamedMainFn<PassableProps, Props, InnerProps, Styles>;
	extendAs: <EL extends keyof JSX.IntrinsicElements>(
		element: EL
	) => ClassNamed<PassableProps, JSX.IntrinsicElements[EL], InnerProps>;
	extendProps: ExtendClassNamedProps<PassableProps, Props, InnerProps>;
	extendDefaultProps: ExtendClassNamedProps<PassableProps, Props, InnerProps>;
	getElement: () => keyof JSX.IntrinsicElements;
	useClassName: (props: Props & PassableProps) => string | undefined;
	useStyle: (props: Props & PassableProps) => React.CSSProperties | undefined;
	useProps: UseCassNamedProps<Props & PassableProps & InnerProps>;
	setName: (name: string) => ClassNamed<PassableProps, Props, InnerProps>;
	setTempName: (name: string) => ClassNamed<PassableProps, Props, InnerProps>;
	setTransformationFn: (
		fn: ClassNamedTransformationFn
	) => ClassNamed<PassableProps, Props, InnerProps>;
	replaceTransformationFn: (
		oldFn: ClassNamedTransformationFn,
		newFn: ClassNamedTransformationFn
	) => void;
};

export interface ExtendClassNamedProps<
	PassableProps extends Record<any, any> = {},
	Props = React.HTMLProps<any>,
	InnerProps extends Record<any, any> = {},
> {
	<Extra extends Record<any, any> = {}>(
		fn: (
			props: Props & PassableProps & InnerProps
		) => Partial<Props & PassableProps & InnerProps> & Extra
	): ClassNamed<PassableProps, Props, InnerProps & Extra>;
}

type UseCassNamedProps<Pr extends Record<any, any>> =
	Pr extends React.HTMLProps<any>
		? {
				(): React.HTMLProps<any>;
				(props: Pr): Pr & React.HTMLProps<any>;
			}
		: (props: Pr) => Pr & React.HTMLProps<any>;
