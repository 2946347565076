import * as React from "react";
import memoizeOne from "memoize-one";
import {
	AnyComponent,
	NotUndefined,
	NotUndefinedAtAll,
	AnyComponentOptionalProps,
} from "../../../../utils/generics";
import {
	Choice,
	IChoiceComponents,
	IChoiceProps,
	IChoiceStyles,
	MCChoice,
} from "./choices";
import {
	Explanation,
	IExplanationProps,
	IExplanationStyles,
	IExplanationTexts,
} from "../common/explanation";
import { IContentProps } from "../schemas";
import {
	IItemContainerProps,
	IItemTextProps,
	ItemContainer,
	ItemText,
} from "../common/stats";
import {
	IMultipleChoiceContent,
	IMultipleChoiceUserAns,
	IRMultipleChoiceContent,
	MCContentDesignStructure,
} from "../../../../schemas/questions/contnets/multiple-choice/schema";
import {
	mergeComponentObjects,
	mergeStylesObjects,
	IAnyObj,
} from "../../../../utils";
import { shuffleArrayByKey } from "@tests-core/utils/shuffle";
import { css } from "emotion";

export interface IMCPassableProps {
	styles?: IMCContainerStyles;
	components?: IMCContentComponents;
	texts?: ITexts;
}

export type IMCContentComponents = IMCComponents & {
	container?: AnyComponent<IMultipleChoiceContentContProps>;
};
export type IMCContainerStyles = IMCStyles & {
	container?: string;
};

interface ITexts {
	explanation?: IExplanationTexts;
}

export interface IProps extends IContentProps<IMultipleChoiceUserAns> {
	content: IRMultipleChoiceContent | IMultipleChoiceContent;
	shuffleKey?: number;
	styles?: IMCContainerStyles;
	components?: IMCContentComponents;
	texts?: ITexts;
}

class MultipleChoiceContainer extends React.PureComponent<IProps> {
	defaultComponents = {
		container:
			MultipleChoiceContentCont as AnyComponent<IMultipleChoiceContentContProps>,
	};

	getComponents = memoizeOne((components: IProps["components"]) => {
		return mergeComponentObjects(components || {}, this.defaultComponents);
	});

	onChoiceCheck = (choiceId: number) => {
		if (this.props.content.canSelectMultiple) {
			const userAnswer = (this.props.userAnswer || []) as number[];
			const newAnswer =
				userAnswer.indexOf(choiceId) > -1
					? userAnswer.filter((e) => e !== choiceId)
					: [...userAnswer, choiceId];
			this.props.onUserAnswerChange(newAnswer);
		} else {
			this.props.onUserAnswerChange(choiceId);
		}
	};

	render() {
		const components = this.getComponents(this.props.components);
		const QContainer = components.container;
		return (
			<div className={this.props.styles?.container}>
				<QContainer
					{...this.props}
					onChoiceCheck={this.onChoiceCheck}
					components={components}
				/>
			</div>
		);
	}
}

export interface IMCStatementComponents {
	container?: AnyComponent<IItemContainerProps>;
	text?: AnyComponent<IItemTextProps>;
}

export interface IMCChoiceAfterProps {
	content?: IMultipleChoiceContentContProps["content"];
}

export interface IMCComponents {
	choices?: IMCChoicesComponents & {
		container?: AnyComponent<IMCChoicesContainer>;
		after?: AnyComponent<IMCChoiceAfterProps>;
	};
	statement?: IMCStatementComponents;
	explanation?: AnyComponent<IExplanationProps>;
}

interface IMCStyles {
	choices?: IChoiceStyles & {
		listContainer?: string;
	};
	statement?: {
		container?: string;
		text?: string;
		twoColumns?: ITwoColumnsStyles;
	};
	explanation?: IExplanationStyles;
}

export interface IMultipleChoiceContentContProps {
	content: IRMultipleChoiceContent | IMultipleChoiceContent;
	onChoiceCheck: (id: number) => void;
	userAnswer?: IMultipleChoiceUserAns;
	styles?: IMCStyles;
	components?: IMCComponents;
	checkOnlyOnCheckmark?: boolean;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	texts?: ITexts;
	shuffleKey?: number;
}

// tslint:disable-next-line:max-line-length
export class MultipleChoiceContentCont extends React.PureComponent<IMultipleChoiceContentContProps> {
	defaultComponents = memoizeOne(
		(designStructure?: MCContentDesignStructure) => {
			let statementContainerComponent: any = ItemContainer;
			if (designStructure === MCContentDesignStructure.twoColumns) {
				statementContainerComponent = TwoColumnsStatementContainer;
			}
			return {
				statement: {
					container: statementContainerComponent,
					text: ItemText,
				},
				choices: {
					container:
						MCChoicesContainer as AnyComponent<IMCChoicesContainer>,
				},
				explanation: Explanation,
			} as NotUndefined<IMCComponents>;
		}
	);

	getComponents = memoizeOne(
		(
			components: IMultipleChoiceContentContProps["components"],
			designStructure?: MCContentDesignStructure
		) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents(designStructure)
			);
		}
	);
	defaultStyles = {
		choices: {},
		statement: {},
		explanation: {},
	} as NotUndefinedAtAll<IMCStyles>;
	getStyles = memoizeOne(
		(styles: IMultipleChoiceContentContProps["styles"]) => {
			return mergeStylesObjects(styles || {}, this.defaultStyles);
		}
	);

	render() {
		const components = this.getComponents(
			this.props.components,
			this.props.content.designStructure
		);
		const styles = this.getStyles(this.props.styles);
		const StatementContainerComponent = components.statement!.container!;
		const ChoicesContainer = components.choices!.container!;
		const ExpContainer = components.explanation!;
		const ChoicesAfter = components.choices!.after;
		return (
			<div>
				<StatementContainerComponent
					index={0}
					statement={this.props.content.statement as any}
					components={components.statement}
					styles={styles.statement}
				/>
				<ChoicesContainer
					content={this.props.content}
					shuffleKey={this.props.shuffleKey}
					onChoiceCheck={this.props.onChoiceCheck}
					userAnswer={this.props.userAnswer}
					styles={this.props.styles}
					components={components.choices}
					checkOnlyOnCheckmark={this.props.checkOnlyOnCheckmark}
					displayAnswer={this.props.displayAnswer}
					disableEditingAnswer={this.props.disableEditingAnswer}
				/>
				{ChoicesAfter && <ChoicesAfter content={this.props.content} />}
				<ExpContainer
					explanation={
						(this.props.content as IMultipleChoiceContent)
							.explanation
					}
					show={!!this.props.displayAnswer}
					styles={styles.explanation}
					texts={this.props.texts && this.props.texts.explanation}
				/>
			</div>
		);
	}
}

export interface IMCChoicesComponents {
	innerChoice?: IChoiceComponents & {
		container?: AnyComponent<IChoiceProps>;
	};
}

export interface IMCChoicesContainer {
	content: IRMultipleChoiceContent | IMultipleChoiceContent;
	onChoiceCheck: (id: number) => void;
	userAnswer?: IMultipleChoiceUserAns;
	styles?: IMCStyles;
	components?: IMCChoicesComponents;
	checkOnlyOnCheckmark?: boolean;
	displayAnswer: boolean;
	disableEditingAnswer: boolean;
	shuffleKey?: number;
}

class MCChoicesContainer extends React.PureComponent<IMCChoicesContainer> {
	defaultComponents = {
		innerChoice: {
			container: Choice,
		},
	} as NotUndefined<IMCChoicesComponents>;

	getComponents = memoizeOne(
		(components: IMCChoicesContainer["components"]) => {
			return mergeComponentObjects(
				components || {},
				this.defaultComponents
			);
		}
	);

	defaultStyles = {
		choices: {},
	};

	getStyles = memoizeOne((styles: IMCChoicesContainer["styles"]) => {
		return mergeStylesObjects(styles || {}, this.defaultStyles);
	});

	isChecked = memoizeOne(
		(choiceId: number, userAnswer?: IMultipleChoiceUserAns) => {
			if (userAnswer === undefined || userAnswer === null) return false;
			if (typeof userAnswer === "number") return userAnswer === choiceId;
			return userAnswer.indexOf(choiceId) > -1;
		}
	);

	defaultShuffleKey = Math.floor(Math.random() * 1e7);

	render() {
		const { content, onChoiceCheck, userAnswer } = this.props;
		const styles = this.getStyles(this.props.styles);
		const components = this.getComponents(this.props.components);
		const ChoiceComponent = components.innerChoice!.container!;
		const choices = content.disableShuffle
			? content.choices
			: this.props.shuffleKey === undefined
				? shuffleArrayByKey(content.choices, this.defaultShuffleKey)
				: shuffleArrayByKey(content.choices, this.props.shuffleKey);
		return (
			<div className={styles.choices.listContainer}>
				{(choices as MCChoice[]).map((choice, i) => (
					<ChoiceComponent
						key={choice.id}
						index={i}
						choice={choice}
						onCheck={onChoiceCheck}
						checked={this.isChecked(choice.id, userAnswer)}
						isCorrect={
							!this.props.displayAnswer
								? undefined
								: (
										choice as IMultipleChoiceContent["choices"][number]
									).score! > 0
						}
						styles={styles.choices}
						components={components.innerChoice}
						checkOnlyOnCheckmark={this.props.checkOnlyOnCheckmark}
						canSelectMultiple={content.canSelectMultiple}
						displayAnswer={this.props.displayAnswer}
						disableEditingAnswer={this.props.disableEditingAnswer}
					/>
				))}
			</div>
		);
	}
}

type ITwoColumnsStatementContainerProps = IItemContainerProps & {
	statement: IAnyObj;
} & {
	styles: IMCStyles["statement"];
};

export interface ITwoColumnsStyles {
	container?: string;
	textsContainer?: string;
	ABSymbolsContainer?: string;
	AContainer?: string;
	BContainer?: string;
	MainTextContainer?: string;
	ABTextContainers?: string;
}

const ABTextContainersCSS = css`
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
	& > div {
		max-width: 50%;
		margin-left: 5px;
		margin-right: 5px;
		&:first-of-type {
			margin-left: 0px;
		}
		&:last-of-type {
			margin-right: 0px;
		}
	}
	@media screen and (max-width: 600px) {
		display: block;
		& > div {
			margin-left: 0px;
			margin-right: 0px;
		}
	}
`;

const MainTextContainerCSS = css`
	text-align: center;
	& > div {
		margin-left: auto;
		margin-right: auto;
	}
`;

const ABSymbolsContainerCSS = css`
	border: 1px solid #000;
	display: flex;
	margin-bottom: 5px;
	text-align: center;
	& > div {
		flex: 1;
		text-align: center;
		&:first-of-type {
			border-right: 1px solid #000;
		}
	}
	@media screen and (max-width: 600px) {
		display: none;
	}
`;

const textsContainerCSS = css`
	border: 1px solid #000;
	padding: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
`;

const singleContainerCSS = css`
	border: 1px solid black;
	padding: 5px;
`;

class TwoColumnsStatementContainer extends React.PureComponent<ITwoColumnsStatementContainerProps> {
	defaultStyles = {
		twoColumns: {
			container: "",
			textsContainer: textsContainerCSS,
			ABSymbolsContainer: ABSymbolsContainerCSS,
			ABTextContainers: ABTextContainersCSS,
			MainTextContainer: MainTextContainerCSS,
			AContainer: singleContainerCSS,
			BContainer: singleContainerCSS,
		},
	} as NotUndefined<ITwoColumnsStatementContainerProps["styles"]>;
	getStyles = memoizeOne(
		(styles: ITwoColumnsStatementContainerProps["styles"]) => {
			return mergeStylesObjects(styles || {}, this.defaultStyles);
		}
	);

	render() {
		const styles = this.getStyles(this.props.styles);
		const statement1 = { ...this.props.statement, isColMainText: true };
		const statementA = {
			...this.props.statement,
			text: this.props.statement.textA,
			isColAText: true,
		};
		const statementB = {
			...this.props.statement,
			text: this.props.statement.textB,
			isColBText: true,
		};
		return (
			<div className={styles.twoColumns!.container}>
				<div className={styles.twoColumns!.ABSymbolsContainer}>
					<div>A</div>
					<div>B</div>
				</div>
				<div className={styles.twoColumns!.textsContainer}>
					<div className={styles.twoColumns!.MainTextContainer}>
						<ItemContainer
							{...this.props}
							styles={styles}
							statement={statement1}
							extraInfo={{ isColMainText: true }}
						/>
					</div>
					<div className={styles.twoColumns!.ABTextContainers}>
						<div className={styles.twoColumns!.AContainer}>
							<ItemContainer
								{...this.props}
								styles={styles}
								statement={statementA}
								extraInfo={{ isColAText: true }}
							/>
						</div>
						<div className={styles.twoColumns!.BContainer}>
							<ItemContainer
								{...this.props}
								styles={styles}
								statement={statementB}
								extraInfo={{ isColBText: true }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MultipleChoiceContainer;
