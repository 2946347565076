import Joi from "../../../utils/joi";

export enum ContentType {
	MultipleChoice = 1,
	TwoColumns = 2,
	SortItems = 3,
	CorrectionOfText = 4,
	GroupingItems = 5,
	FillingBlanks = 6,
	MultipleContents = 7,
	Text = 8,
}
export const ContentTypesFields: ContentType[] = [
	ContentType.MultipleChoice,
	ContentType.TwoColumns,
	ContentType.SortItems,
	ContentType.CorrectionOfText,
	ContentType.GroupingItems,
	ContentType.FillingBlanks,
	ContentType.MultipleContents,
];

///

export const StatementSchema = Joi.object({
	id: Joi.number().required(),
	text: Joi.string().allow("").required(),
});
export interface IStatement {
	id: number;
	text: string;
}

///

export const TextStatementSchema = StatementSchema.keys({
	type: Joi.number().valid(ContentType.Text).required(),
});
export interface ITextStatement extends IStatement {
	type: ContentType.Text;
}

///

export const CommonQuestionPartsSchema = Joi.object({
	type: Joi.number()
		.valid(...ContentTypesFields)
		.required(),
	explanation: StatementSchema,
	allowPartialCredit: Joi.boolean(),
	minScoreForCredit: Joi.number().allow(null),
});
export interface ICommonQuestionParts {
	type: ContentType;
	explanation?: IStatement;
	allowPartialCredit?: boolean;
	minScoreForCredit?: number | null;
}

///

export const contentCommonPartNames: (keyof ICommonQuestionParts)[] = [
	"type",
	"explanation",
	"allowPartialCredit",
	"minScoreForCredit",
];

export type forbiddenProperty = "explanation" | "minScoreForCredit";
export const forbiddenProperties: forbiddenProperty[] = [
	"explanation",
	"minScoreForCredit",
];
