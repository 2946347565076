import {
	IUserTaskTypeLevel,
	UserTaskTypeLevelSchema as OriginalUserTaskTypeLevelSchema,
} from "@app/api/task-types/helper-schemas";
import { store } from "index";
import {
	getDefaultStorageSettings,
	getDefaultReducer,
	filterByLoadTime,
	loadFromStorage,
	listenToLocalStorageChange,
} from "m-model-common";
import { getJoiObjectKeys, validateStorage } from "m-model-joi";
import { createModel, RawInstances, createCRUDActionTypes } from "m-model-core";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CourseCommonMetaInfo } from "./meta-info";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

const UserTaskTypeLevelSchema = OriginalUserTaskTypeLevelSchema.keys({
	needsRecalculation: Joi.boolean(),
});

const keyOfId = "_id";
type IdKey = typeof keyOfId;
type DOC = IUserTaskTypeLevel & { needsRecalculation?: boolean };
export type IStateUserTaskTypeLevels = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(UserTaskTypeLevelSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("userTaskTypeLevel");
const storageSettings = getDefaultStorageSettings("userTaskTypeLevels");
const metaInformationName = "userTaskTypeLevelsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().userTaskTypeLevels) as any,
	dispatch: ((action) => store.dispatch(action)) as any,
	subscribe: ((listener) => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage(
				"ObjectId",
				UserTaskTypeLevelSchema
			),
			filter: isLoadedRecentlyEnough,
		}),
	indices: [{ fields: ["userId", "courseId"], unique: true }],
});

// ==============Main Model=================

export class UserTaskTypeLevel extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	getLevel(taskTypeId: ObjectId) {
		return this.levelsByTaskTypeId[taskTypeId];
	}

	getTotalLevel(taskTypeId: ObjectId): number | null;
	getTotalLevel(taskTypeId: ObjectId, defaultValue: number): number;
	getTotalLevel(taskTypeId: ObjectId, defaultValue?: number): number | null {
		const level = this.levelsByTaskTypeId[taskTypeId];
		if (level) {
			return level.totalLevel;
		}
		if (defaultValue) return defaultValue;
		return null;
	}

	static meta = new CourseCommonMetaInfo(storage, metaInformationName);
}

// ==============ETC=================

listenToLocalStorageChange(
	storage,
	metaInformationName,
	UserTaskTypeLevel.meta
);

export const userTaskTypeLevelsReducer = getDefaultReducer(
	storageSettings,
	() => UserTaskTypeLevel
);
