import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { AnyRoute } from "../../routes/interfaces";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import publicRoutes from "../../routes/public/routes";
import adminRoutes from "../../routes/admin/routes";
import studentRoutes from "@app/routes/student/routes";
import teacherRoutes from "@app/routes/teacher/routes";
import headmasterRoutes from "@app/routes/headmaster/routes";
import parentRoutes from "@app/routes/parent/routes";

const combinedRoutes: AnyRoute[] = publicRoutes
	.toArray()
	.concat(adminRoutes.toArray())
	.concat(studentRoutes.toArray())
	.concat(teacherRoutes.toArray())
	.concat(headmasterRoutes.toArray())
	.concat(parentRoutes.toArray());

export const MainLayout: React.FC<any> = React.memo(
	({ location, match, history }) => {
		const mapRoute = (route: AnyRoute, i: number) => {
			return getRoute(route, i, {
				location,
			});
		};
		return (
			<div>
				<Suspense fallback={<FancyLoadingCenter />}>
					{combinedRoutes.filter((e) => e.avoidSwitch).map(mapRoute)}
					<Switch>
						{combinedRoutes
							.filter((e) => !e.avoidSwitch)
							.map(mapRoute)}
					</Switch>
				</Suspense>
			</div>
		);
	}
);

export const getRoute = (
	route: AnyRoute,
	key: number | string,
	routeProps: any
): JSX.Element => {
	if (!route.$switch) {
		const RouteComponent = route.RouteComponent;
		return (
			<RouteComponent
				key={key}
				path={route.path}
				exact={route.exact}
				component={route.component}
				componentProps={route.componentProps}
				route={route}
				{...route.routeProps}
				{...routeProps}
			/>
		);
	}

	const mapChild = (child: AnyRoute, i: number) => {
		return getRoute(child, i, routeProps);
	};
	return (
		<>
			{route.$switch.filter((e) => e.avoidSwitch).map(mapChild)}
			<Switch>
				{route.$switch.filter((e) => !e.avoidSwitch).map(mapChild)}
			</Switch>
		</>
	);
};
