import React, { useState, useCallback, useContext, useEffect } from "react";
import { useBoolean } from "@app/hooks/general";
import { useLocale } from "@app/hooks/intl";
import { UserType } from "@app/api/helper-schemas";
import { useWindowSize } from "@app/hooks/front";
import { RegisterPopup } from "../../landing/register-type-selection";
import StudentRegisterStyles from "@app/components/auth/styles/student-register-page.module.css";
import Illustration from "../../tv-school-landing/styles/imgs/illustration.png";
import IllustrationNL from "../../tv-school-landing/styles/imgs/illustration-nl.png";
import styles from "./styles/register-landing.module.css";
import { useClassroomsUser } from "@app/hooks/users";
import { useHistory } from "react-router-dom";
import { inject } from "@app/modules";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { GeneralContext } from "@app/components/general-context";
import { LoginPopup } from "@app/components/landing/login";
import { UserInfo } from "@app/models/user-info";

export const RegistrationInviteLangingPage: React.FC<{
	invitationCode: string;
}> = ({ invitationCode }) => {
	const history = useHistory();
	const [teacher, setTeacher] = useState<UserInfo>();
	const user = useClassroomsUser();
	const { onChange: onGeneralContextChange } = useContext(GeneralContext);

	useEffect(() => {
		if (user) {
			const connectToCode = async () => {
				await inject("ClassroomEnrollmentCodesController")
					.connectToCode({
						codes: [invitationCode],
						shouldFulfill: true,
					})
					.then(() => {
						history.push("/");
					})
					.catch((e) =>
						openConfirmationPopup({
							text: getFormattedMessage("errorAlert"),
						})
					);
			};
			if (user.isStudent()) {
				connectToCode();
			} else {
				history.push("/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return onGeneralContextChange("authMetaData", (val) => {
			if (user) return val;
			return {
				...val,
				classroomEnrollmentCodes: [invitationCode],
			};
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onGeneralContextChange, invitationCode]);

	useEffect(() => {
		const fetchTeacherInfo = async () => {
			await inject("ClassroomEnrollmentCodesController")
				.getAuthorInfo({
					code: invitationCode,
				})
				.then((teacher) => {
					setTeacher(teacher);
				})
				.catch((e) =>
					openConfirmationPopup({
						text: getFormattedMessage("errorAlert"),
					})
				);
		};
		fetchTeacherInfo();
	}, [invitationCode]);

	console.log(teacher?.getFullName()); // TODO: render teacher name

	const {
		value: isRegistrationPopupOn,
		setFalse: closeRegistrationPopup,
		setTrue: openRegistrationPopup,
	} = useBoolean(false);

	const {
		value: isLoginPopupOn,
		setFalse: closeLoginPopup,
		setTrue: openLoginPopup,
	} = useBoolean(false);

	const locale = useLocale();
	const isGeo = locale === "ka";
	const isDifferentTitle = locale === "nl" || locale === "en";

	const [defaultSelectedUserType, setDefaultSelectedUserType] =
		useState<UserType>();

	const openLogin = useCallback(() => {
		closeRegistrationPopup();
		openLoginPopup();
	}, [closeRegistrationPopup, openLoginPopup]);

	const openRegisterPage = useCallback(
		(userType?: UserType) => {
			setDefaultSelectedUserType(userType);
			closeLoginPopup();
			openRegistrationPopup();
		},
		[closeLoginPopup, openRegistrationPopup]
	);

	const { width } = useWindowSize();
	const isResponsive = width <= 800;

	return (
		<div>
			<div
				className={
					isGeo ? styles.roleContainer : styles.roleContainerNL
				}
			>
				<div className={styles.roleTexts}>
					<div className={styles.landingTitle}>
						<h1
							className={styles.landingTitle__h1}
							style={{
								fontFamily: isDifferentTitle
									? "Dosis"
									: "Roboto Geo Nus",
								textAlign: "left",
								color: "#626a84",
							}}
						>
							პედაგოგთა ინიციატივით tvschool.ge დისტანციურ
							სასწავლო პროცესში ჩართულ მოსწავლეებს ელექტრონული
							სერტიფიკატებით აჯილდოვებს.
						</h1>
						<h1
							className={styles.landingTitle__h1}
							style={{
								fontFamily: isDifferentTitle
									? "Dosis"
									: "Roboto Geo Nus",
								color: "#626a84",
								textAlign: "left",
							}}
						>
							მასწავლებლისგან სერტიფიკატების მისაღებად აუცილებელია
							მოსწავლე რეგისტრირებული იყოს tvschool.ge–ზე.
						</h1>
					</div>
					<div
						className={styles.areYouRegisteredNotifier}
						style={{ fontWeight: "bold" }}
					>
						<span className={styles.exclamation}>!</span>თუ არ ხარ
						რეგისტრირებული, დარეგისტრირდი
					</div>
					<div className={styles.areYouRegisteredNotifier}>
						<span className={styles.exclamation}>!</span>თუ ხარ
						რეგისტრირებული, გაიარე ავტორიზაცია
					</div>
					<div className={styles.authButtonsContainer}>
						<button
							className={styles.authButton}
							onClick={openLogin}
						>
							ავტორიზაცია
						</button>
						<button
							className={styles.authButton}
							onClick={() => openRegisterPage(UserType.student)}
						>
							რეგისტრაცია
						</button>
					</div>
				</div>

				{isResponsive && (
					<img
						src={isGeo ? Illustration : IllustrationNL}
						alt={isGeo ? "Illustration" : "IllustrationN"}
						className={styles.illustration}
					/>
				)}

				{isRegistrationPopupOn && (
					<RegisterPopup
						onClose={closeRegistrationPopup}
						isOnlyPopup={true}
						hideLogin={closeLoginPopup}
						isLoginPopupOpen={isLoginPopupOn}
						openLogin={openLogin}
						inputClassname={StudentRegisterStyles.inputInPopup}
						registrationButtonClassname={
							StudentRegisterStyles.registrationButtoInPopup
						}
						isCustomStylesInvissible={true}
						isRegisterIllustrationVissible={true}
						userType={defaultSelectedUserType}
						isCountryInputInvissible={true}
					/>
				)}
				{isLoginPopupOn && (
					<LoginPopup
						onClose={closeLoginPopup}
						hideRegisterPage={closeRegistrationPopup}
						isRegisterPageOpen={isRegistrationPopupOn}
						openRegisterPage={() =>
							openRegisterPage(UserType.student)
						}
						isIconVissible={false}
						isUpperTextVissible={true}
						inputClassname={StudentRegisterStyles.inputForLogin}
						isLowerMainQuestionVissible={true}
						placeholder=" "
					/>
				)}
			</div>
		</div>
	);
};
