import { useByTime } from "@app/components/widgets/calls";
import { SubWebsiteOrigin } from "@app/globals";
import { useSubWebsite } from "@app/hooks/bc";
import { useWindowSize } from "@app/hooks/front";
import { useClassroomsUser } from "@app/hooks/users";
import triggerEvent from "@app/utils/events";
import React, { useState } from "react";
import { isUserLightlyEligibleForEducationalFair } from "../popups/edu-fair/educational-fair";
import styles from "./styles/edu-fair.module.scss";

export const EduFairLine = React.memo<{}>(function EduFairLine({}) {
	const subWebsite = useSubWebsite();
	const user = useClassroomsUser();
	const { width } = useWindowSize();
	const useLong = width >= 1200;
	const text = useLong
		? "ამ წუთებში მიმდინარეობს განათლების საერთაშორისო გამოფენა, გამოიყენე შანსი გაეცნო ქართულ და უცხოურ უნივერსიტეტებს!"
		: "მიმდინარეობს განათლების გამოფენა";

	const onConfirm = () => {
		window.open("https://educationfair.ge/?111tt=", "__blank");
		triggerEvent({
			action: "Edu fair",
			category: "Notifier",
			label: "below header",
		});
	};

	const [isHappeningNow, setIsHappeningNow] = useState(() =>
		isWithinIntervalsx(new Date())
	);

	useByTime((date) => setIsHappeningNow(isWithinIntervalsx(date)), 10 * 1000);

	if (
		!isHappeningNow ||
		!isUserLightlyEligibleForEducationalFair(user) ||
		subWebsite !== SubWebsiteOrigin.main
	) {
		return null;
	}

	return (
		<div
			style={{ background: "#ffce00", cursor: "pointer" }}
			onClick={onConfirm}
		>
			<div className={styles.container}>
				<div className={styles.mainText}>
					<div className={styles.live} />
					<span>{text}</span>
				</div>
				<button>გადასვლა</button>
			</div>
		</div>
	);
});

const liveDateIntervals = [
	{
		start: new Date("2021-05-27 11:00:00"),
		end: new Date("2021-05-27 19:00:00"),
	},
	{
		start: new Date("2021-05-28 11:00:00"),
		end: new Date("2021-05-28 19:00:00"),
	},
];

const isWithinIntervalsx = (date: Date) => {
	return liveDateIntervals.some(
		(interval) =>
			date.getTime() >= interval.start.getTime() &&
			date.getTime() <= interval.end.getTime()
	);
};
