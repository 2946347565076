import { Course } from "@app/models/course";
import { Folder } from "@app/models/folder";
import { FolderHierarchy } from "@app/models/folder-hierarchy";
import { inject } from "@app/modules";
import { HierarchyToFoldersComparer } from "@app/services/hierarchy-info/folders/comparer";
import { ObjectId } from "@app/utils/generics";
import { collmise } from "collmise";
import { createModelCollmise } from "../collmise-helpers";
import { IRequest } from "../requests";
import {
	IADELETECourse,
	IAGETCourse,
	IAGETCourseFilteredContent,
	IAGETCourseHierarchy,
	IAGETCourseReminder,
	IAGETGetCourseFolders,
	IAGETManyCoursesByIds,
	IAPOSTCourseReminder,
	IAPOSTCreateCourse,
	IAPOSTUploadCourseMedia,
	IAPUTCourse,
	IAPUTCourseReminder,
	IRGETAllCourses,
	IRGETCourseFilteredContent,
	IRGETCourseFolders,
	IRGETCourseHierarchy,
	IRGETCourseReminder,
	IRPOSTCreateCourse,
	IRPOSTGetCourseMedia,
	IRPOSTUploadCourseMedia,
	RGETAllCoursesSchema,
	RGETCourseFilteredContentSchema,
	RGETCourseFoldersSchema,
	RGETCourseHierarchySchema,
	RGETCourseSchema,
	RGETManyCoursesByIdsSchema,
	RPOSTCreateCourseSchema,
	RPOSTGetCourseMediaSchema,
	RPOSTUploadCourseMediaSchema,
} from "./validators";

export class CoursesController {
	private readonly Request: IRequest;

	private readonly _CourseModel = inject("CourseModel");
	private readonly _FolderModel = inject("FolderModel");
	private readonly _FolderHierarchyModel = inject("FolderHierarchyModel");
	private readonly _TopicHierarchyModel = inject("TopicHierarchyModel");
	private readonly _TaskTypeHierarchyModel = inject("TaskTypeHierarchyModel");
	private readonly _TopicModel = inject("TopicModel");
	private readonly _TaskTypeModel = inject("TaskTypeModel");

	private coursePromises = createModelCollmise({
		model: this._CourseModel,
		name: "Course",
		getMany: (args) =>
			this.Request.send(
				"POST",
				"/api/courses/get-by-ids",
				{ ids: args } as IAGETManyCoursesByIds,
				null,
				{ responseSchema: RGETManyCoursesByIdsSchema }
			),
	});

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateCourse): Promise<IRPOSTCreateCourse> =>
		this.Request.send("POST", "/api/courses", args, null, {
			responseSchema: RPOSTCreateCourseSchema,
		}).then((data: IRPOSTCreateCourse) => {
			this._CourseModel.loadOneSync(data.course);
			this._FolderModel.loadOneSync(data.rootFolder);
			this._TopicModel.loadOneSync(data.rootTopic);
			this._TaskTypeModel.loadOneSync(data.rootTaskType);
			this._FolderHierarchyModel.loadOneSync(
				data.hierarchies.folderHierarchy
			);
			this._TopicHierarchyModel.loadOneSync(
				data.hierarchies.topicHierarchy
			);
			this._TaskTypeHierarchyModel.loadOneSync(
				data.hierarchies.taskTypeHierarchy
			);
			return data;
		});

	getById = async (
		args: IAGETCourse,
		loadFresh?: boolean
	): Promise<Course> => {
		return this.coursePromises
			.on(args._id)
			.fresh(loadFresh)
			.request(() =>
				this.Request.send("GET", "/api/courses/:_id", args, null, {
					responseSchema: RGETCourseSchema,
				})
			);
	};

	getByIds = async (
		args: IAGETManyCoursesByIds,
		loadFresh?: boolean
	): Promise<Course[]> => {
		return this.coursePromises.collectors
			.many(args.ids)
			.fresh(loadFresh)
			.request();
	};

	hierarchyCollmise = collmise();

	getHierarchies = async (
		args: IAGETCourseHierarchy,
		loadFresh?: boolean
	): Promise<IRGETCourseHierarchy> => {
		if (!loadFresh) {
			const folderHierarchy =
				this._FolderHierarchyModel.findOneByCourseSync(args._id);
			if (folderHierarchy) {
				const topicHierarchy =
					this._TopicHierarchyModel.findOneByCourseSync(args._id);
				if (topicHierarchy) {
					const taskTypeHierarchy =
						this._TaskTypeHierarchyModel.findOneByCourseSync(
							args._id
						);
					if (taskTypeHierarchy) {
						return {
							folderHierarchy,
							topicHierarchy,
							taskTypeHierarchy,
						};
					}
				}
			}
		}
		return this.hierarchyCollmise.on(args._id).requestAs(() =>
			this.Request.send(
				"GET",
				"/api/courses/:_id/hierarchies",
				args,
				null,
				{
					responseSchema: RGETCourseHierarchySchema,
				}
			).then((data: IRGETCourseHierarchy) => {
				this._FolderHierarchyModel.loadOneSync(data.folderHierarchy);
				this._TopicHierarchyModel.loadOneSync(data.topicHierarchy);
				this._TaskTypeHierarchyModel.loadOneSync(
					data.taskTypeHierarchy
				);

				const areFoldersWithSync =
					HierarchyToFoldersComparer.areFoldersWithSync({
						hierarchy: data.folderHierarchy,
						folders: this._FolderModel.getAllSync(),
					});
				if (!areFoldersWithSync) {
					this._FolderModel.clearAllSync();
				}
				return data;
			})
		);
	};

	update = async (args: IAPUTCourse): Promise<Course | null> =>
		this.Request.send("PUT", "/api/courses/:_id", args).then(() =>
			this._CourseModel.updateOneSync({ _id: args._id }, args)
		);

	deleteById = async (args: IADELETECourse): Promise<void> =>
		this.Request.send("DELETE", "/api/courses/:_id", args).then(() =>
			this._CourseModel.deleteByIdSync(args._id)
		);

	getAll = async (): Promise<Course[]> =>
		this.Request.send("GET", "/api/courses", undefined, null, {
			responseSchema: RGETAllCoursesSchema,
		}).then((data: IRGETAllCourses) => {
			this._CourseModel.meta.updateLoadTime();
			return this._CourseModel.loadManySync(data, "replaceAll");
		});

	uploadPhotos = async (
		args: IAPOSTUploadCourseMedia
	): Promise<IRPOSTUploadCourseMedia> =>
		this.Request.send("POST", "/api/photos/courses/:courseId", args, null, {
			responseSchema: RPOSTUploadCourseMediaSchema,
		});

	getMedia = async (
		args: IAPOSTUploadCourseMedia
	): Promise<IRPOSTGetCourseMedia> =>
		this.Request.send(
			"GET",
			"/api/photos/courses/:courseId/user",
			args,
			null,
			{
				responseSchema: RPOSTGetCourseMediaSchema,
			}
		);

	getAllMedia = async (
		args: IAPOSTUploadCourseMedia
	): Promise<IRPOSTGetCourseMedia> =>
		this.Request.send("GET", "/api/photos/courses/:courseId", args, null, {
			responseSchema: RPOSTGetCourseMediaSchema,
		});

	allFoldersCollmise = collmise();

	getAllFolders = async (
		args: IAGETGetCourseFolders
	): Promise<{
		rootFolderId: ObjectId;
		allFolders: Folder[];
		folderHierarchy: FolderHierarchy;
	}> =>
		this.allFoldersCollmise.on(args.courseId).requestAs(() =>
			this.Request.send(
				"GET",
				"/api/courses/:courseId/folders",
				args,
				null,
				{
					responseSchema: RGETCourseFoldersSchema,
				}
			).then((data: IRGETCourseFolders) => {
				this._FolderModel.meta.updateLoadTime(args.courseId);
				const allFolders = this._FolderModel.loadManySync(
					data.allFolders
				);
				const folderHierarchy = this._FolderHierarchyModel.loadOneSync(
					data.folderHierarchy
				);
				return {
					rootFolderId: data.rootFolderId,
					allFolders,
					folderHierarchy,
				};
			})
		);

	createReminder = async (args: IAPOSTCourseReminder): Promise<void> =>
		this.Request.send("POST", "/api/courses/:courseId/reminder", args);

	updateReminder = async (args: IAPUTCourseReminder): Promise<void> =>
		this.Request.send("PUT", "/api/courses/:courseId/reminder", args);

	getReminder = async (
		args: IAGETCourseReminder
	): Promise<IRGETCourseReminder> =>
		this.Request.send("GET", "/api/courses/:courseId/reminder", args);

	recreateFolderParentInfoObj = async (
		args: IAGETGetCourseFolders
	): Promise<void> =>
		this.Request.send(
			"PUT",
			"/api/courses/:courseId/recreate-folder-parent-info-obj",
			args
		);

	getCourseFilteredContent = async (
		args: IAGETCourseFilteredContent
	): Promise<IRGETCourseFilteredContent> =>
		this.Request.send(
			"POST",
			"/api/courses/:courseId/contents/filter",
			args,
			null,
			{
				responseSchema: RGETCourseFilteredContentSchema,
			}
		);
}
