import { UserType } from "@app/api/helper-schemas";
import { IRGETMyInfoAsTeacher } from "@app/api/users/validators";
import { inject } from "@app/modules";
import { uniquelize } from "@app/utils/array";
import { isNonNullable } from "@app/utils/common";
import { useEffect, useMemo, useState } from "react";
import { useManySchoolsByIds } from "./cities-and-schools";
import { useClassroomsUserId } from "./users";

export const useTeacherInfo = () => {
	const classroomUserId = useClassroomsUserId();
	const [teacherInfo, setTeacherInfo] = useState<IRGETMyInfoAsTeacher | null>(
		() =>
			classroomUserId
				? inject("UsersController").getMyInfoAsTeacherSync()
				: null
	);

	useEffect(() => {
		if (!classroomUserId) return;
		const UsersController = inject("UsersController");
		UsersController.getMyInfo(UserType.teacher);
		return UsersController.myInfoSubscription.subscribe<IRGETMyInfoAsTeacher>(
			setTeacherInfo
		);
	}, [classroomUserId]);

	return teacherInfo;
};

export const useTeacherClassroomsSchoolIds = () => {
	const teacherInfo = useTeacherInfo();
	return useMemo(() => {
		if (!teacherInfo) return null;
		const groupSchools = teacherInfo.groups.map((group) => group.schoolId);
		const schoolIds = teacherInfo.classrooms
			.map((classroom) => classroom.schoolId)
			.concat(groupSchools)
			.filter(isNonNullable);
		return {
			schoolIds: uniquelize(schoolIds),
			hasSchoollessClassroom: teacherInfo.classrooms.some(
				(e) => !e.schoolId
			),
		};
	}, [teacherInfo]);
};

export const useTeacherClassroomsSchools = () => {
	const info = useTeacherClassroomsSchoolIds();
	const schools = useManySchoolsByIds(info ? info.schoolIds : null);
	if (!info) return null;
	return {
		schools,
		hasSchoollessClassroom: info.hasSchoollessClassroom,
	};
};
