import { getCurrentWebsite, WebsiteOrigin } from "@app/globals";
import { useLocale } from "@app/hooks/intl";
import { useClassroomsUserId } from "@app/hooks/users";
import classNames from "classnames";
import React from "react";
import styles from "./styles/buttons-widget.module.css";

export const TopGe: React.FC<{}> = (props) => {
	const isGeo = useLocale() === "ka";
	const isTvSchool = getCurrentWebsite() === WebsiteOrigin.tvSchool;
	const isLogged = !!useClassroomsUserId();
	const myPathName = window.location.pathname.slice(0, 6);
	const isMainPage = myPathName === "/";

	if (!isGeo || !isTvSchool) return null;

	return (
		<div
			className={
				isLogged || !isMainPage
					? classNames(
							styles.topGeContainerWithotMargin,
							(isLogged || !isMainPage) && styles.hidden
						)
					: styles.topGeContainer
			}
		>
			<div id="top-ge-counter-container" data-site-id="113913" />
		</div>
	);
};
