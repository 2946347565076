import { addLoader } from "@app/common-javascript";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "./locale";

export const wrapInLoading = (
	promise: Promise<unknown>,
	{
		hideErrorAlert,
		throwError,
	}: { hideErrorAlert?: boolean; throwError?: boolean } = {}
): Promise<void> => {
	const removeLoader = addLoader();
	return promise
		.then(() => {
			removeLoader();
		})
		.catch((e) => {
			console.error(e);
			removeLoader();

			if (!hideErrorAlert) {
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			}
			console.log(JSON.parse(JSON.stringify(e)));
			if (throwError) {
				throw e;
			}
		});
};
