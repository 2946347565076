import React, { useRef, useCallback } from "react";
import { containerCSS, statementTextCSS, SaveButtonCSS } from "./custom-styles";
import { css } from "emotion";
import { IQContentEditPassableProps } from "@tests-core/components/questions/contents/edit";
import { IQContentPassableProps } from "@tests-core/components/questions/contents";
import { getFormattedMessage } from "@app/utils/locale";
import {
	FBNonCheckableInputViewContainer,
	FBFilesViewContainer,
} from "@app/components/teachers/assignments-2/edit-assignments/content-components";
import {
	FBNonCheckableInputContainer,
	IFBNonCheckableInputContainerProps,
	IFBUploadsContainerProps,
} from "@tests-core/components/questions/contents/filling-blanks";
import {
	FBItemType,
	FBInputSize,
	IFBFile,
	INonCheckableInputItem,
} from "@tests-core/schemas/questions/contnets/filling-blanks/schema";
import { IStatement } from "@tests-core/schemas/questions/contnets/common-schemas";
import styles from "./styles/q.module.scss";
import classNames from "classnames";
import { AnswerIcon } from "@app/icons";
import { FileViewerAndUpdater } from "@app/components/ui/files/uploader";
import { FormattedMessage } from "react-intl";
import { normalizeHTML } from "@tests-core/components/editor/html";
import { MathEquationsInstruction } from "@app/components/teachers/assignments-2/edit-assignments/math-instruction";
import { useShowMathEquation } from "@app/components/general-context";
import { covertASCIIMathToLaTex } from "@tests-core/components/editor/math";
import { escapeHtml } from "@app/utils/common";

const questionsEditCustomizedProps: IQContentEditPassableProps = {
	commonProps: {
		commonStyles: {
			saveButton: SaveButtonCSS,
		},
		commonTexts: {
			saveButton: "შენახვა",
			addAnotherQuestion: "ახალი კითხვის დამატება",
		},
	},
	mcProps: {
		texts: {
			choices: {
				delete: "ამოშლა",
				add: "სავარაუდო პასუხის დამატება",
				otherAnswer: "სხვა სავარაუდო პასუხი",
				checkedAnswer: "სწორი პასუხი",
			},
			explanation: {
				placeholder: "ახსნა-განმარტება",
				title: "ახსნა-განმარტება:",
			},
			settings: {
				disableShuffle: "სავარაუდო პასუხები არასდროს აირიოს",
				multipleSelect: "ჰქონდეს რამდენიმე სწორი პასუხი",
			},
			statement: {
				placeholder: "პირობა",
			},
		},
	},
	siProps: {
		texts: {
			items: {
				add: "დამატება",
				delete: "ამოშლა",
				placeholder: "დასალაგებელი",
			},
			explanation: {
				placeholder: "ახსნა-განმარტება",
				title: "ახსნა-განმარტება:",
			},
			statement: {
				placeholder: "შეიყვანეთ პირობა",
			},
		},
	},
};
export { questionsEditCustomizedProps };

const nextButtonCSS = css`
	background: linear-gradient(to top right, #004ddb, #168df8);
	color: white;
	&:hover {
		background: linear-gradient(to top right, #0d3c90, #127bda);
	}
`;

export const getQuestionsCustomizedProps = (
	locale: string,
	isStudentViewer: boolean,
	isViewingForOneStudent?: boolean
): IQContentPassableProps => {
	const viewModeTexts = {
		userAnswer: isStudentViewer
			? getFormattedMessage("student:test.qProps.vModeUserAnswerAsUser")
			: getFormattedMessage(
					"student:test.qProps.vModeUserAnswerAsTeacher"
				),
		correctAnswer: getFormattedMessage(
			"student:test.qProps.vModeCorrentAnswer"
		),
	};

	const explanation = getFormattedMessage("student:test.qProps.explanation");

	return {
		mcProps: {
			styles: {
				statement: {
					text: statementTextCSS,
				},
				container: containerCSS,
				choices: {
					checkMark: {
						innerCheckMark: {
							checked: css`
								color: white;
							`,
							container: {
								general: css`
									border-width: 2px;
								`,
							},
							checkedAndUnknown: css`
								background: #5c5de8;
								border: 2px solid #5c5de8;
								&:hover {
									background: #4c4dce;
								}
							`,
							checkedAndCorrect: css`
								background: #50dd39;
								border: 2px solid #50dd39;
								&:hover {
									background: #3fc32a;
								}
							`,
							incorrect: css`
								background: #f64848;
								border: 2px solid #f64848;
								&:hover {
									background: #e03232;
								}
							`,
							correct: css`
								color: white;
								background: #17d5cd;
								border: 2px solid #16c0b9;
								&:hover {
									background: #16c0b9;
								}
							`,
						},
					},
				},
			},
			texts: {
				explanation: {
					title: explanation,
				},
			},
		},
		siProps: {
			texts: {
				viewMode: viewModeTexts,
				explanation: {
					title: explanation,
				},
			},
		},
		fbProps: {
			texts: {
				viewMode: viewModeTexts,
				explanation: {
					title: explanation,
				},
				input: {
					placeholder: getFormattedMessage(
						"student:test.qProps.openEndPlaceholder"
					),
				},
				nonCheckableInput: {
					answerInput: {
						placeholder: getFormattedMessage(
							"student:test.qProps.openEndPlaceholder"
						),
					},
					assessment: {
						before: "",
						after: " ქულა",
					},
				},
			},
			components: {
				nonCheckable: {
					container: !isStudentViewer
						? !isViewingForOneStudent
							? FBNonCheckableInputViewContainer
							: FBNonCheckableInputContainerOfStudentAnswerForTeacher
						: FBNonCheckableInputContainerForStudent,
					inner: {
						uploads: {
							container: FBUploadsContainer,
						},
					},
				},
				statement: {
					files: {
						container: FBFilesViewContainer,
					},
				},
			},
		},
		mucProps: {
			texts: {
				nextButton: "",
				explanation: {
					title: explanation,
				},
				prevButton: "",
			},
			styles: {
				nextButton: nextButtonCSS,
				prevButton: nextButtonCSS,
			},
		},
	};
};

const FBNonCheckableInputContainerOfStudentAnswerForTeacher: React.FC<IFBNonCheckableInputContainerProps> =
	React.memo((props) => {
		const UploadsContainer = props.components?.uploads?.container;
		if (
			props.item.type === FBItemType.NonCheckableInput &&
			(props.item.size === FBInputSize.ExtraLarge ||
				props.item.size === FBInputSize.Large)
		) {
			const userAnswerText =
				props.userAnswer?.[props.item.id]?.text || "";
			const userAnswerFiles =
				props.userAnswer?.[props.item.id]?.files || [];
			const comp = UploadsContainer && (
				<UploadsContainer
					item={props.item}
					onUserAnswerChange={props.onUserAnswerChange}
					disableEditingAnswer={props.disableEditingAnswer}
					userAnswer={props.userAnswer}
					viewMode={props.viewMode}
					extraInfo={props.extraInfo}
					designStructure={props.designStructure}
				/>
			);
			if (!userAnswerText && userAnswerFiles.length === 0) {
				return (
					<div
						className={
							styles.studentAnswerOnNonCheckableInputContainer
						}
					>
						<div className={styles.title}>
							{getFormattedMessage("studentHasNotAnswered")}
						</div>
					</div>
				);
			}
			return (
				<div
					className={styles.studentAnswerOnNonCheckableInputContainer}
				>
					<div className={styles.title + " break-words"}>
						{getFormattedMessage("studentsWork")}
					</div>
					<div
						className={styles.userAnswerCSS}
						dangerouslySetInnerHTML={{
							__html: normalizeStudentAnwer(userAnswerText)!,
						}}
					/>
					{comp}
				</div>
			);
		}
		return <FBNonCheckableInputContainer {...props} />;
	});

const normalizeStudentAnwer = (userAnswerText: string | null) => {
	if (typeof userAnswerText !== "string") return userAnswerText;
	return covertASCIIMathToLaTex(normalizeHTML(escapeHtml(userAnswerText)));
};

const FBNonCheckableInputContainerForStudent: React.FC<IFBNonCheckableInputContainerProps> =
	React.memo((props) => {
		const showMathInstructions = useShowMathEquation();
		if (
			props.item.type === FBItemType.NonCheckableInput &&
			(props.item.size === FBInputSize.ExtraLarge ||
				props.item.size === FBInputSize.Large)
		) {
			const itemId = props.item.id;
			const uAns = props.userAnswer?.[itemId] as IStatement | undefined;
			const text = uAns?.text || "";

			const UploadsContainer = props.components?.uploads?.container;
			const assessment = props.itemsAssessment;

			return (
				<div>
					{showMathInstructions && (
						<MathEquationsInstruction
							containerStyles={{ marginBottom: 35 }}
						/>
					)}
					<div
						className={classNames(
							styles.userInput,
							props.item.size === FBInputSize.ExtraLarge &&
								styles.extraLarge,
							props.item.size === FBInputSize.Large &&
								styles.Large
						)}
					>
						<AnswerIcon />
						<textarea
							readOnly={props.disableEditingAnswer}
							value={text}
							onChange={(e) =>
								props.onUserAnswerChange({
									...props.userAnswer,
									[itemId]: {
										...uAns,
										id: itemId,
										text: e.target.value,
									},
								})
							}
							placeholder={getFormattedMessage(
								"student:test.qProps.openEndPlaceholder"
							)}
						/>
					</div>
					{assessment && (
						<div className={styles.assessmentScore}>
							<FormattedMessage id="student:test.qProps.score" />
							<span>{assessment.credit}</span>
						</div>
					)}
					{UploadsContainer && (
						<UploadsContainer
							item={props.item}
							onUserAnswerChange={props.onUserAnswerChange}
							disableEditingAnswer={props.disableEditingAnswer}
							userAnswer={props.userAnswer}
							viewMode={props.viewMode}
							extraInfo={props.extraInfo}
							designStructure={props.designStructure}
						/>
					)}
				</div>
			);
		}
		return <FBNonCheckableInputContainer {...props} />;
	});

export const FBUploadsContainer: React.FC<IFBUploadsContainerProps> =
	React.memo(
		({
			item: currentItem,
			disableEditingAnswer,
			userAnswer,
			onUserAnswerChange,
		}) => {
			const userAnswerRef = useRef(userAnswer);
			userAnswerRef.current = userAnswer;

			const item = currentItem as INonCheckableInputItem;

			const files = userAnswer?.[item.id]?.files || [];

			const onFilesChange = useCallback(
				(files: IFBFile[]) => {
					onUserAnswerChange({
						...userAnswerRef.current,
						[item.id]: {
							...userAnswerRef.current?.[item.id],
							id: item.id,
							files,
						},
					});
				},
				[onUserAnswerChange, item.id]
			);

			if (!item.uploadFiles) return null;
			if (
				files.length === 0 &&
				(!item.size ||
					item.size === FBInputSize.Normal ||
					item.size === FBInputSize.Small)
			) {
				return null;
			}
			if (disableEditingAnswer && files.length === 0) return null;

			return (
				<FileViewerAndUpdater
					files={files}
					onChange={disableEditingAnswer ? undefined : onFilesChange}
					direction="right"
					twoStepUpload
				/>
			);
		}
	);
