import { techDiffForStudents } from "@app/globals";
import UserOrGuestRoute from "@app/routes/containers/user-or-guest-route";
import {
	StudentRegistrationPage,
	TeacherRegistrationPage,
} from "../../components/auth/student-register-page";
import { Events } from "../../components/events";
import { SpecialRegistration } from "../../components/landing/special-registration-popup";
import { MC } from "../../components/multiple-select";
import { Confidentiality } from "../../components/terms/confidentiality";
import { Terms } from "../../components/terms/terms";
import { TermsPageForApp } from "../../components/terms/termsPageForApp";
import { StudentPublicAssignment } from "../../components/users/assignments/tv-school";
import { Blogs } from "../../components/users/blog/blogs";
import { WholeBlog } from "../../components/users/blog/whole-blog";
import { RegistrationInviteLangingPage } from "../../components/users/register-invitation/register-invitation";
import { TVSChoolWrapper } from "../../components/users/tests/tv-quiz";
import { FaqContainer } from "../../components/widgets/faq";
import { StudentsTechDiffPage } from "../../tech-difficulties";
import { createRouteList } from "../config";
import GuestRoute from "../containers/guest-route";
import { defaultComponentWrapper } from "../wrapper";
import { publicLinks } from "./links";

///

const routes = createRouteList({
	exact: true,
	RouteComponent: UserOrGuestRoute,
	componentWrapper: defaultComponentWrapper,
});

routes.add({
	path: "",
	exact: false,
	component: Events,
	avoidSwitch: true,
});

const AuthPopups = () =>
	import("../../components/landing/auth").then((module) => ({
		default: module.AuthPopups,
	}));

routes.add({
	path: publicLinks.login.routePath,
	component: AuthPopups,
	RouteComponent: GuestRoute,
});

///

const LandingMainPage = () => import("../../components/landing");

routes.add({
	path: publicLinks.landingPage.routePath,
	component: LandingMainPage,
	RouteComponent: GuestRoute,
	avoidSwitch: true,
});

///

routes.add({
	path: "/registration-with-code",
	component: SpecialRegistration,
});

///

routes.add({
	path: "/terms-and-conditions",
	component: TermsPageForApp,
});

///

routes.add({
	path: "/tv-quiz/:level",
	component: TVSChoolWrapper,
	routeProps: {
		headerProps: {
			variant: "colored",
			isStudentHeader: true,
		},
	},
});

///

routes.add({
	path: "/registration",
	component: StudentRegistrationPage,
});

///

routes.add({
	path: "/teacher-registration",
	component: TeacherRegistrationPage,
});

///

routes.add({
	path: "/rame",
	component: MC,
});

///

routes.add({
	path: "/terms",
	component: Terms,
});

///

routes.add({
	path: "/confidentiality",
	component: Confidentiality,
});

///

routes.add({
	path: "/blogs-main-page",
	component: Blogs,
	routeProps: {
		priorityOfEmptyPopup: 2010,
	},
});

///

routes.add({
	path: "/blogs/:blogId",
	component: WholeBlog,
	routeProps: {
		priorityOfEmptyPopup: 201,
	},
});

///

routes.add({
	path: publicLinks.assignment.routePath,
	component: techDiffForStudents
		? StudentsTechDiffPage
		: StudentPublicAssignment,
	routeProps: {
		headerProps: {
			variant: "colored",
			isStudentHeader: true,
		},
		priorityOfEmptyPopup: 2010,
	},
});

///

routes.add({
	path: "/rs/:invitationCode",
	component: RegistrationInviteLangingPage,
});

///

routes.add({
	path: "/faq",
	component: FaqContainer,
	routeProps: {
		headerProps: { variant: "colored" },
	},
});

///

const CSSToClassNames = () =>
	import("../../components/dev/class-names").then((module) => ({
		default: module.CSSToClassNames,
	}));

routes.add({
	path: publicLinks.dev.classNames.routePath,
	component: CSSToClassNames,
});

///

const TestAd = () =>
	import("../../components/dev/ads").then((module) => ({
		default: module.TestAd,
	}));

routes.add({
	path: publicLinks.ads.routePath,
	component: TestAd,
});

///

export default routes;
