import {
	RPublicTestSchema,
	IRPublicTest,
	PublicTestStatusTypes,
	PublicTestStatusType,
	PublicTestType,
	PublicTestTypes,
} from "./helper-schemas";
import {
	markKeysForbidden,
	markKeysOptional,
	ToInsertKeys,
} from "../helper-schemas";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export const APOSTPublicTestSchema = RPublicTestSchema.fork(
	["id", "createdAt", "updatedAt", "author", "status"],
	markKeysForbidden
).fork("feedbackId", markKeysOptional); // TODO: do not mark feedbackId optional after 4th of july

export type IAPOSTPublicTest = ToInsertKeys<
	IRPublicTest,
	"feedbackId",
	"id" | "createdAt" | "updatedAt" | "author" | "status"
>;

export const RPOSTPublicTestSchema = RPublicTestSchema;
export type IRPOSTPublicTest = IRPublicTest;

export const AGETPublicTestsSchema = Joi.object({
	originalCourseId: Joi.objectId(),
	isPublic: Joi.boolean(),
	status: Joi.string().valid(...PublicTestStatusTypes),
	author: Joi.number().integer().min(1),
});

export interface IAGETPublicTests {
	originalCourseId?: ObjectId;
	status?: PublicTestStatusType;
	isPublic?: boolean;
	author?: number;
}

export const RGETPublicTestsSchema = Joi.array().items(RPublicTestSchema);
export type IRGETPublicTests = IRPublicTest[];

///

export const AGETPublicTestsForTeacherSchema = Joi.object({
	originalCourseId: Joi.objectId().required(),
});
export interface IAGETPublicTestsForTeacher {
	originalCourseId: ObjectId;
}

export const RGETPublicTestsForTeacherSchema =
	Joi.array().items(RPublicTestSchema);
export type IRGETPublicTestsForTeacher = IRPublicTest[];

///

export const APUTPublicTestStatusSchema = Joi.alternatives(
	Joi.object({
		id: Joi.number().integer().required(),
		status: Joi.string()
			.valid(...PublicTestStatusTypes)
			.required(),
	}),
	Joi.object({
		courseId: Joi.objectId().required(),
		testId: Joi.objectId().required(),
		status: Joi.string()
			.valid(...PublicTestStatusTypes)
			.required(),
	})
);
export type IAPUTPublicTestStatus =
	| {
			id: number;
			status: PublicTestStatusType;
	  }
	| {
			courseId: ObjectId;
			testId: ObjectId;
			status: PublicTestStatusType;
	  };

export type IRPUTPublicTestStatus = void;

export const AGETPublicTestCountByOriginalCourseIdSchema = Joi.object({
	status: Joi.string().valid(...PublicTestStatusTypes),
});
export interface IAGETPublicTestCountByOriginalCourseId {
	status?: PublicTestStatusType;
	type?: PublicTestType;
}

export const RGETPublicTestCountByOriginalCourseIdSchema = Joi.array().items(
	Joi.object({
		originalCourseId: Joi.objectId().required(),
		count: Joi.number().integer().min(0).required(),
	})
);
export type IRGETPublicTestCountByOriginalCourseId = {
	originalCourseId: ObjectId;
	count: number;
}[];

///

export const APOSTImportCourseTestsSchema = Joi.object({
	courseIds: Joi.array().items(Joi.objectId()).optional(),
});
export interface IAPOSTImportCourseTests {
	courseIds?: ObjectId[];
}

///

export const APUTPublicTestSchema = Joi.object({
	id: Joi.number().integer().required(),
	name: Joi.string(),
	questionsCount: Joi.number().integer().min(0),
	isPublic: Joi.boolean(),
	feedbackId: Joi.string().allow(null).required(),
});
export type IAPUTPublicTest = Partial<
	Pick<IRPublicTest, "name" | "isPublic" | "questionsCount" | "feedbackId">
> &
	Pick<IRPublicTest, "id">;

export type IRPUTPublicTest = void;

export const RGETPublicTestChangesByAuthorSchema = Joi.array().items(
	Joi.object({
		publicTest: RPublicTestSchema.required(),
		changeId: Joi.number().integer().required(),
	})
);
export type IRGETPublicTestChangesByAuthor = {
	changeId: number;
	publicTest: IRPublicTest;
}[];

export const APUTPublicTestChangeSeenSchema = Joi.object({
	changeId: Joi.number().integer().required(),
});
export interface IAPUTPublicTestChangeSeen {
	changeId: number;
}

export type IRPUTPublicTestChangeSeen = void;

export const APUTPublicTestChangeSeenForManySchema = Joi.object({
	changeIds: Joi.array().items(Joi.number().integer()).required(),
});
export interface IAPUTPublicTestChangeSeenForMany {
	changeIds: number[];
}

export type IRPUTPublicTestChangeSeenForMany = void;
//////
export const AGETPublicTestSchema = Joi.object({
	id: Joi.number().required(),
});
export interface IAGETPublicTest {
	id: number;
}

export const RGETPublicTestSchema = RPublicTestSchema;
export type IRGETPublicTest = IRPublicTest;

///

export const APUTPublicTestTypeSchema = Joi.object({
	id: Joi.number().integer().required(),
	type: Joi.string()
		.valid(...PublicTestTypes)
		.required(),
});

export interface APUTPublicTestType {
	id: number;
	type: PublicTestType;
}

export type IRPUTPublicTestType = void;

export const APUTCognitiveAssignmentMetaDataSchema = Joi.object({
	id: Joi.number().required(),
	iconFullUrl: Joi.string().required(),
	iconName: Joi.string().required(),
});

export interface IAPUTCognitiveAssignmentMetaData {
	id: number;
	iconFullUrl: string;
	iconName: string;
}

export type IRPUTCognitiveAssignmentMetaData = void;
