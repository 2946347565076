import { VideoLessonResourceTypes } from "@app/api/video-lessons/helper-schemas";
import { onAuthorOpenVideoLesson } from "@app/common-javascript";
import { ArchiveBoxes } from "@app/components/archive/boxes";
import {
	areSemestersEqual,
	useSemesterFromQuery,
} from "@app/components/archive/utils";
import { CertificateBox } from "@app/components/profile/certificate";
import { MarginContainer } from "@app/components/ui/containers";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { isCertificateServicesAvaliable } from "@app/consts";
import { SubWebsiteOrigin } from "@app/globals";
import { useClassroom } from "@app/hooks/classrooms";
import { useGoToUrl, useHistory } from "@app/hooks/front";
import { useGradeById } from "@app/hooks/grades";
import { useLocale } from "@app/hooks/intl";
import { useSubjectsByIds } from "@app/hooks/subjects";
import { useVideoLesson, useVideoLessonStatus } from "@app/hooks/video-lessons";
import { EnterIcon, SentHomeworkIcon } from "@app/icons";
import { Classroom } from "@app/models/classroom";
import { Conference } from "@app/models/conference";
import { Group } from "@app/models/group";
import { VideoLessonStatus } from "@app/models/video-lesson";
import { inject } from "@app/modules";
import { useChildLink } from "@app/routes/app-link-utils";
import { teacherLinks } from "@app/routes/teacher/links";
import { getFormattedMessage } from "@app/utils/locale";
import DuoIcon from "@material-ui/icons/Duo";
import { classNamed } from "@pckgs/classed-components";
import { ObjectId } from "@tests-core/utils/joi";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { filterGroupsForTeacher } from ".";
import {
	IClassroom,
	ISemester,
} from "../../../../api/classrooms/helper-schemas";
import { CourseType } from "../../../../api/courses-info/helper-schemas";
import { UserType } from "../../../../api/helper-schemas";
import { useSubWebsite } from "../../../../hooks/bc";
import { useFetch } from "../../../../hooks/fetch";
import { useSettableTeachersByScholId } from "../../../../hooks/school";
import {
	useSchoolStructureOfCurrentUser,
	useTeacheruseSchoolAndLabelFiltererFn,
} from "../../../../hooks/school-structure";
import { useMyInfoAdStudentOrChild } from "../../../../hooks/student";
import { useClassroomsUser } from "../../../../hooks/users";
import { haveCommonValue } from "../../../../utils/array";
import { arrayToObject, flatten } from "../../../../utils/common";
import { compareGroups } from "../../../headmaster/main";
import { HeadmasterGroupBox } from "../../../headmaster/main/directorate-box";
import {
	getArchiveHandler,
	HeadmasterClassroomBox,
	sortByClassTimes,
} from "../../../headmaster/main/with-classrooms";
import newStyles from "../../../headmaster/styles/index.module.scss";
import { ClassroomBox } from "../../../users/subjects/students-subjects";
import { FancyLoadingCenter } from "../../../widgets/fancy-loading";
import { ApprovedPublicAssignments } from "../../assignments-2/approved-public-assignments";
import { StudentCertificatesBox } from "../../certificates/certificate-candidates/student-certificates-box";
import { useSelectedSchoolLabelId } from "../../contexts/teacher-school";
import { TVSchoolClassroomBoxLoader } from "./classroom-loader";
import {
	ClassTimesPreview,
	IClassTimesPreviewCoordinates,
} from "./classtimes-preview";
import { CreateClassroomBox } from "./create-classroom-box";
import { GroupBox } from "./group-box";
import styles from "./styles/tvschool-classroom.module.css";
import TeacherConferenceBox from "./TeacherConferenceBox";
import { Timetable } from "./Timetable";

interface ClassroomBoxProps {
	classroomId: ObjectId;
	enableArchive?: boolean;
}

export const TVSchoolClassroomBox: React.FC<ClassroomBoxProps> = React.memo(
	//TODO: Fix this later
	// eslint-disable-next-line max-lines-per-function
	function TVSchoolClassroomBox({ classroomId, enableArchive = false }) {
		const history = useHistory();
		const classroom = useClassroom(classroomId);
		const subjects = useSubjectsByIds(classroom.doc?.subjects || null);
		const [classTimesPrev, setClassTimesPrev] =
			useState<IClassTimesPreviewCoordinates>();
		const handleNewAssignmentAdd = useCallback(() => {
			if (!classroom.doc) return;
			const url = getNewAssignmentUrl(classroom.doc);
			history.push(url!);
		}, [classroom.doc, history]);

		const handleVideoLessonButtonOver = (
			e: React.MouseEvent<HTMLButtonElement>
		) => {
			if (classTimesPrev) return;
			setClassTimesPrev({
				top: e.clientY,
				left: e.clientX,
			});
		};

		const videoLesson = useVideoLesson(
			classroomId,
			VideoLessonResourceTypes.CLASSROOM
		);
		const videoLink = !videoLesson.doc ? null : videoLesson.doc.url;
		const isPremium = classroom.doc ? classroom.doc.isPremium() : false;

		const lessonStatus = useVideoLessonStatus(
			videoLesson.doc,
			classroom.doc?.classTimes
		);
		const isVideoLessonWithinAcademicRange =
			lessonStatus === VideoLessonStatus.ACTIVE;

		const enterAssignemnts = useCallback(() => {
			history.push(`/classrooms/${classroom.doc?._id}`);
		}, [history, classroom.doc]);

		const gradeId = classroom.doc?.grade || null;
		const grade = useGradeById(gradeId);

		const handleOpenVideolink = useCallback(() => {
			onAuthorOpenVideoLesson(
				classroomId,
				VideoLessonResourceTypes.CLASSROOM
			);
		}, [classroomId]);

		const isArchiveable = useMemo(() => {
			return !classroom.doc?.isPremium() || !!classroom.doc?.isArchived;
		}, [classroom.doc]);
		const handleArchiveClick = useCallback(() => {
			if (classroom.doc) {
				const updateArchiveStatus = () => {
					inject("ClassroomsController").update({
						_id: classroomId,
						isArchived: !classroom.doc.isArchived,
					});
				};
				openConfirmationPopup({
					text: classroom.doc.isArchived
						? getFormattedMessage("unarchiveQuestion")
						: getFormattedMessage("archiveQuestion"),
					approveTitle: getFormattedMessage("yes"),
					rejectTitle: getFormattedMessage("no"),
					onApprove: updateArchiveStatus,
					displayRejectButtonAsPrimary: !classroom.doc.isArchived,
				});
			}
		}, [classroom.doc, classroomId]);
		const isBritishCenter =
			useSubWebsite() === SubWebsiteOrigin.britishCenter;

		if (!classroom.isSuccessfullyLoaded) {
			return <TVSchoolClassroomBoxLoader />;
		}

		const subjectTitle = subjects.doc
			?.map((subject) => subject.name)
			.join(", ");
		const useFullName = classroom.doc.name !== subjectTitle; // use this logic temporarily

		return (
			<div className={styles.classroomBox} onClick={enterAssignemnts}>
				{useFullName ? (
					<div className={styles.classname}>{classroom.doc.name}</div>
				) : (
					<div
						className={styles.classname}
						dangerouslySetInnerHTML={{
							__html: grade.doc?.getFullName() || "",
						}}
					/>
				)}
				<div className={styles.subject}>
					{!subjects.doc
						? "..."
						: subjects.doc
								.map((subject) => subject.name)
								.join(", ")}
				</div>
				<button
					onClick={(event) => {
						event.stopPropagation();
						enterAssignemnts();
					}}
					className={styles.sentHomeworks}
				>
					{!isPremium && <SentHomeworkIcon className={styles.icon} />}
					{isPremium && (
						<EnterIcon
							className={styles.icon + " " + styles.enterIcon}
						/>
					)}
					<span>
						{!isPremium && (
							<FormattedMessage id="sentAssignments" />
						)}
						{isPremium && getFormattedMessage("enterClass")}
					</span>
				</button>
				{!isPremium && (
					<button
						onClick={(event) => {
							event.stopPropagation();
							handleNewAssignmentAdd();
						}}
						className={styles.newHomework}
					>
						<FormattedMessage id="newAssignmentForTeacher" />
					</button>
				)}
				{classTimesPrev && (
					<ClassTimesPreview
						{...classTimesPrev}
						classTimes={classroom.doc.classTimes}
						displacement={15}
					/>
				)}
				{isPremium && (
					<React.Fragment>
						<button
							onClick={(event) => {
								event.stopPropagation();
								handleOpenVideolink();
							}}
							onMouseOver={handleVideoLessonButtonOver}
							onMouseOut={() => setClassTimesPrev(undefined)}
							className={
								!videoLink
									? classNames(
											styles.videolink,
											styles.nolink
										)
									: isVideoLessonWithinAcademicRange
										? classNames(
												styles.videolink,
												styles.activelink
											)
										: styles.videolink
							}
						>
							ვიდეოგაკვეთილი
							<DuoIcon className={styles.icon} />
						</button>
						{isBritishCenter && (
							<span
								style={{
									color: "#626a84",
									fontSize: "16px",
								}}
							>
								Zoom passcode: britanuli
							</span>
						)}
					</React.Fragment>
				)}
				{enableArchive && (
					<ArchiveToggleButton
						isArchiveable={isArchiveable}
						isArchived={!!classroom.doc.isArchived}
						onClick={handleArchiveClick}
					/>
				)}
			</div>
		);
	}
);

const ArchiveToggleButton = React.memo<{
	isArchived: boolean;
	isArchiveable: boolean;
	onClick: () => void;
}>(function ArchiveToggleButton({ isArchived, isArchiveable, onClick }) {
	return (
		<div
			style={{
				height: "16px",
			}}
		>
			{isArchiveable && (
				<div className={styles.makeClassroomArchive}>
					<span
						onClick={(event) => {
							event.stopPropagation();
							onClick();
						}}
					>
						{isArchived
							? getFormattedMessage("makeClassroomUnarchive")
							: getFormattedMessage("makeClassroomArchive")}
					</span>
				</div>
			)}
		</div>
	);
});

export const TVSchoolClassroomBoxesContainer: React.FC<{
	classroomIds: ObjectId[];
	enableArchive?: boolean;
	groups: Group[] | null;
	academicPersonnelConferences: Conference[] | null;
	displayTimetable: boolean;
	archivedSemesters?: ISemester[] | null;
}> = React.memo(
	({
		classroomIds,
		groups,
		enableArchive = false,
		academicPersonnelConferences,
		displayTimetable,
		archivedSemesters,
	}) => {
		const locale = useLocale();
		const subWebsite = useSubWebsite();
		const isHeadTeacher = !!groups && groups.length > 0;
		const conferenceBoxes = useMemo(
			() =>
				academicPersonnelConferences &&
				academicPersonnelConferences.map((conf) => (
					<TeacherConferenceBox key={conf._id} conference={conf} />
				)),
			[academicPersonnelConferences]
		);
		return (
			<React.Fragment>
				{isHeadTeacher && (
					<>
						<div className={styles.classroomsContainer}>
							<InnerContainer>
								{groups!.map((gr) => (
									<GroupBox group={gr} key={gr._id} />
								))}
								{conferenceBoxes}
								{displayTimetable && <Timetable />}
							</InnerContainer>
						</div>
					</>
				)}
				<div className={newStyles.classroomsContainer}>
					<InnerContainer withSeparator={isHeadTeacher}>
						{!isHeadTeacher && displayTimetable && <Timetable />}
						{!isHeadTeacher && conferenceBoxes}
						{classroomIds.map((classroomId) => (
							<TVSchoolClassroomBox
								key={classroomId}
								classroomId={classroomId}
								enableArchive={enableArchive}
							/>
						))}

						{subWebsite === SubWebsiteOrigin.main && (
							<CreateClassroomBox
								goto={teacherLinks.classrooms.create()}
							/>
						)}
						{locale === "ka" && isCertificateServicesAvaliable && (
							<React.Fragment>
								<CertificateBox imgSize={150} />
								<StudentCertificatesBox />
							</React.Fragment>
						)}
						{locale === "ka" && <ApprovedPublicAssignments />}
						<ArchiveBoxes
							route={teacherLinks.archived.classrooms}
							semesters={archivedSemesters}
						/>
					</InnerContainer>
				</div>
			</React.Fragment>
		);
	}
);

const InnerContainer = classNamed("div")<{ withSeparator?: boolean }>(
	styles.marginContainerStart
).extendStyled((props) =>
	props.withSeparator
		? {
				borderTop: "1.5px solid #aeb5c9",
				paddingTop: "20px",
			}
		: {
				borderTop: "initial",
				paddingTop: "initial",
			}
);

export const TVSchoolClassroomBoxesLoaderContainer = () => {
	return (
		<div className={styles.classroomsContainer}>
			<MarginContainer itemsMargin={20} style={{ textAlign: "center" }}>
				{new Array(7).fill(0).map((e, i) => (
					<TVSchoolClassroomBoxLoader key={i} />
				))}
			</MarginContainer>
		</div>
	);
};

export const getNewAssignmentUrl = (classroom: Classroom): string | null => {
	const grade = classroom.grade;
	const subject = classroom.subjects[0];
	const courseId = classroom.course.originalCourseId;
	if (!subject) return null;
	return `/assignments/new?g=${grade}&s=${subject}&originalCourseId=${courseId}&classroomIds=${classroom._id}`;
};

export const StudentsClassroomsArchivePage = React.memo(() => {
	const semester = useSemesterFromQuery()!;
	const studentInfo = useMyInfoAdStudentOrChild();

	const classroomsById = useMemo(() => {
		if (!studentInfo) return null;
		return arrayToObject(
			studentInfo.classrooms.filter(
				(x) =>
					x.isArchived === true &&
					areSemestersEqual(x.semester, semester)
			),
			"_id"
		);
	}, [studentInfo, semester]);

	const gotoClassroom = useGoToUrl(
		useChildLink((link) => link.classrooms.assignments.list)
	);

	return (
		<div className={newStyles.mainContainer}>
			<div className={newStyles.heading}>
				<FormattedMessage id="myClassrooms" />
			</div>
			<div className={newStyles.boxesWrapper}>
				{classroomsById &&
					studentInfo &&
					studentInfo.classroomIds.map((classroomId) => {
						const classroom = classroomsById[classroomId];
						if (!classroom) return null;

						if (classroom.course.type === CourseType.cognitive) {
							return null;
						}
						return (
							<ClassroomBox
								key={classroomId}
								classroom={classroom}
								onClick={() =>
									gotoClassroom({
										classroomId: classroom._id,
									})
								}
							/>
						);
					})}
			</div>
		</div>
	);
});

// eslint-disable-next-line max-lines-per-function
export const HeadmastersClassroomsArchivePage = React.memo(() => {
	const semester = useSemesterFromQuery();
	const user = useClassroomsUser();
	const [groups, setGroups] = useState<Group[]>();
	const selectedLabelId = useSelectedSchoolLabelId();

	const showClassrooms = useSubWebsite() === SubWebsiteOrigin.britishCenter;

	const [teachersAndClassrooms, setTeachersAndClassrooms] =
		useSettableTeachersByScholId(
			showClassrooms
				? {
						schoolId: user!.school,
						schoolLabelId: selectedLabelId,
						includeOnlyArchived: true,
					}
				: null
		);
	useEffect(() => {
		if (user && user.isHeadmaster()) {
			inject("GroupController")
				.getArchivedGroupsForHeadmaster({
					schoolId: user.school!,
					semester: semester || undefined,
					isArchived: true,
				})
				.then((data) => setGroups([...data].sort(compareGroups)));
		}
	}, [user, semester]);

	const schoolStructure = useSchoolStructureOfCurrentUser();
	const ownLabelIds = useMemo(() => user!.getOwnLabelIds(), [user]);
	const onArchive = useMemo(
		() => getArchiveHandler(setTeachersAndClassrooms),
		[setTeachersAndClassrooms]
	);
	if (showClassrooms && !teachersAndClassrooms) {
		return <FancyLoadingCenter />;
	}
	const archivedClassrooms = teachersAndClassrooms
		? flatten(teachersAndClassrooms.map((e) => e.classrooms)).filter(
				(e) => e.isArchived
			)
		: null;

	const hasEditPermission =
		!schoolStructure.isFound ||
		(!!selectedLabelId &&
			schoolStructure.isFound &&
			haveCommonValue(
				ownLabelIds || [],
				schoolStructure.doc
					?.getAncestorLabels(selectedLabelId)
					.map((e) => e._id)
					.concat([selectedLabelId])
			));

	const getClassrooms = (cls: IClassroom[]) => {
		return [...cls]
			.sort(sortByClassTimes)
			.map((classroom) => (
				<HeadmasterClassroomBox
					classroom={classroom}
					key={classroom._id}
					onArchive={onArchive}
					canEdit={hasEditPermission}
				/>
			));
	};

	return (
		<div className={newStyles.mainContainer}>
			<div className={newStyles.heading}>
				<FormattedMessage id="myClassrooms" />
			</div>
			<div className={newStyles.boxesWrapperGrid}>
				{groups &&
					user?.isHeadmaster() &&
					groups.map((e) => (
						<HeadmasterGroupBox key={e._id} group={e} />
					))}
				{!!archivedClassrooms && getClassrooms(archivedClassrooms)}
			</div>
		</div>
	);
});

export const TeachersClassroomsArchivePage = React.memo(() => {
	const semester = useSemesterFromQuery()!;
	const user = useClassroomsUser()!;
	const archivedClassrooms = useFetch(
		() =>
			inject("SchoolsController").getArchivedClassroomsForTeacher({
				teacherId: user.id,
				semester: semester,
				isArchived: true,
			}),
		[user.id, semester]
	);

	const filterBySchool = useTeacheruseSchoolAndLabelFiltererFn();

	const classrooms = archivedClassrooms.doc?.filter(filterBySchool);
	const teacherInfo = useFetch(
		() => inject("UsersController").getMyInfo(UserType.teacher),
		[]
	).doc;

	const archivedGroups = filterGroupsForTeacher(
		teacherInfo,
		"archived",
		filterBySchool
	)?.filter((group) => areSemestersEqual(group.semester, semester));
	const isHeadTeacher = !!archivedGroups && archivedGroups.length > 0;

	return (
		<div className={newStyles.mainContainer}>
			<div className={newStyles.heading}>
				<FormattedMessage id="myClassrooms" />
			</div>
			<div className={newStyles.boxesWrapper}>
				<div className={styles.classroomsContainer}>
					<div className={styles.marginContainerStart}>
						{archivedGroups &&
							archivedGroups.map((gr) => (
								<GroupBox group={gr} key={gr._id} />
							))}
					</div>
				</div>
				<div
					style={
						isHeadTeacher
							? {
									borderTop: "1.5px solid #aeb5c9 ",
									paddingTop: "20px",
									width: "91%",
								}
							: undefined
					}
				>
					{" "}
				</div>
				<div className={styles.classroomBoxsContainers}>
					{classrooms?.map((e) => (
						<TVSchoolClassroomBox classroomId={e._id} key={e._id} />
					))}
				</div>
			</div>
		</div>
	);
});
