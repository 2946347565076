import React, { useCallback, useState } from "react";
import classnames from "classnames";
import genStyles from "./styles/general_styles.module.css";
import { useBoolean, useErrors } from "@app/hooks/general";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import { MobileInput, Button } from "@app/components/auth/input-forms";
import { useClassroomsUser } from "@app/hooks/users";

interface ICheckCodeProps {
	setIsInputMobileClicked: (args: boolean) => void;
	setToken: (args: string) => void;
	mobileRef?: React.MutableRefObject<string> | null;
}

export const CheckMobile: React.FC<ICheckCodeProps> = (props) => {
	const user = useClassroomsUser()!;

	const {
		value: isLoading,
		setTrue: startLoading,
		setFalse: stopLoading,
	} = useBoolean();

	const { errors, setErrors } = useErrors<{
		mobile?: string;
	}>(null);

	const sendCodeRequest = useCallback(() => {
		startLoading();
		const UsersController = inject("UsersController");
		UsersController.sendVerificationCode({
			mobile:
				props.mobileRef?.current === user.mobile
					? undefined
					: props.mobileRef?.current,
			userId: user.id,
		})
			.then(({ token }) => {
				props.setToken(token);
				props.setIsInputMobileClicked(true);
				stopLoading();
			})

			.catch((e) => {
				if (
					e.response &&
					e.response.data === "user is already registered"
				) {
					setErrors({
						mobile: "ამ ნომრით უკვე დარეგისტირებულია მომხმარებელი",
					});
				}
				stopLoading();
			});
	}, [props, setErrors, startLoading, stopLoading, user.id, user.mobile]);

	const onPhoneChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (props.mobileRef) {
				props.mobileRef.current = e.target.value;
			}
		},
		[props.mobileRef]
	);

	return (
		<>
			<div className={genStyles.resetPasswordContainer} tabIndex={0}>
				<div className={genStyles.personal_info_form}>
					<h2 className={genStyles.personal_info_form__title}>
						ტელეფონი <span>(დაადასტურეთ მობილური ნომერი)</span>
					</h2>
					<section
						className={genStyles.personal_info_form__input_wrapper}
					>
						<section
							className={classnames(
								genStyles.personal_info_form__input,
								genStyles.personal_info_form__input_long
							)}
						>
							<MobileInput
								onChange={onPhoneChange}
								errorText={errors && errors.mobile}
								isDisabled={isLoading}
								defaultValue={
									props.mobileRef
										? props.mobileRef.current
										: ""
								}
								className={genStyles.input}
								type={"text"}
								placeholder="შეიყვანეთ ახალი ნომერი"
								fullWidthInput={{ width: "100%" }}
							/>
						</section>

						<Button
							isLoading={isLoading}
							onClick={() => sendCodeRequest()}
							text={"კოდის მიღება"}
							className={genStyles.personal_info_form__code_btn}
							wrapperDivClassName={genStyles.fullWidth}
						/>
					</section>
					<p className={genStyles.personal_info_form__text}>
						* მობილური ნომრის დასადასტურებლად, გთხოვთ გაიგზავნოთ
						კოდი ნომერზე
					</p>
				</div>
			</div>
		</>
	);
};
