import { ISemester } from "@app/api/classrooms/helper-schemas";
import { addMergingArgumentPrefix, addPrefix, withParams } from "link-builders";

const commonFunctionality = {
	// timetable: "timetable",
	gradebook: "gradebook",
	semesterbook: "semesterbook",
	assignments: "assignments",
	finalStats: "statistics",
};

export const teacherLinks = addPrefix("/")({
	groups: {
		assignments: "/group/:groupId/assignments",
	},
	classrooms: {
		assignment: {
			review: "/classrooms/:classroomId/assignments/:assignmentId/review",
		},
		passignment: {
			writtenAssignment: {
				review: "/classrooms/:classroomId/passignments/:assignmentId/wt/:writtenAssignmentId/review",
			},
		},
		create: "/new-classroom",
		...addMergingArgumentPrefix(
			({ classroomId }: { classroomId: string }) =>
				`classrooms/${classroomId}`,
			`classrooms/:classroomId`
		)({
			...commonFunctionality,
			assignments: "",
			students: "/students",
		}),
	},
	archived: addPrefix("archive")({
		classrooms: withParams(
			(semester: ISemester) =>
				`classrooms?startYear=${semester.startYear}&ordinal=${semester.ordinal}`,
			"classrooms"
		),
	}),
});
