import { IClassroom } from "@app/api/classrooms/helper-schemas";
import { UserTestStatus } from "@app/api/users/helper-schemas";
import { IRGETTeachersBySchoolsIds } from "@app/api/schools/validators";
import {
	useSelectedSchoolLabelId,
	useSelectedSchoolLabelSetterFn,
} from "@app/components/teachers/contexts/teacher-school";
import { ReactComponent as CRT } from "@app/components/teachers/main/tvschool/styles/imgs/CreateClassroom.svg";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { FancyLoadingCenter } from "@app/components/widgets/fancy-loading";
import { Settings } from "@app/components/widgets/settings";
import { TabNav } from "@app/components/widgets/tab-nav";
import { isStudentStatusVisible } from "@app/consts";
import { useGoToUrl } from "@app/hooks/front";
import { useSettableTeachersByScholId } from "@app/hooks/school";
import {
	useLowLevelAccessibleSchoolLabels,
	useSchoolStructureOfCurrentUser,
} from "@app/hooks/school-structure";
import { useManyUsersStatus } from "@app/hooks/user-status";
import { useClassroomsUser, useUserShortInfo } from "@app/hooks/users";
import { UserListIcon } from "@app/icons";
import { inject } from "@app/modules";
import { headmasterLinks } from "@app/routes/headmaster/link";
import { haveCommonValue, uniquelize } from "@app/utils/array";
import { flatten } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import SettingsIcon from "@material-ui/icons/Settings";
import { classNamed } from "@pckgs/classed-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SetState } from "react-prop-hooks";
import { BoxesGrid, HeadmasterFrame } from "..";
import { useSubject } from "../../../../hooks/subjects";
import { Subject } from "../../../../models/subject";
import { HeadmasterArchive, HeadmasterBox } from "../box";
import { HeadmasterGroupBoxMarkup } from "../directorate-box";
export const HeadmasterWithClassroomsMainPage = React.memo(
	// eslint-disable-next-line max-lines-per-function
	function HeadmasterWithClassroomsMainPage() {
		const selectedLabelId = useSelectedSchoolLabelId();
		const gotoAddClassroom = useGoToUrl(headmasterLinks.classrooms.create);
		const user = useClassroomsUser()!;
		const [teachersAndClassrooms, setTeachersAndClassrooms] =
			useSettableTeachersByScholId({
				schoolId: user.school,
				schoolLabelId: selectedLabelId,
			});
		const [subjects, setSubjects] = useState<Subject[]>();
		useEffect(() => {
			if (teachersAndClassrooms) {
				const classrooms = flatten(
					teachersAndClassrooms.map((e) => e.classrooms)
				);
				const subjectIds = uniquelize(
					classrooms.map((x) => x.subjects[0])
				);
				inject("SubjectsController")
					.getManyByIds(subjectIds)
					.then((data) => setSubjects(data));
			}
		}, [teachersAndClassrooms]);
		const schoolStructure = useSchoolStructureOfCurrentUser();
		const ownLabelIds = useMemo(() => user.getOwnLabelIds(), [user]);
		const hasEditPermission =
			!schoolStructure.isFound ||
			(!!selectedLabelId &&
				!!schoolStructure.isFound &&
				haveCommonValue(
					ownLabelIds || [],
					schoolStructure.doc
						?.getAncestorLabels(selectedLabelId)
						.map((e) => e._id)
						.concat([selectedLabelId])
				));
		const onArchive = useMemo(
			() => getArchiveHandler(setTeachersAndClassrooms),
			[setTeachersAndClassrooms]
		);
		const gotoList = useGoToUrl(headmasterLinks.users.list);

		const gotoTeacherAdd = useGoToUrl(headmasterLinks.users.teachers.add);

		if (!teachersAndClassrooms) {
			return <FancyLoadingCenter />;
		}

		const classrooms = flatten(
			teachersAndClassrooms.map((e) => e.classrooms)
		).filter((e) => !e.isArchived);

		const getClassrooms = (cls: IClassroom[]) => {
			return [...cls]
				.sort(sortByClassTimes)
				.map((classroom) => (
					<HeadmasterClassroomBox
						classroom={classroom}
						key={classroom._id}
						onArchive={onArchive}
						canEdit={hasEditPermission}
					/>
				));
		};
		if (schoolStructure.isFound && !selectedLabelId) {
			return null;
		}
		const getSubjectNames = (id: ObjectId) => {
			return subjects?.filter((x) => x._id === id)[0]?.name;
		};
		const getSuperMindsGroup = classrooms.filter((x) => {
			return getSubjectNames(x.subjects[0])?.includes("Super Minds");
		});
		const getOtherGroups = classrooms.filter(
			(x) => !getSubjectNames(x.subjects[0])?.includes("Super Minds")
		);

		const additionalBoxes = (
			<>
				{hasEditPermission && (
					<HeadmasterBox
						onClick={gotoAddClassroom}
						title={<FormattedMessage id="createClassroom" />}
						img={<CRT style={{ height: 120 }} />}
					/>
				)}
				{hasEditPermission && (
					<HeadmasterBox
						onClick={gotoList}
						title="სია"
						img={<UserListIcon style={{ height: 120 }} />}
					/>
				)}
				{hasEditPermission && (
					<HeadmasterArchive
						goto={headmasterLinks.archived.classrooms(null)}
					/>
				)}
				{/* {hasEditPermission && (
					<HeadmasterBox
						onClick={gotoTeacherAdd}
						title="მასწავლებლის დამატება"
						img={
							<img
								src={"/imgs/add-user.svg"}
								style={{ marginLeft: 21.5 }}
							/>
						}
					/>
				)} */}
			</>
		);
		return (
			<HeadmasterFrame
				belowTitle={<SubLabelsSwitcher />}
				hideTimetable={!hasEditPermission}
				topTitle={!hasEditPermission ? "დასასწრები ჯგუფები" : undefined}
				afterChildrenContainer={
					getSuperMindsGroup.length > 0 ? (
						<>
							<Separator />
							<BoxesGrid>
								{getClassrooms(getOtherGroups)}
								{additionalBoxes}
							</BoxesGrid>
						</>
					) : null
				}
			>
				{getSuperMindsGroup.length > 0
					? getClassrooms(getSuperMindsGroup)
					: getClassrooms(classrooms)}

				{getSuperMindsGroup.length > 0 ? null : additionalBoxes}
			</HeadmasterFrame>
		);
	}
);

const Separator = classNamed("div")("block").extendStyled({
	width: "96%",
	backgroundColor: "#626A84",
	height: "2px",
	margin: "10px auto",
});

export const SubLabelsSwitcher = React.memo(function SubLabelsSwitcher() {
	const schoolStructure = useSchoolStructureOfCurrentUser();
	const labels = useLowLevelAccessibleSchoolLabels();
	const selectedLabelId = useSelectedSchoolLabelId();
	const setLabel = useSelectedSchoolLabelSetterFn();
	if (!schoolStructure.doc || !labels) return null;
	const options = labels.map((e) => ({ value: e._id, label: e.name }));
	return (
		<div className="flex justify-center">
			<TabNav
				options={options}
				value={selectedLabelId}
				onChange={({ value }) => setLabel(value)}
				whiteSelectedOption={true}
			/>
		</div>
	);
});

export const sortByClassTimes = (a: IClassroom, b: IClassroom) => {
	const minutes = ({ day, start: { hour, minute } }) =>
		day * 24 * 60 + hour * 60 + minute;

	const getMin = (classTimes: IClassroom["classTimes"]) =>
		classTimes.reduce(
			(min, o) => Math.min(min, minutes(o)),
			Number.MAX_VALUE
		);

	return getMin(a.classTimes) - getMin(b.classTimes);
};

export const getArchiveHandler =
	(setTeachersAndClassrooms: SetState<IRGETTeachersBySchoolsIds | null>) =>
	({ classroomId, archive }: { classroomId: ObjectId; archive: boolean }) => {
		return openConfirmationPopup({
			text: `ნამდვილად გსურთ ჯგუფის ${
				!archive ? "ამოარქივება" : "დაარქივება"
			}?`,
			rejectTitle: getFormattedMessage("no"),
			approveTitle: getFormattedMessage("yes"),
			displayRejectButtonAsPrimary: true,
			onApprove: () =>
				inject("ClassroomsController")
					.update({
						_id: classroomId,
						isArchived: archive,
					})
					.then(() => {
						setTeachersAndClassrooms((data) => {
							if (!data) return data;
							return updateSync({ data, classroomId, archive });
						});
					}),
		});
	};

const updateSync = ({
	data,
	classroomId,
	archive,
}: {
	data: IRGETTeachersBySchoolsIds;
	classroomId: ObjectId;
	archive: boolean;
}) => {
	return data.map((each) => {
		const hasThisClassroom = each.classrooms.some(
			(e) => e._id === classroomId
		);
		if (!hasThisClassroom) return each;
		return {
			...each,
			classrooms: each.classrooms.map((classroom) =>
				classroom._id !== classroomId
					? classroom
					: {
							...classroom,
							isArchived: archive,
						}
			),
		};
	});
};

export const HeadmasterClassroomBox: React.FC<{
	classroom: IClassroom;
	onArchive: (args: { classroomId: ObjectId; archive: boolean }) => void;
	canEdit: boolean;
}> = React.memo(({ classroom, onArchive, canEdit }) => {
	const teacher = useUserShortInfo(classroom.teacherId);
	const gotoClassroom = useGoToUrl(
		headmasterLinks.classrooms.timetable({ classroomId: classroom._id })
	);

	const subject = useSubject(classroom.subjects[0] ?? null);

	const testStudentsCount = useTestUsersCount(classroom.studentIds);

	const top = (
		<HeadmasterTopPart
			classroom={classroom}
			onArchive={onArchive}
			canEdit={canEdit}
		/>
	);

	if (!subject.doc) return null;

	return (
		<HeadmasterGroupBoxMarkup
			title={classroom.name}
			studentsCount={classroom.studentIds.length}
			onClick={gotoClassroom}
			mentorName={teacher.doc?.getFullName() || ""}
			topPart={top}
			subject={{
				photo: subject.doc.photo || null,
				name: subject.doc.name,
			}}
			studentsCountRight={
				testStudentsCount !== null &&
				isStudentStatusVisible && (
					<span
						style={{
							fontSize: "0.7em",
							color:
								testStudentsCount > 0 ? "#d51920" : undefined,
						}}
					>
						{" "}
						({testStudentsCount} საცდ.)
					</span>
				)
			}
		/>
	);
});

const useTestUsersCount = (studentIds: number[] | null) => {
	const { statusByIds } = useManyUsersStatus({ userIds: studentIds });

	return useMemo(() => {
		if (!studentIds || !statusByIds) return null;
		let count = 0;
		for (const studentId of studentIds) {
			if (statusByIds[studentId] === UserTestStatus.TEST) count++;
		}
		return count;
	}, [statusByIds, studentIds]);
};

enum ClassroomSettingsOption {
	edit,
	archive,
}

const HeadmasterTopPart = React.memo<{
	classroom: IClassroom;
	onArchive: (args: { classroomId: ObjectId; archive: boolean }) => void;
	canEdit: boolean;
}>(function HeadmasterTopPart({ classroom, onArchive, canEdit }) {
	const gotoEdit = useGoToUrl(
		headmasterLinks.classrooms.edit({ classroomId: classroom._id })
	);
	const isArchived = classroom.isArchived;
	const settingsOptions = useMemo(() => {
		return [
			{
				value: ClassroomSettingsOption.edit,
				label: getFormattedMessage("teacher:editClassroom"),
			},
			{
				value: ClassroomSettingsOption.archive,
				label: isArchived ? "ამოარქვება" : "დაარქივება",
			},
		];
	}, [isArchived]);

	const onSelect = useCallback(
		({ value }: { value: ClassroomSettingsOption }) => {
			if (value === ClassroomSettingsOption.edit) {
				gotoEdit();
			} else if (value === ClassroomSettingsOption.archive) {
				onArchive({
					classroomId: classroom._id,
					archive: !isArchived,
				});
			}
		},
		[classroom._id, gotoEdit, isArchived, onArchive]
	);

	return (
		<div className="absolute w-full top-none left-none flex justify-between px-2 text-sm">
			<div
				className="overflow-hidden whitespace-no-wrap truncate pr-2 font-roboto-geo-nus"
				title={classroom.description}
			>
				{classroom.description}
			</div>
			{canEdit && (
				<span className="text-gray-500">
					<Settings
						icon={<SettingsIcon style={{ fontSize: 17 }} />}
						options={settingsOptions}
						onSelect={onSelect}
						optionsIconPosition="left"
					/>
				</span>
			)}
		</div>
	);
});
